import $ from "jquery";
import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  MerchantNumberListService,
  CountryListService,
  ToastHelperService,
  TabHelperService,
  SearchHelperService
} from "../shared/services";
import { CircularLoaderService } from "../shared/components/circular-loader/circular-loader.component";

export class MerchantNumberListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "merchantNumberListService",
    "$mdDialog",
    "gettextCatalog",
    "toastHelper",
    "circularLoader",
    "tabHelper",
    "searchHelperService",
    "$mdMedia",
    "$timeout",
    "$rootScope"
  ];

  constructor(
    private $scope: IMerchantNumberListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private merchantNumberListService: MerchantNumberListService,
    private $mdDialog: ng.material.IDialogService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService
  ) {
    tabHelper.currentTab = 6;

    $scope.merchantNumberListService = merchantNumberListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on(
      "merchantNumberListService.itemAdded",
      (
        event,
        merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
      ) => {
        $timeout(() => {
          $(`md-list div[name='${merchantNumber.number}']`)
            .velocity("scroll", {
              container: $("#container"),
              duration: 500,
              offset: -100
            })
            .velocity("callout.pulse");
        });
      }
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.merchantNumberListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The merchant number list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The merchant number list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./merchant-number-create.html"),
      controller: "merchantNumberCreateController",
      controllerAs: "merchantNumberCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    });
  }

  edit(
    $event: MouseEvent,
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ) {
    this.$mdDialog.show({
      template: require("./merchant-number-edit.html"),
      controller: "merchantNumberEditController",
      controllerAs: "merchantNumberEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        companyid: this.$stateParams.companyid,
        merchantNumber
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  delete(
    $event: MouseEvent,
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm Merchant Number Deletion"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to delete the merchant number <i>{{merchantnumber}}</i>?",
          { merchantnumber: merchantNumber.number }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Delete"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      this.merchantNumberListService.delete(merchantNumber).then(
        success => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{merchantnumber}} was successfully deleted.",
              { merchantnumber: merchantNumber.number }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{merchantnumber}} could not be deleted at this time.",
                { merchantnumber: merchantNumber.number }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
    });
  }

  toggleMerchantNumberStatus(
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ) {
    const request: webserviceModels.Common.Request.MerchantNumber.patchmerchantnumber = {
      merchantnumber: {
        status: merchantNumber.status === "active" ? "inactive" : "active"
      }
    };

    this.merchantNumberListService.patch(merchantNumber.number, request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "{{merchantnumber}} was successfully updated.",
            { merchantnumber: merchantNumber.number }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{merchantnumber}} could not be updated at this time.",
              { merchantnumber: merchantNumber.number }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  generateKey(
    $event: MouseEvent,
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm Key Change"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to change the key for merchant number <i>{{merchantnumber}}</i>?",
          { merchantnumber: merchantNumber.number }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Generate New Key"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      let key = "";
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (let i = 0; i < 32; i++) {
        key += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      const request: webserviceModels.Common.Request.MerchantNumber.updatemerchantnumber = {
        merchantnumber: {
          countrycode: merchantNumber.countrycode,
          status: merchantNumber.status,
          failedcallbackemail: merchantNumber.failedcallbackemail,
          md5key: key,
          type: merchantNumber.type,
          uniquereferencetimeout: merchantNumber.uniquereferencetimeout
        } as any
      };
      this.merchantNumberListService
        .update(merchantNumber.number, request)
        .then(
          success => {
            this.toastHelper.toast(
              this.gettextCatalog.getString(
                "A new key was successfully generated for {{merchantnumber}}.",
                { merchantnumber: merchantNumber.number }
              ),
              this.gettextCatalog.getString("Dismiss")
            );
          },
          error => {
            this.toastHelper.toast(
              error ||
                this.gettextCatalog.getString(
                  "A new key could not be generated for {{merchantnumber}} at this time.",
                  { merchantnumber: merchantNumber.number }
                ),
              this.gettextCatalog.getString("Dismiss")
            );
          }
        );
    });
  }
}

export interface IMerchantNumberListScope extends ng.IScope {
  merchantNumberListController: MerchantNumberListController;
  merchantNumberListService: MerchantNumberListService;
  searchHelperService: SearchHelperService;
}

export function AnimateWidth(
  $animateCss: ng.animate.IAnimateCssService,
  $timeout: ng.ITimeoutService
): any {
  const animateIn = (element: JQuery, doneFn) => {
    element.css({
      display: "none",
      "max-width": ""
    });

    const width = element.width();

    element.css({
      display: "",
      "max-width": "0",
      "overflow-x": "hidden"
    });

    element.velocity(
      {
        translateZ: "0", // for HW acceleration
        "max-width": width,
        scaleX: [1, 0]
      },
      width / Math.log(width) + 200,
      "ease-in-out",
      () => {
        doneFn();
        element.css({
          "overflow-x": "",
          "max-width": "",
          transform: ""
        });
      }
    );
  };

  const animateOut = (element: JQuery, doneFn) => {
    const width = element.width();

    element.css({
      "max-width": width + "px",
      "overflow-x": "hidden"
    });

    element.velocity(
      {
        translateZ: "0", // for HW acceleration
        "max-width": "0",
        scaleX: [0, 1]
      },
      width / Math.log(width) + 200,
      "ease-in-out",
      () => {
        doneFn();
        element.css({
          "overflow-x": ""
        });
      }
    );
  };

  return {
    enter: animateIn,
    leave: animateOut,
    addClass: (element: JQuery, className: string, doneFn) => {
      animateOut(element, doneFn);
    },
    removeClass: (element: JQuery, className: string, doneFn) => {
      animateIn(element, doneFn);
    }
  };
}
AnimateWidth.$inject = ["$animateCss", "$timeout"];

import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  AgreementGroupListService,
  AcquirerListService,
  CurrencyListService,
  PaymentCollectionListService,
  ToastHelperService
} from "../shared/services";
import { IAgreementGroup } from "../shared/interfaces";
import { IPaymentTypeFeeListScope } from "./payment-type-fee-list.controller";

export interface IPaymentTypeFeeCreateScope extends IPaymentTypeFeeListScope {
  agreementGroup: IAgreementGroup;
  paymentGroup: webserviceModels.Data.Response.ListPaymentTypes.paymentgroup;
  paymentTypeIsTaken: (agreementId: string, paymentTypeId: number) => boolean;
  agreementIsTaken: (agreementId: string) => boolean;
  request: webserviceModels.Reseller.Request.PaymentTypeFee.addpaymenttypefee;
  common: any;
  ranges: any;
  showSecurityLevel: boolean;
  agreementGroupListService: AgreementGroupListService;
  currencyListService: CurrencyListService;
}

export class PaymentTypeFeeCreateController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "agreementGroupListService",
    "acquirerListService",
    "newPaymentTypeFee",
    "agreementGroup",
    "currencyListService",
    "paymentCollectionListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IPaymentTypeFeeCreateScope,
    private $mdDialog: ng.material.IDialogService,
    private agreementGroupListService: AgreementGroupListService,
    private acquirerListService: AcquirerListService,
    private newPaymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee,
    private agreementGroup: IAgreementGroup,
    private currencyListService: CurrencyListService,
    private paymentCollectionListService: PaymentCollectionListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.agreementGroupListService = agreementGroupListService;
    $scope.currencyListService = currencyListService;
    $scope.agreementGroup = agreementGroup;

    this.selectedCurrency = agreementGroup.currencyCode;

    $scope.paymentGroup = Enumerable.from(
      paymentCollectionListService.paymentCollections
    )
      .selectMany(
        (
          paymentCollection: webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
        ) => paymentCollection.paymentgroups
      )
      .first(
        paymentGroup =>
          paymentGroup.id === newPaymentTypeFee.paymenttype.groupid
      );

    $scope.showSecurityLevel =
      [1, 2, 3, 4].indexOf($scope.paymentGroup.id) > -1;

    $scope.paymentTypeIsTaken = (
      agreementId: string,
      paymentTypeId: number
    ): boolean => {
      return Enumerable.from(agreementGroup.paymentTypeFees).any(
        paymentTypeFee =>
          paymentTypeFee.agreementid === agreementId &&
          paymentTypeFee.paymenttype.id === paymentTypeId
      );
    };

    $scope.agreementIsTaken = (agreementId: string): boolean => {
      return Enumerable.from(
        $scope.paymentGroup.paymenttypes
      ).all(paymentType =>
        Enumerable.from(agreementGroup.paymentTypeFees).any(
          paymentTypeFee =>
            paymentTypeFee.agreementid === agreementId &&
            paymentTypeFee.paymenttype.id === paymentType.id
        )
      );
    };

    const defaultAgreement = Enumerable.from(
      agreementGroup.agreements
    ).firstOrDefault(agreement => !$scope.agreementIsTaken(agreement.id));

    const defaultPaymentType = Enumerable.from(
      $scope.paymentGroup.paymenttypes
    ).firstOrDefault(
      paymentType =>
        !$scope.paymentTypeIsTaken(defaultAgreement.id, paymentType.id)
    );

    $scope.request = {
      paymenttypefee: {
        agreementid: defaultAgreement ? defaultAgreement.id : null,
        paymenttypeid: defaultPaymentType ? defaultPaymentType.id : null,
        feecalculatorname: "NoFee",
        feedata: {
          common: [],
          ranges: []
        },
        addfee: "Default",
        order: Enumerable.from(
          Enumerable.from(agreementGroupListService.agreementGroups).first(
            aG => aG === agreementGroup
          ).paymentTypeFees
        ).indexOf(newPaymentTypeFee),
        filters: []
      }
    };

    $scope.common = {
      CurrencyCode: Enumerable.from(currencyListService.currencies).first(
        currency => currency.code === agreementGroup.currencyCode
      ).code,
      Rounding: "Ceiling",
      Type: "FeeFee"
    };

    $scope.ranges = [];

    ($scope as any).$watch(
      () => $scope.request.paymenttypefee.agreementid,
      (newValue: string) => {
        if (
          $scope.paymentTypeIsTaken(
            newValue,
            $scope.request.paymenttypefee.paymenttypeid
          )
        ) {
          const firstValidPaymentType = Enumerable.from(
            $scope.paymentGroup.paymenttypes
          ).firstOrDefault(
            paymentType => !$scope.paymentTypeIsTaken(newValue, paymentType.id)
          );

          if (firstValidPaymentType) {
            $scope.request.paymenttypefee.paymenttypeid =
              firstValidPaymentType.id;
          }
        }
      }
    );
  }

  // tslint:disable-next-line:member-ordering
  selectedCurrency: string;

  addRange() {
    this.$scope.ranges.push({});
    $("md-dialog-content.dynamic-dialog-content").animate(
      {
        scrollTop: $("md-content.dynamic-dialog-content").height()
      },
      200
    );
  }

  removeRange(range: any) {
    this.$scope.ranges.splice(this.$scope.ranges.indexOf(range), 1);
  }

  submit(
    request: webserviceModels.Reseller.Request.PaymentTypeFee.addpaymenttypefee,
    common: any = {},
    ranges: any = {}
  ) {
    switch (request.paymenttypefee.feecalculatorname) {
      case "NoFee": {
        common = {
          CurrencyCode: common.CurrencyCode
        };
        break;
      }
      case "FixedFee": {
        common = {
          CurrencyCode: common.CurrencyCode,
          DefaultFixedFee: common.DefaultFixedFee
        };
        break;
      }
      case "PercentageWiseFee": {
        common = {
          DefaultPercentage: common.DefaultPercentage,
          DefaultFixedFee: common.DefaultFixedFee,
          DefaultMinimumFee: common.DefaultMinimumFee,
          DefaultMaximumFee: common.DefaultMaximumFee,
          Rounding: common.Rounding,
          Type: common.Type,
          CurrencyCode: common.CurrencyCode
        };
        break;
      }
    }

    request.paymenttypefee.feedata.common = $.map(common, (value, index) => {
      return { key: index, value } as webserviceModels.Common.input;
    });

    request.paymenttypefee.feedata.ranges = [];

    ranges.forEach(range => {
      request.paymenttypefee.feedata.ranges.push({
        range: $.map(angular.copy(range), (value, index) => {
          return { key: index, value } as webserviceModels.Common.input;
        })
      });
    });

    const replacePos = Enumerable.from(
      this.agreementGroup.paymentTypeFees
    ).indexOf(this.newPaymentTypeFee);

    this.agreementGroupListService
      .createPaymentTypeFee(request, replacePos)
      .then(
        success => {
          this.$mdDialog.hide();
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "The payment type was successfully added."
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "The payment type could not be added at this time."
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  cancel() {
    this.agreementGroup.paymentTypeFees.splice(
      this.agreementGroup.paymentTypeFees.indexOf(this.newPaymentTypeFee),
      1
    );

    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

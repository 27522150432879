import * as Enumerable from "linq";

export interface IErrorScope extends ng.IScope {
  error: IApplicationError;
}

export interface IApplicationError {
  code: number;
  title: string;
  detailedMessage: string;
  stackTrace?: string;
}

export class ErrorController {
  static $inject = ["$scope", "$stateParams", "gettextCatalog"];

  constructor(
    private $scope: IErrorScope,
    private $stateParams: ng.ui.IStateParamsService,
    private gettextCatalog: any
  ) {
    let error = Enumerable.from(this.errors).firstOrDefault(
      errorX => errorX.code === $stateParams.errorCode,
      null
    );

    if (error === null) {
      error = Enumerable.from(this.errors).first(errorX => errorX.code === 0);
    }

    $scope.error = error;
  }

  // tslint:disable-next-line:member-ordering
  errors: Array<IApplicationError> = [
    {
      code: 0,
      title: this.gettextCatalog.getString("Generic Error"),
      detailedMessage: null
    },
    {
      code: 1,
      title: this.gettextCatalog.getString("Initialization Error"),
      detailedMessage: this.gettextCatalog.getString(
        "The application could not be initialized."
      )
    }
  ];
}

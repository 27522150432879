import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import { DomainListService, ToastHelperService } from "../shared/services";

export class DomainEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "domainListService",
    "domain",
    "gettextCatalog",
    "toastHelper",
    "punycodeFilter"
  ];

  constructor(
    private $scope: IDomainEditScope,
    private $mdDialog: ng.material.IDialogService,
    private domainListService: DomainListService,
    private domain: webserviceModels.Common.Response.Domain.domain,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private punycodeFilter: (...args) => string
  ) {
    $scope.domainListService = domainListService;
    $scope.domain = domain;

    $scope.request = {
      domain: angular.copy(domain)
    };
  }

  submit(request: webserviceModels.Common.Request.Domain.updatedomain) {
    this.domainListService.update(this.domain.id, request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "{{domainname}} was successfully updated.",
            {
              domainname: this.punycodeFilter(request.domain.name, "toUnicode")
            }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{domainname}} could not be updated at this time.",
              {
                domainname: this.punycodeFilter(
                  request.domain.name,
                  "toUnicode"
                )
              }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface IDomainEditScope extends ng.IScope {
  request: webserviceModels.Common.Request.Domain.updatedomain;
  domainListService: DomainListService;
  domain: webserviceModels.Common.Response.Domain.domain;
}

import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService, DataService } from "../clients";
import { WebStorageService } from "../model";

export class MerchantNumberListService {
  // tslint:disable-next-line:variable-name
  private _merchantNumbers: Array<
    webserviceModels.Common.Response.MerchantNumber.merchantnumber
  >;
  // tslint:disable-next-line:variable-name
  private _defaultMerchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber;
  // tslint:disable-next-line:variable-name
  private _selectedMerchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber;
  private companyid: string;
  private _availableMerchantFeatures: Array<
    webserviceModels.Data.Response.ProductFeatures.productfeature
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "resellerService",
    "$q",
    "webStorageService",
    "$rootScope",
    "dataService"
  ];

  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService,
    private webStorageService: WebStorageService,
    private $rootScope: ng.IRootScopeService,
    private dataService: DataService
  ) {}

  get merchantNumbers(): Array<
    webserviceModels.Common.Response.MerchantNumber.merchantnumber
  > {
    if (this._merchantNumbers) return this._merchantNumbers;
    throw new ReferenceError(
      "The merchant number list has not been initialized."
    );
  }

  get availableMerchantFeatures(): Array<
    webserviceModels.Data.Response.ProductFeatures.productfeature
  > {
    if (this._availableMerchantFeatures) return this._availableMerchantFeatures;
    throw new ReferenceError(
      "The merchant feature list has not been initialized."
    );
  }

  get defaultMerchantNumber(): webserviceModels.Common.Response.MerchantNumber.merchantnumber {
    if (this._defaultMerchantNumber) return this._defaultMerchantNumber;
    throw new ReferenceError(
      "The merchant number list has not been initialized."
    );
  }

  get selectedMerchantNumber(): webserviceModels.Common.Response.MerchantNumber.merchantnumber {
    return this._selectedMerchantNumber || this.defaultMerchantNumber;
  }

  set selectedMerchantNumber(
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ) {
    (this.webStorageService.$storage as any).merchantNumberSelections =
      (this.webStorageService.$storage as any).merchantNumberSelections || {};
    (this.webStorageService.$storage as any).merchantNumberSelections[
      this.companyid
    ] = merchantNumber ? merchantNumber.number : null;
    this._selectedMerchantNumber = merchantNumber;
  }

  setSelectedMerchantNumber(merchantNumber: string) {
    this.selectedMerchantNumber =
      Enumerable.from(this.merchantNumbers).firstOrDefault(
        mN => mN.number === merchantNumber,
        null
      ) || this.selectedMerchantNumber;
  }

  load(
    companyid: string,
    selectedMerchantNumber?: webserviceModels.Common.Response.MerchantNumber.merchantnumber,
    forceReload: boolean = false
  ): ng.IPromise<any> {
    return this.loadMerchants(
      companyid,
      selectedMerchantNumber,
      forceReload
    ).then(() => this.loadAvailableMerchantFeatures(forceReload));
  }

  private loadAvailableMerchantFeatures(
    forceReload: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._availableMerchantFeatures) {
      deferred.resolve();
    } else {
      const response = this.dataService.listproductfeatures("merchant");

      response.then(
        success => {
          if (success.meta.result) {
            this._availableMerchantFeatures = success.features;
            deferred.resolve();
          } else {
            deferred.reject(success.meta);
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  private loadMerchants(
    companyid: string,
    selectedMerchantNumber?: webserviceModels.Common.Response.MerchantNumber.merchantnumber,
    forceReload: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this.companyid === companyid) {
      deferred.resolve();
    } else {
      const response = this.resellerService.listmerchantnumbers(companyid);

      response.then(
        success => {
          if (success.meta.result) {
            this._merchantNumbers = success.merchantnumbers;
            this.companyid = companyid;

            // Set the default merchant number:
            this._defaultMerchantNumber =
              Enumerable.from(this._merchantNumbers).firstOrDefault(
                x => x.type === "production",
                null
              ) || Enumerable.from(this._merchantNumbers).firstOrDefault(null); // Take first production number. // If there is no production number, just take any number.

            // Set the selected merchant number:
            if (selectedMerchantNumber) {
              // from URL:
              this.selectedMerchantNumber = Enumerable.from(
                this._merchantNumbers
              ).firstOrDefault(
                merchantNumber => merchantNumber === selectedMerchantNumber,
                null
              );
            } else {
              // if not in URL, try from webstorage:
              try {
                this.selectedMerchantNumber = Enumerable.from(
                  this._merchantNumbers
                ).firstOrDefault(
                  merchantNumber =>
                    merchantNumber.number ===
                    (this.webStorageService.$storage as any)
                      .merchantNumberSelections[companyid],
                  null
                );
              } catch (e) {
                this.selectedMerchantNumber = null;
              }
            }

            // Resolve if default merchant number exists:
            if (
              Enumerable.from(this._merchantNumbers).any(
                merchantNumber => merchantNumber === this._defaultMerchantNumber
              )
            ) {
              deferred.resolve();
            } else {
              try {
                deferred.reject(success.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._merchantNumbers) {
      return this.load(this.companyid, this.selectedMerchantNumber, true);
    }

    throw new ReferenceError(
      "The merchant number list has not been initialized."
    );
  }

  create(
    request: webserviceModels.Common.Request.MerchantNumber.addmerchantnumber
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.merchantnumber
  > {
    if (!this._merchantNumbers) {
      throw new ReferenceError(
        "The merchant number list has not been initialized."
      );
    }

    const deferred = this.$q.defer<
      webserviceModels.Common.Response.MerchantNumber.merchantnumber
    >();
    const response = this.resellerService.addmerchantnumber(
      this.companyid,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          this._merchantNumbers.push(success.merchantnumber);
          this.$rootScope.$broadcast(
            "merchantNumberListService.itemAdded",
            success.merchantnumber
          );
          deferred.resolve(success.merchantnumber);
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  update(
    merchantNumber: string,
    request: webserviceModels.Common.Request.MerchantNumber.patchmerchantnumber
  ): ng.IPromise<any> {
    if (!this._merchantNumbers) {
      throw new ReferenceError(
        "The merchant number list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.patchmerchantnumber(
      this.companyid,
      merchantNumber,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.extend(
            Enumerable.from(this._merchantNumbers).first(
              mN => mN.number === merchantNumber
            ),
            success.merchantnumber
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  patch(
    merchantNumber: string,
    request: webserviceModels.Common.Request.MerchantNumber.patchmerchantnumber
  ): ng.IPromise<any> {
    if (!this._merchantNumbers) {
      throw new ReferenceError(
        "The merchant number list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.patchmerchantnumber(
      this.companyid,
      merchantNumber,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.extend(
            Enumerable.from(this._merchantNumbers).first(
              mN => mN.number === merchantNumber
            ),
            success.merchantnumber
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ): ng.IPromise<any> {
    if (!this._merchantNumbers) {
      throw new ReferenceError(
        "The merchant number list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.deletemerchantnumber(
      this.companyid,
      merchantNumber.number
    );

    response.then(
      success => {
        if (success.meta.result) {
          this._merchantNumbers = Enumerable.from(this._merchantNumbers)
            .except([merchantNumber])
            .toArray();
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

export default MerchantNumberListService;

import { PermissionService } from "../services";
import { WebserviceType } from "../enums/webservice-types";

export class RequiresPermission implements ng.IDirective {
  static $inject = ["permissionService"];
  constructor(private permissionService: PermissionService) {}

  // tslint:disable:member-ordering
  restrict = "A";
  scope = {
    requiresPermission: "@",
    ifRestricted: "@"
  };

  link: ng.IDirectiveLinkFn = (
    scope: any,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    const args: Array<string> = scope.requiresPermission.split("#");
    const hasPermission = this.permissionService.hasPermission(
      WebserviceType[args[0]],
      args[1]
    );

    if (!hasPermission) {
      switch (scope.ifRestricted) {
        case "hide":
          instanceElement.hide();
          break;
        case "disable":
          instanceElement.prop("disabled", "disabled").off();
          break;
        case "disable-list":
          instanceElement.children().prop("disabled", "disabled");
          break;
        case "unbind":
          instanceElement.off();
          break;
      }
    }
  };
}

import moment from "moment";

export function DateFormatFilter() {
  return (input: string, action: string) => {
    input = input || "";
    try {
      return moment(input).calendar(null, {
        sameDay: "[Today at] hh:mm a",
        nextDay: "[Tomorrow at] hh:mm a",
        nextWeek: "[Next] dddd [at] hh:mm a",
        lastDay: "[Yesterday at] hh:mm a",
        lastWeek: "[Last] dddd [at] hh:mm a",
        sameElse: "YYYY-MM-DD [at] hh:mm a"
      });
    } catch (e) {
      return "";
    }
  };
}

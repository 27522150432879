export function AnimateHeightScale(): any {
  const animateIn = (element: JQuery, doneFn) => {
    element.css({
      display: "none",
      "max-height": ""
    });

    const height = element.height();

    element.css({
      display: "",
      "overflow-y": "hidden",
      "transform-origin": "10% 20%"
    });

    element.velocity(
      {
        translateZ: 0, // for HW acceleration
        "max-height": [height, 0],
        scaleX: [1, 0],
        scaleY: [1, 0]
      },
      height / Math.log(height) + 200,
      "ease-in-out",
      () => {
        doneFn();
        element.css({
          "overflow-y": "",
          "max-height": "",
          transform: "",
          "transform-origin": ""
        });
      }
    );
  };

  const animateOut = (element: JQuery, doneFn) => {
    const height = element.height();

    element.css({
      "overflow-y": "hidden",
      "transform-origin": "10% 20%"
    });

    element.velocity(
      {
        translateZ: 0, // for HW acceleration
        "max-height": [0, height],
        scaleX: [0, 1],
        scaleY: [0, 1]
      },
      height / Math.log(height) + 200,
      "ease-in-out",
      () => {
        doneFn();
        element.css({
          "overflow-y": "",
          "transform-origin": ""
        });
      }
    );
  };

  return {
    enter: animateIn,
    leave: animateOut,
    addClass: (element: JQuery, className: string, doneFn) => {
      animateOut(element, doneFn);
    },
    removeClass: (element: JQuery, className: string, doneFn) => {
      animateIn(element, doneFn);
    }
  };
}

export function animateHeight(): any {
  const bezier = [0.25, 0.8, 0.25, 1];

  const animateIn = (element: JQuery, doneFn) => {
    const height = element.outerHeight();
    element.velocity("slideDown", {
      duration: height / Math.log(height) + 300,
      complete: () => {
        element.css("height", "");
        doneFn();
      },
      easing: bezier
    });
  };

  const animateOut = (element: JQuery, doneFn) => {
    const height = element.outerHeight();
    element.velocity("slideUp", {
      duration: height / Math.log(height) + 300,
      complete: () => {
        element.css("height", "");
        doneFn();
      },
      easing: bezier
    });
  };

  return {
    enter: animateIn,
    leave: animateOut,
    addClass: (element: JQuery, className: string, doneFn) => {
      className === "animate-height"
        ? animateIn(element, doneFn)
        : animateOut(element, doneFn);
    },
    removeClass: (element: JQuery, className: string, doneFn) => {
      className === "animate-height"
        ? animateOut(element, doneFn)
        : animateIn(element, doneFn);
    }
  };
}

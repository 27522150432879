import * as endpoints from "../../../app.endpoints";
import * as webserviceModels from "../../interfaces/webservice-models";
import ImpersonateService from "../model/impersonate.service";

export class MerchantService {
  static $inject: Array<string> = ["$http", "impersonateService"];
  constructor(
    private $http: ng.IHttpService,
    private impersonateService: ImpersonateService
  ) {}

  // tslint:disable-next-line:member-ordering
  private endpoint: string = endpoints.merchant;

  private get config(): ng.IRequestShortcutConfig {
    if (!this.impersonateService.authenticationToken) return null;

    return {
      headers: {
        Authorization: `Basic ${this.impersonateService.authenticationToken}`
      }
    };
  }

  adddomain(
    request: webserviceModels.Common.Request.Domain.adddomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.adddomainresponse> {
    return this.$http
      .post(`${this.endpoint}/domains`, request, this.config)
      .then<any>(response => response.data);
  }

  getdomain(
    domainid: string
  ): ng.IPromise<webserviceModels.Common.Response.Domain.getdomainresponse> {
    return this.$http
      .get(`${this.endpoint}/domains/${domainid}`, this.config)
      .then<any>(response => response.data);
  }

  updatedomain(
    domainid: string,
    request: webserviceModels.Common.Request.Domain.updatedomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.updatedomainresponse> {
    return this.$http
      .put(`${this.endpoint}/domains/${domainid}`, request, this.config)
      .then<any>(response => response.data);
  }

  patchdomain(
    domainid: string,
    request: webserviceModels.Common.Request.Domain.patchdomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.patchdomainresponse> {
    return this.$http
      .patch(`${this.endpoint}/domains/${domainid}`, request, this.config)
      .then<any>(response => response.data);
  }

  deletedomain(
    domainid: string
  ): ng.IPromise<webserviceModels.Common.Response.Domain.deletedomainresponse> {
    return this.$http
      .delete(`${this.endpoint}/domains/${domainid}`, this.config)
      .then<any>(response => response.data);
  }

  listdomains(): ng.IPromise<
    webserviceModels.Common.Response.Domain.listdomainsresponse
  > {
    return this.$http
      .get(`${this.endpoint}/domains`, this.config)
      .then<any>(response => response.data);
  }

  listmerchantnumbers(): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.listmerchantnumbersresponse
  > {
    return this.$http
      .get(`${this.endpoint}/merchantnumbers`, this.config)
      .then<any>(response => response.data);
  }

  getmerchantnumber(
    merchantnumber: string
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.getmerchantnumberresponse
  > {
    return this.$http
      .get(`${this.endpoint}/merchantnumbers/${merchantnumber}`, this.config)
      .then<any>(response => response.data);
  }

  updatemerchantnumber(
    merchantnumber: string,
    request: webserviceModels.Common.Request.MerchantNumber.updatemerchantnumber
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.updatemerchantnumberresponse
  > {
    return this.$http
      .put(
        `${this.endpoint}/merchantnumbers/${merchantnumber}`,
        request,
        this.config
      )
      .then<any>(response => response.data);
  }

  patchmerchantnumber(
    merchantnumber: string,
    request: webserviceModels.Common.Request.MerchantNumber.patchmerchantnumber
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.patchmerchantnumberresponse
  > {
    return this.$http
      .patch(
        `${this.endpoint}/merchantnumbers/${merchantnumber}`,
        request,
        this.config
      )
      .then<any>(response => response.data);
  }

  listtransactions(
    exclusivestartkey?: string,
    pagesize?: string,
    filters?: string,
    engine?: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Transaction.listtransactionsresponse
  > {
    return this.$http
      .get(
        `${this
          .endpoint}/transactions?exclusivestartkey=${exclusivestartkey}&pagesize=${pagesize}&filters=${filters}&engine=${engine}`,
        this.config
      )
      .then<any>(response => response.data);
  }

  gettransaction(
    transactionid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Transaction.gettransactionresponse
  > {
    return this.$http
      .get(`${this.endpoint}/transactions/${transactionid}`, this.config)
      .then<any>(response => response.data);
  }

  listpaymenttypes(
    currencycode: string,
    amount: string,
    cardnumberprefix: string,
    acquirername: string,
    paymenttypeid: string,
    paymentgroupid: string,
    paymentcollectionid: string,
    paymentcollectiontypes: string,
    supports: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.PaymentType.listpaymenttypesresponse
  > {
    return this.$http
      .get(`${this.endpoint}/paymenttypes`, this.config)
      .then<any>(response => response.data);
  }

  listpaymenttypefees(): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.listpaymenttypefeesresponse
  > {
    return this.$http
      .get(`${this.endpoint}/paymenttypefees`, this.config)
      .then<any>(response => response.data);
  }

  listtransactionoperations(
    transactionid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.TransactionOperation.listtransactionoperationsresponse
  > {
    return this.$http
      .get(
        `${this.endpoint}/transactions/${transactionid}/transactionoperations`,
        this.config
      )
      .then<any>(response => response.data);
  }

  gettransactionoperation(
    transactionid: string,
    transactionoperationid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.TransactionOperation.gettransactionoperationresponse
  > {
    return this.$http
      .get(
        `${this
          .endpoint}/transactions/${transactionid}/transactionoperations/${transactionoperationid}`,
        this.config
      )
      .then<any>(response => response.data);
  }

  listinvoices(
    transactionid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Invoice.listinvoicesresponse
  > {
    return this.$http
      .get(
        `${this.endpoint}/transactions/${transactionid}/invoices`,
        this.config
      )
      .then<any>(response => response.data);
  }

  getinvoice(
    transactionid: string,
    transactionoperationid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Invoice.getinvoiceresponse
  > {
    return this.$http
      .get(
        `${this
          .endpoint}/transactions/${transactionid}/invoices/${transactionoperationid}`,
        this.config
      )
      .then<any>(response => response.data);
  }

  listapiusers(): ng.IPromise<
    webserviceModels.Merchant.Response.ApiUser.listapiusersresponse
  > {
    return this.$http
      .get(`${this.endpoint}/apiusers`, this.config)
      .then<any>(response => response.data);
  }

  getapiuser(
    accesstoken: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.ApiUser.getapiuserresponse
  > {
    return this.$http
      .get(`${this.endpoint}/apiusers/${accesstoken}`, this.config)
      .then<any>(response => response.data);
  }

  addapiuser(
    request: webserviceModels.Merchant.Request.ApiUser.addapiuser
  ): ng.IPromise<
    webserviceModels.Merchant.Response.ApiUser.addapiuserresponse
  > {
    return this.$http
      .post(`${this.endpoint}/apiusers`, request, this.config)
      .then<any>(response => response.data);
  }

  updateapiuser(
    accesstoken: string,
    request: webserviceModels.Merchant.Request.ApiUser.updateapiuser
  ): ng.IPromise<
    webserviceModels.Merchant.Response.ApiUser.updateapiuserresponse
  > {
    return this.$http
      .put(`${this.endpoint}/apiusers/${accesstoken}`, request, this.config)
      .then<any>(response => response.data);
  }

  deleteaccesstomerchantnumber(
    accesstoken: string
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.deletemerchantnumberresponse
  > {
    return this.$http
      .delete(
        `${this.endpoint}/accesstomerchantnumbers/${accesstoken}`,
        this.config
      )
      .then<any>(response => response.data);
  }

  addinvitation(
    request: webserviceModels.Merchant.Request.Invitation.addinvitation
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Invitation.addinvitationresponse
  > {
    return this.$http
      .post(`${this.endpoint}/invitations`, request, this.config)
      .then<any>(response => response.data);
  }

  getinvitation(
    invitationid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Invitation.getinvitationresponse
  > {
    return this.$http
      .get(`${this.endpoint}/invitations/${invitationid}`, this.config)
      .then<any>(response => response.data);
  }

  acceptinvitation(
    invitationid: string
  ): ng.IPromise<
    webserviceModels.Merchant.Response.Invitation.acceptinvitationresponse
  > {
    return this.$http
      .put(
        `${this.endpoint}/invitations/${invitationid}/accept`,
        null,
        this.config
      )
      .then<any>(response => response.data);
  }

  listwebusers(): ng.IPromise<
    webserviceModels.Common.Response.WebUser.listwebusersresponse
  > {
    return this.$http
      .get(`${this.endpoint}/webusers`, this.config)
      .then<any>(response => response.data);
  }

  getwebuser(
    accesstoken: string
  ): ng.IPromise<webserviceModels.Common.Response.WebUser.getwebuserresponse> {
    return this.$http
      .get(`${this.endpoint}/webusers/${accesstoken}`, this.config)
      .then<any>(response => response.data);
  }

  addwebuser(
    request: webserviceModels.Merchant.Request.WebUser.addwebuser
  ): ng.IPromise<
    webserviceModels.Merchant.Response.WebUser.addwebuserresponse
  > {
    return this.$http
      .post(`${this.endpoint}/webusers`, request, this.config)
      .then<any>(response => response.data);
  }
}

export default MerchantService;

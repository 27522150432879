import { IResellerRootScopeService } from "../../interfaces/reseller-rootscope";
import * as webserviceModels from "../../interfaces/webservice-models";
import { LoginService } from "../clients";
import { WebStorageService } from "./";

export class AuthenticationService {
  // tslint:disable-next-line:variable-name
  private _userSession: IUserSession = null;

  // tslint:disable-next-line:member-ordering
  returnTo: IReturnOptions = {
    params: null,
    state: null
  };

  // tslint:disable-next-line:member-ordering
  static $inject: Array<string> = [
    "$http",
    "loginService",
    "webStorageService",
    "$location",
    "$q",
    "$state",
    "$rootScope"
  ];

  constructor(
    private $http: ng.IHttpService,
    private loginService: LoginService,
    private webStorageService: WebStorageService,
    private $location: ng.ILocationService,
    private $q: ng.IQService,
    private $state: ng.ui.IStateService,
    private $rootScope: IResellerRootScopeService
  ) {}

  isAuthenticated(): boolean {
    return !(this._userSession === null || this._userSession === undefined);
  }

  get userSession(): IUserSession {
    return this._userSession;
  }

  //first step
  loginsession(
    request: webserviceModels.Login.Request.logonrequest
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.logonresellersession(request).then(
      response => {
        if (response.meta.result && response.otptoken) {
          this.$rootScope.otpresponsedata = {
            otptoken: response.otptoken,
            configureauthenticator: response.configureauthenticator,
            otpsecretkey: response.otpsecretkey,
            email: request.email
          };
          this.$state.go("otp");
        } else {
          deferred.reject("Could not authenticate.");
        }
      },
      error => {
        deferred.reject(
          "The e-mail and password combination was not recognized."
        );
      }
    );

    return deferred.promise;
  }

  //second step
  loginsessionotp(
    request: webserviceModels.Login.Request.otprequest
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.logonresellersessionotp(request).then(
      response => {
        if (response.meta.result) {
          const userSession = this.getCredentials(response);
          this.authenticate(userSession).then(
            success => {
              if (this.returnTo.state) {
                if (this.returnTo.params) {
                  this.$state.go(this.returnTo.state, this.returnTo.params);
                } else this.$state.go(this.returnTo.state);
                this.returnTo.state = null;
              } else this.$state.go("companyList");

              deferred.resolve();
            },
            error => {
              deferred.reject("Could not get account permissions.");
            }
          );
        } else {
          deferred.reject("Could not authenticate.");
        }
      },
      error => {
        deferred.reject("The verification code is not correct.");
      }
    );

    return deferred.promise;
  }

  //forgot OTP
  forgototp(
    request: webserviceModels.Login.Request.forgototprequest
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.forgototpreseller(request).then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          deferred.reject(success.meta.message);
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  //reset OTP
  resetotp(
    request: webserviceModels.Login.Request.resetotprequest
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.resetotpreseller(request).then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          deferred.reject(success.meta.message);
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  logout() {
    this.unauthenticate().finally(() =>
      window.location.replace(this.$state.href("login"))
    );
  }

  forgotPassword(
    request: webserviceModels.Login.Request.forgotpassword
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.forgotpasswordreseller(request).then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          deferred.reject(success.meta.message);
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  resetPassword(
    request: webserviceModels.Login.Request.resetpassword
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.resetpasswordreseller(request).then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          deferred.reject(success.meta.message);
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  changeResellerNumber(
    resellerNumber: webserviceModels.Login.Response.resellernumber
  ) {
    this._userSession.selectedResellerNumber = resellerNumber.number;
    const authdata = btoa(
      this._userSession.accessToken +
        "@" +
        this._userSession.selectedResellerNumber +
        ":" +
        this._userSession.secretToken
    );
    this.$http.defaults.headers.common.Authorization = "Basic " + authdata;
    (this.webStorageService.$storage as any).user = this._userSession;
    window.location.replace(this.$state.href("companyList"));
  }

  private getCredentials(
    response: webserviceModels.Login.Response.logonresellerresponse
  ): IUserSession {
    return {
      selectedResellerNumber: response.resellernumbers[0].number,
      resellerNumbers: response.resellernumbers,
      accessToken: response.accesstoken,
      secretToken: response.secrettoken,
      email: response.email,
      expireDate: response.expiredate,
      languageCode: "" // TODO: change to browser language based.
    };
  }

  // tslint:disable-next-line:member-ordering
  authenticate(userSession: IUserSession): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const authdata = btoa(
      userSession.accessToken +
        "@" +
        userSession.selectedResellerNumber +
        ":" +
        userSession.secretToken
    );

    this._userSession = userSession;
    this.$http.defaults.headers.common.Authorization = "Basic " + authdata;
    (this.webStorageService.$storage as any).user = userSession;

    this.loginService
      .listfunctionpermissionsbyaccesstoken(userSession.accessToken)
      .then(
        success => {
          userSession.permissions = success.meta.result
            ? success.functionpermissions
            : null;

          if (userSession.permissions) {
            deferred.resolve();
          } else {
            this.unauthenticate().finally(() => {
              deferred.reject(success.meta.message);
            });
          }
        },
        error => {
          this.unauthenticate().finally(() => {
            deferred.reject("Your session has expired.");
          });
        }
      );

    return deferred.promise;
  }

  // tslint:disable-next-line:member-ordering
  unauthenticate(): ng.IPromise<any> {
    const deferred = this.$q.defer();

    this.loginService.logoffreseller().finally(() => {
      this._userSession = null;
      delete (this.webStorageService.$storage as any).user;
      this.$http.defaults.headers.common.Authorization = undefined;
      deferred.resolve();
    });

    return deferred.promise;
  }

  // tslint:disable-next-line:member-ordering
  authenticateByClientSideStorage(): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const userSession = (this.webStorageService.$storage as any).user;

    if (userSession) {
      this.authenticate(userSession).then(
        success => {
          deferred.resolve();
        },
        error => {
          deferred.reject();
        }
      );
    } else deferred.reject();

    return deferred.promise;
  }
}

export interface IUserSession {
  selectedResellerNumber: string;
  resellerNumbers: Array<{ number: string }>;
  accessToken: string;
  secretToken: string;
  email: string;
  expireDate: string;
  languageCode: string;
  permissions?: Array<
    webserviceModels.Login.Response.FunctionPermission.functionpermission
  >;
}

export interface IReturnOptions {
  state: ng.ui.IState;
  params?: any;
}

export default AuthenticationService;

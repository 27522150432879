import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  PaymentWindowListService,
  ToastHelperService
} from "../shared/services";

export class PaymentWindowCreateController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "paymentWindowListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IPaymentWindowCreateScope,
    private $mdDialog: ng.material.IDialogService,
    private paymentWindowListService: PaymentWindowListService,
    private gettextCatalog: ng.gettext.gettextCatalog,
    private toastHelper: ToastHelperService
  ) {
    $scope.paymentWindowListService = paymentWindowListService;

    $scope.request = {
      paymentwindow: {
        active: true,
        addfee: false,
        callbackurl: "",
        cardholdername: false,
        companyname: "",
        text: "",
        type: "production",
        enablepaymenttypeselector: false
      }
    };
  }

  submit(request: webserviceModels.PaymentWindow.Request.addpaymentwindow) {
    if (!request.paymentwindow.companyname) {
      request.paymentwindow.companyname = null;
    }
    if (!request.paymentwindow.text) request.paymentwindow.text = null;
    if (!request.paymentwindow.callbackurl) {
      request.paymentwindow.callbackurl = null;
    }

    this.paymentWindowListService.create(request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The payment window was successfully created."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The payment window could not be created at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  hide() {
    this.$mdDialog.hide();
  }
}

export interface IPaymentWindowCreateScope extends ng.IScope {
  request: webserviceModels.PaymentWindow.Request.addpaymentwindow;
  paymentWindowListService: PaymentWindowListService;
}

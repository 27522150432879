import * as angular from "angular";
import * as webserviceModels from "../../shared/interfaces/webservice-models";
import {
  MerchantService,
  ImpersonateService,
  SearchHelperService
} from "../../shared/services";

export class TransactionsService {
  // tslint:disable:variable-name
  private _transactions: Array<webserviceModels.Merchant.transaction>;
  private _companyid: string;
  private _merchantnumber: string;
  private _lastEvaluatedKey: string;
  private _lastSearchFilter: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "merchantService",
    "$q",
    "impersonateService",
    "searchHelperService",
    "$rootScope",
    "$timeout"
  ];
  constructor(
    private merchantService: MerchantService,
    private $q: ng.IQService,
    private impersonateService: ImpersonateService,
    private searchHelperService: SearchHelperService,
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService
  ) {
    let timeout = null;
    $rootScope.$on("BamboraPartner:PaymentSearchFilterUpdated", () => {
      $timeout.cancel(timeout);
      timeout = $timeout(() => {
        this.load(this._companyid, this._merchantnumber);
      }, 300);
    });
  }

  get transactions(): Array<webserviceModels.Merchant.transaction> {
    if (this._transactions) return this._transactions;
    throw new ReferenceError("The transactions have not been initialized.");
  }

  load(
    companyid: string,
    merchantnumber: string,
    forceReload: boolean = false,
    conditionalLoad: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const searchQuery = this.searchHelperService.searchQuery;
    const filterString = this.searchHelperService.filterString;
    const searchFilter = searchQuery + filterString;

    let filters: string = null;

    if (searchFilter) {
      if (searchQuery && filterString) {
        filters = `(orderid::bw ${searchFilter.toLowerCase()})or(primaryaccountnumber::ew ${searchFilter})or(reference::eq ${searchFilter})`;

        if (!isNaN(searchQuery as any)) {
          filters += `or(transactionid::eq ${searchFilter})`;
        }
      } else if (searchQuery) {
        filters = `(orderid::bw ${searchQuery.toLowerCase()})or(primaryaccountnumber::ew ${searchQuery})or(reference::eq ${searchQuery})`;

        if (!isNaN(searchQuery as any)) {
          filters += `or(transactionid::eq ${searchFilter})`;
        }
      } else {
        filters = `(${filterString})`;
      }
    }
    if (searchFilter !== this._lastSearchFilter) forceReload = true;

    if (
      forceReload ||
      this._lastEvaluatedKey === undefined ||
      this._merchantnumber !== merchantnumber
    ) {
      this.merchantService
        .listtransactions(null, "20", filters)
        .then(success => {
          if (success.meta.result) {
            this._transactions = success.transactions;
            this._companyid = companyid;
            this._merchantnumber = merchantnumber;
            this._lastEvaluatedKey = success.meta.paging.lastevaluatedkey;
            this._lastSearchFilter = searchFilter;

            deferred.resolve();
          } else {
            this._companyid = undefined;
            this._merchantnumber = undefined;
            this._transactions = undefined;
            this._lastEvaluatedKey = undefined;
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        })
        .catch(error => {
          this._transactions = undefined;
          this._companyid = undefined;
          this._merchantnumber = undefined;
          this._lastEvaluatedKey = undefined;
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        });
    } else {
      if (
        this._lastEvaluatedKey === null ||
        (conditionalLoad && this._transactions)
      ) {
        deferred.resolve();
      } else {
        this.merchantService
          .listtransactions(this._lastEvaluatedKey, null, filters)
          .then(success => {
            if (success.meta.result) {
              Array.prototype.push.apply(
                this._transactions,
                success.transactions
              );

              this._companyid = companyid;
              this._merchantnumber = merchantnumber;
              this._lastEvaluatedKey = success.meta.paging.lastevaluatedkey;
              this._lastSearchFilter = searchFilter;

              deferred.resolve();
            } else {
              try {
                deferred.reject(success.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          })
          .catch(error => {
            try {
              deferred.reject(error.data.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          });
      }
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    return this.load(this._companyid, this._merchantnumber, true);
  }
}

import * as angular from "angular";
import * as webserviceModels from "../../shared/interfaces/webservice-models";

export class PaymentsFilterService {
  // tslint:disable-next-line:variable-name
  private _filter: {
    date?: Date;
    time?: Date;
    paymentTypes?: Array<string>;
    currency?: webserviceModels.Common.currency;
    fromAmount?: number;
    toAmount?: number;
  } = {};

  get filter() {
    return this._filter;
  }

  clearAll() {
    angular.copy({}, this.filter);
  }
}

import * as endpoints from "../../../app.endpoints";
import * as checkoutModels from "../../interfaces/checkout-models";
import ImpersonateService from "../model/impersonate.service";

export class CheckoutService {
  static $inject: Array<string> = ["$http", "impersonateService"];
  constructor(
    private $http: ng.IHttpService,
    private impersonateService: ImpersonateService
  ) {}

  // tslint:disable-next-line:member-ordering
  private endpoint: string = endpoints.checkout;

  private get config(): ng.IRequestShortcutConfig {
    if (!this.impersonateService.authenticationToken) return null;

    return {
      headers: {
        Authorization: `Basic ${this.impersonateService.authenticationToken}`
      }
    };
  }

  listevents(
    datefrom?: string,
    dateto?: string,
    types?: string,
    states?: string,
    paging?: string,
    transactionid?: string,
    sessiontoken?: string
  ): ng.IPromise<checkoutModels.checkouteventsresponse> {
    return this.$http
      .get(
        `${this.endpoint}/events?${datefrom
          ? `&datefrom=${datefrom}`
          : ""}${dateto ? `&dateto=${dateto}` : ""}${types
          ? `&types=${types}`
          : ""}${states ? `&states=${states}` : ""}${paging
          ? `&paging=${paging}`
          : ""}${transactionid
          ? `&transactionid=${transactionid}`
          : ""}${sessiontoken ? `&sessiontoken=${sessiontoken}` : ""}`,
        this.config
      )
      .then<any>(response => response.data);
  }

  getevent(
    eventid: string
  ): ng.IPromise<checkoutModels.checkouteventsresponse> {
    return this.$http
      .get(`${this.endpoint}/event/${eventid}`, this.config)
      .then<any>(response => response.data);
  }
}

export default CheckoutService;

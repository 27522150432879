import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  CompanyListService,
  CountryListService,
  CurrencyListService,
  ToastHelperService,
  ICompanyListServiceCreate
} from "../shared/services";
import { ICompanyListScope } from "./company-list.controller";

export class CompanyCreateController {
  static $inject = [
    "$scope",
    "$state",
    "$mdDialog",
    "companyListService",
    "countryListService",
    "currencyListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: ICompanyCreateScope,
    private $state: ng.ui.IStateService,
    private $mdDialog: ng.material.IDialogService,
    private companyListService: CompanyListService,
    private countryListService: CountryListService,
    private currencyListService: CurrencyListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.countryListService = countryListService;
    $scope.currencyListService = currencyListService;

    const defaultIsoA3Code = "DNK"; // TODO: change to localstorage or browser language based?
    const defaultCountry = Enumerable.from(countryListService.countries).first(
      country => country.isoa3code === defaultIsoA3Code
    );
    const defaultCurrency = Enumerable.from(
      currencyListService.currencies
    ).first(currency => currency.code === defaultCountry.currency.code);

    $scope.request = {
      companyname: undefined,
      contactemail: undefined,
      domainname: undefined,
      countrycode: defaultCountry.isoa3code,
      currencycode: defaultCurrency.code
    };

    $scope.created = null;
  }

  submit(request: webserviceModels.Reseller.Request.SignUp.signup) {
    this.companyListService.create(request).then(
      success => {
        this.$scope.created = success;
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{companyname}} could not be created at this time.",
              { companyname: request.companyname }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  goToCompany(companyid: string, merchantnumber: string) {
    this.$state.go("agreementList", { companyid, merchantnumber });
    this.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface ICompanyCreateScope extends ICompanyListScope {
  request: webserviceModels.Reseller.Request.SignUp.signup;
  created: ICompanyListServiceCreate;
  countryListService: CountryListService;
  currencyListService: CurrencyListService;
}

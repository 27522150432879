import { Component } from "../../../../shared/decorators";
import * as checkoutModels from "../../../../shared/interfaces/checkout-models";

@Component({
  selector: "eventDetails",
  template: require("./event-details.html"),
  bindings: {
    _event: "<event"
  }
})
export class EventDetailsComponent {
  request: object;
  response: object;

  // tslint:disable:member-ordering
  static $inject = ["$mdDialog"];
  constructor(private $mdDialog: ng.material.IDialogService) {}

  // tslint:disable-next-line:variable-name
  private _event: checkoutModels.checkouteventitem;

  get event() {
    return this._event;
  }

  closeDialog() {
    this.$mdDialog.hide();
  }

  $onInit() {
    this.setRequest();
    this.setResponse();
  }

  private setRequest() {
    if (!this._event || !this._event.request) return "";

    const jsonRequest = JSON.parse(this._event.request);
    const object = EventDetailsComponent.traverseAndFindObject(jsonRequest);

    this.request = object;
  }

  private setResponse() {
    if (!this._event || !this._event.reply) return "";

    const jsonResponse = JSON.parse(this._event.reply);
    const object = EventDetailsComponent.traverseAndFindObject(jsonResponse);

    this.response = object;
  }

  private static traverseAndFindObject(item: any) {
    if (!item) return null;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < item.length; i++) {
      if (item[i].constructor === Object) return item[i];

      if (item[i].constructor === Array) {
        return this.traverseAndFindObject(item[i]);
      }
    }

    return null;
  }
}

import { getFactoryFor } from "../../helpers/directive-helper";

export class AppLoader implements ng.IDirective {
  static $inject = ["$rootScope", "$animate", "gettextCatalog"];
  constructor(
    private $rootScope: ng.IRootScopeService,
    private $animate: ng.animate.IAnimateService,
    private gettextCatalog: any
  ) {}

  // tslint:disable-next-line:member-ordering
  restrict = "E";
  // tslint:disable-next-line:member-ordering
  template = "<giraffy-loader></giraffy-loader>";

  link: ng.IDirectiveLinkFn = (
    scope: IAppLoaderScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    scope.loaderMessage = this.gettextCatalog.getString(
      "Loading Bambora Reseller"
    );

    this.$rootScope.$on(
      "$stateChangeSuccess",
      (
        event: ng.IAngularEvent,
        toState,
        toParams,
        fromState,
        fromParams,
        error
      ) => {
        if (fromState.name !== "") return;

        scope.loaderMessage = this.gettextCatalog.getString("Welcome!");

        this.$animate
          .addClass(
            instanceElement.find(".giraffy").first(),
            "animate-loader",
            {}
          )
          .then(() => {
            instanceElement.remove();
          });
      }
    );
  };
}

export interface IAppLoaderScope extends ng.IScope {
  loaderMessage: string;
}

import $ from "jquery";
import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  AgreementGroupListService,
  CurrencyListService,
  CountryListService,
  AcquirerListService,
  ToastHelperService
} from "../shared/services";
import { IAgreementListScope } from "./agreement-list.controller";

export class AgreementEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "agreementGroupListService",
    "currencyListService",
    "countryListService",
    "acquirerListService",
    "gettextCatalog",
    "toastHelper",
    "agreement",
    "agreementDataTypes"
  ];

  searchTerm: string = "";

  constructor(
    private $scope: IAgreementEditScope,
    private $mdDialog: ng.material.IDialogService,
    private agreementGroupListService: AgreementGroupListService,
    private currencyListService: CurrencyListService,
    private countryListService: CountryListService,
    private acquirerListService: AcquirerListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private agreement: webserviceModels.Common.Response.Agreement.agreement,
    private agreementDataTypes: Array<
      webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
    >
  ) {
    $scope.agreementGroupListService = agreementGroupListService;
    $scope.currencyListService = currencyListService;
    $scope.countryListService = countryListService;
    $scope.acquirerListService = acquirerListService;
    $scope.acquireDisplayName = this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      agreement.acquirername
    );

    $scope.agreementDataTypes = agreementDataTypes;

    $scope.request = {
      agreement: {
        acquirername: agreement.acquirername,
        agreementdata: [],
        canfailover: agreement.canfailover,
        capturedelaytimeinminutes: agreement.capturedelaytimeinminutes,
        capturetype: agreement.capturetype,
        currency: agreement.currency.code,
        deletedelaytimeinminutes: agreement.deletedelaytimeinminutes,
        deletetype: agreement.deletetype,
        status: agreement.status,
        currencyhandling: agreement.currencyhandling.toLowerCase(),
        currencylist: (agreement.currencylist || []).map(currency =>
          currency.toLowerCase()
        )
      } as any
    };

    $scope.agreementData = {
      uploadedFiles: {}
    };

    $scope.agreementId = agreement.id;
    $scope.isOnboarded = agreement.isonboarded;

    agreement.agreementdata.forEach(keyValuePair => {
      $scope.agreementData[keyValuePair.key] = keyValuePair.value;
    });
  }

  createRequestAgreementData(
    agreementData: any
  ): webserviceModels.Common.input[] {
    const data = ($ as any).map(agreementData, (value, index) => {
      if (index != "uploadedFiles") {
        if (agreementData.uploadedFiles[index] != undefined)
          value = agreementData.uploadedFiles[index];

        return { key: index, value: !!value ? value : null };
      } else return null;
    });

    return data;
  }

  submit(
    request: webserviceModels.Common.Request.Agreement.updateagreement,
    agreementData: any = {}
  ) {
    request.agreement.agreementdata = this.createRequestAgreementData(
      agreementData
    );

    if (
      request.agreement.currencyhandling === ("onlymaincurrency" as any) ||
      request.agreement.currencyhandling === ("allcurrencies" as any)
    ) {
      request.agreement.currencylist = [];
    }

    this.agreementGroupListService
      .patchAgreement(this.agreement.id, request)
      .then(
        success => {
          this.$mdDialog.hide();
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{acquirername}} ({{currency}}) was successfully updated.",
              {
                acquirername: this.$scope.acquireDisplayName,
                currency: this.agreement.currency.code
              }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              error ||
                "{{acquirername}} ({{currency}}) could not be updated at this time.",
              {
                acquirername: this.$scope.acquireDisplayName,
                currency: this.agreement.currency.code
              }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }

  clearSearchTerm() {
    this.searchTerm = "";
  }
}

export interface IAgreementEditScope extends IAgreementListScope {
  request: webserviceModels.Common.Request.Agreement.updateagreement;
  agreementDataTypes: Array<
    webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  >;
  agreementGroupListService: AgreementGroupListService;
  currencyListService: CurrencyListService;
  countryListService: CountryListService;
  acquirerListService: AcquirerListService;
  agreementData: object;
  acquireDisplayName: string;
  agreementId: string;
  isOnboarded?: boolean;
}

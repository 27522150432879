import * as webserviceModels from "../../interfaces/webservice-models";
import { DataService } from "../clients";

export class ResponseCodeListService {
  // tslint:disable-next-line:variable-name
  private _responseCodes: Array<
    webserviceModels.Data.Response.ResponseCodes.responsecode
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = ["dataService", "$q"];
  constructor(private dataService: DataService, private $q: ng.IQService) {}

  get responseCodes(): Array<
    webserviceModels.Data.Response.ResponseCodes.responsecode
  > {
    if (this._responseCodes) return this._responseCodes;
    throw new ReferenceError(
      "The response code list has not been initialized."
    );
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._responseCodes) {
      deferred.resolve();
    } else {
      const response = this.dataService.listresponsecodes();

      response.then(
        success => {
          if (success.meta.result) {
            this._responseCodes = success.responsecodes;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._responseCodes) return this.load(true);
    throw new ReferenceError(
      "The response code list has not been initialized."
    );
  }

  getResponseCode(
    actionsource: string,
    actioncode: string
  ): webserviceModels.Data.Response.ResponseCodes.responsecode {
    return this.responseCodes.find(
      responseCode =>
        responseCode.source.toLowerCase() === actionsource.toLowerCase() &&
        responseCode.actioncode.toLowerCase() === actioncode.toLowerCase()
    );
  }
}

import $ from "jquery";
import "velocity-animate";
import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  DataService,
  AgreementGroupListService,
  AcquirerListService,
  TabHelperService,
  ToastHelperService,
  SearchHelperService
} from "../shared/services";
import { CircularLoaderService } from "../shared/components/circular-loader/circular-loader.component";
import { IFilterable } from "../shared/components";

export class AgreementListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "dataService",
    "agreementGroupListService",
    "acquirerListService",
    "$mdDialog",
    "$mdToast",
    "gettextCatalog",
    "circularLoader",
    "tabHelper",
    "toastHelper",
    "searchHelperService",
    "$mdMedia",
    "$timeout",
    "$rootScope"
  ];

  constructor(
    private $scope: IAgreementListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private dataService: DataService,
    private agreementGroupListService: AgreementGroupListService,
    private acquirerListService: AcquirerListService,
    private $mdDialog: ng.material.IDialogService,
    private $mdToast: ng.material.IToastService,
    private gettextCatalog: any,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private toastHelper: ToastHelperService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService
  ) {
    tabHelper.currentTab = 0;

    $scope.acquirerListService = acquirerListService;
    $scope.agreementGroupListService = agreementGroupListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on(
      "agreementGroupListService.agreementAdded",
      (
        event,
        agreement: webserviceModels.Common.Response.Agreement.agreement
      ) => {
        $timeout(() => {
          $(`md-list div[name='${agreement.id}']`)
            .velocity("scroll", {
              container: $("#container"),
              duration: 500,
              offset: -100
            })
            .velocity("callout.pulse");
        });
      }
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.agreementGroupListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The agreement list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The agreement list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./agreement-create.html"),
      controller: "agreementCreateController",
      controllerAs: "agreementCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  edit(
    $event: MouseEvent,
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ) {
    this.dataService.getagreementdatatype(agreement.acquirername).then(
      success => {
        if (success.meta.result) {
          this.$mdDialog.show({
            template: require("./agreement-edit.html"),
            controller: "agreementEditController",
            controllerAs: "agreementEdit",
            targetEvent: $event,
            parent: angular.element(document.body),
            escapeToClose: false,
            clickOutsideToClose: false,
            locals: {
              companyid: this.$stateParams.companyid,
              agreement,
              agreementDataTypes: success.agreementdatatypes
            },
            fullscreen: !this.$mdMedia("gt-md")
          } as any);
        } else {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "The acquirer data is not available."
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The acquirer data is not available."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  delete(
    $event: MouseEvent,
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm Agreement Deletion"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to delete the agreement <i>{{acquirername}} ({{currencycode}})</i>?",
          {
            acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
              agreement.acquirername
            ),
            currencycode: agreement.currency.code
          }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Delete"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      this.agreementGroupListService.deleteAgreement(agreement).then(
        success => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{acquirername}} ({{currencycode}}) was successfully deleted.",
              {
                acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
                  agreement.acquirername
                ),
                currencycode: agreement.currency.code
              }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{acquirername}} ({{currencycode}}) could not be deleted at this time.",
                {
                  acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
                    agreement.acquirername
                  ),
                  currencycode: agreement.currency.code
                }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
    });
  }

  copy(
    $event: MouseEvent,
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ) {
    this.$mdDialog.show({
      template: require("./agreement-copy.html"),
      controller: "agreementCopyController",
      controllerAs: "agreementCopy",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        agreement
      },
      fullscreen: !this.$mdMedia("gt-md"),
      preserveScope: true
    } as any);
  }

  viewFilters(
    $event: MouseEvent,
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ) {
    const $mdDialog = this.$mdDialog;
    const headline = `Filter config for ${this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      agreement.acquirername
    )} (${agreement.currency.code}) agreement`;
    const onSave = filters =>
      this.agreementGroupListService.patchAgreement(agreement.id, {
        agreement: {
          filters: filters
        }
      });
    const currencyCode = agreement.currency.code;

    this.$mdDialog.show({
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      controller: () => ({
        agreement,
        headline,
        onSave,
        currencyCode
      }),
      controllerAs: "$ctrl",
      fullscreen: true,
      autoWrap: false,
      template: `
        <md-dialog layout="column" flex="100" flex-lg="50" flex-gt-lg="40">
          <condition-filter
            filterable="$ctrl.agreement"
            headline="$ctrl.headline"
            on-save="$ctrl.onSave(filters)"
            currency-code="$ctrl.currencyCode">
          </condition-filter>
        </md-dialog>
      `
    });
  }

  toggleAgreementStatus(
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ) {
    const request: webserviceModels.Common.Request.Agreement.patchagreement = {
      agreement: {
        status: agreement.status === "active" ? "inactive" : "active"
      }
    };

    this.agreementGroupListService.patchAgreement(agreement.id, request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "{{acquirername}} ({{currencycode}}) was successfully updated.",
            {
              acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
                agreement.acquirername
              ),
              currencycode: agreement.currency.code
            }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{acquirername}} ({{currencycode}}) could not be updated at this time.",
              {
                acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
                  agreement.acquirername
                ),
                currencycode: agreement.currency.code
              }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }
}

export interface IAgreementListScope extends ng.IScope {
  agreementGroupListService: AgreementGroupListService;
  acquirerListService: AcquirerListService;
  searchHelperService: SearchHelperService;
}

import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  DataService,
  AgreementGroupListService,
  CurrencyListService,
  CountryListService,
  AcquirerListService,
  ToastHelperService,
  CompanyService
} from "../shared/services";
import { IAgreementListScope } from "./agreement-list.controller";

export class AgreementCreateController {
  static $inject = [
    "$scope",
    "dataService",
    "$mdDialog",
    "agreementGroupListService",
    "currencyListService",
    "countryListService",
    "acquirerListService",
    "gettextCatalog",
    "toastHelper",
    "companyService"
  ];

  searchTerm: string = "";

  constructor(
    private $scope: IAgreementCreateScope,
    private dataService: DataService,
    private $mdDialog: ng.material.IDialogService,
    private agreementGroupListService: AgreementGroupListService,
    private currencyListService: CurrencyListService,
    private countryListService: CountryListService,
    private acquirerListService: AcquirerListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private companyService: CompanyService
  ) {
    $scope.agreementGroupListService = agreementGroupListService;
    $scope.currencyListService = currencyListService;
    $scope.countryListService = countryListService;
    $scope.acquirerListService = acquirerListService;

    $scope.request = {
      agreement: {
        acquirername: undefined,
        agreementdata: [],
        canfailover: false,
        capturedelaytimeinminutes: 0,
        capturetype: "manual",
        currency: Enumerable.from(currencyListService.currencies).first(
          currency => currency.code === "DKK"
        ).code, // TODO: change to browser language based.
        deletedelaytimeinminutes: 0,
        deletetype: "agreementdefault",
        status: "active",
        currencyhandling: "onlymaincurrency" as any,
        currencylist: [],
        filters: []
      }
    };

    $scope.agreementData = {
      uploadedFiles: {}
    };
  }

  getAgreementDataTypes() {
    const response = this.dataService.getagreementdatatype(
      this.$scope.request.agreement.acquirername
    );

    response.then(
      success => {
        if (success.meta && success.meta.result) {
          this.$scope.agreementDataTypes = success.agreementdatatypes;
          this.$scope.agreementDataTypes.forEach(agreementDataType => {
            let defaultValue: number | string = agreementDataType.defaultvalue;

            if (
              agreementDataType.type === "int32" &&
              typeof defaultValue === "string"
            ) {
              defaultValue = parseInt(defaultValue as string, 10);
            }

            if (!defaultValue) {
              try {
                switch (agreementDataType.type) {
                  case "CompanyName":
                    defaultValue = this.companyService.company.name;
                    break;
                  case "CompanyAddress":
                    defaultValue = this.companyService.company.address;
                    break;
                  case "CompanyCity":
                    defaultValue = this.companyService.company.city;
                    break;
                  case "CompanyZipCode":
                    defaultValue = this.companyService.company.zipcode;
                    break;
                  case "CountryIsoA3Code":
                    defaultValue = this.companyService.company.countryname;
                    break;
                  case "CountryIsoA2Code":
                    defaultValue = this.getCountryIsoA2Code(
                      this.companyService.company.countryname
                    );
                    break;
                }

                if (
                  agreementDataType.validation.maximumlength &&
                  (defaultValue as string).length >
                    agreementDataType.validation.maximumlength
                ) {
                  defaultValue = (defaultValue as string).slice(
                    0,
                    agreementDataType.validation.maximumlength
                  );
                }
                // tslint:disable-next-line:no-empty
              } catch (e) {}
            }
            this.$scope.agreementData[agreementDataType.key] = defaultValue;
          });
        } else {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "The acquirer information could not be loaded at this time."
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The acquirer information could not be loaded at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  getCountryIsoA2Code(countryIsoA3Code: string): string {
    return Enumerable.from(this.countryListService.countries).first(
      country => country.isoa3code === countryIsoA3Code
    ).isoa2code;
  }

  createRequestAgreementData(
    agreementData: any
  ): webserviceModels.Common.input[] {
    const data = ($ as any).map(agreementData, (value, index) => {
      if (index != "uploadedFiles") {
        if (agreementData.uploadedFiles[index] != undefined)
          value = agreementData.uploadedFiles[index];

        if (value === null) return null;
        return { key: index, value };
      } else return null;
    });

    return data;
  }

  submit(
    request: webserviceModels.Common.Request.Agreement.addagreement,
    agreementData: any = {}
  ) {
    request.agreement.agreementdata = this.createRequestAgreementData(
      agreementData
    );

    if (
      request.agreement.currencyhandling === ("onlymaincurrency" as any) ||
      request.agreement.currencyhandling === ("allcurrencies" as any)
    ) {
      request.agreement.currencylist = null;
    }

    this.agreementGroupListService.createAgreement(request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            success.isonboarded === false
              ? "{{acquirername}} ({{currency}}) agreement was created, but onboarding is not complete. Open the agreement and correct any incorrect/malformed data. If the problem remains, contact support."
              : "{{acquirername}} ({{currency}}) was successfully created.",
            {
              acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
                request.agreement.acquirername
              ),
              currency: request.agreement.currency
            }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{acquirername }} ({{currency }}) could not be created at this time.",
              {
                acquirername: this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
                  request.agreement.acquirername
                ),
                currency: request.agreement.currency
              }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }

  clearSearchTerm() {
    this.searchTerm = "";
  }
}

export interface IAgreementCreateScope extends IAgreementListScope {
  request: webserviceModels.Common.Request.Agreement.addagreement;
  agreementDataTypes: Array<
    webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  >;
  agreementGroupListService: AgreementGroupListService;
  currencyListService: CurrencyListService;
  countryListService: CountryListService;
  acquirerListService: AcquirerListService;
  agreementData: object;
}

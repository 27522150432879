import * as endpoints from "../../../app.endpoints";
import * as webserviceModels from "../../interfaces/webservice-models";

export class DataService {
  static $inject: Array<string> = ["$http"];
  constructor(private $http: ng.IHttpService) {}

  // tslint:disable-next-line:member-ordering
  private endpoint: string = endpoints.data;

  listacquirers(): ng.IPromise<
    webserviceModels.Data.Response.ListAcquirer.listacquirerresponse
  > {
    return this.$http
      .get(this.endpoint + "/acquirers")
      .then<any>(response => response.data);
  }

  listpaymenttypes(): ng.IPromise<
    webserviceModels.Data.Response.ListPaymentTypes.listpaymenttypesresponse
  > {
    return this.$http
      .get(this.endpoint + "/paymenttypes")
      .then<any>(response => response.data);
  }

  listfeecalculators(): ng.IPromise<
    webserviceModels.Data.Response.ListFeeCalculator.listfeecalculatorsresponse
  > {
    return this.$http
      .get(this.endpoint + "/feecalculators")
      .then<any>(response => response.data);
  }

  getcurrency(
    currency: string
  ): ng.IPromise<webserviceModels.Data.Response.Currency.getcurrencyresponse> {
    return this.$http
      .get(this.endpoint + "/currencies/" + currency)
      .then<any>(response => response.data);
  }

  listcurrencies(): ng.IPromise<
    webserviceModels.Data.Response.Currency.listcurrenciesresponse
  > {
    return this.$http
      .get(this.endpoint + "/currencies")
      .then<any>(response => response.data);
  }

  listrequirementsfilters(): ng.IPromise<
    webserviceModels.Data.Response.RequirementsFilters.listrequirementsfiltersresponse
  > {
    return this.$http
      .get(this.endpoint + "/requirementsfilters")
      .then<any>(response => response.data);
  }

  listcountries(): ng.IPromise<
    webserviceModels.Data.Response.Country.listcountriesresponse
  > {
    return this.$http
      .get(this.endpoint + "/countries")
      .then<any>(response => response.data);
  }

  getacquirerconfiguration(
    acquirername: string,
    currency: string
  ): ng.IPromise<
    webserviceModels.Data.Response.AcquirerConfiguration.getacquirerconfigurationresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/acquirers/" +
          acquirername +
          "/configurations/" +
          currency
      )
      .then<any>(response => response.data);
  }

  getagreementdatatype(
    acquirername: string
  ): ng.IPromise<
    webserviceModels.Data.Response.GetAgreementDataType.getagreementdatatyperesponse
  > {
    return this.$http
      .get(this.endpoint + "/acquirers/" + acquirername + "/agreementdatatypes")
      .then<any>(response => response.data);
  }

  getpaymenttypefeedatatype(
    feecalculatorname: string
  ): ng.IPromise<
    webserviceModels.Data.Response.GetPaymentTypeFeeDataType.getpaymenttypefeedatatyperesponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/feecalculators/" +
          feecalculatorname +
          "/paymenttypefeedatatypes"
      )
      .then<any>(response => response.data);
  }

  getresponsecode(
    source: string,
    actioncode: string
  ): ng.IPromise<
    webserviceModels.Data.Response.ResponseCodes.getresponsecoderesponse
  > {
    return this.$http
      .get(`${this.endpoint}/responsecodes/${source}/${actioncode}`)
      .then<any>(response => response.data);
  }

  listresponsecodes(): ng.IPromise<
    webserviceModels.Data.Response.ResponseCodes.listresponsecodesresponse
  > {
    return this.$http
      .get(`${this.endpoint}/responsecodes`)
      .then<any>(response => response.data);
  }

  listresponsecodesbysource(
    source: string
  ): ng.IPromise<
    webserviceModels.Data.Response.ResponseCodes.listresponsecodesresponse
  > {
    return this.$http
      .get(`${this.endpoint}/responsecodes/${source}`)
      .then<any>(response => response.data);
  }

  listproductfeatures(
    scope: string
  ): ng.IPromise<
    webserviceModels.Data.Response.ProductFeatures.listproductfeaturesresponse
  > {
    return this.$http
      .get(`${this.endpoint}/productfeatures/${scope}`)
      .then<any>(response => response.data);
  }
}

export default DataService;

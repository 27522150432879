export class AccentifyList implements ng.IDirective {
  restrict = "A";
  scope = false;

  compile: ng.IDirectiveCompileFn = (templateElement, templateAttributes) => {
    return {
      post: (
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes
      ) => {
        $element
          .find("md-list-item > .md-button > button")
          .addClass("md-accent");
      }
    };
  };
}

import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  CompanyService,
  CompanyListService,
  ToastHelperService
} from "../shared/services";

import { ICompanyListScope } from "./company-list.controller";

export class CompanyCancelDeleteController {
         static $inject = [
           "$scope", 
           "$mdDialog",
            "company", 
            "companyService", 
            "companyListService", 
            "gettextCatalog", 
            "toastHelper",
            "isListItem"];

         constructor(
           private $scope: ICancelDeleteCompanyScope, 
           private $mdDialog: ng.material.IDialogService, 
           private company: webserviceModels.Common.Response.Company.company, 
           private companyService: CompanyService,
           private companyListService: CompanyListService,
           private gettextCatalog: any, 
           private toastHelper: ToastHelperService,
           private isListItem: boolean) {
           $scope.company = company;
           $scope.companyService = companyService;
           $scope.companyListService = companyListService;
         }

         canceldelete() {
          if (!this.isListItem) {
           this.companyService
             .canceldelete()
             .then(
               success => {
                 this.showSuccessCancelDeletedToast(
                   this.company.name,
                   this.company.id
                 );
               },
               error => {
                 this.showErrorCancelDeletedToast(
                   this.company.name,
                   this.company.id,
                   error
                 );
               }
             );
              }else{
                this.companyListService
                .canceldelete(this.company)
                .then(
                  success => {
                    this.showSuccessCancelDeletedToast(
                      this.company.name,
                      this.company.id
                    );
                  },
                  error => {
                    this.showErrorCancelDeletedToast(
                      this.company.name,
                      this.company.id,
                      error
                    );
                  }
                );
            }
          this.$mdDialog.hide();
        }

         showSuccessCancelDeletedToast(companyName: string, companyId: string) {
           let message = this.gettextCatalog.getString(
             "Deletion of {{companyName}} ({{companyId}}) was successfully canceled.",
             {
               companyName,
               companyId
             }
           );
           this.toastHelper.toast(message, this.gettextCatalog.getString("Dismiss"));
         }

         showErrorCancelDeletedToast(companyName: string, companyId: string, error: any) {
           this.toastHelper.toast(error || this.gettextCatalog.getString(
                 "Deletion of {{companyName}} ({{companyId}}) could not be canceled at this time.",
                 {
                   companyName,
                   companyId
                 }
               ), this.gettextCatalog.getString("Dismiss"));
         }

         cancel() {
           this.$mdDialog.cancel();
         }
       }

export interface ICancelDeleteCompanyScope extends ICompanyListScope {
  company: webserviceModels.Common.Response.Company.company;
  companyService: CompanyService;
  companyListService: CompanyListService;
}

import "./logitems.scss";
import * as Enumerable from "linq";
import moment from "moment";
import urlParse from "url-parse";
import * as angular from "angular";
import { Component } from "../../shared/decorators";
import { ResellerService, ToastHelperService } from "../../shared/services";
import * as webserviceModels from "../../shared/interfaces/webservice-models";

@Component({
  selector: "logitems",
  template: require("./logitems.html"),
  bindings: {
    logitems: "<"
  }
})
export class LogitemsComponent {
  // tslint:disable-next-line:member-ordering
  static $inject = [
    "gettextCatalog",
    "$mdDialog",
    "resellerService",
    "toastHelper",
    "$stateParams"
  ];

  constructor(
    private gettextCatalog: ng.gettext.gettextCatalog,
    private $mdDialog: ng.material.IDialogService,
    private resellerService: ResellerService,
    private toastHelper: ToastHelperService,
    private $stateParams: ng.ui.IStateParamsService
  ) {}

  getTypeColor(logitem: webserviceModels.Common.Response.Logging.logitem) {
    if (logitem.result === false) return "#CC4547";

    if (logitem.logtype === "api") {
      const isError = Enumerable.from(
        (logitem as webserviceModels.Common.Response.Logging.ApiSpecificItem)
          .properties
      ).any(
        property =>
          property.key === "httpstatuscode" &&
          ((httpStatusCode: number) =>
            httpStatusCode < 200 && httpStatusCode > 299)(
            parseInt(property.value, 10)
          )
      );

      if (isError) return "#CC4547";
    }

    if (logitem.logtype === "event") {
      if (this.getAmountOfCallbackTries(logitem) > 0) {
        return "#EFCB47";
      }
    }

    switch (logitem.logtype) {
      case "api":
        return "#1C92B1";
      case "event":
        return "#41AF94";
      case "log":
        return "#537EB2";
      default:
        return null;
    }
  }

  getType(type: string) {
    switch (type) {
      case "api":
        return this.gettextCatalog.getString("API");
      case "event":
        return this.gettextCatalog.getString("Event");
      case "log":
        return this.gettextCatalog.getString("Application");
      default:
        return null;
    }
  }

  getLogitemProperty(
    logitem: webserviceModels.Common.Response.Logging.logitem,
    key: string
  ) {
    const property = Enumerable.from(logitem.properties).firstOrDefault(
      propertyX => propertyX.key === key,
      null
    );

    return (property && property.value) || null;
  }

  getHostName(url: string) {
    return urlParse(url).hostname;
  }

  getAmountOfCallbackTries(
    logitem: webserviceModels.Common.Response.Logging.EventSpecificItem
  ) {
    if (!logitem.children || !logitem.children.length) return 0;
    return logitem.children.length - 1;
  }

  getTime(timestamp: string) {
    return moment(timestamp).format("HH:mm:ss.SSS");
  }

  viewDetails($event: MouseEvent, logitemId: string) {
    const { companyid, merchantnumber } = this.$stateParams;
    const { $mdDialog } = this;

    this.resellerService
      .getlogitem(companyid, merchantnumber, logitemId)
      .then(response => {
        const { logitem } = response;
        const logitemHeadline = `${this.getType(
          logitem.logtype
        )}${logitem.logsubtype ? `(${logitem.logsubtype})` : ""}`;

        this.$mdDialog.show({
          targetEvent: $event,
          parent: angular.element(document.body),
          escapeToClose: false,
          clickOutsideToClose: false,
          controller: () => ({
            logitem,
            logitemHeadline,
            close() {
              $mdDialog.hide();
            }
          }),
          controllerAs: "$ctrl",
          fullscreen: true,
          autoWrap: false,
          template: `
            <md-dialog layout="column" flex="100" flex-lg="60" flex-gt-lg="50">
              <md-toolbar class="header-dark">
                <div class="md-toolbar-tools">
                  <h2 translate>{{::$ctrl.logitemHeadline}}</h2>
                  <span flex></span>
                  <md-button class="md-icon-button" ng-click="$ctrl.close()">
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </md-toolbar>
              <md-dialog-content layout="column">
                <div class="md-dialog-content">
                  <logitem-details logitem="$ctrl.logitem"></logitem-details>
                </div>
              </md-dialog-content>
            </md-dialog>
          `
        });
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The log entry could not be fetched at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });
  }
}

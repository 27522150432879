import $ from "jquery";
import angular from "angular";

export class SearchHelperService {
  // tslint:disable-next-line:variable-name
  private _searchQuery = "";
  // tslint:disable-next-line:variable-name
  private _filterString = "";
  // tslint:disable-next-line:member-ordering
  showSearch = false;

  get searchQuery() {
    return this._searchQuery;
  }

  set searchQuery(value) {
    this._searchQuery = value;
    this.emitEvent();
  }

  get filterString() {
    return this._filterString;
  }

  set filterString(value) {
    this._filterString = value;
    this.emitEvent();
  }

  get searchFilter() {
    return this.searchQuery + this.filterString;
  }

  // tslint:disable-next-line:member-ordering
  static $inject = ["$timeout", "$rootScope", "$state"];
  constructor(
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
    private $state: ng.ui.IStateService
  ) {
    (angular.element(
      window as any
    ) as any).keydown((event: JQueryKeyEventObject) => {
      const target = $(event.target);
      if (
        target.is("input") ||
        target.is("textarea") ||
        target.is("select") ||
        angular.element(document.body).find("md-dialog").length > 0 ||
        window.getSelection().toString()
      ) {
        return;
      }

      if (event.ctrlKey) {
        this.$timeout(() => {
          this.showSearch = true;
          this.focus();
        });
      } else if (
        (event.which >= 48 && event.which <= 57) ||
        (event.which >= 65 && event.which <= 90) ||
        (event.which >= 96 && event.which <= 105)
      ) {
        if (!this.showSearch) {
          this.$timeout(() => {
            let key = event.key;
            if (!key) key = String.fromCharCode(event.which).toLowerCase();
            this.searchQuery += key || "";
            this.showSearch = true;
            this.focus();
          });
        }
      }
    });

    (angular.element(
      window as any
    ) as any).keyup((event: JQueryKeyEventObject) => {
      if (event.target !== $("#searchQueryInput")[0]) return;
      if (event.which === 17 && !this.searchQuery) {
        this.$rootScope.$apply(() => {
          this.reset();
        });
      }
    });
  }

  emitEvent() {
    switch (this.$state.current.name) {
      case "logs": {
        this.$rootScope.$emit("BamboraPartner:LogSearchFilterUpdated");
        break;
      }

      case "payments": {
        this.$rootScope.$emit("BamboraPartner:PaymentSearchFilterUpdated");
        break;
      }
    }
  }

  resetWithSessionStorage() {
    this.reset();

    if (this.$state.current.name === "companyList") {
      sessionStorage.setItem("companyListSearch", "");
    }
  }

  reset() {
    this._searchQuery = "";
    this._filterString = "";
    this.emitEvent();
    this.showSearch = false;
    $("#container").scrollTop(0);
  }

  toggle() {
    this.showSearch = !this.showSearch;
    if (this.showSearch) this.focus();
  }

  focus() {
    const input = $("#searchQueryInput");
    this.$timeout(() => {
      input.focus();
      (input[0] as any).selectionStart = this.searchQuery.length;
    });

    input.off("keydown.searchHelperService");
    input.on("keydown.searchHelperService", (event: JQueryKeyEventObject) => {
      if (event.which === 27) {
        this.$timeout(() => this.resetWithSessionStorage());
      }
    });
  }
}

export default SearchHelperService;

import * as angular from "angular";
import { IResellerRootScopeService } from "../shared/interfaces/reseller-rootscope";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  AuthenticationService,
  ToastHelperService,
  WebStorageService
} from "../shared/services";

export class AccountController {
  static $inject = [
    "$scope",
    "$state",
    "authenticationService",
    "$stateParams",
    "gettextCatalog",
    "toastHelper",
    "$mdDialog",
    "webStorageService",
    "$rootScope",
    "$localStorage"
  ];

  constructor(
    private $scope: ng.IScope,
    private $state: ng.ui.IStateService,
    private authenticationService: AuthenticationService,
    private $stateParams: ng.ui.IStateParamsService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private $mdDialog: ng.material.IDialogService,
    private webStorageService: WebStorageService,
    private $rootScope: IResellerRootScopeService,
    private $localStorage: any
  ) {
    if ($stateParams.resetpasswordtoken) {
      ($scope as any).request = {};
      ($scope as any).request.email = $stateParams.email;
      ($scope as any).request.resetpasswordtoken =
        $stateParams.resetpasswordtoken;
    }

    if ($rootScope.otpresponsedata && $rootScope.otpresponsedata.otptoken) {
      ($scope as any).request = {};
      ($scope as any).request.otptoken = $rootScope.otpresponsedata.otptoken;
      ($scope as any).request.email = $rootScope.otpresponsedata.email;

      if ($rootScope.otpresponsedata.otpsecretkey) {
        ($scope as any).request.otpsecretkey =
          $rootScope.otpresponsedata.otpsecretkey;
        ($scope as any).request.configureauthenticator =
          $rootScope.otpresponsedata.configureauthenticator;
      }
    }

    if ($stateParams.resetotptoken) {
      ($scope as any).request = {};
      ($scope as any).request.resetotptoken = $stateParams.resetotptoken;
      ($scope as any).request.email = $stateParams.email;
      ($scope as any).request.invalidResetOtpLink = false;
      ($scope as any).request.displayResetMFAButton = true;
    }

    ($scope as any).remember = $localStorage["login_remember_checked"];
  }

  login(
    $event,
    request: webserviceModels.Login.Request.logonrequest,
    remember: boolean
  ) {
    this.$localStorage["login_remember_checked"] = remember;

    this.webStorageService.$storage = remember
      ? "localStorage"
      : "sessionStorage";

    this.authenticationService.loginsession(request).then(null, error => {
      this.$mdDialog.show(
        this.$mdDialog
          .alert()
          .parent(angular.element(document.body))
          .clickOutsideToClose(true)
          .title("Error")
          .textContent(error)
          .ok("Ok")
          .targetEvent($event)
      );
    });
  }

  otp($event, request: webserviceModels.Login.Request.otprequest) {
    this.authenticationService
      .loginsessionotp({
        otptoken: request.otptoken,
        otpcode: request.otpcode
      })
      .then(null, error => {
        this.$mdDialog.show(
          this.$mdDialog
            .alert()
            .parent(angular.element(document.body))
            .clickOutsideToClose(true)
            .title("Error")
            .textContent(error)
            .ok("Ok")
            .targetEvent($event)
        );
      });
  }

  forgotOTP($event, request: webserviceModels.Login.Request.forgototprequest) {
    this.authenticationService
      .forgototp({
        otptoken: request.otptoken
      })
      .then(
        success => {
          this.$mdDialog.show(
            this.$mdDialog
              .alert()
              .parent(angular.element(document.body))
              .clickOutsideToClose(true)
              .title("Success")
              .textContent(
                "A reset link has been sent to your email, open it and follow the instructions in order to reconfigure MFA."
              )
              .ok("Ok")
              .targetEvent($event)
          );
        },
        error => {
          this.$mdDialog.show(
            this.$mdDialog
              .alert()
              .parent(angular.element(document.body))
              .clickOutsideToClose(true)
              .title("Error")
              .textContent(error)
              .ok("Ok")
              .targetEvent($event)
          );
        }
      );
  }

  forgotPassword(request: webserviceModels.Login.Request.forgotpassword) {
    this.authenticationService.forgotPassword(request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "If registered, an e-mail was sent to {{email}}.",
            { email: request.email }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Whoops! Something went wrong. Please contact support."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  resetPassword(request: webserviceModels.Login.Request.resetpassword) {
    this.authenticationService.resetPassword(request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString("Your password has been updated."),
          this.gettextCatalog.getString("Dismiss")
        );
        this.$state.go("login");
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Your password could not be updated at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  resetOtp(request: webserviceModels.Login.Request.resetotprequest) {
    this.authenticationService.resetotp(request).then(
      success => {
        (this.$scope as any).request.displayResetMFAButton = false;

        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "Your multi-factor authentication configuration has been reset, please login and reconfigure your authentication app.."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        (this.$scope as any).request.invalidResetOtpLink = true;
        (this.$scope as any).request.displayResetMFAButton = false;

        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Your multi-factor authentication configuration could not be updated at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  resetMFA() {
    const request = {
      resetotptoken: (this.$scope as any).request.resetotptoken
    } as webserviceModels.Login.Request.resetotprequest;
    this.resetOtp(request);
  }

  redirectToLogin() {
    this.$state.go("login");
  }
}

import { MD5 } from "crypto-js";
import { AuthenticationService } from "../../services";

export class UserAvatar implements ng.IDirective {
  static $inject = ["authenticationService"];
  constructor(private authenticationService: AuthenticationService) {}

  // tslint:disable:member-ordering
  restrict = "E";
  replace = true;
  scope = {
    user: "@?"
  };

  template = "<div class='user-avatar'></div>'";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    controller: {},
    transclude: ng.ITranscludeFunction
  ): void => {
    if (instanceAttributes.hasOwnProperty("user")) {
      instanceAttributes.$observe("user", value => {
        const emailHash = MD5((scope as any).user).toString();
        const profileImg =
          "url('https://www.gravatar.com/avatar/" + emailHash + "?s=232&d=mp')";
        instanceElement.css("background-image", profileImg);
      });
    } else if (this.authenticationService.isAuthenticated()) {
      const emailHash = MD5(
        this.authenticationService.userSession.email
      ).toString();
      const profileImg =
        "url('https://www.gravatar.com/avatar/" + emailHash + "?s=232&d=mp')";
      instanceElement.css("background-image", profileImg);
    } else {
      instanceElement.remove();
    }
  };
}

import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";

export class AcceptInvitationService {
  // tslint:disable-next-line:variable-name
  private _invitation: any;

  // tslint:disable-next-line:member-ordering
  static $inject = ["resellerService", "$q"];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService
  ) {}

  get invitation(): webserviceModels.Merchant.Response.Invitation.invitation {
    if (this._invitation) return this._invitation;
    throw new ReferenceError("Could not retrieve the invitation");
  }

  load(invitationId: string): ng.IPromise<any> {
    const deferred = this.$q.defer();

    const response = this.resellerService.getInvitation(invitationId);

    response.then(
      success => {
        if (success.meta.result) {
          this._invitation = success.invitation;
          deferred.resolve(success.invitation);
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  accept(invitationId: string): ng.IPromise<any> {
    const deferred = this.$q.defer();

    const response = this.resellerService.acceptInvitation(invitationId);

    response.then(
      success => {
        if (success.meta.result) {
          deferred.resolve(success);
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

import * as endpoints from "../../../app.endpoints";
import * as webserviceModels from "../../interfaces/webservice-models";
import ImpersonateService from "../model/impersonate.service";

export class PaymentWindowService {
  static $inject: Array<string> = ["$http", "impersonateService"];
  constructor(
    private $http: ng.IHttpService,
    private impersonateService: ImpersonateService
  ) {}

  // tslint:disable-next-line:member-ordering
  private endpoint: string = endpoints.paymentWindow;

  private get config(): ng.IRequestShortcutConfig {
    if (!this.impersonateService.authenticationToken) return null;

    return {
      headers: {
        Authorization: `Basic ${this.impersonateService.authenticationToken}`
      }
    };
  }

  addpaymentwindow(
    request: webserviceModels.PaymentWindow.Request.addpaymentwindow
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/paymentwindows`, request, this.config)
      .then<any>(response => response.data);
  }

  listpaymentwindows(): ng.IPromise<
    webserviceModels.PaymentWindow.Response.listpaymentwindowresponse
  > {
    return this.$http
      .get(`${this.endpoint}/paymentwindows`, this.config)
      .then<any>(response => response.data);
  }

  getpaymentwindow(
    paymentwindowid: number
  ): ng.IPromise<
    webserviceModels.PaymentWindow.Response.listpaymentwindowresponse
  > {
    return this.$http
      .get(`${this.endpoint}/paymentwindows/${paymentwindowid}`, this.config)
      .then<any>(response => response.data);
  }

  updatepaymentwindow(
    paymentwindowid: number,
    request: webserviceModels.PaymentWindow.Request.updatepaymentwindow
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(
        `${this.endpoint}/paymentwindows/${paymentwindowid}`,
        request,
        this.config
      )
      .then<any>(response => response.data);
  }

  deletepaymentwindow(
    paymentwindowid: number
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(`${this.endpoint}/paymentwindows/${paymentwindowid}`, this.config)
      .then<any>(response => response.data);
  }

  addpaymentwindowlogo(
    paymentwindowid: number,
    request: webserviceModels.PaymentWindow.Request.addpaymentwindowlogo
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(
        `${this.endpoint}/paymentwindows/${paymentwindowid}/logo`,
        request,
        this.config
      )
      .then<any>(response => response.data);
  }

  deletepaymentwindowlogo(
    paymentwindowid: number
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(
        `${this.endpoint}/paymentwindows/${paymentwindowid}/logo`,
        this.config
      )
      .then<any>(response => response.data);
  }

  getdefaultpaymentwindow(): ng.IPromise<
    webserviceModels.PaymentWindow.Response.listpaymentwindowresponse
  > {
    return this.$http
      .get(`${this.endpoint}/paymentwindows/default`, this.config)
      .then<any>(response => response.data);
  }
}

export default PaymentWindowService;

import * as angular from "angular";
import { Component } from "../shared/decorators";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  CountryListService,
  AgreementGroupListService,
  ResellerService,
  ToastHelperService
} from "../shared/services";

@Component({
  selector: "agreementDataEdit",
  bindings: {
    agreementDataTypes: "<",
    agreementData: "<",
    agreementId: "<",
    isOnboarded: "<"
  },
  require: {
    parentForm: "^form"
  },
  template: require("./agreement-data-edit.html")
})
export class AgreementDataEditComponent {
  agreementDataTypes: Array<
    webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  >;
  agreementData: object;
  agreementId: string;
  isOnboarded?: boolean;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "countryListService",
    "$scope",
    "agreementGroupListService",
    "resellerService",
    "gettextCatalog",
    "toastHelper"
  ];
  constructor(
    private countryListService: CountryListService,
    private $scope: ng.IScope,
    private agreementGroupListService: AgreementGroupListService,
    private resellerService: ResellerService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {}

  get countries() {
    return this.countryListService.countries;
  }

  showErrorOnTab(formController: ng.IFormController) {
    if (!formController) return false;
    if (formController.$valid) return false;
    if (formController.$submitted) return true;

    let isInvalid = false;

    angular.forEach(formController.$error, valdiators => {
      angular.forEach(valdiators, errorElem => {
        if (errorElem.$touched && errorElem.$invalid) {
          isInvalid = true;
        }
      });
    });

    return isInvalid;
  }

  showAgreementFile(
    agreementDataType: webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  ) {
    this.resellerService
      .getagreementdatafile(
        this.agreementGroupListService.companyid,
        this.agreementGroupListService.merchantnumber,
        this.agreementId,
        agreementDataType.key
      )
      .then(
        success => {
          var objectUrl = URL.createObjectURL(success as Blob);
          window.open(objectUrl);
        },
        error => {
          this.toastHelper.toast(
            error.meta.message.merchant ||
              this.gettextCatalog.getString(
                "Error getting agreement data file."
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  showUploadFileDialog(
    $event: MouseEvent,
    agreementDataType: webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  ) {
    const element = angular.element(
      `#agreementDataFileInput_${agreementDataType.key}`
    );

    element.on("change", event => {
      element.off("change");

      this.uploadFile(element[0] as HTMLInputElement, agreementDataType);
    });

    element.click();
  }

  uploadFile(
    input: HTMLInputElement,
    agreementDataType: webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  ) {
    if (!input || !input.files[0]) return;

    const file = input.files[0];
    const reader = new FileReader();

    reader.onload = event => {
      (this.agreementData as any).uploadedFiles[
        agreementDataType.key
      ] = (event.target as any).result;
      this.agreementData[agreementDataType.key] = file.name;

      this.$scope.$apply();

      input.value = "";
    };

    reader.readAsDataURL(file);
  }

  deleteUploadedFile(
    agreementDataType: webserviceModels.Data.Response.GetAgreementDataType.agreementdatatype
  ) {
    delete (this.agreementData as any).uploadedFiles[agreementDataType.key];
    this.agreementData[agreementDataType.key] = null;
  }
}

import {
  TabHelperService,
  MerchantNumberListService,
  SearchHelperService
} from "../../services";

export class AppBarAuthenticatedController {
  static $inject = [
    "$scope",
    "tabHelper",
    "merchantNumberListService",
    "searchHelperService",
    "$mdMedia"
  ];

  constructor(
    private $scope: IAppBarAuthenticatedScope,
    private tabHelper: TabHelperService,
    private merchantNumberListService: MerchantNumberListService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia
  ) {
    this.$scope.tabHelper = tabHelper;
    this.$scope.merchantNumberListService = merchantNumberListService;
    this.$scope.searchHelperService = searchHelperService;
    this.$scope.appBarTheme = $mdMedia("gt-xs") ? "black" : "default";
    this.$scope.tabsTheme = $mdMedia("gt-xs") ? "default-inverted" : "default";
  }
}

export interface IAppBarAuthenticatedScope extends ng.IScope {
  tabHelper: TabHelperService;
  merchantNumberListService: MerchantNumberListService;
  searchHelperService: SearchHelperService;
  appBarTheme: string;
  tabsTheme: string;
}

import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";
import { SearchHelperService } from "../helpers";

export interface ICompanyListServiceCreate {
  company: webserviceModels.Common.Response.Company.company;
  merchantnumber: string;
  password: string;
}

export class CompanyListService {
  // tslint:disable-next-line:variable-name
  private _companies: Array<webserviceModels.Common.Response.Company.company>;
  private lastEvaluatedKey: string;
  private lastSearchQuery: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "resellerService",
    "$q",
    "searchHelperService",
    "$rootScope"
  ];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService,
    private searchHelperService: SearchHelperService,
    private $rootScope: ng.IRootScopeService
  ) {}

  get companies(): Array<webserviceModels.Common.Response.Company.company> {
    if (this._companies) return this._companies;
    throw new ReferenceError("The company list has not been initialized.");
  }

  load(
    forceReload: boolean = false,
    conditionalLoad: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    const searchQuery = this.searchHelperService.searchQuery;
    let filters: string = null;

    if (searchQuery) {
      filters = `(name::q ${searchQuery})or(vatnumber::q ${searchQuery})or(phone::q ${searchQuery})or(contactpersonemail::q ${searchQuery})or(merchantnumber::q ${searchQuery})or(domainname::q ${searchQuery})or(webuseremail::q ${searchQuery})or(merchantagreementagreementdata::q ${searchQuery})`;
      if (!isNaN(searchQuery as any)) filters += `or(id::eq ${searchQuery})`;
    }

    // tslint:disable-next-line:triple-equals
    if (searchQuery != this.lastSearchQuery) forceReload = true;

    if (forceReload || this.lastEvaluatedKey === undefined) {
      this.resellerService.listcompanies(null, "20", filters).then(
        success => {
          if (success.meta.result) {
            this._companies = Enumerable.from(success.companies)
              .except([null])
              .toArray();
            this.lastEvaluatedKey = success.meta.paging.lastevaluatedkey;
            this.lastSearchQuery = searchQuery;
            deferred.resolve();
          } else {
            this._companies = undefined;
            this.lastEvaluatedKey = undefined;
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          this._companies = undefined;
          this.lastEvaluatedKey = undefined;
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    } else {
      if (
        this.lastEvaluatedKey === null ||
        (conditionalLoad && this._companies)
      ) {
        deferred.resolve();
      } else {
        this.resellerService
          .listcompanies(this.lastEvaluatedKey, "20", filters)
          .then(
            success => {
              if (success.meta.result) {
                Array.prototype.push.apply(
                  this._companies,
                  Enumerable.from(success.companies)
                    .except([null])
                    .toArray()
                );
                this.lastEvaluatedKey = success.meta.paging.lastevaluatedkey;
                this.lastSearchQuery = searchQuery;
                deferred.resolve();
              } else {
                try {
                  deferred.reject(success.meta.message.merchant);
                } catch (e) {
                  deferred.reject();
                }
              }
            },
            error => {
              try {
                deferred.reject(error.data.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          );
      }
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._companies) return this.load(true);
    throw new ReferenceError("The company list has not been initialized.");
  }

  create(
    request: webserviceModels.Reseller.Request.SignUp.signup
  ): ng.IPromise<ICompanyListServiceCreate> {
    if (!this._companies) {
      throw new ReferenceError("The company list has not been initialized.");
    }

    const deferred = this.$q.defer<ICompanyListServiceCreate>();
    const response = this.resellerService.signup(request);

    response.then(
      success => {
        if (success.meta.result) {
          // TODO: Change this when webservice properly returns company in signup response.
          this.resellerService.getcompany(success.companyid.toString()).then(
            getCompanySuccess => {
              this._companies.push(getCompanySuccess.company);
              this.$rootScope.$broadcast(
                "agreementGroupListService.paymentTypeFeeAdded",
                getCompanySuccess.company
              );
              deferred.resolve({
                company: getCompanySuccess.company,
                merchantnumber: success.merchantnumber,
                password: success.password
              });
            },
            error => {
              try {
                deferred.reject(error.data.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          );
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  update(
    companyId: string,
    request: webserviceModels.Common.Request.Company.updatecompany
  ): ng.IPromise<any> {
    if (!this._companies) {
      throw new ReferenceError("The company list has not been initialized.");
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.updatecompany(companyId, request);

    response.then(
      success => {
        if (success.meta.result) {
          const company = Enumerable.from(this._companies).first(
            companyX => companyX.id === companyId
          );
          company.address = request.company.address;
          company.city = request.company.city;
          company.contactperson = request.company.contactperson;
          company.contactpersonemail = request.company.contactpersonemail;
          company.countryname = request.company.countryname;
          company.establishedyear = request.company.establishedyear;
          company.name = request.company.name;
          company.phone = request.company.phone;
          company.vatnumber = request.company.vatnumber;
          company.zipcode = request.company.zipcode;
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(
    company: webserviceModels.Common.Response.Company.company,
    isFutureDate: boolean,
    date?: string
  ): ng.IPromise<any> {
    if (!this._companies) {
      throw new ReferenceError("The company list has not been initialized.");
    }

    const deferred = this.$q.defer();

    if (!company) {
      return;
    }

    if (isFutureDate) {
      this.resellerService.deletecompany(company.id, date);
      company.deleteddate = date;
    } else {
      this.resellerService.deletecompany(company.id).then(
        success => {
          if (success.meta.result) {
            this._companies = Enumerable.from(this._companies)
              .except([company])
              .toArray();
            if (!this._companies.length) {
              this.load(true).finally(() => {
                deferred.resolve();
              });
            } else {
              deferred.resolve();
            }
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }
    return deferred.promise;
  }

  canceldelete(
    company: webserviceModels.Common.Response.Company.company
  ): ng.IPromise<any> {
    if (!this._companies) {
      throw new ReferenceError("The company list has not been initialized.");
    }

    const deferred = this.$q.defer();

    if (!company) {
      return;
    }
    const companyId = company.id;
    this.resellerService.canceldeletecompany(company.id).then(
      success => {
        if (success.meta.result) {
          const company = Enumerable.from(this._companies).first(
            companyX => companyX.id === companyId
          );
          company.deleteddate = "9999-12-31";
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

export default CompanyListService;

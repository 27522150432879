import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { DataService } from "../clients";

export class RequirementsFilterListService {
  // tslint:disable-next-line:variable-name
  private _requirementsFilters: Array<
    webserviceModels.Common.requirementsfilter
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = ["dataService", "$q"];
  constructor(private dataService: DataService, private $q: ng.IQService) {}

  get requirementsFilters(): Array<webserviceModels.Common.requirementsfilter> {
    if (this._requirementsFilters) return this._requirementsFilters;
    throw new ReferenceError(
      "The requirements filter list has not been initialized."
    );
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._requirementsFilters) {
      deferred.resolve();
    } else {
      const response = this.dataService.listrequirementsfilters();

      response.then(success => {
        this._requirementsFilters = success.requirementsfilters;
        deferred.resolve();
      });
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._requirementsFilters) return this.load(true);
    throw new ReferenceError(
      "The requirements filter list has not been initialized."
    );
  }
}

export default RequirementsFilterListService;

import $ from "jquery";
import * as angular from "angular";

export class KeyEvent implements ng.IDirective {
  static $inject = ["$timeout"];

  constructor(private $timeout: ng.ITimeoutService) {}

  // tslint:disable-next-line:member-ordering
  restrict = "A";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    switch (instanceAttributes.keyEvent) {
      case "keydown": {
        angular.element(window).keydown((eventObject: any) => {
          const target = $(eventObject.target);
          if (
            target.is("input") ||
            target.is("textarea") ||
            target.is("select")
          ) {
            return;
          }
          if (eventObject.which === instanceAttributes.bindToKey) {
            const index = instanceElement
              .parent()
              .children()
              .index(instanceElement);
            this.$timeout(() => {
              instanceElement.click();
            });
          }
        });
        break;
      }
      case "keypress": {
        angular.element(window).keypress((eventObject: any) => {
          const target = $(eventObject.target);
          if (
            target.is("input") ||
            target.is("textarea") ||
            target.is("select")
          ) {
            return;
          }
          if (eventObject.which === instanceAttributes.bindToKey) {
            const index = instanceElement
              .parent()
              .children()
              .index(instanceElement);
            this.$timeout(() => {
              instanceElement.click();
            });
          }
        });
        break;
      }
      case "keyup": {
        angular.element(window).keyup((eventObject: any) => {
          const target = $(eventObject.target);
          if (
            target.is("input") ||
            target.is("textarea") ||
            target.is("select")
          ) {
            return;
          }
          if (eventObject.which === instanceAttributes.bindToKey) {
            const index = instanceElement
              .parent()
              .children()
              .index(instanceElement);
            this.$timeout(() => {
              instanceElement.click();
            });
          }
        });
        break;
      }
    }
  };
}

import * as angular from "angular";
import * as Enumerable from "linq";
import tinycolor from "tinycolor2";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  ResponseCodeListService,
  ToastHelperService,
  SearchHelperService
} from "../shared/services";
import { ResponseCodesFilterService } from "./shared/responsecodes-filter.service";

export class ResponseCodeListController {
  static $inject = [
    "$scope",
    "$state",
    "gettextCatalog",
    "responseCodeListService",
    "toastHelper",
    "searchHelperService",
    "$rootScope",
    "$mdBottomSheet",
    "responseCodesFilterService",
    "$timeout"
  ];

  // tslint:disable-next-line:variable-name
  private _showFilters = false;

  constructor(
    private $scope: IResponseCodeListScope,
    private $state: ng.ui.IStateService,
    private gettextCatalog: any,
    private responseCodeListService: ResponseCodeListService,
    private toastHelper: ToastHelperService,
    private searchHelperService: SearchHelperService,
    private $rootScope: ng.IRootScopeService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private responseCodesFilterService: ResponseCodesFilterService,
    private $timeout: ng.ITimeoutService
  ) {
    $scope.responseCodeListService = responseCodeListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });

    let timeout = null;
    $scope.$watch("searchHelperService.searchQuery", (newValue: string) => {
      $timeout.cancel(timeout);
      timeout = $timeout(() => {
        this.searchQuery = newValue;
      }, 300);
    });
  }

  // tslint:disable-next-line:member-ordering
  searchQuery: string;

  get filter() {
    return this.responseCodesFilterService.filter;
  }

  get responseCodes() {
    const self = this;
    let responseCodes = this.responseCodeListService.responseCodes;

    if (this.filter.sources && this.filter.sources.length) {
      if (this.filter.includeRelated) {
        responseCodes = responseCodes.filter(
          responseCode =>
            hasSourceSelfOrAncestor(responseCode) ||
            hasSourceSelfOrDescendant(responseCode)
        );
      } else {
        responseCodes = responseCodes.filter(
          responseCode => this.filter.sources.indexOf(responseCode.source) > -1
        );
      }
    }

    return responseCodes;

    function hasSourceSelfOrAncestor(
      responseCode: webserviceModels.Data.Response.ResponseCodes.responsecode
    ) {
      if (self.filter.sources.indexOf(responseCode.source) > -1) return true;
      if (!responseCode.parentsource || !responseCode.parentactioncode) {
        return false;
      }

      const parentResponseCode = Enumerable.from(
        self.responseCodeListService.responseCodes
      ).firstOrDefault(
        rC =>
          rC.source === responseCode.parentsource &&
          rC.actioncode === responseCode.parentactioncode,
        null
      );

      if (!parentResponseCode) return false;

      return hasSourceSelfOrAncestor(parentResponseCode);
    }

    function hasSourceSelfOrDescendant(
      responseCode: webserviceModels.Data.Response.ResponseCodes.responsecode
    ) {
      if (self.filter.sources.indexOf(responseCode.source) > -1) return true;

      const children = self.responseCodeListService.responseCodes.filter(
        rC =>
          rC.parentsource === responseCode.source &&
          rC.parentactioncode === responseCode.actioncode
      );

      return Enumerable.from(children).any(hasSourceSelfOrDescendant);
    }
  }

  getAncestors(
    responseCode: webserviceModels.Data.Response.ResponseCodes.responsecode
  ) {
    if (!responseCode.parentsource || !responseCode.parentactioncode) return [];

    const ancestors = [];

    const parentResponseCode = Enumerable.from(
      this.responseCodeListService.responseCodes
    ).firstOrDefault(
      rC =>
        rC.source === responseCode.parentsource &&
        rC.actioncode === responseCode.parentactioncode,
      null
    );

    if (parentResponseCode) {
      ancestors.push(
        parentResponseCode,
        ...this.getAncestors(parentResponseCode)
      );
    }

    return ancestors;
  }

  onRefreshClicked() {
    const refreshPromise = this.responseCodeListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The response code list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The response code list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  getParentSourceColor(parentsource: string): string {
    let hash = 0;

    for (let i = 0; i < parentsource.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = parentsource.charCodeAt(i) + ((hash << 5) - hash);
    }

    // tslint:disable-next-line:no-bitwise
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();

    const hex = "00000".substring(0, 6 - c.length) + c;

    const hsl = tinycolor(hex).toHsl();
    hsl.h = parseFloat((Math.round(hsl.h * 10) / 10).toFixed(1)); // Decrease number of variants.
    hsl.s = 0.5; // Normalizing saturation.
    hsl.l = 0.5; // Normalizing lightness.

    return tinycolor(hsl).toRgbString();
  }

  toggleFilters() {
    if (this._showFilters) {
      this.$mdBottomSheet.hide();
    } else {
      this._showFilters = true;
      this.$mdBottomSheet
        .show({
          parent: angular.element("#overlay-container"),
          clickOutsideToClose: false,
          disableBackdrop: true,
          disableParentScroll: false,
          escapeToClose: false,
          template: `
                        <md-bottom-sheet class="md-grid md-whiteframe-z1" layout="column">
                            <responsecodes-filter></responsecodes-filter>
                        </md-bottom-sheet>
                    `
        } as any)
        .finally(() => (this._showFilters = false));
    }
  }
}

export interface IResponseCodeListScope extends ng.IScope {
  responseCodeListService: ResponseCodeListService;
  searchHelperService: SearchHelperService;
}

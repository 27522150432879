import * as angular from "angular";

export class Fabify implements ng.IDirective {
  static $inject = ["$animate"];
  constructor(private $animate: ng.animate.IAnimateService) {}

  // tslint:disable:member-ordering
  restrict = "A";
  scope = false;

  compile: ng.IDirectiveCompileFn = (templateElement, templateAttributes) => {
    return {
      post: (
        $scope: ng.IScope,
        $element: ng.IAugmentedJQuery,
        $attrs: ng.IAttributes
      ) => {
        this.$animate.enter(
          $element,
          angular.element("#overlay-container"),
          null,
          {
            addClass: "animate-fab"
          }
        );

        $scope.$on("$destroy", event => {
          this.$animate.leave($element);
        });
      }
    };
  };
}

import "./visited-companies-list.scss";
import * as Enumerable from "linq";
import { getFactoryFor } from "../../helpers/directive-helper";
import {
  MerchantNumberListService,
  ToastHelperService,
  WebStorageService,
  AuthenticationService,
  CompanyService
} from "../../services";
import * as webserviceModels from "../../interfaces/webservice-models";

export class VisitedCompaniesList implements ng.IDirective {
  static $inject = [
    "visitedCompaniesListService",
    "$state",
    "merchantNumberListService",
    "toastHelper",
    "gettextCatalog",
    "$mdSidenav"
  ];
  constructor(
    private visitedCompaniesListService: VisitedCompaniesListService,
    private $state: ng.ui.IStateService,
    private merchantNumberListService: MerchantNumberListService,
    private toastHelper: ToastHelperService,
    private gettextCatalog: any,
    private $mdSidenav: ng.material.ISidenavService
  ) {}

  // tslint:disable:member-ordering
  restrict = "E";
  replace = true;

  template = `
    <md-menu class="animate-bounce" md-position-mode="target-right target" md-theme="default" ng-show="visitedCompaniesListService.savedStates.length">
        <md-button class="md-icon-button md-accent md-hue-3" ng-click="$mdMenu.open($event)">
            <md-tooltip>
                <translate>History</translate>
            </md-tooltip>
            <md-icon md-menu-origin>history</md-icon>
        </md-button>
        <md-menu-content width="4">
            <md-menu-item ng-repeat="savedState in visitedCompaniesListService.savedStates">
                <md-button ng-click="goToSavedState($event, savedState)">
                    <p flex translate>{{savedState.company.name}}</p>
                </md-button
            </md-menu-item>
        </md-menu-content>
    </md-menu>
`;

  link: ng.IDirectiveLinkFn = (
    scope: IVisitedCompaniesListScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    scope.visitedCompaniesListService = this.visitedCompaniesListService;

    scope.goToSavedState = (
      $event: JQueryEventObject,
      savedState: IVisitedCompanySavedState
    ) => {
      this.$state.go(savedState.state.name, savedState.state.params);
      this.$mdSidenav("sidenav-left").close();
    };
  };
}

export interface IVisitedCompaniesListScope extends ng.IScope {
  visitedCompaniesListService: VisitedCompaniesListService;
  goToSavedState(
    $event: JQueryEventObject,
    savedState: IVisitedCompanySavedState
  );
  hideCompanyWithId: string;
}

export interface ISavedStateParams {
  name: string;
  params: any;
}

export interface IVisitedCompanySavedState {
  state: ISavedStateParams;
  company: webserviceModels.Common.Response.Company.company;
}

export interface IVisitedCompaniesListService {
  savedStates: Array<IVisitedCompanySavedState>;
  insert(savedStateParams: ISavedStateParams);
  hideCurrentCompany: boolean;
}

// tslint:disable:max-classes-per-file
export class VisitedCompaniesListService
  implements IVisitedCompaniesListService {
  private maxShownSavedStates: number;
  hideCurrentCompany: boolean;

  static $inject = [
    "$timeout",
    "webStorageService",
    "authenticationService",
    "companyService"
  ];
  constructor(
    private $timeout: ng.ITimeoutService,
    private webStorageService: WebStorageService,
    private authenticationService: AuthenticationService,
    private companyService: CompanyService
  ) {
    this.maxShownSavedStates = 15; // TODO: from config
  }

  get savedStates(): Array<IVisitedCompanySavedState> {
    const currentCompanyId =
      this.hideCurrentCompany && this.companyService.company
        ? this.companyService.company.id
        : null;

    return Enumerable.from(this.getFromStorage())
      .where(savedState => savedState.company.id !== currentCompanyId)
      .take(this.maxShownSavedStates)
      .toArray();
  }

  set savedStates(savedStates: Array<IVisitedCompanySavedState>) {
    this.webStorageService.$storage[
      this.authenticationService.userSession.selectedResellerNumber +
        "#visitedCompaniesList"
    ] = savedStates;
  }

  insert(savedStateParams: ISavedStateParams) {
    if (!this.companyService.company) {
      throw new ReferenceError("No company has been loaded.");
    }

    const visitedCompanySavedState: IVisitedCompanySavedState = {
      state: savedStateParams,
      company: this.companyService.company
    };

    const savedStates = this.getFromStorage();

    const index = Enumerable.from(savedStates).indexOf(
      savedState =>
        savedState.state.params.companyid ===
        visitedCompanySavedState.company.id
    );

    if (index > -1) {
      savedStates[index].state = visitedCompanySavedState.state;
      if (index === 0) return;

      savedStates.splice(index, 1);
      this.savedStates = savedStates;

      this.$timeout(() => {
        savedStates.unshift(visitedCompanySavedState);
        this.savedStates = savedStates;
      }, 300);
    } else {
      savedStates.unshift(visitedCompanySavedState);
      this.savedStates = savedStates;

      if (savedStates.length > this.maxShownSavedStates + 1) {
        this.$timeout(() => {
          savedStates.length = this.maxShownSavedStates + 1;
          this.savedStates = savedStates;
        }, 300);
      }
    }
  }

  private getFromStorage(): Array<IVisitedCompanySavedState> {
    return (
      this.webStorageService.$storage[
        this.authenticationService.userSession.selectedResellerNumber +
          "#visitedCompaniesList"
      ] || []
    );
  }
}

export class VisitedCompaniesListRunConfig {
  static $inject = ["$rootScope", "visitedCompaniesListService"];

  constructor(
    $rootScope: any,
    visitedCompaniesListService: VisitedCompaniesListService
  ) {
    $rootScope.visitedCompaniesListService = visitedCompaniesListService;

    $rootScope.$on(
      "$stateChangeSuccess",
      (
        event: ng.IAngularEvent,
        toState,
        toParams,
        fromState,
        fromParams,
        error
      ) => {
        if (toState.saveVisitedCompaniesState) {
          $rootScope.visitedCompaniesListService.insert({
            name: toState.name,
            params: toParams
          });

          $rootScope.visitedCompaniesListService.hideCurrentCompany = true;
        } else {
          $rootScope.visitedCompaniesListService.hideCurrentCompany = false;
        }
      }
    );
  }
}

import { IRegionInfo } from "./angular-bambora-currency";

export const currencyData: Array<IRegionInfo> = [
  {
    englishName: "Saudi Arabia",
    nativeName: "المملكة العربية السعودية",
    threeLetterISORegionName: "SAU",
    twoLetterISORegionName: "SA",
    language: {
      nativeName: "العربية (المملكة العربية السعودية)",
      englishName: "Arabic (Saudi Arabia)",
      code: "ar-SA",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "SAR",
      englishName: "Saudi Riyal",
      nativeName: "ريال سعودي",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ر.س.‏"
      }
    }
  },
  {
    englishName: "Bulgaria",
    nativeName: "България",
    threeLetterISORegionName: "BGR",
    twoLetterISORegionName: "BG",
    language: {
      nativeName: "български (България)",
      englishName: "Bulgarian (Bulgaria)",
      code: "bg-BG",
      threeLetterIsoLanguageName: "bul",
      twoLetterIsoLanguageName: "bg"
    },
    currency: {
      isoSymbol: "BGN",
      englishName: "Bulgarian Lev",
      nativeName: "България лев",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "лв."
      }
    }
  },
  {
    englishName: "Spain",
    nativeName: "Espanya",
    threeLetterISORegionName: "ESP",
    twoLetterISORegionName: "ES",
    language: {
      nativeName: "català (català)",
      englishName: "Catalan (Catalan)",
      code: "ca-ES",
      threeLetterIsoLanguageName: "cat",
      twoLetterIsoLanguageName: "ca"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Taiwan",
    nativeName: "台灣",
    threeLetterISORegionName: "TWN",
    twoLetterISORegionName: "TW",
    language: {
      nativeName: "中文(台灣)",
      englishName: "Chinese (Traditional, Taiwan)",
      code: "zh-TW",
      threeLetterIsoLanguageName: "zho",
      twoLetterIsoLanguageName: "zh"
    },
    currency: {
      isoSymbol: "TWD",
      englishName: "New Taiwan Dollar",
      nativeName: "新台幣",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "NT$"
      }
    }
  },
  {
    englishName: "Czech Republic",
    nativeName: "Česká republika",
    threeLetterISORegionName: "CZE",
    twoLetterISORegionName: "CZ",
    language: {
      nativeName: "čeština (Česká republika)",
      englishName: "Czech (Czech Republic)",
      code: "cs-CZ",
      threeLetterIsoLanguageName: "ces",
      twoLetterIsoLanguageName: "cs"
    },
    currency: {
      isoSymbol: "CZK",
      englishName: "Czech Koruna",
      nativeName: "Koruna Česká",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "Kč"
      }
    }
  },
  {
    englishName: "Denmark",
    nativeName: "Danmark",
    threeLetterISORegionName: "DNK",
    twoLetterISORegionName: "DK",
    language: {
      nativeName: "dansk (Danmark)",
      englishName: "Danish (Denmark)",
      code: "da-DK",
      threeLetterIsoLanguageName: "dan",
      twoLetterIsoLanguageName: "da"
    },
    currency: {
      isoSymbol: "DKK",
      englishName: "Danish Krone",
      nativeName: "Dansk krone",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr."
      }
    }
  },
  {
    englishName: "Germany",
    nativeName: "Deutschland",
    threeLetterISORegionName: "DEU",
    twoLetterISORegionName: "DE",
    language: {
      nativeName: "Deutsch (Deutschland)",
      englishName: "German (Germany)",
      code: "de-DE",
      threeLetterIsoLanguageName: "deu",
      twoLetterIsoLanguageName: "de"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Greece",
    nativeName: "Ελλάδα",
    threeLetterISORegionName: "GRC",
    twoLetterISORegionName: "GR",
    language: {
      nativeName: "Ελληνικά (Ελλάδα)",
      englishName: "Greek (Greece)",
      code: "el-GR",
      threeLetterIsoLanguageName: "ell",
      twoLetterIsoLanguageName: "el"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "ευρώ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "United States",
    nativeName: "United States",
    threeLetterISORegionName: "USA",
    twoLetterISORegionName: "US",
    language: {
      nativeName: "English (United States)",
      englishName: "English (United States)",
      code: "en-US",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "US Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Finland",
    nativeName: "Suomi",
    threeLetterISORegionName: "FIN",
    twoLetterISORegionName: "FI",
    language: {
      nativeName: "suomi (Suomi)",
      englishName: "Finnish (Finland)",
      code: "fi-FI",
      threeLetterIsoLanguageName: "fin",
      twoLetterIsoLanguageName: "fi"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "France",
    nativeName: "France",
    threeLetterISORegionName: "FRA",
    twoLetterISORegionName: "FR",
    language: {
      nativeName: "français (France)",
      englishName: "French (France)",
      code: "fr-FR",
      threeLetterIsoLanguageName: "fra",
      twoLetterIsoLanguageName: "fr"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Israel",
    nativeName: "ישראל",
    threeLetterISORegionName: "ISR",
    twoLetterISORegionName: "IL",
    language: {
      nativeName: "עברית (ישראל)",
      englishName: "Hebrew (Israel)",
      code: "he-IL",
      threeLetterIsoLanguageName: "heb",
      twoLetterIsoLanguageName: "he"
    },
    currency: {
      isoSymbol: "ILS",
      englishName: "Israeli New Shekel",
      nativeName: "שקל חדש",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "₪"
      }
    }
  },
  {
    englishName: "Hungary",
    nativeName: "Magyarország",
    threeLetterISORegionName: "HUN",
    twoLetterISORegionName: "HU",
    language: {
      nativeName: "magyar (Magyarország)",
      englishName: "Hungarian (Hungary)",
      code: "hu-HU",
      threeLetterIsoLanguageName: "hun",
      twoLetterIsoLanguageName: "hu"
    },
    currency: {
      isoSymbol: "HUF",
      englishName: "Hungarian Forint",
      nativeName: "forint",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "Ft"
      }
    }
  },
  {
    englishName: "Iceland",
    nativeName: "Ísland",
    threeLetterISORegionName: "ISL",
    twoLetterISORegionName: "IS",
    language: {
      nativeName: "íslenska (Ísland)",
      englishName: "Icelandic (Iceland)",
      code: "is-IS",
      threeLetterIsoLanguageName: "isl",
      twoLetterIsoLanguageName: "is"
    },
    currency: {
      isoSymbol: "ISK",
      englishName: "Icelandic Krona",
      nativeName: "Króna",
      format: {
        decimalDigits: 0,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr."
      }
    }
  },
  {
    englishName: "Italy",
    nativeName: "Italia",
    threeLetterISORegionName: "ITA",
    twoLetterISORegionName: "IT",
    language: {
      nativeName: "italiano (Italia)",
      englishName: "Italian (Italy)",
      code: "it-IT",
      threeLetterIsoLanguageName: "ita",
      twoLetterIsoLanguageName: "it"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Japan",
    nativeName: "日本",
    threeLetterISORegionName: "JPN",
    twoLetterISORegionName: "JP",
    language: {
      nativeName: "日本語 (日本)",
      englishName: "Japanese (Japan)",
      code: "ja-JP",
      threeLetterIsoLanguageName: "jpn",
      twoLetterIsoLanguageName: "ja"
    },
    currency: {
      isoSymbol: "JPY",
      englishName: "Japanese Yen",
      nativeName: "円",
      format: {
        decimalDigits: 0,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "¥"
      }
    }
  },
  {
    englishName: "Korea",
    nativeName: "대한민국",
    threeLetterISORegionName: "KOR",
    twoLetterISORegionName: "KR",
    language: {
      nativeName: "한국어 (대한민국)",
      englishName: "Korean (Korea)",
      code: "ko-KR",
      threeLetterIsoLanguageName: "kor",
      twoLetterIsoLanguageName: "ko"
    },
    currency: {
      isoSymbol: "KRW",
      englishName: "Korean Won",
      nativeName: "원",
      format: {
        decimalDigits: 0,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "₩"
      }
    }
  },
  {
    englishName: "Netherlands",
    nativeName: "Nederland",
    threeLetterISORegionName: "NLD",
    twoLetterISORegionName: "NL",
    language: {
      nativeName: "Nederlands (Nederland)",
      englishName: "Dutch (Netherlands)",
      code: "nl-NL",
      threeLetterIsoLanguageName: "nld",
      twoLetterIsoLanguageName: "nl"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Norway",
    nativeName: "Norge",
    threeLetterISORegionName: "NOR",
    twoLetterISORegionName: "NO",
    language: {
      nativeName: "norsk, bokmål (Norge)",
      englishName: "Norwegian, Bokmål (Norway)",
      code: "nb-NO",
      threeLetterIsoLanguageName: "nob",
      twoLetterIsoLanguageName: "nb"
    },
    currency: {
      isoSymbol: "NOK",
      englishName: "Norwegian Krone",
      nativeName: "Norsk krone",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Poland",
    nativeName: "Polska",
    threeLetterISORegionName: "POL",
    twoLetterISORegionName: "PL",
    language: {
      nativeName: "polski (Polska)",
      englishName: "Polish (Poland)",
      code: "pl-PL",
      threeLetterIsoLanguageName: "pol",
      twoLetterIsoLanguageName: "pl"
    },
    currency: {
      isoSymbol: "PLN",
      englishName: "Polish Zloty",
      nativeName: "Złoty",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "zł"
      }
    }
  },
  {
    englishName: "Brazil",
    nativeName: "Brasil",
    threeLetterISORegionName: "BRA",
    twoLetterISORegionName: "BR",
    language: {
      nativeName: "Português (Brasil)",
      englishName: "Portuguese (Brazil)",
      code: "pt-BR",
      threeLetterIsoLanguageName: "por",
      twoLetterIsoLanguageName: "pt"
    },
    currency: {
      isoSymbol: "BRL",
      englishName: "Real",
      nativeName: "Real",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "R$"
      }
    }
  },
  {
    englishName: "Switzerland",
    nativeName: "Svizra",
    threeLetterISORegionName: "CHE",
    twoLetterISORegionName: "CH",
    language: {
      nativeName: "Rumantsch (Svizra)",
      englishName: "Romansh (Switzerland)",
      code: "rm-CH",
      threeLetterIsoLanguageName: "roh",
      twoLetterIsoLanguageName: "rm"
    },
    currency: {
      isoSymbol: "CHF",
      englishName: "Swiss Franc",
      nativeName: "Franc svizzer",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: "'",
        groupSizes: [3],
        symbol: "fr."
      }
    }
  },
  {
    englishName: "Romania",
    nativeName: "România",
    threeLetterISORegionName: "ROU",
    twoLetterISORegionName: "RO",
    language: {
      nativeName: "română (România)",
      englishName: "Romanian (Romania)",
      code: "ro-RO",
      threeLetterIsoLanguageName: "ron",
      twoLetterIsoLanguageName: "ro"
    },
    currency: {
      isoSymbol: "RON",
      englishName: "Romanian Leu",
      nativeName: "Leu",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "lei"
      }
    }
  },
  {
    englishName: "Russia",
    nativeName: "Россия",
    threeLetterISORegionName: "RUS",
    twoLetterISORegionName: "RU",
    language: {
      nativeName: "русский (Россия)",
      englishName: "Russian (Russia)",
      code: "ru-RU",
      threeLetterIsoLanguageName: "rus",
      twoLetterIsoLanguageName: "ru"
    },
    currency: {
      isoSymbol: "RUB",
      englishName: "Russian Ruble",
      nativeName: "рубль",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "₽"
      }
    }
  },
  {
    englishName: "Croatia",
    nativeName: "Hrvatska",
    threeLetterISORegionName: "HRV",
    twoLetterISORegionName: "HR",
    language: {
      nativeName: "hrvatski (Hrvatska)",
      englishName: "Croatian (Croatia)",
      code: "hr-HR",
      threeLetterIsoLanguageName: "hrv",
      twoLetterIsoLanguageName: "hr"
    },
    currency: {
      isoSymbol: "HRK",
      englishName: "Croatian Kuna",
      nativeName: "hrvatska kuna",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kn"
      }
    }
  },
  {
    englishName: "Slovakia",
    nativeName: "Slovenská republika",
    threeLetterISORegionName: "SVK",
    twoLetterISORegionName: "SK",
    language: {
      nativeName: "slovenčina (Slovenská republika)",
      englishName: "Slovak (Slovakia)",
      code: "sk-SK",
      threeLetterIsoLanguageName: "slk",
      twoLetterIsoLanguageName: "sk"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Albania",
    nativeName: "Shqipëria",
    threeLetterISORegionName: "ALB",
    twoLetterISORegionName: "AL",
    language: {
      nativeName: "shqipe (Shqipëria)",
      englishName: "Albanian (Albania)",
      code: "sq-AL",
      threeLetterIsoLanguageName: "sqi",
      twoLetterIsoLanguageName: "sq"
    },
    currency: {
      isoSymbol: "ALL",
      englishName: "Albanian Lek",
      nativeName: "Lek",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Lek"
      }
    }
  },
  {
    englishName: "Sweden",
    nativeName: "Sverige",
    threeLetterISORegionName: "SWE",
    twoLetterISORegionName: "SE",
    language: {
      nativeName: "svenska (Sverige)",
      englishName: "Swedish (Sweden)",
      code: "sv-SE",
      threeLetterIsoLanguageName: "swe",
      twoLetterIsoLanguageName: "sv"
    },
    currency: {
      isoSymbol: "SEK",
      englishName: "Swedish Krona",
      nativeName: "Svensk krona",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Thailand",
    nativeName: "ไทย",
    threeLetterISORegionName: "THA",
    twoLetterISORegionName: "TH",
    language: {
      nativeName: "ไทย (ไทย)",
      englishName: "Thai (Thailand)",
      code: "th-TH",
      threeLetterIsoLanguageName: "tha",
      twoLetterIsoLanguageName: "th"
    },
    currency: {
      isoSymbol: "THB",
      englishName: "Thai Baht",
      nativeName: "บาท",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "฿"
      }
    }
  },
  {
    englishName: "Turkey",
    nativeName: "Türkiye",
    threeLetterISORegionName: "TUR",
    twoLetterISORegionName: "TR",
    language: {
      nativeName: "Türkçe (Türkiye)",
      englishName: "Turkish (Turkey)",
      code: "tr-TR",
      threeLetterIsoLanguageName: "tur",
      twoLetterIsoLanguageName: "tr"
    },
    currency: {
      isoSymbol: "TRY",
      englishName: "Turkish Lira",
      nativeName: "Türk Lirası",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "₺"
      }
    }
  },
  {
    englishName: "Islamic Republic of Pakistan",
    nativeName: "پاکستان",
    threeLetterISORegionName: "PAK",
    twoLetterISORegionName: "PK",
    language: {
      nativeName: "اُردو (پاکستان)",
      englishName: "Urdu (Islamic Republic of Pakistan)",
      code: "ur-PK",
      threeLetterIsoLanguageName: "urd",
      twoLetterIsoLanguageName: "ur"
    },
    currency: {
      isoSymbol: "PKR",
      englishName: "Pakistan Rupee",
      nativeName: "روپيه",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "Rs"
      }
    }
  },
  {
    englishName: "Indonesia",
    nativeName: "Indonesia",
    threeLetterISORegionName: "IDN",
    twoLetterISORegionName: "ID",
    language: {
      nativeName: "Bahasa Indonesia (Indonesia)",
      englishName: "Indonesian (Indonesia)",
      code: "id-ID",
      threeLetterIsoLanguageName: "ind",
      twoLetterIsoLanguageName: "id"
    },
    currency: {
      isoSymbol: "IDR",
      englishName: "Indonesian Rupiah",
      nativeName: "Rupiah",
      format: {
        decimalDigits: 0,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Rp"
      }
    }
  },
  {
    englishName: "Ukraine",
    nativeName: "Україна",
    threeLetterISORegionName: "UKR",
    twoLetterISORegionName: "UA",
    language: {
      nativeName: "українська (Україна)",
      englishName: "Ukrainian (Ukraine)",
      code: "uk-UA",
      threeLetterIsoLanguageName: "ukr",
      twoLetterIsoLanguageName: "uk"
    },
    currency: {
      isoSymbol: "UAH",
      englishName: "Ukrainian Grivna",
      nativeName: "гривня",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "₴"
      }
    }
  },
  {
    englishName: "Belarus",
    nativeName: "Беларусь",
    threeLetterISORegionName: "BLR",
    twoLetterISORegionName: "BY",
    language: {
      nativeName: "Беларускі (Беларусь)",
      englishName: "Belarusian (Belarus)",
      code: "be-BY",
      threeLetterIsoLanguageName: "bel",
      twoLetterIsoLanguageName: "be"
    },
    currency: {
      isoSymbol: "BYR",
      englishName: "Belarusian Ruble",
      nativeName: "рубль",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "р."
      }
    }
  },
  {
    englishName: "Slovenia",
    nativeName: "Slovenija",
    threeLetterISORegionName: "SVN",
    twoLetterISORegionName: "SI",
    language: {
      nativeName: "slovenski (Slovenija)",
      englishName: "Slovenian (Slovenia)",
      code: "sl-SI",
      threeLetterIsoLanguageName: "slv",
      twoLetterIsoLanguageName: "sl"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Estonia",
    nativeName: "Eesti",
    threeLetterISORegionName: "EST",
    twoLetterISORegionName: "EE",
    language: {
      nativeName: "eesti (Eesti)",
      englishName: "Estonian (Estonia)",
      code: "et-EE",
      threeLetterIsoLanguageName: "est",
      twoLetterIsoLanguageName: "et"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Latvia",
    nativeName: "Latvija",
    threeLetterISORegionName: "LVA",
    twoLetterISORegionName: "LV",
    language: {
      nativeName: "latviešu (Latvija)",
      englishName: "Latvian (Latvia)",
      code: "lv-LV",
      threeLetterIsoLanguageName: "lav",
      twoLetterIsoLanguageName: "lv"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Lithuania",
    nativeName: "Lietuva",
    threeLetterISORegionName: "LTU",
    twoLetterISORegionName: "LT",
    language: {
      nativeName: "lietuvių (Lietuva)",
      englishName: "Lithuanian (Lithuania)",
      code: "lt-LT",
      threeLetterIsoLanguageName: "lit",
      twoLetterIsoLanguageName: "lt"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Tajikistan",
    nativeName: "Тоҷикистон",
    threeLetterISORegionName: "TAJ",
    twoLetterISORegionName: "TJ",
    language: {
      nativeName: "Тоҷикӣ (Тоҷикистон)",
      englishName: "Tajik (Cyrillic, Tajikistan)",
      code: "tg-Cyrl-TJ",
      threeLetterIsoLanguageName: "tgk",
      twoLetterIsoLanguageName: "tg"
    },
    currency: {
      isoSymbol: "TJS",
      englishName: "Ruble",
      nativeName: "рубл",
      format: {
        decimalDigits: 2,
        decimalSeparator: ";",
        groupSeparator: " ",
        groupSizes: [3, 0],
        symbol: "т.р."
      }
    }
  },
  {
    englishName: "Iran",
    nativeName: "ایران",
    threeLetterISORegionName: "IRN",
    twoLetterISORegionName: "IR",
    language: {
      nativeName: "فارسى (ایران)",
      englishName: "Persian",
      code: "fa-IR",
      threeLetterIsoLanguageName: "fas",
      twoLetterIsoLanguageName: "fa"
    },
    currency: {
      isoSymbol: "IRR",
      englishName: "Iranian Rial",
      nativeName: "رىال",
      format: {
        decimalDigits: 2,
        decimalSeparator: "/",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ريال"
      }
    }
  },
  {
    englishName: "Vietnam",
    nativeName: "Việt Nam",
    threeLetterISORegionName: "VNM",
    twoLetterISORegionName: "VN",
    language: {
      nativeName: "Tiếng Việt (Việt Nam)",
      englishName: "Vietnamese (Vietnam)",
      code: "vi-VN",
      threeLetterIsoLanguageName: "vie",
      twoLetterIsoLanguageName: "vi"
    },
    currency: {
      isoSymbol: "VND",
      englishName: "Vietnamese Dong",
      nativeName: "Đồng",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "₫"
      }
    }
  },
  {
    englishName: "Armenia",
    nativeName: "Հայաստան",
    threeLetterISORegionName: "ARM",
    twoLetterISORegionName: "AM",
    language: {
      nativeName: "Հայերեն (Հայաստան)",
      englishName: "Armenian (Armenia)",
      code: "hy-AM",
      threeLetterIsoLanguageName: "hye",
      twoLetterIsoLanguageName: "hy"
    },
    currency: {
      isoSymbol: "AMD",
      englishName: "Armenian Dram",
      nativeName: "դրամ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "դր."
      }
    }
  },
  {
    englishName: "Azerbaijan",
    nativeName: "Azərbaycan",
    threeLetterISORegionName: "AZE",
    twoLetterISORegionName: "AZ",
    language: {
      nativeName: "Azərbaycan­ılı (Azərbaycan)",
      englishName: "Azeri (Latin, Azerbaijan)",
      code: "az-Latn-AZ",
      threeLetterIsoLanguageName: "aze",
      twoLetterIsoLanguageName: "az"
    },
    currency: {
      isoSymbol: "AZN",
      englishName: "Azerbaijanian Manat",
      nativeName: "manat",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "man."
      }
    }
  },
  {
    englishName: "Spain",
    nativeName: "Espainia",
    threeLetterISORegionName: "ESP",
    twoLetterISORegionName: "ES",
    language: {
      nativeName: "euskara (euskara)",
      englishName: "Basque (Basque)",
      code: "eu-ES",
      threeLetterIsoLanguageName: "eus",
      twoLetterIsoLanguageName: "eu"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Germany",
    nativeName: "Němska",
    threeLetterISORegionName: "DEU",
    twoLetterISORegionName: "DE",
    language: {
      nativeName: "hornjoserbšćina (Němska)",
      englishName: "Upper Sorbian (Germany)",
      code: "hsb-DE",
      threeLetterIsoLanguageName: "hsb",
      twoLetterIsoLanguageName: "hsb"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Macedonia (FYROM)",
    nativeName: "Македонија",
    threeLetterISORegionName: "MKD",
    twoLetterISORegionName: "MK",
    language: {
      nativeName: "македонски јазик (Македонија)",
      englishName: "Macedonian (Former Yugoslav Republic of Macedonia)",
      code: "mk-MK",
      threeLetterIsoLanguageName: "mkd",
      twoLetterIsoLanguageName: "mk"
    },
    currency: {
      isoSymbol: "MKD",
      englishName: "Macedonian Denar",
      nativeName: "денар",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "ден."
      }
    }
  },
  {
    englishName: "South Africa",
    nativeName: "Aforika Borwa",
    threeLetterISORegionName: "ZAF",
    twoLetterISORegionName: "ZA",
    language: {
      nativeName: "Setswana (Aforika Borwa)",
      englishName: "Setswana (South Africa)",
      code: "tn-ZA",
      threeLetterIsoLanguageName: "tsn",
      twoLetterIsoLanguageName: "tn"
    },
    currency: {
      isoSymbol: "ZAR",
      englishName: "South African Rand",
      nativeName: "Rand",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "R"
      }
    }
  },
  {
    englishName: "South Africa",
    nativeName: "uMzantsi Afrika",
    threeLetterISORegionName: "ZAF",
    twoLetterISORegionName: "ZA",
    language: {
      nativeName: "isiXhosa (uMzantsi Afrika)",
      englishName: "isiXhosa (South Africa)",
      code: "xh-ZA",
      threeLetterIsoLanguageName: "xho",
      twoLetterIsoLanguageName: "xh"
    },
    currency: {
      isoSymbol: "ZAR",
      englishName: "South African Rand",
      nativeName: "Rand",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "R"
      }
    }
  },
  {
    englishName: "South Africa",
    nativeName: "iNingizimu Afrika",
    threeLetterISORegionName: "ZAF",
    twoLetterISORegionName: "ZA",
    language: {
      nativeName: "isiZulu (iNingizimu Afrika)",
      englishName: "isiZulu (South Africa)",
      code: "zu-ZA",
      threeLetterIsoLanguageName: "zul",
      twoLetterIsoLanguageName: "zu"
    },
    currency: {
      isoSymbol: "ZAR",
      englishName: "South African Rand",
      nativeName: "Rand",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "R"
      }
    }
  },
  {
    englishName: "South Africa",
    nativeName: "Suid Afrika",
    threeLetterISORegionName: "ZAF",
    twoLetterISORegionName: "ZA",
    language: {
      nativeName: "Afrikaans (Suid Afrika)",
      englishName: "Afrikaans (South Africa)",
      code: "af-ZA",
      threeLetterIsoLanguageName: "afr",
      twoLetterIsoLanguageName: "af"
    },
    currency: {
      isoSymbol: "ZAR",
      englishName: "South African Rand",
      nativeName: "Rand",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "R"
      }
    }
  },
  {
    englishName: "Georgia",
    nativeName: "საქართველო",
    threeLetterISORegionName: "GEO",
    twoLetterISORegionName: "GE",
    language: {
      nativeName: "ქართული (საქართველო)",
      englishName: "Georgian (Georgia)",
      code: "ka-GE",
      threeLetterIsoLanguageName: "kat",
      twoLetterIsoLanguageName: "ka"
    },
    currency: {
      isoSymbol: "GEL",
      englishName: "Lari",
      nativeName: "ლარი",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "Lari"
      }
    }
  },
  {
    englishName: "Faroe Islands",
    nativeName: "Føroyar",
    threeLetterISORegionName: "FRO",
    twoLetterISORegionName: "FO",
    language: {
      nativeName: "føroyskt (Føroyar)",
      englishName: "Faroese (Faroe Islands)",
      code: "fo-FO",
      threeLetterIsoLanguageName: "fao",
      twoLetterIsoLanguageName: "fo"
    },
    currency: {
      isoSymbol: "DKK",
      englishName: "Danish Krone",
      nativeName: "Dansk krone",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr."
      }
    }
  },
  {
    englishName: "India",
    nativeName: "भारत",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "हिंदी (भारत)",
      englishName: "Hindi (India)",
      code: "hi-IN",
      threeLetterIsoLanguageName: "hin",
      twoLetterIsoLanguageName: "hi"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "रुपया",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "Malta",
    nativeName: "Malta",
    threeLetterISORegionName: "MLT",
    twoLetterISORegionName: "MT",
    language: {
      nativeName: "Malti (Malta)",
      englishName: "Maltese (Malta)",
      code: "mt-MT",
      threeLetterIsoLanguageName: "mlt",
      twoLetterIsoLanguageName: "mt"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Norway",
    nativeName: "Norga",
    threeLetterISORegionName: "NOR",
    twoLetterISORegionName: "NO",
    language: {
      nativeName: "davvisámegiella (Norga)",
      englishName: "Sami, Northern (Norway)",
      code: "se-NO",
      threeLetterIsoLanguageName: "sme",
      twoLetterIsoLanguageName: "se"
    },
    currency: {
      isoSymbol: "NOK",
      englishName: "Norwegian Krone",
      nativeName: "kruvdno",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Malaysia",
    nativeName: "Malaysia",
    threeLetterISORegionName: "MYS",
    twoLetterISORegionName: "MY",
    language: {
      nativeName: "Bahasa Melayu (Malaysia)",
      englishName: "Malay (Malaysia)",
      code: "ms-MY",
      threeLetterIsoLanguageName: "msa",
      twoLetterIsoLanguageName: "ms"
    },
    currency: {
      isoSymbol: "MYR",
      englishName: "Malaysian Ringgit",
      nativeName: "Ringgit Malaysia",
      format: {
        decimalDigits: 0,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "RM"
      }
    }
  },
  {
    englishName: "Kazakhstan",
    nativeName: "Қазақстан",
    threeLetterISORegionName: "KAZ",
    twoLetterISORegionName: "KZ",
    language: {
      nativeName: "Қазақ (Қазақстан)",
      englishName: "Kazakh (Kazakhstan)",
      code: "kk-KZ",
      threeLetterIsoLanguageName: "kaz",
      twoLetterIsoLanguageName: "kk"
    },
    currency: {
      isoSymbol: "KZT",
      englishName: "Tenge",
      nativeName: "Т",
      format: {
        decimalDigits: 2,
        decimalSeparator: "-",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "Т"
      }
    }
  },
  {
    englishName: "Kyrgyzstan",
    nativeName: "Кыргызстан",
    threeLetterISORegionName: "KGZ",
    twoLetterISORegionName: "KG",
    language: {
      nativeName: "Кыргыз (Кыргызстан)",
      englishName: "Kyrgyz (Kyrgyzstan)",
      code: "ky-KG",
      threeLetterIsoLanguageName: "kir",
      twoLetterIsoLanguageName: "ky"
    },
    currency: {
      isoSymbol: "KGS",
      englishName: "som",
      nativeName: "сом",
      format: {
        decimalDigits: 2,
        decimalSeparator: "-",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "сом"
      }
    }
  },
  {
    englishName: "Kenya",
    nativeName: "Kenya",
    threeLetterISORegionName: "KEN",
    twoLetterISORegionName: "KE",
    language: {
      nativeName: "Kiswahili (Kenya)",
      englishName: "Kiswahili (Kenya)",
      code: "sw-KE",
      threeLetterIsoLanguageName: "swa",
      twoLetterIsoLanguageName: "sw"
    },
    currency: {
      isoSymbol: "KES",
      englishName: "Kenyan Shilling",
      nativeName: "Shilingi",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "S"
      }
    }
  },
  {
    englishName: "Turkmenistan",
    nativeName: "Türkmenistan",
    threeLetterISORegionName: "TKM",
    twoLetterISORegionName: "TM",
    language: {
      nativeName: "türkmençe (Türkmenistan)",
      englishName: "Turkmen (Turkmenistan)",
      code: "tk-TM",
      threeLetterIsoLanguageName: "tuk",
      twoLetterIsoLanguageName: "tk"
    },
    currency: {
      isoSymbol: "TMT",
      englishName: "Turkmen manat",
      nativeName: "manat",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "m."
      }
    }
  },
  {
    englishName: "Uzbekistan",
    nativeName: "U'zbekiston Respublikasi",
    threeLetterISORegionName: "UZB",
    twoLetterISORegionName: "UZ",
    language: {
      nativeName: "U'zbek (U'zbekiston Respublikasi)",
      englishName: "Uzbek (Latin, Uzbekistan)",
      code: "uz-Latn-UZ",
      threeLetterIsoLanguageName: "uzb",
      twoLetterIsoLanguageName: "uz"
    },
    currency: {
      isoSymbol: "UZS",
      englishName: "Uzbekistan Som",
      nativeName: "so‘m",
      format: {
        decimalDigits: 0,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "so'm"
      }
    }
  },
  {
    englishName: "Russia",
    nativeName: "Россия",
    threeLetterISORegionName: "RUS",
    twoLetterISORegionName: "RU",
    language: {
      nativeName: "Татар (Россия)",
      englishName: "Tatar (Russia)",
      code: "tt-RU",
      threeLetterIsoLanguageName: "tat",
      twoLetterIsoLanguageName: "tt"
    },
    currency: {
      isoSymbol: "RUB",
      englishName: "Russian Ruble",
      nativeName: "рубль",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "₽"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ভারত",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "বাংলা (ভারত)",
      englishName: "Bengali (India)",
      code: "bn-IN",
      threeLetterIsoLanguageName: "bng",
      twoLetterIsoLanguageName: "bn"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "টাকা",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ਭਾਰਤ",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "ਪੰਜਾਬੀ (ਭਾਰਤ)",
      englishName: "Punjabi (India)",
      code: "pa-IN",
      threeLetterIsoLanguageName: "pan",
      twoLetterIsoLanguageName: "pa"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "ਰੁਪਿਆ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ભારત",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "ગુજરાતી (ભારત)",
      englishName: "Gujarati (India)",
      code: "gu-IN",
      threeLetterIsoLanguageName: "guj",
      twoLetterIsoLanguageName: "gu"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "રૂપિયો",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ଭାରତ",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "ଓଡ଼ିଆ (ଭାରତ)",
      englishName: "Oriya (India)",
      code: "or-IN",
      threeLetterIsoLanguageName: "ori",
      twoLetterIsoLanguageName: "or"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "ଟଙ୍କା",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "இந்தியா",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "தமிழ் (இந்தியா)",
      englishName: "Tamil (India)",
      code: "ta-IN",
      threeLetterIsoLanguageName: "tam",
      twoLetterIsoLanguageName: "ta"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "ரூபாய்",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "భారత దేశం",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "తెలుగు (భారత దేశం)",
      englishName: "Telugu (India)",
      code: "te-IN",
      threeLetterIsoLanguageName: "tel",
      twoLetterIsoLanguageName: "te"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "రూపాయి",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ಭಾರತ",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "ಕನ್ನಡ (ಭಾರತ)",
      englishName: "Kannada (India)",
      code: "kn-IN",
      threeLetterIsoLanguageName: "kan",
      twoLetterIsoLanguageName: "kn"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "ರೂಪಾಯಿ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ഭാരതം",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "മലയാളം (ഭാരതം)",
      englishName: "Malayalam (India)",
      code: "ml-IN",
      threeLetterIsoLanguageName: "mym",
      twoLetterIsoLanguageName: "ml"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "രൂപ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "ভাৰত",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "অসমীয়া (ভাৰত)",
      englishName: "Assamese (India)",
      code: "as-IN",
      threeLetterIsoLanguageName: "asm",
      twoLetterIsoLanguageName: "as"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "টকা",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "भारत",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "मराठी (भारत)",
      englishName: "Marathi (India)",
      code: "mr-IN",
      threeLetterIsoLanguageName: "mar",
      twoLetterIsoLanguageName: "mr"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "रुपया",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "भारतम्",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "संस्कृत (भारतम्)",
      englishName: "Sanskrit (India)",
      code: "sa-IN",
      threeLetterIsoLanguageName: "san",
      twoLetterIsoLanguageName: "sa"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "रु्यकम्",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "Mongolia",
    nativeName: "Монгол улс",
    threeLetterISORegionName: "MNG",
    twoLetterISORegionName: "MN",
    language: {
      nativeName: "Монгол хэл (Монгол улс)",
      englishName: "Mongolian (Cyrillic, Mongolia)",
      code: "mn-MN",
      threeLetterIsoLanguageName: "mon",
      twoLetterIsoLanguageName: "mn"
    },
    currency: {
      isoSymbol: "MNT",
      englishName: "Tugrik",
      nativeName: "Төгрөг",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "₮"
      }
    }
  },
  {
    englishName: "People's Republic of China",
    nativeName: "ཀྲུང་ཧྭ་མི་དམངས་སྤྱི་མཐུན་རྒྱལ་ཁབ།",
    threeLetterISORegionName: "CHN",
    twoLetterISORegionName: "CN",
    language: {
      nativeName: "བོད་ཡིག (ཀྲུང་ཧྭ་མི་དམངས་སྤྱི་མཐུན་རྒྱལ་ཁབ།)",
      englishName: "Tibetan (PRC)",
      code: "bo-CN",
      threeLetterIsoLanguageName: "bod",
      twoLetterIsoLanguageName: "bo"
    },
    currency: {
      isoSymbol: "CNY",
      englishName: "PRC Renminbi",
      nativeName: "མི་དམངས་ཤོག་སྒོཪ།",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "¥"
      }
    }
  },
  {
    englishName: "United Kingdom",
    nativeName: "y Deyrnas Unedig",
    threeLetterISORegionName: "GBR",
    twoLetterISORegionName: "GB",
    language: {
      nativeName: "Cymraeg (y Deyrnas Unedig)",
      englishName: "Welsh (United Kingdom)",
      code: "cy-GB",
      threeLetterIsoLanguageName: "cym",
      twoLetterIsoLanguageName: "cy"
    },
    currency: {
      isoSymbol: "GBP",
      englishName: "UK Pound Sterling",
      nativeName: "UK Pound Sterling",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "£"
      }
    }
  },
  {
    englishName: "Cambodia",
    nativeName: "កម្ពុជា",
    threeLetterISORegionName: "KHM",
    twoLetterISORegionName: "KH",
    language: {
      nativeName: "ខ្មែរ (កម្ពុជា)",
      englishName: "Khmer (Cambodia)",
      code: "km-KH",
      threeLetterIsoLanguageName: "khm",
      twoLetterIsoLanguageName: "km"
    },
    currency: {
      isoSymbol: "KHR",
      englishName: "Riel",
      nativeName: "x179Aៀល",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "៛"
      }
    }
  },
  {
    englishName: "Lao P.D.R.",
    nativeName: "ສ.ປ.ປ. ລາວ",
    threeLetterISORegionName: "LAO",
    twoLetterISORegionName: "LA",
    language: {
      nativeName: "ລາວ (ສ.ປ.ປ. ລາວ)",
      englishName: "Lao (Lao P.D.R.)",
      code: "lo-LA",
      threeLetterIsoLanguageName: "lao",
      twoLetterIsoLanguageName: "lo"
    },
    currency: {
      isoSymbol: "LAK",
      englishName: "Kip",
      nativeName: "ກີບ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "₭"
      }
    }
  },
  {
    englishName: "Spain",
    nativeName: "España",
    threeLetterISORegionName: "ESP",
    twoLetterISORegionName: "ES",
    language: {
      nativeName: "galego (galego)",
      englishName: "Galician (Galician)",
      code: "gl-ES",
      threeLetterIsoLanguageName: "glg",
      twoLetterIsoLanguageName: "gl"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "भारत",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "कोंकणी (भारत)",
      englishName: "Konkani (India)",
      code: "kok-IN",
      threeLetterIsoLanguageName: "kok",
      twoLetterIsoLanguageName: "kok"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "रुपय",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "Syria",
    nativeName: "سوريا",
    threeLetterISORegionName: "SYR",
    twoLetterISORegionName: "SY",
    language: {
      nativeName: "ܣܘܪܝܝܐ (سوريا)",
      englishName: "Syriac (Syria)",
      code: "syr-SY",
      threeLetterIsoLanguageName: "syr",
      twoLetterIsoLanguageName: "syr"
    },
    currency: {
      isoSymbol: "SYP",
      englishName: "Syrian Pound",
      nativeName: "جنيه سوري",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ل.س.‏"
      }
    }
  },
  {
    englishName: "Sri Lanka",
    nativeName: "ශ්‍රී ලංකා",
    threeLetterISORegionName: "LKA",
    twoLetterISORegionName: "LK",
    language: {
      nativeName: "සිංහල (ශ්‍රී ලංකා)",
      englishName: "Sinhala (Sri Lanka)",
      code: "si-LK",
      threeLetterIsoLanguageName: "sin",
      twoLetterIsoLanguageName: "si"
    },
    currency: {
      isoSymbol: "LKR",
      englishName: "Sri Lanka Rupee",
      nativeName: "රුපියල්",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "රු."
      }
    }
  },
  {
    englishName: "Canada",
    nativeName: "ᑲᓇᑕ",
    threeLetterISORegionName: "CAN",
    twoLetterISORegionName: "CA",
    language: {
      nativeName: "ᐃᓄᒃᑎᑐᑦ (ᑲᓇᑕᒥ)",
      englishName: "Inuktitut (Syllabics, Canada)",
      code: "iu-Cans-CA",
      threeLetterIsoLanguageName: "iku",
      twoLetterIsoLanguageName: "iu"
    },
    currency: {
      isoSymbol: "CAD",
      englishName: "Canadian Dollar",
      nativeName: "ᑲᓇᑕᐅᑉ ᑮᓇᐅᔭᖓ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Ethiopia",
    nativeName: "ኢትዮጵያ",
    threeLetterISORegionName: "ETH",
    twoLetterISORegionName: "ET",
    language: {
      nativeName: "አማርኛ (ኢትዮጵያ)",
      englishName: "Amharic (Ethiopia)",
      code: "am-ET",
      threeLetterIsoLanguageName: "amh",
      twoLetterIsoLanguageName: "am"
    },
    currency: {
      isoSymbol: "ETB",
      englishName: "Ethiopian Birr",
      nativeName: "ብር",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "ETB"
      }
    }
  },
  {
    englishName: "Nepal",
    nativeName: "नेपाल",
    threeLetterISORegionName: "NPL",
    twoLetterISORegionName: "NP",
    language: {
      nativeName: "नेपाली (नेपाल)",
      englishName: "Nepali (Nepal)",
      code: "ne-NP",
      threeLetterIsoLanguageName: "nep",
      twoLetterIsoLanguageName: "ne"
    },
    currency: {
      isoSymbol: "NPR",
      englishName: "Nepalese Rupees",
      nativeName: "रुपैयाँ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "रु"
      }
    }
  },
  {
    englishName: "Netherlands",
    nativeName: "Nederlân",
    threeLetterISORegionName: "NLD",
    twoLetterISORegionName: "NL",
    language: {
      nativeName: "Frysk (Nederlân)",
      englishName: "Frisian (Netherlands)",
      code: "fy-NL",
      threeLetterIsoLanguageName: "fry",
      twoLetterIsoLanguageName: "fy"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Afghanistan",
    nativeName: "افغانستان",
    threeLetterISORegionName: "AFG",
    twoLetterISORegionName: "AF",
    language: {
      nativeName: "پښتو (افغانستان)",
      englishName: "Pashto (Afghanistan)",
      code: "ps-AF",
      threeLetterIsoLanguageName: "pus",
      twoLetterIsoLanguageName: "ps"
    },
    currency: {
      isoSymbol: "AFN",
      englishName: "Afghani",
      nativeName: "افغانى",
      format: {
        decimalDigits: 2,
        decimalSeparator: "٫",
        groupSeparator: "٬",
        groupSizes: [3],
        symbol: "؋"
      }
    }
  },
  {
    englishName: "Philippines",
    nativeName: "Pilipinas",
    threeLetterISORegionName: "PHL",
    twoLetterISORegionName: "PH",
    language: {
      nativeName: "Filipino (Pilipinas)",
      englishName: "Filipino (Philippines)",
      code: "fil-PH",
      threeLetterIsoLanguageName: "fil",
      twoLetterIsoLanguageName: "fil"
    },
    currency: {
      isoSymbol: "PHP",
      englishName: "Philippine Peso",
      nativeName: "Philippine Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "PhP"
      }
    }
  },
  {
    englishName: "Maldives",
    nativeName: "ދިވެހި ރާއްޖެ",
    threeLetterISORegionName: "MDV",
    twoLetterISORegionName: "MV",
    language: {
      nativeName: "ދިވެހިބަސް (ދިވެހި ރާއްޖެ)",
      englishName: "Divehi (Maldives)",
      code: "dv-MV",
      threeLetterIsoLanguageName: "div",
      twoLetterIsoLanguageName: "dv"
    },
    currency: {
      isoSymbol: "MVR",
      englishName: "Rufiyaa",
      nativeName: "ރުފިޔާ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ރ."
      }
    }
  },
  {
    englishName: "Nigeria",
    nativeName: "Nigeria",
    threeLetterISORegionName: "NGA",
    twoLetterISORegionName: "NG",
    language: {
      nativeName: "Hausa (Nigeria)",
      englishName: "Hausa (Latin, Nigeria)",
      code: "ha-Latn-NG",
      threeLetterIsoLanguageName: "hau",
      twoLetterIsoLanguageName: "ha"
    },
    currency: {
      isoSymbol: "NIO",
      englishName: "Nigerian Naira",
      nativeName: "Naira",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "N"
      }
    }
  },
  {
    englishName: "Nigeria",
    nativeName: "Nigeria",
    threeLetterISORegionName: "NGA",
    twoLetterISORegionName: "NG",
    language: {
      nativeName: "Yoruba (Nigeria)",
      englishName: "Yoruba (Nigeria)",
      code: "yo-NG",
      threeLetterIsoLanguageName: "yor",
      twoLetterIsoLanguageName: "yo"
    },
    currency: {
      isoSymbol: "NIO",
      englishName: "Nigerian Naira",
      nativeName: "Naira",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "N"
      }
    }
  },
  {
    englishName: "Bolivia",
    nativeName: "Bolivia Suyu",
    threeLetterISORegionName: "BOL",
    twoLetterISORegionName: "BO",
    language: {
      nativeName: "runasimi (Qullasuyu)",
      englishName: "Quechua (Bolivia)",
      code: "quz-BO",
      threeLetterIsoLanguageName: "qub",
      twoLetterIsoLanguageName: "quz"
    },
    currency: {
      isoSymbol: "BOB",
      englishName: "Boliviano",
      nativeName: "Boliviano",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$b"
      }
    }
  },
  {
    englishName: "South Africa",
    nativeName: "Afrika Borwa",
    threeLetterISORegionName: "ZAF",
    twoLetterISORegionName: "ZA",
    language: {
      nativeName: "Sesotho sa Leboa (Afrika Borwa)",
      englishName: "Sesotho sa Leboa (South Africa)",
      code: "nso-ZA",
      threeLetterIsoLanguageName: "nso",
      twoLetterIsoLanguageName: "nso"
    },
    currency: {
      isoSymbol: "ZAR",
      englishName: "South African Rand",
      nativeName: "Rand",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "R"
      }
    }
  },
  {
    englishName: "Russia",
    nativeName: "Россия",
    threeLetterISORegionName: "RUS",
    twoLetterISORegionName: "RU",
    language: {
      nativeName: "Башҡорт (Россия)",
      englishName: "Bashkir (Russia)",
      code: "ba-RU",
      threeLetterIsoLanguageName: "bak",
      twoLetterIsoLanguageName: "ba"
    },
    currency: {
      isoSymbol: "RUB",
      englishName: "Russian Ruble",
      nativeName: "һум",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3, 0],
        symbol: "₽"
      }
    }
  },
  {
    englishName: "Luxembourg",
    nativeName: "Luxembourg",
    threeLetterISORegionName: "LUX",
    twoLetterISORegionName: "LU",
    language: {
      nativeName: "Lëtzebuergesch (Luxembourg)",
      englishName: "Luxembourgish (Luxembourg)",
      code: "lb-LU",
      threeLetterIsoLanguageName: "ltz",
      twoLetterIsoLanguageName: "lb"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Greenland",
    nativeName: "Kalaallit Nunaat",
    threeLetterISORegionName: "GRL",
    twoLetterISORegionName: "GL",
    language: {
      nativeName: "kalaallisut (Kalaallit Nunaat)",
      englishName: "Greenlandic (Greenland)",
      code: "kl-GL",
      threeLetterIsoLanguageName: "kal",
      twoLetterIsoLanguageName: "kl"
    },
    currency: {
      isoSymbol: "DKK",
      englishName: "Danish Krone",
      nativeName: "korunni",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3, 0],
        symbol: "kr."
      }
    }
  },
  {
    englishName: "Nigeria",
    nativeName: "Nigeria",
    threeLetterISORegionName: "NGA",
    twoLetterISORegionName: "NG",
    language: {
      nativeName: "Igbo (Nigeria)",
      englishName: "Igbo (Nigeria)",
      code: "ig-NG",
      threeLetterIsoLanguageName: "ibo",
      twoLetterIsoLanguageName: "ig"
    },
    currency: {
      isoSymbol: "NIO",
      englishName: "Nigerian Naira",
      nativeName: "Naira",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "N"
      }
    }
  },
  {
    englishName: "People's Republic of China",
    nativeName: "ꍏꉸꏓꂱꇭꉼꇩ",
    threeLetterISORegionName: "CHN",
    twoLetterISORegionName: "CN",
    language: {
      nativeName: "ꆈꌠꁱꂷ (ꍏꉸꏓꂱꇭꉼꇩ)",
      englishName: "Yi (PRC)",
      code: "ii-CN",
      threeLetterIsoLanguageName: "iii",
      twoLetterIsoLanguageName: "ii"
    },
    currency: {
      isoSymbol: "CNY",
      englishName: "PRC Renminbi",
      nativeName: "ꎆꃀ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "¥"
      }
    }
  },
  {
    englishName: "Chile",
    nativeName: "Chile",
    threeLetterISORegionName: "CHL",
    twoLetterISORegionName: "CL",
    language: {
      nativeName: "Mapudungun (Chile)",
      englishName: "Mapudungun (Chile)",
      code: "arn-CL",
      threeLetterIsoLanguageName: "arn",
      twoLetterIsoLanguageName: "arn"
    },
    currency: {
      isoSymbol: "CLP",
      englishName: "Chilean Peso",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Canada",
    nativeName: "Canada",
    threeLetterISORegionName: "CAN",
    twoLetterISORegionName: "CA",
    language: {
      nativeName: "Kanien'kéha",
      englishName: "Mohawk (Mohawk)",
      code: "moh-CA",
      threeLetterIsoLanguageName: "moh",
      twoLetterIsoLanguageName: "moh"
    },
    currency: {
      isoSymbol: "CAD",
      englishName: "Canadian Dollar",
      nativeName: "Canadian Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "France",
    nativeName: "Frañs",
    threeLetterISORegionName: "FRA",
    twoLetterISORegionName: "FR",
    language: {
      nativeName: "brezhoneg (Frañs)",
      englishName: "Breton (France)",
      code: "br-FR",
      threeLetterIsoLanguageName: "bre",
      twoLetterIsoLanguageName: "br"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "People's Republic of China",
    nativeName: "جۇڭخۇا خەلق جۇمھۇرىيىتى",
    threeLetterISORegionName: "CHN",
    twoLetterISORegionName: "CN",
    language: {
      nativeName: "ئۇيغۇرچە (جۇڭخۇا خەلق جۇمھۇرىيىتى)",
      englishName: "Uyghur (PRC)",
      code: "ug-CN",
      threeLetterIsoLanguageName: "uig",
      twoLetterIsoLanguageName: "ug"
    },
    currency: {
      isoSymbol: "CNY",
      englishName: "PRC Renminbi",
      nativeName: "خەلق پۇلى",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "¥"
      }
    }
  },
  {
    englishName: "New Zealand",
    nativeName: "Aotearoa",
    threeLetterISORegionName: "NZL",
    twoLetterISORegionName: "NZ",
    language: {
      nativeName: "Reo Māori (Aotearoa)",
      englishName: "Maori (New Zealand)",
      code: "mi-NZ",
      threeLetterIsoLanguageName: "mri",
      twoLetterIsoLanguageName: "mi"
    },
    currency: {
      isoSymbol: "NZD",
      englishName: "New Zealand Dollar",
      nativeName: "tāra",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "France",
    nativeName: "França",
    threeLetterISORegionName: "FRA",
    twoLetterISORegionName: "FR",
    language: {
      nativeName: "Occitan (França)",
      englishName: "Occitan (France)",
      code: "oc-FR",
      threeLetterIsoLanguageName: "oci",
      twoLetterIsoLanguageName: "oc"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "France",
    nativeName: "France",
    threeLetterISORegionName: "FRA",
    twoLetterISORegionName: "FR",
    language: {
      nativeName: "Corsu (France)",
      englishName: "Corsican (France)",
      code: "co-FR",
      threeLetterIsoLanguageName: "cos",
      twoLetterIsoLanguageName: "co"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "France",
    nativeName: "Frànkrisch",
    threeLetterISORegionName: "FRA",
    twoLetterISORegionName: "FR",
    language: {
      nativeName: "Elsässisch (Frànkrisch)",
      englishName: "Alsatian (France)",
      code: "gsw-FR",
      threeLetterIsoLanguageName: "gsw",
      twoLetterIsoLanguageName: "gsw"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Russia",
    nativeName: "Россия",
    threeLetterISORegionName: "RUS",
    twoLetterISORegionName: "RU",
    language: {
      nativeName: "саха (Россия)",
      englishName: "Yakut (Russia)",
      code: "sah-RU",
      threeLetterIsoLanguageName: "sah",
      twoLetterIsoLanguageName: "sah"
    },
    currency: {
      isoSymbol: "RUB",
      englishName: "Russian Ruble",
      nativeName: "солкуобай",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "₽"
      }
    }
  },
  {
    englishName: "Guatemala",
    nativeName: "Guatemala",
    threeLetterISORegionName: "GTM",
    twoLetterISORegionName: "GT",
    language: {
      nativeName: "K'iche (Guatemala)",
      englishName: "K'iche (Guatemala)",
      code: "qut-GT",
      threeLetterIsoLanguageName: "qut",
      twoLetterIsoLanguageName: "qut"
    },
    currency: {
      isoSymbol: "GTQ",
      englishName: "Guatemalan Quetzal",
      nativeName: "Quetzal",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "Q"
      }
    }
  },
  {
    englishName: "Rwanda",
    nativeName: "Rwanda",
    threeLetterISORegionName: "RWA",
    twoLetterISORegionName: "RW",
    language: {
      nativeName: "Kinyarwanda (Rwanda)",
      englishName: "Kinyarwanda (Rwanda)",
      code: "rw-RW",
      threeLetterIsoLanguageName: "kin",
      twoLetterIsoLanguageName: "rw"
    },
    currency: {
      isoSymbol: "RWF",
      englishName: "Rwandan Franc",
      nativeName: "Ifaranga",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "RWF"
      }
    }
  },
  {
    englishName: "Senegal",
    nativeName: "Sénégal",
    threeLetterISORegionName: "SEN",
    twoLetterISORegionName: "SN",
    language: {
      nativeName: "Wolof (Sénégal)",
      englishName: "Wolof (Senegal)",
      code: "wo-SN",
      threeLetterIsoLanguageName: "wol",
      twoLetterIsoLanguageName: "wo"
    },
    currency: {
      isoSymbol: "XOF",
      englishName: "XOF Senegal",
      nativeName: "XOF Senegal",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "XOF"
      }
    }
  },
  {
    englishName: "Afghanistan",
    nativeName: "افغانستان",
    threeLetterISORegionName: "AFG",
    twoLetterISORegionName: "AF",
    language: {
      nativeName: "درى (افغانستان)",
      englishName: "Dari (Afghanistan)",
      code: "prs-AF",
      threeLetterIsoLanguageName: "prs",
      twoLetterIsoLanguageName: "prs"
    },
    currency: {
      isoSymbol: "AFN",
      englishName: "Afghani",
      nativeName: "افغانى",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "؋"
      }
    }
  },
  {
    englishName: "United Kingdom",
    nativeName: "An Rìoghachd Aonaichte",
    threeLetterISORegionName: "GBR",
    twoLetterISORegionName: "GB",
    language: {
      nativeName: "Gàidhlig (An Rìoghachd Aonaichte)",
      englishName: "Scottish Gaelic (United Kingdom)",
      code: "gd-GB",
      threeLetterIsoLanguageName: "gla",
      twoLetterIsoLanguageName: "gd"
    },
    currency: {
      isoSymbol: "GBP",
      englishName: "UK Pound Sterling",
      nativeName: "Nota Bhreatannach",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "£"
      }
    }
  },
  {
    englishName: "Iraq",
    nativeName: "العراق",
    threeLetterISORegionName: "IRQ",
    twoLetterISORegionName: "IQ",
    language: {
      nativeName: "العربية (العراق)",
      englishName: "Arabic (Iraq)",
      code: "ar-IQ",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "IQD",
      englishName: "Iraqi Dinar",
      nativeName: "دينار عراقي",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ع.‏"
      }
    }
  },
  {
    englishName: "People's Republic of China",
    nativeName: "中华人民共和国",
    threeLetterISORegionName: "CHN",
    twoLetterISORegionName: "CN",
    language: {
      nativeName: "中文(中华人民共和国)",
      englishName: "Chinese (Simplified, PRC)",
      code: "zh-CN",
      threeLetterIsoLanguageName: "zho",
      twoLetterIsoLanguageName: "zh"
    },
    currency: {
      isoSymbol: "CNY",
      englishName: "PRC Yuan Renminbi",
      nativeName: "人民币",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "¥"
      }
    }
  },
  {
    englishName: "Switzerland",
    nativeName: "Schweiz",
    threeLetterISORegionName: "CHE",
    twoLetterISORegionName: "CH",
    language: {
      nativeName: "Deutsch (Schweiz)",
      englishName: "German (Switzerland)",
      code: "de-CH",
      threeLetterIsoLanguageName: "deu",
      twoLetterIsoLanguageName: "de"
    },
    currency: {
      isoSymbol: "CHF",
      englishName: "Swiss Franc",
      nativeName: "Schweizer Franken",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: "'",
        groupSizes: [3],
        symbol: "Fr."
      }
    }
  },
  {
    englishName: "United Kingdom",
    nativeName: "United Kingdom",
    threeLetterISORegionName: "GBR",
    twoLetterISORegionName: "GB",
    language: {
      nativeName: "English (United Kingdom)",
      englishName: "English (United Kingdom)",
      code: "en-GB",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "GBP",
      englishName: "UK Pound Sterling",
      nativeName: "Pound Sterling",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "£"
      }
    }
  },
  {
    englishName: "Mexico",
    nativeName: "México",
    threeLetterISORegionName: "MEX",
    twoLetterISORegionName: "MX",
    language: {
      nativeName: "Español (México)",
      englishName: "Spanish (Mexico)",
      code: "es-MX",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "MXN",
      englishName: "Mexican Peso",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Belgium",
    nativeName: "Belgique",
    threeLetterISORegionName: "BEL",
    twoLetterISORegionName: "BE",
    language: {
      nativeName: "français (Belgique)",
      englishName: "French (Belgium)",
      code: "fr-BE",
      threeLetterIsoLanguageName: "fra",
      twoLetterIsoLanguageName: "fr"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Switzerland",
    nativeName: "Svizzera",
    threeLetterISORegionName: "CHE",
    twoLetterISORegionName: "CH",
    language: {
      nativeName: "italiano (Svizzera)",
      englishName: "Italian (Switzerland)",
      code: "it-CH",
      threeLetterIsoLanguageName: "ita",
      twoLetterIsoLanguageName: "it"
    },
    currency: {
      isoSymbol: "CHF",
      englishName: "Swiss Franc",
      nativeName: "Franco svizzero",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: "'",
        groupSizes: [3],
        symbol: "fr."
      }
    }
  },
  {
    englishName: "Belgium",
    nativeName: "België",
    threeLetterISORegionName: "BEL",
    twoLetterISORegionName: "BE",
    language: {
      nativeName: "Nederlands (België)",
      englishName: "Dutch (Belgium)",
      code: "nl-BE",
      threeLetterIsoLanguageName: "nld",
      twoLetterIsoLanguageName: "nl"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Norway",
    nativeName: "Noreg",
    threeLetterISORegionName: "NOR",
    twoLetterISORegionName: "NO",
    language: {
      nativeName: "norsk, nynorsk (Noreg)",
      englishName: "Norwegian, Nynorsk (Norway)",
      code: "nn-NO",
      threeLetterIsoLanguageName: "nno",
      twoLetterIsoLanguageName: "nn"
    },
    currency: {
      isoSymbol: "NOK",
      englishName: "Norwegian Krone",
      nativeName: "Norsk krone",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Portugal",
    nativeName: "Portugal",
    threeLetterISORegionName: "PRT",
    twoLetterISORegionName: "PT",
    language: {
      nativeName: "português (Portugal)",
      englishName: "Portuguese (Portugal)",
      code: "pt-PT",
      threeLetterIsoLanguageName: "por",
      twoLetterIsoLanguageName: "pt"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Serbia and Montenegro (Former)",
    nativeName: "Srbija i Crna Gora (Prethodno)",
    threeLetterISORegionName: "SCG",
    twoLetterISORegionName: "CS",
    language: {
      nativeName: "srpski (Srbija i Crna Gora (Prethodno))",
      englishName: "Serbian (Latin, Serbia and Montenegro (Former))",
      code: "sr-Latn-CS",
      threeLetterIsoLanguageName: "srp",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "CSD",
      englishName: "Serbian Dinar",
      nativeName: "dinar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Din."
      }
    }
  },
  {
    englishName: "Finland",
    nativeName: "Finland",
    threeLetterISORegionName: "FIN",
    twoLetterISORegionName: "FI",
    language: {
      nativeName: "svenska (Finland)",
      englishName: "Swedish (Finland)",
      code: "sv-FI",
      threeLetterIsoLanguageName: "swe",
      twoLetterIsoLanguageName: "sv"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Azerbaijan",
    nativeName: "Азәрбајҹан",
    threeLetterISORegionName: "AZE",
    twoLetterISORegionName: "AZ",
    language: {
      nativeName: "Азәрбајҹан (Азәрбајҹан)",
      englishName: "Azeri (Cyrillic, Azerbaijan)",
      code: "az-Cyrl-AZ",
      threeLetterIsoLanguageName: "aze",
      twoLetterIsoLanguageName: "az"
    },
    currency: {
      isoSymbol: "AZN",
      englishName: "Azerbaijanian Manat",
      nativeName: "рубль",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "ман."
      }
    }
  },
  {
    englishName: "Germany",
    nativeName: "Nimska",
    threeLetterISORegionName: "DEU",
    twoLetterISORegionName: "DE",
    language: {
      nativeName: "dolnoserbšćina (Nimska)",
      englishName: "Lower Sorbian (Germany)",
      code: "dsb-DE",
      threeLetterIsoLanguageName: "dsb",
      twoLetterIsoLanguageName: "dsb"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Sweden",
    nativeName: "Ruoŧŧa",
    threeLetterISORegionName: "SWE",
    twoLetterISORegionName: "SE",
    language: {
      nativeName: "davvisámegiella (Ruoŧŧa)",
      englishName: "Sami, Northern (Sweden)",
      code: "se-SE",
      threeLetterIsoLanguageName: "smf",
      twoLetterIsoLanguageName: "se"
    },
    currency: {
      isoSymbol: "SEK",
      englishName: "Swedish Krona",
      nativeName: "kruvdno",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Ireland",
    nativeName: "Éire",
    threeLetterISORegionName: "IRL",
    twoLetterISORegionName: "IE",
    language: {
      nativeName: "Gaeilge (Éire)",
      englishName: "Irish (Ireland)",
      code: "ga-IE",
      threeLetterIsoLanguageName: "gle",
      twoLetterIsoLanguageName: "ga"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Brunei Darussalam",
    nativeName: "Brunei Darussalam",
    threeLetterISORegionName: "BRN",
    twoLetterISORegionName: "BN",
    language: {
      nativeName: "Bahasa Melayu (Brunei Darussalam)",
      englishName: "Malay (Brunei Darussalam)",
      code: "ms-BN",
      threeLetterIsoLanguageName: "msa",
      twoLetterIsoLanguageName: "ms"
    },
    currency: {
      isoSymbol: "BND",
      englishName: "Brunei Dollar",
      nativeName: "Ringgit",
      format: {
        decimalDigits: 0,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Uzbekistan",
    nativeName: "Ўзбекистон Республикаси",
    threeLetterISORegionName: "UZB",
    twoLetterISORegionName: "UZ",
    language: {
      nativeName: "Ўзбек (Ўзбекистон)",
      englishName: "Uzbek (Cyrillic, Uzbekistan)",
      code: "uz-Cyrl-UZ",
      threeLetterIsoLanguageName: "uzb",
      twoLetterIsoLanguageName: "uz"
    },
    currency: {
      isoSymbol: "UZS",
      englishName: "Uzbekistan Som",
      nativeName: "сўм",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "сўм"
      }
    }
  },
  {
    englishName: "Bangladesh",
    nativeName: "বাংলাদেশ",
    threeLetterISORegionName: "BGD",
    twoLetterISORegionName: "BD",
    language: {
      nativeName: "বাংলা (বাংলাদেশ)",
      englishName: "Bengali (Bangladesh)",
      code: "bn-BD",
      threeLetterIsoLanguageName: "bng",
      twoLetterIsoLanguageName: "bn"
    },
    currency: {
      isoSymbol: "BDT",
      englishName: "Bangladeshi Taka",
      nativeName: "টাকা",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "৳"
      }
    }
  },
  {
    englishName: "People's Republic of China",
    nativeName: "ᠪᠦᠭᠦᠳᠡ ᠨᠠᠢᠷᠠᠮᠳᠠᠬᠤ ᠳᠤᠮᠳᠠᠳᠤ ᠠᠷᠠᠳ ᠣᠯᠣᠰ",
    threeLetterISORegionName: "CHN",
    twoLetterISORegionName: "CN",
    language: {
      nativeName: "ᠮᠤᠨᠭᠭᠤᠯ ᠬᠡᠯᠡ (ᠪᠦᠭᠦᠳᠡ ᠨᠠᠢᠷᠠᠮᠳᠠᠬᠤ ᠳᠤᠮᠳᠠᠳᠤ ᠠᠷᠠᠳ ᠣᠯᠣᠰ)",
      englishName: "Mongolian (Traditional Mongolian, PRC)",
      code: "mn-Mong-CN",
      threeLetterIsoLanguageName: "mon",
      twoLetterIsoLanguageName: "mn"
    },
    currency: {
      isoSymbol: "CNY",
      englishName: "PRC Renminbi",
      nativeName: "ᠠᠷᠠᠳ  ᠤᠨ ᠵᠤᠭᠤᠰ",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "¥"
      }
    }
  },
  {
    englishName: "Canada",
    nativeName: "kanata",
    threeLetterISORegionName: "CAN",
    twoLetterISORegionName: "CA",
    language: {
      nativeName: "Inuktitut (Kanatami)",
      englishName: "Inuktitut (Latin, Canada)",
      code: "iu-Latn-CA",
      threeLetterIsoLanguageName: "iku",
      twoLetterIsoLanguageName: "iu"
    },
    currency: {
      isoSymbol: "CAD",
      englishName: "Canadian Dollar",
      nativeName: "Kanataup Kiinaujanga;",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Algeria",
    nativeName: "Djazaïr",
    threeLetterISORegionName: "DZA",
    twoLetterISORegionName: "DZ",
    language: {
      nativeName: "Tamazight (Djazaïr)",
      englishName: "Tamazight (Latin, Algeria)",
      code: "tzm-Latn-DZ",
      threeLetterIsoLanguageName: "tzm",
      twoLetterIsoLanguageName: "tzm"
    },
    currency: {
      isoSymbol: "DZD",
      englishName: "Algerian Dinar",
      nativeName: "Dinar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "DZD"
      }
    }
  },
  {
    englishName: "Ecuador",
    nativeName: "Ecuador Suyu",
    threeLetterISORegionName: "ECU",
    twoLetterISORegionName: "EC",
    language: {
      nativeName: "runasimi (Ecuador)",
      englishName: "Quechua (Ecuador)",
      code: "quz-EC",
      threeLetterIsoLanguageName: "que",
      twoLetterIsoLanguageName: "quz"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "US Dolar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Egypt",
    nativeName: "مصر",
    threeLetterISORegionName: "EGY",
    twoLetterISORegionName: "EG",
    language: {
      nativeName: "العربية (مصر)",
      englishName: "Arabic (Egypt)",
      code: "ar-EG",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "EGP",
      englishName: "Egyptian Pound",
      nativeName: "جنيه مصري",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ج.م.‏"
      }
    }
  },
  {
    englishName: "Hong Kong S.A.R.",
    nativeName: "香港特別行政區",
    threeLetterISORegionName: "HKG",
    twoLetterISORegionName: "HK",
    language: {
      nativeName: "中文(香港特別行政區)",
      englishName: "Chinese (Traditional, Hong Kong S.A.R.)",
      code: "zh-HK",
      threeLetterIsoLanguageName: "zho",
      twoLetterIsoLanguageName: "zh"
    },
    currency: {
      isoSymbol: "HKD",
      englishName: "Hong Kong Dollar",
      nativeName: "港幣",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "HK$"
      }
    }
  },
  {
    englishName: "Austria",
    nativeName: "Österreich",
    threeLetterISORegionName: "AUT",
    twoLetterISORegionName: "AT",
    language: {
      nativeName: "Deutsch (Österreich)",
      englishName: "German (Austria)",
      code: "de-AT",
      threeLetterIsoLanguageName: "deu",
      twoLetterIsoLanguageName: "de"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Australia",
    nativeName: "Australia",
    threeLetterISORegionName: "AUS",
    twoLetterISORegionName: "AU",
    language: {
      nativeName: "English (Australia)",
      englishName: "English (Australia)",
      code: "en-AU",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "AUD",
      englishName: "Australian Dollar",
      nativeName: "Australian Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Spain",
    nativeName: "España",
    threeLetterISORegionName: "ESP",
    twoLetterISORegionName: "ES",
    language: {
      nativeName: "Español (España, alfabetización internacional)",
      englishName: "Spanish (Spain, International Sort)",
      code: "es-ES",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Canada",
    nativeName: "Canada",
    threeLetterISORegionName: "CAN",
    twoLetterISORegionName: "CA",
    language: {
      nativeName: "français (Canada)",
      englishName: "French (Canada)",
      code: "fr-CA",
      threeLetterIsoLanguageName: "fra",
      twoLetterIsoLanguageName: "fr"
    },
    currency: {
      isoSymbol: "CAD",
      englishName: "Canadian Dollar",
      nativeName: "Dollar canadien",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Serbia and Montenegro (Former)",
    nativeName: "Србија и Црна Гора (Претходно)",
    threeLetterISORegionName: "SCG",
    twoLetterISORegionName: "CS",
    language: {
      nativeName: "српски (Србија и Црна Гора (Претходно))",
      englishName: "Serbian (Cyrillic, Serbia and Montenegro (Former))",
      code: "sr-Cyrl-CS",
      threeLetterIsoLanguageName: "srp",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "CSD",
      englishName: "Serbian Dinar",
      nativeName: "динар",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Дин."
      }
    }
  },
  {
    englishName: "Finland",
    nativeName: "Suopma",
    threeLetterISORegionName: "FIN",
    twoLetterISORegionName: "FI",
    language: {
      nativeName: "davvisámegiella (Suopma)",
      englishName: "Sami, Northern (Finland)",
      code: "se-FI",
      threeLetterIsoLanguageName: "smg",
      twoLetterIsoLanguageName: "se"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Peru",
    nativeName: "Peru Suyu",
    threeLetterISORegionName: "PER",
    twoLetterISORegionName: "PE",
    language: {
      nativeName: "runasimi (Piruw)",
      englishName: "Quechua (Peru)",
      code: "quz-PE",
      threeLetterIsoLanguageName: "qup",
      twoLetterIsoLanguageName: "quz"
    },
    currency: {
      isoSymbol: "PEN",
      englishName: "Peruvian Nuevo Sol",
      nativeName: "Nuevo Sol",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "S/."
      }
    }
  },
  {
    englishName: "Libya",
    nativeName: "ليبيا",
    threeLetterISORegionName: "LBY",
    twoLetterISORegionName: "LY",
    language: {
      nativeName: "العربية (ليبيا)",
      englishName: "Arabic (Libya)",
      code: "ar-LY",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "LYD",
      englishName: "Libyan Dinar",
      nativeName: "دينار ليبي",
      format: {
        decimalDigits: 3,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ل.‏"
      }
    }
  },
  {
    englishName: "Singapore",
    nativeName: "新加坡",
    threeLetterISORegionName: "SGP",
    twoLetterISORegionName: "SG",
    language: {
      nativeName: "中文(新加坡)",
      englishName: "Chinese (Simplified, Singapore)",
      code: "zh-SG",
      threeLetterIsoLanguageName: "zho",
      twoLetterIsoLanguageName: "zh"
    },
    currency: {
      isoSymbol: "SGD",
      englishName: "Singapore Dollar",
      nativeName: "新币",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Luxembourg",
    nativeName: "Luxemburg",
    threeLetterISORegionName: "LUX",
    twoLetterISORegionName: "LU",
    language: {
      nativeName: "Deutsch (Luxemburg)",
      englishName: "German (Luxembourg)",
      code: "de-LU",
      threeLetterIsoLanguageName: "deu",
      twoLetterIsoLanguageName: "de"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Canada",
    nativeName: "Canada",
    threeLetterISORegionName: "CAN",
    twoLetterISORegionName: "CA",
    language: {
      nativeName: "English (Canada)",
      englishName: "English (Canada)",
      code: "en-CA",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "CAD",
      englishName: "Canadian Dollar",
      nativeName: "Canadian Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Guatemala",
    nativeName: "Guatemala",
    threeLetterISORegionName: "GTM",
    twoLetterISORegionName: "GT",
    language: {
      nativeName: "Español (Guatemala)",
      englishName: "Spanish (Guatemala)",
      code: "es-GT",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "GTQ",
      englishName: "Guatemalan Quetzal",
      nativeName: "Quetzal",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "Q"
      }
    }
  },
  {
    englishName: "Switzerland",
    nativeName: "Suisse",
    threeLetterISORegionName: "CHE",
    twoLetterISORegionName: "CH",
    language: {
      nativeName: "français (Suisse)",
      englishName: "French (Switzerland)",
      code: "fr-CH",
      threeLetterIsoLanguageName: "fra",
      twoLetterIsoLanguageName: "fr"
    },
    currency: {
      isoSymbol: "CHF",
      englishName: "Swiss Franc",
      nativeName: "Franc suisse",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: "'",
        groupSizes: [3],
        symbol: "fr."
      }
    }
  },
  {
    englishName: "Bosnia and Herzegovina",
    nativeName: "Bosna i Hercegovina",
    threeLetterISORegionName: "BIH",
    twoLetterISORegionName: "BA",
    language: {
      nativeName: "hrvatski (Bosna i Hercegovina)",
      englishName: "Croatian (Latin, Bosnia and Herzegovina)",
      code: "hr-BA",
      threeLetterIsoLanguageName: "hrb",
      twoLetterIsoLanguageName: "hr"
    },
    currency: {
      isoSymbol: "BAM",
      englishName: "Convertible Marks",
      nativeName: "konvertibilna marka",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "KM"
      }
    }
  },
  {
    englishName: "Norway",
    nativeName: "Vuodna",
    threeLetterISORegionName: "NOR",
    twoLetterISORegionName: "NO",
    language: {
      nativeName: "julevusámegiella (Vuodna)",
      englishName: "Sami, Lule (Norway)",
      code: "smj-NO",
      threeLetterIsoLanguageName: "smj",
      twoLetterIsoLanguageName: "smj"
    },
    currency: {
      isoSymbol: "NOK",
      englishName: "Norwegian Krone",
      nativeName: "kråvnnå",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Algeria",
    nativeName: "الجزائر",
    threeLetterISORegionName: "DZA",
    twoLetterISORegionName: "DZ",
    language: {
      nativeName: "العربية (الجزائر)",
      englishName: "Arabic (Algeria)",
      code: "ar-DZ",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "DZD",
      englishName: "Algerian Dinar",
      nativeName: "دينار جزائري",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ج.‏"
      }
    }
  },
  {
    englishName: "Macao S.A.R.",
    nativeName: "澳門特別行政區",
    threeLetterISORegionName: "MAC",
    twoLetterISORegionName: "MO",
    language: {
      nativeName: "中文(澳門特別行政區)",
      englishName: "Chinese (Traditional, Macao S.A.R.)",
      code: "zh-MO",
      threeLetterIsoLanguageName: "zho",
      twoLetterIsoLanguageName: "zh"
    },
    currency: {
      isoSymbol: "MOP",
      englishName: "Macao Pataca",
      nativeName: "澳門幣",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "MOP"
      }
    }
  },
  {
    englishName: "Liechtenstein",
    nativeName: "Liechtenstein",
    threeLetterISORegionName: "LIE",
    twoLetterISORegionName: "LI",
    language: {
      nativeName: "Deutsch (Liechtenstein)",
      englishName: "German (Liechtenstein)",
      code: "de-LI",
      threeLetterIsoLanguageName: "deu",
      twoLetterIsoLanguageName: "de"
    },
    currency: {
      isoSymbol: "CHF",
      englishName: "Swiss Franc",
      nativeName: "Schweizer Franken",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: "'",
        groupSizes: [3],
        symbol: "CHF"
      }
    }
  },
  {
    englishName: "New Zealand",
    nativeName: "New Zealand",
    threeLetterISORegionName: "NZL",
    twoLetterISORegionName: "NZ",
    language: {
      nativeName: "English (New Zealand)",
      englishName: "English (New Zealand)",
      code: "en-NZ",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "NZD",
      englishName: "New Zealand Dollar",
      nativeName: "New Zealand Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Costa Rica",
    nativeName: "Costa Rica",
    threeLetterISORegionName: "CRI",
    twoLetterISORegionName: "CR",
    language: {
      nativeName: "Español (Costa Rica)",
      englishName: "Spanish (Costa Rica)",
      code: "es-CR",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "CRC",
      englishName: "Costa Rican Colon",
      nativeName: "Colón",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "₡"
      }
    }
  },
  {
    englishName: "Luxembourg",
    nativeName: "Luxembourg",
    threeLetterISORegionName: "LUX",
    twoLetterISORegionName: "LU",
    language: {
      nativeName: "français (Luxembourg)",
      englishName: "French (Luxembourg)",
      code: "fr-LU",
      threeLetterIsoLanguageName: "fra",
      twoLetterIsoLanguageName: "fr"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Bosnia and Herzegovina",
    nativeName: "Bosna i Hercegovina",
    threeLetterISORegionName: "BIH",
    twoLetterISORegionName: "BA",
    language: {
      nativeName: "bosanski (Bosna i Hercegovina)",
      englishName: "Bosnian (Latin, Bosnia and Herzegovina)",
      code: "bs-Latn-BA",
      threeLetterIsoLanguageName: "bsb",
      twoLetterIsoLanguageName: "bs"
    },
    currency: {
      isoSymbol: "BAM",
      englishName: "Convertible Marks",
      nativeName: "konvertibilna marka",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "KM"
      }
    }
  },
  {
    englishName: "Sweden",
    nativeName: "Svierik",
    threeLetterISORegionName: "SWE",
    twoLetterISORegionName: "SE",
    language: {
      nativeName: "julevusámegiella (Svierik)",
      englishName: "Sami, Lule (Sweden)",
      code: "smj-SE",
      threeLetterIsoLanguageName: "smk",
      twoLetterIsoLanguageName: "smj"
    },
    currency: {
      isoSymbol: "SEK",
      englishName: "Swedish Krona",
      nativeName: "kråvnnå",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Morocco",
    nativeName: "المملكة المغربية",
    threeLetterISORegionName: "MAR",
    twoLetterISORegionName: "MA",
    language: {
      nativeName: "العربية (المملكة المغربية)",
      englishName: "Arabic (Morocco)",
      code: "ar-MA",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "MAD",
      englishName: "Moroccan Dirham",
      nativeName: "درهم مغربي",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.م.‏"
      }
    }
  },
  {
    englishName: "Ireland",
    nativeName: "Ireland",
    threeLetterISORegionName: "IRL",
    twoLetterISORegionName: "IE",
    language: {
      nativeName: "English (Ireland)",
      englishName: "English (Ireland)",
      code: "en-IE",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Panama",
    nativeName: "Panamá",
    threeLetterISORegionName: "PAN",
    twoLetterISORegionName: "PA",
    language: {
      nativeName: "Español (Panamá)",
      englishName: "Spanish (Panama)",
      code: "es-PA",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "PAB",
      englishName: "Panamanian Balboa",
      nativeName: "Balboa",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "B/."
      }
    }
  },
  {
    englishName: "Principality of Monaco",
    nativeName: "Principauté de Monaco",
    threeLetterISORegionName: "MCO",
    twoLetterISORegionName: "MC",
    language: {
      nativeName: "français (Principauté de Monaco)",
      englishName: "French (Monaco)",
      code: "fr-MC",
      threeLetterIsoLanguageName: "fra",
      twoLetterIsoLanguageName: "fr"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Bosnia and Herzegovina",
    nativeName: "Bosna i Hercegovina",
    threeLetterISORegionName: "BIH",
    twoLetterISORegionName: "BA",
    language: {
      nativeName: "srpski (Bosna i Hercegovina)",
      englishName: "Serbian (Latin, Bosnia and Herzegovina)",
      code: "sr-Latn-BA",
      threeLetterIsoLanguageName: "srs",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "BAM",
      englishName: "Convertible Marks",
      nativeName: "konvertibilna marka",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "KM"
      }
    }
  },
  {
    englishName: "Norway",
    nativeName: "Nöörje",
    threeLetterISORegionName: "NOR",
    twoLetterISORegionName: "NO",
    language: {
      nativeName: "åarjelsaemiengiele (Nöörje)",
      englishName: "Sami, Southern (Norway)",
      code: "sma-NO",
      threeLetterIsoLanguageName: "sma",
      twoLetterIsoLanguageName: "sma"
    },
    currency: {
      isoSymbol: "NOK",
      englishName: "Norwegian Krone",
      nativeName: "kråvnoe",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Tunisia",
    nativeName: "تونس",
    threeLetterISORegionName: "TUN",
    twoLetterISORegionName: "TN",
    language: {
      nativeName: "العربية (تونس)",
      englishName: "Arabic (Tunisia)",
      code: "ar-TN",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "TND",
      englishName: "Tunisian Dinar",
      nativeName: "دينار تونسي",
      format: {
        decimalDigits: 3,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ت.‏"
      }
    }
  },
  {
    englishName: "South Africa",
    nativeName: "South Africa",
    threeLetterISORegionName: "ZAF",
    twoLetterISORegionName: "ZA",
    language: {
      nativeName: "English (South Africa)",
      englishName: "English (South Africa)",
      code: "en-ZA",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "ZAR",
      englishName: "South African Rand",
      nativeName: "Rand",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "R"
      }
    }
  },
  {
    englishName: "Dominican Republic",
    nativeName: "República Dominicana",
    threeLetterISORegionName: "DOM",
    twoLetterISORegionName: "DO",
    language: {
      nativeName: "Español (República Dominicana)",
      englishName: "Spanish (Dominican Republic)",
      code: "es-DO",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "DOP",
      englishName: "Dominican Peso",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "RD$"
      }
    }
  },
  {
    englishName: "Bosnia and Herzegovina",
    nativeName: "Босна и Херцеговина",
    threeLetterISORegionName: "BIH",
    twoLetterISORegionName: "BA",
    language: {
      nativeName: "српски (Босна и Херцеговина)",
      englishName: "Serbian (Cyrillic, Bosnia and Herzegovina)",
      code: "sr-Cyrl-BA",
      threeLetterIsoLanguageName: "srn",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "BAM",
      englishName: "Convertible Marks",
      nativeName: "конвертибилна марка",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "КМ"
      }
    }
  },
  {
    englishName: "Sweden",
    nativeName: "Sveerje",
    threeLetterISORegionName: "SWE",
    twoLetterISORegionName: "SE",
    language: {
      nativeName: "åarjelsaemiengiele (Sveerje)",
      englishName: "Sami, Southern (Sweden)",
      code: "sma-SE",
      threeLetterIsoLanguageName: "smb",
      twoLetterIsoLanguageName: "sma"
    },
    currency: {
      isoSymbol: "SEK",
      englishName: "Swedish Krona",
      nativeName: "kråvnoe",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "kr"
      }
    }
  },
  {
    englishName: "Oman",
    nativeName: "عمان",
    threeLetterISORegionName: "OMN",
    twoLetterISORegionName: "OM",
    language: {
      nativeName: "العربية (عمان)",
      englishName: "Arabic (Oman)",
      code: "ar-OM",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "OMR",
      englishName: "Omani Rial",
      nativeName: "ريال عماني",
      format: {
        decimalDigits: 3,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ر.ع.‏"
      }
    }
  },
  {
    englishName: "Jamaica",
    nativeName: "Jamaica",
    threeLetterISORegionName: "JAM",
    twoLetterISORegionName: "JM",
    language: {
      nativeName: "English (Jamaica)",
      englishName: "English (Jamaica)",
      code: "en-JM",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "JMD",
      englishName: "Jamaican Dollar",
      nativeName: "Jamaican Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "J$"
      }
    }
  },
  {
    englishName: "Bolivarian Republic of Venezuela",
    nativeName: "Republica Bolivariana de Venezuela",
    threeLetterISORegionName: "VEN",
    twoLetterISORegionName: "VE",
    language: {
      nativeName: "Español (Republica Bolivariana de Venezuela)",
      englishName: "Spanish (Bolivarian Republic of Venezuela)",
      code: "es-VE",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "VEF",
      englishName: "Venezuelan Bolivar",
      nativeName: "Bolívar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Bs. F."
      }
    }
  },
  {
    englishName: "Bosnia and Herzegovina",
    nativeName: "Босна и Херцеговина",
    threeLetterISORegionName: "BIH",
    twoLetterISORegionName: "BA",
    language: {
      nativeName: "босански (Босна и Херцеговина)",
      englishName: "Bosnian (Cyrillic, Bosnia and Herzegovina)",
      code: "bs-Cyrl-BA",
      threeLetterIsoLanguageName: "bsc",
      twoLetterIsoLanguageName: "bs"
    },
    currency: {
      isoSymbol: "BAM",
      englishName: "Convertible Marks",
      nativeName: "конвертибилна марка",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "КМ"
      }
    }
  },
  {
    englishName: "Finland",
    nativeName: "Lää´ddjânnam",
    threeLetterISORegionName: "FIN",
    twoLetterISORegionName: "FI",
    language: {
      nativeName: "sääm´ǩiõll (Lää´ddjânnam)",
      englishName: "Sami, Skolt (Finland)",
      code: "sms-FI",
      threeLetterIsoLanguageName: "sms",
      twoLetterIsoLanguageName: "sms"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Yemen",
    nativeName: "اليمن",
    threeLetterISORegionName: "YEM",
    twoLetterISORegionName: "YE",
    language: {
      nativeName: "العربية (اليمن)",
      englishName: "Arabic (Yemen)",
      code: "ar-YE",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "YER",
      englishName: "Yemeni Rial",
      nativeName: "ريال يمني",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ر.ي.‏"
      }
    }
  },
  {
    englishName: "Caribbean",
    nativeName: "Caribbean",
    threeLetterISORegionName: "029",
    twoLetterISORegionName: "029",
    language: {
      nativeName: "English (Caribbean)",
      englishName: "English (Caribbean)",
      code: "en-029",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "US Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Colombia",
    nativeName: "Colombia",
    threeLetterISORegionName: "COL",
    twoLetterISORegionName: "CO",
    language: {
      nativeName: "Español (Colombia)",
      englishName: "Spanish (Colombia)",
      code: "es-CO",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "COP",
      englishName: "Colombian Peso",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Serbia",
    nativeName: "Srbija",
    threeLetterISORegionName: "SRB",
    twoLetterISORegionName: "RS",
    language: {
      nativeName: "srpski (Srbija)",
      englishName: "Serbian (Latin, Serbia)",
      code: "sr-Latn-RS",
      threeLetterIsoLanguageName: "srp",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "RSD",
      englishName: "Serbian Dinar",
      nativeName: "dinar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Din."
      }
    }
  },
  {
    englishName: "Finland",
    nativeName: "Suomâ",
    threeLetterISORegionName: "FIN",
    twoLetterISORegionName: "FI",
    language: {
      nativeName: "sämikielâ (Suomâ)",
      englishName: "Sami, Inari (Finland)",
      code: "smn-FI",
      threeLetterIsoLanguageName: "smn",
      twoLetterIsoLanguageName: "smn"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "evro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: " ",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Syria",
    nativeName: "سوريا",
    threeLetterISORegionName: "SYR",
    twoLetterISORegionName: "SY",
    language: {
      nativeName: "العربية (سوريا)",
      englishName: "Arabic (Syria)",
      code: "ar-SY",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "SYP",
      englishName: "Syrian Pound",
      nativeName: "ليرة سوري",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ل.س.‏"
      }
    }
  },
  {
    englishName: "Belize",
    nativeName: "Belize",
    threeLetterISORegionName: "BLZ",
    twoLetterISORegionName: "BZ",
    language: {
      nativeName: "English (Belize)",
      englishName: "English (Belize)",
      code: "en-BZ",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "BZD",
      englishName: "Belize Dollar",
      nativeName: "Belize Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "BZ$"
      }
    }
  },
  {
    englishName: "Peru",
    nativeName: "Perú",
    threeLetterISORegionName: "PER",
    twoLetterISORegionName: "PE",
    language: {
      nativeName: "Español (Perú)",
      englishName: "Spanish (Peru)",
      code: "es-PE",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "PEN",
      englishName: "Peruvian Nuevo Sol",
      nativeName: "Nuevo Sol",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "S/."
      }
    }
  },
  {
    englishName: "Serbia",
    nativeName: "Србија",
    threeLetterISORegionName: "SRB",
    twoLetterISORegionName: "RS",
    language: {
      nativeName: "српски (Србија)",
      englishName: "Serbian (Cyrillic, Serbia)",
      code: "sr-Cyrl-RS",
      threeLetterIsoLanguageName: "srp",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "RSD",
      englishName: "Serbian Dinar",
      nativeName: "динар",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Дин."
      }
    }
  },
  {
    englishName: "Jordan",
    nativeName: "الأردن",
    threeLetterISORegionName: "JOR",
    twoLetterISORegionName: "JO",
    language: {
      nativeName: "العربية (الأردن)",
      englishName: "Arabic (Jordan)",
      code: "ar-JO",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "JOD",
      englishName: "Jordanian Dinar",
      nativeName: "دينار اردني",
      format: {
        decimalDigits: 3,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ا.‏"
      }
    }
  },
  {
    englishName: "Trinidad and Tobago",
    nativeName: "Trinidad y Tobago",
    threeLetterISORegionName: "TTO",
    twoLetterISORegionName: "TT",
    language: {
      nativeName: "English (Trinidad y Tobago)",
      englishName: "English (Trinidad and Tobago)",
      code: "en-TT",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "TTD",
      englishName: "Trinidad Dollar",
      nativeName: "Trinidad Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "TT$"
      }
    }
  },
  {
    englishName: "Argentina",
    nativeName: "Argentina",
    threeLetterISORegionName: "ARG",
    twoLetterISORegionName: "AR",
    language: {
      nativeName: "Español (Argentina)",
      englishName: "Spanish (Argentina)",
      code: "es-AR",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "ARS",
      englishName: "Argentine Peso",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Montenegro",
    nativeName: "Crna Gora",
    threeLetterISORegionName: "MNE",
    twoLetterISORegionName: "ME",
    language: {
      nativeName: "srpski (Crna Gora)",
      englishName: "Serbian (Latin, Montenegro)",
      code: "sr-Latn-ME",
      threeLetterIsoLanguageName: "srp",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Euro",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Lebanon",
    nativeName: "لبنان",
    threeLetterISORegionName: "LBN",
    twoLetterISORegionName: "LB",
    language: {
      nativeName: "العربية (لبنان)",
      englishName: "Arabic (Lebanon)",
      code: "ar-LB",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "LBP",
      englishName: "Lebanese Pound",
      nativeName: "ليرة لبناني",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ل.ل.‏"
      }
    }
  },
  {
    englishName: "Zimbabwe",
    nativeName: "Zimbabwe",
    threeLetterISORegionName: "ZWE",
    twoLetterISORegionName: "ZW",
    language: {
      nativeName: "English (Zimbabwe)",
      englishName: "English (Zimbabwe)",
      code: "en-ZW",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "ZWL",
      englishName: "Zimbabwe Dollar",
      nativeName: "Zimbabwe Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "Z$"
      }
    }
  },
  {
    englishName: "Ecuador",
    nativeName: "Ecuador",
    threeLetterISORegionName: "ECU",
    twoLetterISORegionName: "EC",
    language: {
      nativeName: "Español (Ecuador)",
      englishName: "Spanish (Ecuador)",
      code: "es-EC",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "US Dolar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Montenegro",
    nativeName: "Црна Гора",
    threeLetterISORegionName: "MNE",
    twoLetterISORegionName: "ME",
    language: {
      nativeName: "српски (Црна Гора)",
      englishName: "Serbian (Cyrillic, Montenegro)",
      code: "sr-Cyrl-ME",
      threeLetterIsoLanguageName: "srp",
      twoLetterIsoLanguageName: "sr"
    },
    currency: {
      isoSymbol: "EUR",
      englishName: "Euro",
      nativeName: "Еуро",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "€"
      }
    }
  },
  {
    englishName: "Kuwait",
    nativeName: "الكويت",
    threeLetterISORegionName: "KWT",
    twoLetterISORegionName: "KW",
    language: {
      nativeName: "العربية (الكويت)",
      englishName: "Arabic (Kuwait)",
      code: "ar-KW",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "KWD",
      englishName: "Kuwaiti Dinar",
      nativeName: "دينار كويتي",
      format: {
        decimalDigits: 3,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ك.‏"
      }
    }
  },
  {
    englishName: "Republic of the Philippines",
    nativeName: "Philippines",
    threeLetterISORegionName: "PHL",
    twoLetterISORegionName: "PH",
    language: {
      nativeName: "English (Philippines)",
      englishName: "English (Republic of the Philippines)",
      code: "en-PH",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "PHP",
      englishName: "Philippine Peso",
      nativeName: "Philippine Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "Php"
      }
    }
  },
  {
    englishName: "Chile",
    nativeName: "Chile",
    threeLetterISORegionName: "CHL",
    twoLetterISORegionName: "CL",
    language: {
      nativeName: "Español (Chile)",
      englishName: "Spanish (Chile)",
      code: "es-CL",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "CLP",
      englishName: "Chilean Peso",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "U.A.E.",
    nativeName: "الإمارات العربية المتحدة",
    threeLetterISORegionName: "ARE",
    twoLetterISORegionName: "AE",
    language: {
      nativeName: "العربية (الإمارات العربية المتحدة)",
      englishName: "Arabic (U.A.E.)",
      code: "ar-AE",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "AED",
      englishName: "UAE Dirham",
      nativeName: "درهم اماراتي",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.إ.‏"
      }
    }
  },
  {
    englishName: "Uruguay",
    nativeName: "Uruguay",
    threeLetterISORegionName: "URY",
    twoLetterISORegionName: "UY",
    language: {
      nativeName: "Español (Uruguay)",
      englishName: "Spanish (Uruguay)",
      code: "es-UY",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "UYU",
      englishName: "Peso Uruguayo",
      nativeName: "Peso",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$U"
      }
    }
  },
  {
    englishName: "Bahrain",
    nativeName: "البحرين",
    threeLetterISORegionName: "BHR",
    twoLetterISORegionName: "BH",
    language: {
      nativeName: "العربية (البحرين)",
      englishName: "Arabic (Bahrain)",
      code: "ar-BH",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "BHD",
      englishName: "Bahraini Dinar",
      nativeName: "دينار بحريني",
      format: {
        decimalDigits: 3,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "د.ب.‏"
      }
    }
  },
  {
    englishName: "Paraguay",
    nativeName: "Paraguay",
    threeLetterISORegionName: "PRY",
    twoLetterISORegionName: "PY",
    language: {
      nativeName: "Español (Paraguay)",
      englishName: "Spanish (Paraguay)",
      code: "es-PY",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "PYG",
      englishName: "Paraguay Guarani",
      nativeName: "Guaraní",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "Gs"
      }
    }
  },
  {
    englishName: "Qatar",
    nativeName: "قطر",
    threeLetterISORegionName: "QAT",
    twoLetterISORegionName: "QA",
    language: {
      nativeName: "العربية (قطر)",
      englishName: "Arabic (Qatar)",
      code: "ar-QA",
      threeLetterIsoLanguageName: "ara",
      twoLetterIsoLanguageName: "ar"
    },
    currency: {
      isoSymbol: "QAR",
      englishName: "Qatari Rial",
      nativeName: "ريال قطري",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "ر.ق.‏"
      }
    }
  },
  {
    englishName: "India",
    nativeName: "India",
    threeLetterISORegionName: "IND",
    twoLetterISORegionName: "IN",
    language: {
      nativeName: "English (India)",
      englishName: "English (India)",
      code: "en-IN",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "INR",
      englishName: "Indian Rupee",
      nativeName: "Rupee",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 2],
        symbol: "₹"
      }
    }
  },
  {
    englishName: "Bolivia",
    nativeName: "Bolivia",
    threeLetterISORegionName: "BOL",
    twoLetterISORegionName: "BO",
    language: {
      nativeName: "Español (Bolivia)",
      englishName: "Spanish (Bolivia)",
      code: "es-BO",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "BOB",
      englishName: "Boliviano",
      nativeName: "Boliviano",
      format: {
        decimalDigits: 2,
        decimalSeparator: ",",
        groupSeparator: ".",
        groupSizes: [3],
        symbol: "$b"
      }
    }
  },
  {
    englishName: "Malaysia",
    nativeName: "Malaysia",
    threeLetterISORegionName: "MYS",
    twoLetterISORegionName: "MY",
    language: {
      nativeName: "English (Malaysia)",
      englishName: "English (Malaysia)",
      code: "en-MY",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "MYR",
      englishName: "Malaysian Ringgit",
      nativeName: "Malaysian Ringgit",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "RM"
      }
    }
  },
  {
    englishName: "El Salvador",
    nativeName: "El Salvador",
    threeLetterISORegionName: "SLV",
    twoLetterISORegionName: "SV",
    language: {
      nativeName: "Español (El Salvador)",
      englishName: "Spanish (El Salvador)",
      code: "es-SV",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "US Dolar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Singapore",
    nativeName: "Singapore",
    threeLetterISORegionName: "SGP",
    twoLetterISORegionName: "SG",
    language: {
      nativeName: "English (Singapore)",
      englishName: "English (Singapore)",
      code: "en-SG",
      threeLetterIsoLanguageName: "eng",
      twoLetterIsoLanguageName: "en"
    },
    currency: {
      isoSymbol: "SGD",
      englishName: "Singapore Dollar",
      nativeName: "Singapore Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  },
  {
    englishName: "Honduras",
    nativeName: "Honduras",
    threeLetterISORegionName: "HND",
    twoLetterISORegionName: "HN",
    language: {
      nativeName: "Español (Honduras)",
      englishName: "Spanish (Honduras)",
      code: "es-HN",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "HNL",
      englishName: "Honduran Lempira",
      nativeName: "Lempira",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "L."
      }
    }
  },
  {
    englishName: "Nicaragua",
    nativeName: "Nicaragua",
    threeLetterISORegionName: "NIC",
    twoLetterISORegionName: "NI",
    language: {
      nativeName: "Español (Nicaragua)",
      englishName: "Spanish (Nicaragua)",
      code: "es-NI",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "NIO",
      englishName: "Nicaraguan Cordoba Oro",
      nativeName: "Córdoba",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "C$"
      }
    }
  },
  {
    englishName: "Puerto Rico",
    nativeName: "Puerto Rico",
    threeLetterISORegionName: "PRI",
    twoLetterISORegionName: "PR",
    language: {
      nativeName: "Español (Puerto Rico)",
      englishName: "Spanish (Puerto Rico)",
      code: "es-PR",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "US Dollar",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3, 0],
        symbol: "$"
      }
    }
  },
  {
    englishName: "United States",
    nativeName: "Estados Unidos",
    threeLetterISORegionName: "USA",
    twoLetterISORegionName: "US",
    language: {
      nativeName: "Español (Estados Unidos)",
      englishName: "Spanish (United States)",
      code: "es-US",
      threeLetterIsoLanguageName: "spa",
      twoLetterIsoLanguageName: "es"
    },
    currency: {
      isoSymbol: "USD",
      englishName: "US Dollar",
      nativeName: "Dólar de EE.UU.",
      format: {
        decimalDigits: 2,
        decimalSeparator: ".",
        groupSeparator: ",",
        groupSizes: [3],
        symbol: "$"
      }
    }
  }
];

export default currencyData;

import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  CompanyService,
  CountryListService,
  CurrencyListService,
  ToastHelperService
} from "../shared/services";
import { ICompanyListScope } from "./company-list.controller";

export class CompanyEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "companyService",
    "countryListService",
    "currencyListService",
    "gettextCatalog",
    "toastHelper",
    "company"
  ];

  constructor(
    private $scope: ICompanyEditScope,
    private $mdDialog: ng.material.IDialogService,
    private companyService: CompanyService,
    private countryListService: CountryListService,
    private currencyListService: CurrencyListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private company: webserviceModels.Common.Response.Company.company
  ) {
    $scope.countryListService = countryListService;
    $scope.currencyListService = currencyListService;
    companyService.load(company.id);

    $scope.request = {
      company: {
        address: company.address,
        city: company.city,
        contactperson: company.contactperson,
        contactpersonemail: company.contactpersonemail,
        countryname: company.countryname,
        establishedyear: company.establishedyear,
        name: company.name,
        phone: company.phone,
        vatnumber: company.vatnumber,
        zipcode: company.zipcode,
        status: company.status
      }
    };
  }

  submit(request: webserviceModels.Common.Request.Company.updatecompany) {
    if (this.$scope.editCompanyForm.$invalid) {
      this.$scope.editCompanyForm.$setSubmitted();
      return;
    }
    this.companyService.update(request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "{{companyname}} was successfully updated.",
            { companyname: request.company.name }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{companyname}} could not be updated at this time.",
              { companyname: request.company.name }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface ICompanyEditScope extends ICompanyListScope {
  request: webserviceModels.Common.Request.Company.updatecompany;
  countryListService: CountryListService;
  currencyListService: CurrencyListService;
  editCompanyForm: any;
}

export class TransparentOnDrag implements ng.IDirective {
  restrict = "A";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    instanceElement.get(0).addEventListener(
      "dragstart",
      event => {
        const img = document.createElement("img");
        img.src =
          "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";
        event.dataTransfer.setDragImage(img, 0, 0);
      },
      false
    );
  };
}

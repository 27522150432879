import $ from "jquery";
import * as Enumerable from "linq";

export class ValidateRangeOverlap implements ng.IDirective {
  static $inject = ["$timeout"];

  constructor(private $timeout: ng.ITimeoutService) {}

  // tslint:disable:member-ordering
  restrict = "A";
  require = "ngModel";
  priority = 0;

  link: ng.IDirectiveLinkFn = (
    scope: any,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    controller: ng.INgModelController
  ) => {
    scope.$watch(
      () => controller.$modelValue,
      (newValue: number) => {
        let forms = $.map(scope.rangedFeesForm, (value, key: string) => {
          if (key.indexOf("rangedFeeForm") === 0) return value;
        });

        const currentForm = (controller as any).$$parentForm;
        forms = Enumerable.from(forms)
          .except([currentForm])
          .toArray();

        const errorForms: any = Enumerable.from(forms).where(
          (form: any) =>
            form.ToAmount.$modelValue >= currentForm.FromAmount.$modelValue &&
            currentForm.ToAmount.$modelValue >= form.FromAmount.$modelValue
        );

        const successForms: any = Enumerable.from(forms).except(errorForms);

        successForms.forEach(successForm => {
          successForm.FromAmount.$setValidity("rangeOverlap", true);
          successForm.ToAmount.$setValidity("rangeOverlap", true);
        });

        errorForms.forEach(errorForm => {
          errorForm.FromAmount.$setValidity("rangeOverlap", false);
          errorForm.ToAmount.$setValidity("rangeOverlap", false);
          errorForm.FromAmount.$setTouched();
          errorForm.ToAmount.$setTouched();
        });

        currentForm.FromAmount.$setValidity("rangeOverlap", !errorForms.any());
        currentForm.ToAmount.$setValidity("rangeOverlap", !errorForms.any());
        currentForm.FromAmount.$setTouched();
        currentForm.ToAmount.$setTouched();
      }
    );
  };
}

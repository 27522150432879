export class WebStorageService {
  private storage: any;

  // tslint:disable-next-line:member-ordering
  static $inject = ["$sessionStorage", "$localStorage"];
  constructor(private $sessionStorage: any, private $localStorage: any) {
    this.$storage = $localStorage.storageType || "sessionStorage";
  }

  get $storage() {
    return this.storage;
  }

  set $storage(value: string) {
    this.storage =
      value.toLowerCase() === "localstorage"
        ? this.$localStorage
        : this.$sessionStorage;
    this.$localStorage.storageType = value;
  }
}

export default WebStorageService;

import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../../shared/decorators";
import {
  SearchHelperService,
  ResponseCodeListService
} from "../../shared/services";
import { ResponseCodesFilterService } from "../shared/responsecodes-filter.service";

@Component({
  selector: "responsecodesFilter",
  template: require("./responsecodes-filter.html")
})
export class ResponseCodesFilterComponent {
  searchTerm: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "searchHelperService",
    "$mdBottomSheet",
    "responseCodeListService",
    "responseCodesFilterService",
    "$mdMedia"
  ];

  constructor(
    private searchHelperService: SearchHelperService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private responseCodeListService: ResponseCodeListService,
    private responseCodesFilterService: ResponseCodesFilterService,
    private $mdMedia: ng.material.IMedia
  ) {
    if (this.filter) angular.copy(this.filter, this.request);
  }

  get sources() {
    return Enumerable.from(this.responseCodeListService.responseCodes)
      .select(responseCode => responseCode.source)
      .distinct()
      .toArray();
  }

  get parentSources() {
    return Enumerable.from(this.responseCodeListService.responseCodes)
      .select(responseCode => responseCode.parentsource)
      .distinct()
      .where(source => !!source)
      .toArray();
  }

  get filter() {
    return this.responseCodesFilterService.filter;
  }

  // tslint:disable-next-line:member-ordering
  request = {};

  $onInit() {
    if (
      this.isGtSm() &&
      !!(angular.element("#overlay-container")[0] as any).$mdGesture
    ) {
      // tslint:disable-next-line:no-empty
      (angular.element("#overlay-container")[0] as any).$mdGesture = () => {};
    }
  }

  close() {
    this.$mdBottomSheet.hide();
  }

  clearSearchTerm() {
    this.searchTerm = "";
  }

  clearAll() {
    angular.copy({}, this.request);
    this.responseCodesFilterService.clearAll();
  }

  isGtSm(): boolean {
    return this.$mdMedia("gt-sm");
  }

  apply(filter: any) {
    if (filter) angular.copy(filter, this.filter);
  }
}

// tslint:disable-next-line:one-variable-per-declaration
let environment,
  mpi,
  paymentWindow,
  subscription,
  transaction,
  merchant,
  data,
  cardRegister,
  reseller,
  authorize,
  login,
  impersonate,
  checkout;

declare var process;
environment = process.env.API_ENV || "development";

switch (environment) {
  case "staging":
  case "production": {
    mpi = "https://mpi-v1.api-eu.bambora.com";
    paymentWindow = "https://paymentwindow-v1.api-eu.bambora.com";
    subscription = "https://subscription-v1.api-eu.bambora.com";
    transaction = "https://transaction-v1.api-eu.bambora.com";
    merchant = "https://merchant-v1.api-eu.bambora.com";
    data = "https://data-v1.api-eu.bambora.com";
    cardRegister = "https://cardregister-v1.api-eu.bambora.com";
    reseller = "https://reseller-v1.api-eu.bambora.com";
    authorize = "https://authorize-v1.api-eu.bambora.com";
    login = "https://login-v1.api-eu.bambora.com";
    impersonate = "https://merchant.bambora.com/auth/impersonate";
    checkout = "https://api.v1.checkout.bambora.com";
    break;
  }

  case "test": {
    mpi = "https://mpi-v1-test.api-eu.bambora.com";
    paymentWindow = "https://paymentwindow-v1-test.api-eu.bambora.com";
    subscription = "https://subscription-v1-test.api-eu.bambora.com";
    transaction = "https://transaction-v1-test.api-eu.bambora.com";
    merchant = "https://merchant-v1-test.api-eu.bambora.com";
    data = "https://data-v1-test.api-eu.bambora.com";
    cardRegister = "https://cardregister-v1-test.api-eu.bambora.com";
    reseller = "https://reseller-v1-test.api-eu.bambora.com";
    authorize = "https://authorize-v1-test.api-eu.bambora.com";
    login = "https://login-v1-test.api-eu.bambora.com";
    impersonate = "https://test.merchant.bambora.com/auth/impersonate";
    checkout = "https://api-test.v1.checkout.bambora.com";
    break;
  }

  case "local": {
    environment = "local";
    mpi = "https://localhost:44309";
    paymentWindow = "https://localhost:44305";
    subscription = "https://localhost:44304";
    transaction = "https://localhost:44301";
    merchant = "https://localhost:44303";
    data = "https://localhost:44308";
    cardRegister = "https://localhost:44307";
    reseller = "https://localhost:44306";
    authorize = "https://localhost:44300";
    login = "https://localhost:44302";
    impersonate = "http://localhost:3128/auth/impersonate";
    checkout = "";
    break;
  }

  default: {
    mpi = "https://mpi-v1-dev.api-eu.bambora.com";
    paymentWindow = "https://paymentwindow-v1-dev.api-eu.bambora.com";
    subscription = "https://subscription-v1-dev.api-eu.bambora.com";
    transaction = "https://transaction-v1-dev.api-eu.bambora.com";
    merchant = "https://merchant-v1-dev.api-eu.bambora.com";
    data = "https://data-v1-dev.api-eu.bambora.com";
    cardRegister = "https://cardregister-v1-dev.api-eu.bambora.com";
    reseller = "https://reseller-v1-dev.api-eu.bambora.com";
    authorize = "https://authorize-v1-dev.api-eu.bambora.com";
    login = "https://login-v1-dev.api-eu.bambora.com";
    impersonate = "https://dev.merchant.bambora.com/auth/impersonate";
    checkout = "https://api-dev.v1.checkout.bambora.com";
    break;
  }
}

export {
  environment,
  mpi,
  paymentWindow,
  subscription,
  transaction,
  merchant,
  data,
  cardRegister,
  reseller,
  authorize,
  login,
  impersonate,
  checkout
};

import * as endpoints from "../../../app.endpoints";
import * as webserviceModels from "../../interfaces/webservice-models";
import { Common } from "../../interfaces/webservice-models";
import ImpersonateService from "../model/impersonate.service";

export class LoginService {
  static $inject: Array<string> = ["$http", "impersonateService"];
  constructor(
    private $http: ng.IHttpService,
    private impersonateService: ImpersonateService
  ) {}

  // tslint:disable-next-line:member-ordering
  private endpoint: string = endpoints.login;

  private get config(): ng.IRequestShortcutConfig {
    if (!this.impersonateService.authenticationToken) return null;

    return {
      headers: {
        Authorization: `Basic ${this.impersonateService.authenticationToken}`
      }
    };
  }

  listfunctionpermissionsbyaccesstoken(
    accesstoken: string
  ): ng.IPromise<
    webserviceModels.Login.Response.FunctionPermission.listfunctionpermissionsbyaccesstokenresponse
  > {
    return this.$http
      .get(
        `${this.endpoint}/accesstokens/${accesstoken}/functionpermissions`,
        this.config
      )
      .then<any>(response => response.data);
  }

  updatefunctionpermissions(
    accesstoken: string,
    request: webserviceModels.Login.Request.FunctionPermission.updatefunctionpermission
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(
        `${this.endpoint}/accesstokens/${accesstoken}/functionpermissions`,
        request,
        this.config
      )
      .then<any>(response => response.data);
  }

  deletefunctionpermission(
    accesstoken: string,
    permission: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(
        `${this
          .endpoint}/accesstokens/${accesstoken}/functionpermissions/${permission}`,
        this.config
      )
      .then<any>(response => response.data);
  }

  listfunctionpermissions(): ng.IPromise<
    webserviceModels.Login.Response.FunctionPermission.listfunctionpermissionresponse
  > {
    return this.$http
      .get(`${this.endpoint}/functionpermissions`, this.config)
      .then<any>(response => response.data);
  }

  generatenewsecrettokenmerchant(
    accesstoken: string
  ): ng.IPromise<webserviceModels.Login.Response.updatesecrettokenresponse> {
    return this.$http
      .post(
        `${this.endpoint}/merchant/accesstokens/${accesstoken}/secrettoken`,
        null,
        this.config
      )
      .then<any>(response => response.data);
  }

  forgotpasswordmerchant(
    request: webserviceModels.Login.Request.forgotpassword
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/merchant/forgotpassword`, request, this.config)
      .then<any>(response => response.data);
  }

  logoffmerchant(): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(`${this.endpoint}/merchant/logoff`, this.config)
      .then<any>(response => response.data);
  }

  resetpasswordmerchant(
    request: webserviceModels.Login.Request.resetpassword
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/merchant/logon`, request, this.config)
      .then<any>(response => response.data);
  }

  getaccesstokenreseller(
    accesstoken: string
  ): ng.IPromise<webserviceModels.Login.Response.listaccesstokenreseller> {
    return this.$http
      .get(`${this.endpoint}/reseller/accesstokens/${accesstoken}`, this.config)
      .then<any>(response => response.data);
  }

  generatenewsecrettokenreseller(
    accesstoken: string
  ): ng.IPromise<webserviceModels.Login.Response.updatesecrettokenresponse> {
    return this.$http
      .post(
        `${this.endpoint}/reseller/accesstokens/${accesstoken}/secrettoken`,
        null,
        this.config
      )
      .then<any>(response => response.data);
  }

  forgotpasswordreseller(
    request: webserviceModels.Login.Request.forgotpassword
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/reseller/forgotpassword`, request, this.config)
      .then<any>(response => response.data);
  }

  logoffreseller(): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(`${this.endpoint}/reseller/logoff`, this.config)
      .then<any>(response => response.data);
  }

  //new endpoint
  logonresellersession(
    request: webserviceModels.Login.Request.logonrequest
  ): ng.IPromise<webserviceModels.Login.Response.otplogonresponse> {
    return this.$http
      .post(`${this.endpoint}/reseller/session`, request, this.config)
      .then<any>(response => response.data);
  }

  //new endpoint
  logonresellersessionotp(
    request: webserviceModels.Login.Request.otprequest
  ): ng.IPromise<webserviceModels.Login.Response.logonresellerresponse> {
    return this.$http
      .post(`${this.endpoint}/reseller/session/otp`, request, this.config)
      .then<any>(response => response.data);
  }

  //new endpoint
  forgototpreseller(
    request: webserviceModels.Login.Request.forgototprequest
  ): ng.IPromise<Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/reseller/forgototp`, request, this.config)
      .then<any>(response => response.data);
  }

  //new endpoint
  resetotpreseller(
    request: webserviceModels.Login.Request.resetotprequest
  ): ng.IPromise<Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/reseller/resetotp`, request, this.config)
      .then<any>(response => response.data);
  }

  listapiusersreseller(): ng.IPromise<
    webserviceModels.Login.Response.listaccesstokenreseller
  > {
    return this.$http
      .get(`${this.endpoint}/reseller/resellerapiuser`, this.config)
      .then<any>(response => response.data);
  }

  resetpasswordreseller(
    request: webserviceModels.Login.Request.resetpassword
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(`${this.endpoint}/reseller/resetpassword`, request, this.config)
      .then<any>(response => response.data);
  }
}

export default LoginService;

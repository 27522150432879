import * as angular from "angular";
import * as Enumerable from "linq";
import { PaymentCollectionListService } from "../shared/services";
import * as webserviceModels from "../shared/interfaces/webservice-models";

export class PaymentGroupListService {
  active: boolean = false;
  originalMappedPaymentTypes: Array<
    Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
  >;
  // tslint:disable-next-line:variable-name
  private _mappedPaymentTypes: Array<
    Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = ["paymentCollectionListService"];

  constructor(
    // tslint:disable-next-line:variable-name
    private _paymentCollectionListService: PaymentCollectionListService
  ) {}

  get mappedPaymentTypes() {
    if (!this._mappedPaymentTypes) {
      this.originalMappedPaymentTypes = PaymentGroupListService.mapPaymentCollectionsToPaymentTypeFees(
        this._paymentCollectionListService.paymentCollections
      );
      this.mappedPaymentTypes = angular.copy(this.originalMappedPaymentTypes);
    }
    return this._mappedPaymentTypes;
  }

  set mappedPaymentTypes(value) {
    this._mappedPaymentTypes = value;
  }

  reset() {
    this.mappedPaymentTypes = angular.copy(this.originalMappedPaymentTypes);
  }

  // tslint:disable-next-line:member-ordering
  static mapPaymentCollectionsToPaymentTypeFees(
    paymentCollections: Array<
      webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
    >
  ): Array<
    Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
  > {
    const mappedPaymentTypeFees: Array<
      Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
    > = [];

    Enumerable.from(paymentCollections)
      .selectMany(
        (
          paymentCollection: webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
        ) => paymentCollection.paymentgroups
      )
      .forEach(
        (
          paymentGroup: webserviceModels.Data.Response.ListPaymentTypes.paymentgroup
        ) => {
          mappedPaymentTypeFees.push([
            {
              agreementid: null,
              agreementidvalue: null,
              feecalculatorname: null,
              feedata: {
                common: [{ key: "CurrencyCode", value: "" }],
                ranges: []
              },
              id: null,
              merchantnumber: null,
              order: null,
              paymenttype: {
                displayname: paymentGroup.displayname,
                groupid: paymentGroup.id,
                id: paymentGroup.id,
                name: paymentGroup.name
              },
              addfee: "Default",
              filters: []
            }
          ]);
        }
      );

    return mappedPaymentTypeFees;
  }
}

import * as Enumerable from "linq";

export class ValidateInCollection implements ng.IDirective {
  static $inject = ["$timeout"];

  constructor(private $timeout: ng.ITimeoutService) {}

  // tslint:disable:member-ordering
  restrict = "A";
  require = "ngModel";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    ngModel: ng.INgModelController
  ) => {
    let collection: Array<any>;

    instanceAttributes.$observe("validateInCollection", (value: string) => {
      collection = scope.$eval(value);
      ngModel.$validate();
    });

    ngModel.$validators.notInCollection = (
      modelValue: string,
      viewValue: string
    ) => {
      return Enumerable.from(collection).any(
        item => item[instanceAttributes.collectionComparator] === viewValue
      );
    };
  };
}

// tslint:disable-next-line:max-classes-per-file
export class ValidateNotInCollection implements ng.IDirective {
  static $inject = ["$timeout"];

  constructor(private $timeout: ng.ITimeoutService) {}

  restrict = "A";
  require = "ngModel";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    ngModel: ng.INgModelController
  ) => {
    let collection: Array<any>;

    instanceAttributes.$observe("validateNotInCollection", (value: string) => {
      collection = scope.$eval(value);
      ngModel.$validate();
    });

    ngModel.$validators.inCollection = (
      modelValue: string,
      viewValue: string
    ) => {
      return !Enumerable.from(collection).any(
        item => item[instanceAttributes.collectionComparator] === viewValue
      );
    };
  };
}

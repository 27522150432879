import * as Enumerable from "linq";
import { PaymentCollectionListService } from "../services";

export function GetPaymentTypeByGroupIdFilter(
  paymentCollectionListService: PaymentCollectionListService
) {
  return (input: number) => {
    return Enumerable.from(paymentCollectionListService.paymentCollections)
      .selectMany(paymentCollection => paymentCollection.paymentgroups)
      .firstOrDefault(pG => pG.id === input);
  };
}

GetPaymentTypeByGroupIdFilter.$inject = ["paymentCollectionListService"];

import * as webserviceModels from "../shared/interfaces/webservice-models";

import {
  CompanyListService,
  CompanyService,
  ToastHelperService
} from "../shared/services";

import { ICompanyListScope } from "./company-list.controller";

export class CompanyDeleteController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "company",
    "companyService",
    "companyListService",
    "$state",
    "gettextCatalog",
    "toastHelper",
    "isListItem"
  ];

  constructor(
    private $scope: IDeleteCompanyScope,
    private $mdDialog: ng.material.IDialogService,
    private company: webserviceModels.Common.Response.Company.company,
    private companyService: CompanyService,
    private companyListService: CompanyListService,
    private $state: ng.ui.IStateService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private isListItem: boolean,
    private chosenDate: Date = new Date(),
    private dateError: boolean,
    private verification: string
  ) {
    $scope.company = company;
    $scope.companyService = companyService;
    $scope.companyListService = companyListService;
    $scope.chosenDate = chosenDate;
    $scope.dateError = dateError;
    $scope.verification = verification;
  }

  delete() {
    const currentDate = new Date();
    let chosenDateString = "";
    let isFutureDate = false;

    this.chosenDate.setUTCHours(0, 0, 0, 0);
    currentDate.setUTCHours(0, 0, 0, 0);
    currentDate > this.chosenDate
      ? (this.dateError = true)
      : (this.dateError = false);
    currentDate < this.chosenDate
      ? (isFutureDate = true)
      : (isFutureDate = false);

    !this.chosenDate
      ? (chosenDateString = "")
      : (chosenDateString = this.chosenDate.toISOString());

    if (!this.dateError || this.chosenDate === null) {
      if (!this.isListItem) {
        this.companyService.delete(isFutureDate, chosenDateString).then(
          success => {
            if (!isFutureDate) {
              this.$state.go("companyList", null, { reload: true });
            }

            this.showSuccessDeletedToast(
              this.company.name,
              this.company.id,
              isFutureDate,
              chosenDateString
            );
          },
          error => {
            this.showErrorDeletedToast(
              this.company.name,
              this.company.id,
              error
            );
          }
        );
      } else {
        this.companyListService
          .delete(this.company, isFutureDate, chosenDateString)
          .then(
            success => {
              this.showSuccessDeletedToast(
                this.company.name,
                this.company.id,
                isFutureDate,
                chosenDateString
              );
            },
            error => {
              this.showErrorDeletedToast(
                this.company.name,
                this.company.id,
                error
              );
            }
          );
      }
      this.$mdDialog.hide();
    }
  }

  showSuccessDeletedToast(
    companyName: string,
    companyId: string,
    isFuture: boolean,
    deleteDate: string
  ) {
    let message = "";
    if (isFuture) {
      message = this.gettextCatalog.getString(
        "{{companyName}} ({{companyId}}) was successfully scheduled to be deleted on {{deleteDate}}.",
        {
          companyName,
          companyId,
          deleteDate
        }
      );
    } else {
      message = this.gettextCatalog.getString(
        "{{companyName}} ({{companyId}}) was successfully deleted.",
        {
          companyName,
          companyId
        }
      );
    }
    this.toastHelper.toast(message, this.gettextCatalog.getString("Dismiss"));
  }

  showErrorDeletedToast(companyName: string, companyId: string, error: any) {
    this.toastHelper.toast(
      error ||
        this.gettextCatalog.getString(
          "{{companyName}} ({{companyId}}) could not be deleted at this time.",
          {
            companyName,
            companyId
          }
        ),
      this.gettextCatalog.getString("Dismiss")
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

export interface IDeleteCompanyScope extends ICompanyListScope {
  company: webserviceModels.Common.Response.Company.company;
  companyService: CompanyService;
  companyListService: CompanyListService;
  chosenDate: Date;
  dateError: boolean;
  verification: string;
}

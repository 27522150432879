import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";
import { SearchHelperService } from "../helpers";

export class UserListService {
  // tslint:disable-next-line:variable-name
  private _webUsers: Array<webserviceModels.Common.Response.WebUser.webuser>;
  private lastSearchQuery: string;

  // tslint:disable-next-line:member-ordering
  static $inject = ["resellerService", "$q", "searchHelperService"];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService,
    private searchHelperService: SearchHelperService
  ) {}

  get users(): Array<webserviceModels.Common.Response.WebUser.webuser> {
    if (this._webUsers) return this._webUsers;
    throw new ReferenceError("The web user list has not been initialized.");
  }

  capitalizeFirstLetter(query: string): string {
    const [first, ...rest] = query.split("");
    return [first.toUpperCase(), ...rest].join("");
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    const searchQuery = this.searchHelperService.searchQuery;
    let filters: string = null;

    if (searchQuery) {
      filters = `or(name::q ${this.capitalizeFirstLetter(
        searchQuery
      )}|email::q ${searchQuery})`;
    }

    // tslint:disable-next-line:triple-equals
    if (searchQuery != this.lastSearchQuery) forceReload = true;

    if (!forceReload) {
      deferred.resolve();
    } else {
      const response = this.resellerService.listusers(filters);

      response.then(
        success => {
          if (success.meta.result) {
            this._webUsers = success.webusers;
            deferred.resolve(success.webusers);
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._webUsers) {
      return this.load(true);
    }

    throw new ReferenceError("The domain list has not been initialized.");
  }

  invite(
    user: webserviceModels.Common.Request.WebUser.sendInvitation
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    const response = this.resellerService.sendInvitation(user);

    response.then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  createAndAccept(
    user: webserviceModels.Merchant.Request.WebUser.addwebuser
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    const response = this.resellerService.createUserAndAcceptInvitation(user);

    response.then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  activate(accesstoken: string): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const response = this.resellerService.activatewebuser(accesstoken);

    response.then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  deactivate(accesstoken: string): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const response = this.resellerService.deactivatewebuser(accesstoken);

    response.then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(
    user: webserviceModels.Common.Response.WebUser.webuser
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const response = this.resellerService.deleteUser(user.accesstoken);

    response.then(
      success => {
        if (success.meta.result) {
          deferred.resolve();
          this.refresh();
        } else {
          try {
            deferred.reject(success.meta.message);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

import "./payments.scss";
import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../shared/decorators";
import {
  ImpersonateService,
  SearchHelperService,
  ToastHelperService,
  CheckoutService,
  MerchantService,
  PaymentCollectionListService
} from "../shared/services";
import { TransactionsService } from "./shared/transactions.service";
import { Merchant } from "../shared/interfaces/webservice-models";

@Component({
  selector: "payments",
  template: require("./payments.html"),
  bindings: {
    _companyId: "@companyId",
    _merchantNumber: "@merchantNumber"
  }
})
export class PaymentsComponent {
  // tslint:disable:variable-name
  private _companyId: string;
  private _merchantNumber: string;
  private _showFilters: boolean;
  private _isInitializing: boolean;
  private _unbindOnRefreshHandler: () => any;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "impersonateService",
    "transactionsService",
    "$stateParams",
    "searchHelperService",
    "$mdBottomSheet",
    "$rootScope",
    "toastHelper",
    "gettextCatalog",
    "checkoutService",
    "merchantService",
    "paymentCollectionListService"
  ];

  constructor(
    private impersonateService: ImpersonateService,
    private transactionsService: TransactionsService,
    private $stateParams: ng.ui.IStateParamsService,
    private searchHelperService: SearchHelperService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $rootScope: ng.IRootScopeService,
    private toastHelper: ToastHelperService,
    private gettextCatalog: ng.gettext.gettextCatalog,
    private checkoutService: CheckoutService,
    private merchantService: MerchantService,
    private paymentCollectionListService: PaymentCollectionListService
  ) {}

  get payments() {
    if (this._isInitializing) return [];
    try {
      return this.transactionsService.transactions;
    } catch (e) {
      return null;
    }
  }

  get searchFilter() {
    return this.searchHelperService.searchFilter;
  }

  get isInitializing() {
    return this._isInitializing;
  }

  get showFilters() {
    return this._showFilters;
  }

  $onInit() {
    this._isInitializing = true;

    this.load();

    this._unbindOnRefreshHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefresh()
    );
  }

  $onDestroy() {
    if (this._unbindOnRefreshHandler) this._unbindOnRefreshHandler();
  }

  load() {
    this.impersonateService
      .impersonateMasterWebUser(this._companyId, this._merchantNumber)
      .then(impersonateWebUserResponse => {
        this.transactionsService
          .load(this._companyId, this._merchantNumber)
          .finally(() => (this._isInitializing = false));
      })
      .catch(error => (this._isInitializing = false));
  }

  onRefresh() {
    const refreshPromise = this.transactionsService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The payment list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The payment list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  getEvents(paymentId: string) {
    return this.checkoutService.listevents(
      null,
      null,
      null,
      null,
      null,
      paymentId,
      null
    );
  }

  getOperations(paymentId: string) {
    return this.merchantService.listtransactionoperations(paymentId);
  }

  toggleFilters() {
    if (this._showFilters) {
      this.$mdBottomSheet.hide();
    } else {
      this._showFilters = true;
      this.$mdBottomSheet
        .show({
          parent: angular.element("#overlay-container"),
          clickOutsideToClose: false,
          disableBackdrop: true,
          disableParentScroll: false,
          escapeToClose: false,
          template: `
                        <md-bottom-sheet class="md-grid md-whiteframe-z1" layout="column">
                            <payments-filter></payments-filter>
                        </md-bottom-sheet>
                    `
        } as any)
        .finally(() => (this._showFilters = false));
    }
  }

  getDistinctPaymentTypes(
    paymenttypes: Array<Merchant.Response.Transaction.paymenttype>
  ) {
    return paymenttypes.filter(
      (paymentTypeItem, index, array) =>
        array.findIndex(
          arrayItem => arrayItem.groupid === paymentTypeItem.groupid
        ) === index
    );
  }

  getWalletDisplayName(walletName: string) {
    let walletPaymentType = this.paymentCollectionListService.paymentTypes.find(
      paymentType => {
        return (
          paymentType.name.toLocaleLowerCase() == walletName.toLocaleLowerCase()
        );
      }
    );

    if (walletPaymentType) return walletPaymentType.displayname;

    return walletName;
  }
}

export function getFactoryFor<T extends ng.IDirective>(
  classType: any
): ng.IDirectiveFactory {
  const factory = (...args): T => {
    const directive = classType as any;
    return new (directive.bind(directive, ...args))();
  };
  factory.$inject = classType.$inject;
  return factory;
}

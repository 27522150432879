export function AnimateExpandList(): any {
  const bezier = [0.25, 0.8, 0.25, 1];

  const animateIn = (element: JQuery, doneFn) => {
    const height = element.outerHeight();
    element.outerHeight(height);

    const listItems = element.find(".animate-expand-list-item");
    listItems.css("display", "none");

    element.velocity("stop");
    listItems.velocity("stop");

    element.velocity(
      "slideDown",
      50 * listItems.length + (height / Math.log(height) + 300),
      // tslint:disable-next-line:no-empty
      () => {}
    );

    listItems.velocity("transition.slideUpIn", {
      stagger: 50,
      begin: () => {
        listItems.css("display", "");
      },
      complete: () => {
        element.css("height", "");
        doneFn();
      },
      duration: height / Math.log(height) + 300,
      display: null,
      translateZ: 0,
      delay: 150,
      easing: bezier
    } as any);
  };

  const animateOut = (element: JQuery, doneFn) => {
    const height = element.outerHeight();
    element.outerHeight(height);

    const listItems = element.find(".animate-expand-list-item");

    element.velocity("stop");
    listItems.velocity("stop");

    listItems.velocity("transition.slideUpOut", {
      stagger: 50,
      // tslint:disable-next-line:no-empty
      complete: () => {},
      backwards: true,
      duration: height / Math.log(height) + 300,
      display: null,
      translateZ: 0,
      easing: bezier
    } as any);
    element.velocity("slideUp", {
      duration: 50 * listItems.length + (height / Math.log(height) + 300),
      complete: doneFn
    });
  };

  return {
    enter: animateIn,
    leave: animateOut,
    addClass: (element: JQuery, className: string, doneFn) => {
      animateOut(element, doneFn);
    },
    removeClass: (element: JQuery, className: string, doneFn) => {
      animateIn(element, doneFn);
    }
  };
}

export function AnimateSlideList(): any {
  const animateIn = (element: JQuery, doneFn) => {
    const height = element.outerHeight();
    element.outerHeight(height);
    element.velocity("slideDown", height / Math.log(height) + 200, () => {
      element
        .find(".animate-slide-list-item")
        .velocity("transition.slideRightIn", {
          stagger: 300,
          complete: doneFn,
          duration: height / Math.log(height) + 300,
          display: null
        });
    });
  };

  const animateOut = (element: JQuery, doneFn) => {
    const height = element.outerHeight();
    element.outerHeight(height);

    element
      .find(".animate-slide-list-item")
      .velocity("transition.slideRightOut", {
        stagger: 300,
        complete: () => {
          element.velocity("slideUp", height / Math.log(height) + 200, doneFn);
        },
        backwards: true,
        duration: height / Math.log(height) + 300,
        display: null
      });
  };

  return {
    enter: animateIn,
    leave: animateOut,
    addClass: (element: JQuery, className: string, doneFn) => {
      animateOut(element, doneFn);
    },
    removeClass: (element: JQuery, className: string, doneFn) => {
      animateIn(element, doneFn);
    }
  };
}

import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  MerchantNumberListService,
  CountryListService,
  ToastHelperService
} from "../shared/services";

export class MerchantNumberEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "merchantNumberListService",
    "merchantNumber",
    "countryListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IMerchantNumberEditScope,
    private $mdDialog: ng.material.IDialogService,
    private merchantNumberListService: MerchantNumberListService,
    private merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber,
    private countryListService: CountryListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.countryListService = countryListService;

    $scope.request = {
      merchantnumber: angular.copy(merchantNumber)
    };

    $scope.featureSelections = merchantNumberListService.availableMerchantFeatures.map(
      amf => ({
        feature: amf,
        selected: this.$scope.request.merchantnumber.features.some(
          f => f.name === amf.name
        ),
        locked: amf.locked
      })
    );
  }

  submit(
    request: webserviceModels.Common.Request.MerchantNumber.updatemerchantnumber
  ) {
    request.merchantnumber.features = this.$scope.featureSelections
      .filter(fs => fs.selected && !fs.locked)
      .map(fs => ({ name: fs.feature.name }));

    this.merchantNumberListService
      .update(this.merchantNumber.number, request)
      .then(
        success => {
          this.$mdDialog.hide();
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{merchantnumber}} was successfully updated.",
              { merchantnumber: this.merchantNumber.number }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{merchantnumber}} could not be updated at this time.",
                { merchantnumber: this.merchantNumber.number }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface IMerchantNumberEditScope extends ng.IScope {
  request: webserviceModels.Common.Request.MerchantNumber.updatemerchantnumber;
  countryListService: CountryListService;
  featureSelections: Array<{
    feature: webserviceModels.Data.Response.ProductFeatures.productfeature;
    selected: boolean;
    locked: boolean;
  }>;
}

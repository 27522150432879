import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  AuthenticationService,
  ResellerListService,
  ToastHelperService,
  UserListService
} from "../shared/services";
import { IUserListScope } from "./user-list.controller";

export class UserCreateController {
  static $inject = [
    "$scope",
    "$state",
    "$mdDialog",
    "userListService",
    "resellerListService",
    "authenticationService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IUserCreateScope,
    private $state: ng.ui.IStateService,
    private $mdDialog: ng.material.IDialogService,
    private userListService: UserListService,
    private resellerListService: ResellerListService,
    private authenticationService: AuthenticationService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.created = null;
    $scope.resellerListService = resellerListService;

    $scope.request = {
      name: undefined,
      email: undefined,
      reseller: undefined,
      senderEmail: undefined
    };
  }

  printSelectedItem(reseller: webserviceModels.Common.reseller) {
    return `${reseller.companyname} (${reseller.number})`;
  }

  submit(request: createUserDialogRequest) {
    request.senderEmail = this.authenticationService.userSession.email;

    const sendInvitation: webserviceModels.Common.Request.WebUser.sendInvitation = {
      name: request.name,
      email: request.email,
      resellernumber: request.reseller.number,
      senderemail: request.senderEmail
    };

    this.userListService.invite(sendInvitation).then(
      success => {
        this.$scope.created = success;
        this.toastHelper.toast(
          this.gettextCatalog.getString("User has been invited"),
          this.gettextCatalog.getString("Dismiss")
        );
        this.$mdDialog.cancel();
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{name}} could not be created at this time.",
              { name: request.name }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

interface createUserDialogRequest {
  name: string;
  email: string;
  reseller: webserviceModels.Common.reseller;
  senderEmail: string;
}

export interface IUserCreateScope extends IUserListScope {
  request: createUserDialogRequest;
  created: any;
  resellerListService: ResellerListService;
}

import * as angular from "angular";
import * as webserviceModels from "../../interfaces/webservice-models";
import { PaymentWindowService } from "../clients";
import { AddApiUserResponse } from ".";

export class PaymentWindowListService {
  // tslint:disable-next-line:variable-name
  private _paymentWindows: Array<
    webserviceModels.PaymentWindow.Response.paymentwindow
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = ["paymentWindowService", "$q"];
  constructor(
    private paymentWindowService: PaymentWindowService,
    private $q: ng.IQService
  ) {}

  get paymentWindows(): Array<
    webserviceModels.PaymentWindow.Response.paymentwindow
  > {
    if (this._paymentWindows) return this._paymentWindows;
    throw new ReferenceError(
      "The payment window list has not been initialized."
    );
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._paymentWindows) {
      deferred.resolve();
    } else {
      const response = this.paymentWindowService.listpaymentwindows();

      response.then(
        success => {
          if (success.meta.result) {
            this._paymentWindows = success.paymentwindows;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._paymentWindows) return this.load(true);
    throw new ReferenceError(
      "The payment window list has not been initialized."
    );
  }

  create(
    request: webserviceModels.PaymentWindow.Request.addpaymentwindow
  ): ng.IPromise<AddApiUserResponse> {
    if (!this._paymentWindows) {
      throw new ReferenceError(
        "The payment window list has not been initialized."
      );
    }

    const deferred = this.$q.defer<
      webserviceModels.Merchant.Response.ApiUser.addapiuserresponse
    >();
    const response = this.paymentWindowService.addpaymentwindow(request);

    response.then(
      success => {
        if (success.meta.result) {
          this.paymentWindowService.listpaymentwindows().then(
            result => {
              angular.copy(result.paymentwindows, this._paymentWindows);
              deferred.resolve();
            },
            error => deferred.resolve()
          );
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise as any;
  }

  update(
    paymentWindowId: number,
    request: webserviceModels.PaymentWindow.Request.updatepaymentwindow
  ): ng.IPromise<any> {
    if (!this._paymentWindows) {
      throw new ReferenceError(
        "The payment window list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.paymentWindowService.updatepaymentwindow(
      paymentWindowId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          this.paymentWindowService.listpaymentwindows().then(
            result => {
              angular.copy(result.paymentwindows, this._paymentWindows);
              deferred.resolve();
            },
            error => deferred.resolve()
          );
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ): ng.IPromise<any> {
    if (!this._paymentWindows) {
      throw new ReferenceError(
        "The payment window list has not been initialized."
      );
    }

    const deferred = this.$q.defer();

    const response = this.paymentWindowService.deletepaymentwindow(
      paymentWindow.id
    );

    response.then(
      success => {
        if (success.meta.result) {
          this.paymentWindowService.listpaymentwindows().then(
            result => {
              angular.copy(result.paymentwindows, this._paymentWindows);
              deferred.resolve();
            },
            error => deferred.resolve()
          );
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

export default PaymentWindowListService;

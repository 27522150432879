import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  AgreementGroupListService,
  CurrencyListService,
  ToastHelperService,
  AcquirerListService
} from "../shared/services";

export class AgreementCopyController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "agreementGroupListService",
    "acquirerListService",
    "currencyListService",
    "gettextCatalog",
    "toastHelper",
    "agreement",
    "$q",
    "$mdMedia"
  ];

  constructor(
    private $scope: IAgreementCopyScope,
    private $mdDialog: ng.material.IDialogService,
    private agreementGroupListService: AgreementGroupListService,
    private acquirerListService: AcquirerListService,
    private currencyListService: CurrencyListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private agreement: webserviceModels.Common.Response.Agreement.agreement,
    private $q: ng.IQService,
    private $mdMedia: ng.material.IMedia
  ) {
    $scope.currencyListService = currencyListService;
    $scope.agreement = agreement;
    $scope.acquireDisplayName = this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      agreement.acquirername
    );
    $scope.copyPaymentTypeFees = this.getPaymentTypeFeesByAgreementId(
      agreement.id
    ).length
      ? true
      : false;

    $scope.request = {
      agreement: {
        acquirername: agreement.acquirername,
        agreementdata: agreement.agreementdata,
        canfailover: agreement.canfailover,
        capturedelaytimeinminutes: agreement.capturedelaytimeinminutes,
        capturetype: agreement.capturetype,
        currency: agreement.currency.code,
        deletedelaytimeinminutes: agreement.deletedelaytimeinminutes,
        deletetype: agreement.deletetype,
        status: agreement.status,
        currencyhandling: agreement.currencyhandling,
        currencylist: agreement.currencylist,
        filters: agreement.filters
      }
    };
  }

  submit(request: webserviceModels.Common.Request.Agreement.addagreement) {
    const paymentTypeFees = this.getPaymentTypeFeesByAgreementId(
      this.agreement.id
    );
    const allNoFee: boolean = Enumerable.from(paymentTypeFees).all(
      paymentTypeFee =>
        paymentTypeFee.feecalculatorname.toLowerCase() === "nofee"
    );

    if (!allNoFee) request.agreement.status = "inactive";

    this.agreementGroupListService.createAgreement(request).then(
      success => {
        if (this.$scope.copyPaymentTypeFees) {
          const createArray = ($ as any).map(
            paymentTypeFees,
            (paymentTypeFee, index) => {
              const feeData = JSON.parse(
                JSON.stringify(paymentTypeFee.feedata)
              );
              Enumerable.from<any>(feeData.common)
                .where(data => data.key.toLowerCase() === "currencycode")
                .forEach(data => {
                  data.value = success.currency.code;
                });

              return this.agreementGroupListService.createPaymentTypeFee({
                paymenttypefee: {
                  feecalculatorname: paymentTypeFee.feecalculatorname,
                  agreementid: success.id,
                  feedata: feeData,
                  order: paymentTypeFee.order,
                  paymenttypeid: paymentTypeFee.paymenttype.id,
                  securitylevel: (paymentTypeFee as any).securitylevel
                }
              } as any);
            }
          );

          this.$q.all(createArray).then(
            allSuccess => {
              if (allNoFee) {
                this.$mdDialog.hide();
              } else {
                this.$scope.newAgreement = success;
                this.$scope.showProblematicPaymentTypeFees = true;
              }

              this.toastHelper.toast(
                this.gettextCatalog.getString(
                  "{{acquirername}} ({{currency}}) was successfully created.",
                  {
                    acquirername: this.$scope.acquireDisplayName,
                    currency: request.agreement.currency
                  }
                ),
                this.gettextCatalog.getString("Dismiss")
              );
            },
            error => {
              this.toastHelper.toast(
                this.gettextCatalog.getString(
                  "{{acquirername }} ({{currency }}) was created, but not all payment type fees could be copied.",
                  {
                    acquirername: this.$scope.acquireDisplayName,
                    currency: request.agreement.currency
                  }
                ),
                this.gettextCatalog.getString("Dismiss")
              );
            }
          );
        } else {
          this.$mdDialog.hide();
        }
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{acquirername }} ({{currency }}) could not be created at this time.",
              {
                acquirername: this.$scope.acquireDisplayName,
                currency: request.agreement.currency
              }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  getPaymentTypeFeesByAgreementId(
    agreementId: string
  ): Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee> {
    return Enumerable.from(this.agreementGroupListService.agreementGroups)
      .selectMany(agreementGroup => agreementGroup.paymentTypeFees)
      .where(paymentTypeFee => paymentTypeFee.agreementid === agreementId)
      .toArray();
  }

  getProblematicPaymentTypeFeesByAgreementId(
    agreementId: string
  ): Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee> {
    return Enumerable.from(this.getPaymentTypeFeesByAgreementId(agreementId))
      .where(
        paymentTypeFee =>
          paymentTypeFee.feecalculatorname.toLowerCase() !== "nofee"
      )
      .toArray();
  }

  dismissAndReenable(
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ) {
    this.agreementGroupListService
      .patchAgreement(agreement.id, { agreement: { status: "active" } })
      .then(
        success => {
          this.$mdDialog.cancel();

          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{acquirername}} ({{currency}}) was re-enabled.",
              {
                acquirername: this.$scope.acquireDisplayName,
                currency: agreement.currency.code
              }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{acquirername}} ({{currency}}) could not be re-enabled.",
                {
                  acquirername: this.$scope.acquireDisplayName,
                  currency: agreement.currency.code
                }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  editPaymentTypeFee(
    $event: MouseEvent,
    paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
  ) {
    const scope = this.$scope;
    this.$mdDialog
      .show({
        template: require("../paymenttypefee/payment-type-fee-edit.html"),
        controller: "paymentTypeFeeEditController",
        controllerAs: "paymentTypeFeeEdit",
        targetEvent: $event,
        parent: angular.element(document.body),
        escapeToClose: false,
        clickOutsideToClose: false,
        locals: {
          paymentTypeFee
        },
        fullscreen: !this.$mdMedia("gt-md")
      } as any)
      .finally(() => {
        this.$mdDialog.show({
          template: require("./agreement-copy.html"),
          controller: "agreementCopyController",
          controllerAs: "agreementCopy",
          parent: angular.element(document.body),
          escapeToClose: false,
          clickOutsideToClose: false,
          locals: {
            agreement: this.agreement
          },
          fullscreen: !this.$mdMedia("gt-md"),
          scope,
          preserveScope: true
        } as any);
      });
  }
}

export interface IAgreementCopyScope extends ng.IScope {
  request: webserviceModels.Common.Request.Agreement.addagreement;
  agreement: webserviceModels.Common.Response.Agreement.agreement;
  newAgreement?: webserviceModels.Common.Response.Agreement.agreement;
  currencyListService: CurrencyListService;
  copyPaymentTypeFees: boolean;
  showProblematicPaymentTypeFees: boolean;
  acquireDisplayName: string;
}

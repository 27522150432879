import * as angular from "angular";
import * as uiRouter from "angular-ui-router";
import {
  AuthenticationService,
  MerchantNumberListService,
  ToastHelperService,
  SearchHelperService
} from "./shared/services";
import { CircularLoaderService } from "./shared/components/circular-loader";

export class AppRun {
  static $inject = [
    "$http",
    "$rootScope",
    "$location",
    "authenticationService",
    "$state",
    "circularLoader",
    "merchantNumberListService",
    "toastHelper",
    "searchHelperService",
    "$log",
    "gettextCatalog",
    "$mdBottomSheet",
    "$templateRequest"
  ];

  constructor(
    $http: ng.IHttpService,
    $rootScope: ng.IRootScopeService,
    $location: ng.ILocationService,
    authenticationService: AuthenticationService,
    $state: ng.ui.IStateService,
    circularLoader: CircularLoaderService,
    merchantNumberListService: MerchantNumberListService,
    toastHelper: ToastHelperService,
    searchHelperService: SearchHelperService,
    $log: ng.ILogService,
    gettextCatalog: ng.gettext.gettextCatalog,
    $mdBottomSheet: ng.material.IBottomSheetService,
    $templateRequest: ng.ITemplateRequestService
  ) {
    // $http.defaults.headers.common["X-EPay-LogCollationId"] = JL.requestId;
    let version = process.env.APP_VERSION;
    if (!version) {
      version = "0.0.0";
    }
    $http.defaults.headers.common[
      "X-EPay-System"
    ] = `BamboraReseller/${version}`;

    $rootScope.$on(
      "$stateChangeStart",
      (
        event: ng.IAngularEvent,
        toState,
        toParams,
        fromState,
        fromParams,
        options
      ) => {
        $log.debug({
          type: "stateChangeEvent",
          data: {
            name: "$stateChangeStart",
            toState,
            toParams,
            fromState,
            fromParams
          }
        });

        if (toState.redirectTo) {
          event.preventDefault();
          $state.go(toState.redirectTo, toParams);
        }
      }
    );

    $rootScope.$on(
      "$stateChangeError",
      (
        event: ng.IAngularEvent,
        toState,
        toParams,
        fromState,
        fromParams,
        error
      ) => {
        circularLoader.active = false;

        if (
          error &&
          (error.needsAuthentication === true || error.status === 401)
        ) {
          authenticationService.returnTo.state = toState;
          authenticationService.returnTo.params = toParams;
          $http.defaults.headers.common.Authorization = undefined;
          $state.go("login");
        } else if (fromState.name === "") {
          $log.error({
            type: "stateChangeEvent",
            data: {
              name: "$stateChangeError",
              toState,
              toParams,
              fromState,
              fromParams,
              error
            }
          });

          $state.transitionTo("error", { errorCode: 1 }, false);
        } else {
          $log.error({
            type: "stateChangeEvent",
            data: {
              name: "$stateChangeError",
              toState,
              toParams,
              fromState,
              fromParams,
              error
            }
          });

          toastHelper.toast(
            gettextCatalog.getString(
              "Whoops! Something went wrong. Please contact support."
            ),
            gettextCatalog.getString("Dismiss")
          );
        }
      }
    );

    $rootScope.$on(
      "$stateChangeSuccess",
      (
        event: ng.IAngularEvent,
        toState,
        toParams,
        fromState,
        fromParams,
        error
      ) => {
        $log.debug({
          type: "stateChangeEvent",
          data: {
            name: "$stateChangeSuccess",
            toState,
            toParams,
            fromState,
            fromParams,
            error
          }
        });

        if (fromState.name === "companyList") {
          sessionStorage.setItem(
            "companyListSearch",
            searchHelperService.searchQuery || ""
          );
        }

        searchHelperService.reset();

        if (toState.name === "companyList") {
          searchHelperService.searchQuery =
            sessionStorage.getItem("companyListSearch") || "";

          if (searchHelperService.searchQuery) {
            searchHelperService.showSearch = true;
          }
        }

        circularLoader.active = false;
        $mdBottomSheet.hide();
        (angular.element("#container") as any).first().scrollTop(0);

        if (toParams.merchantnumber === "") {
          $state.go(toState.name, {
            merchantnumber:
              merchantNumberListService.selectedMerchantNumber.number
          });
        }
      }
    );

    ($rootScope as any).$state = $state;
  }
}

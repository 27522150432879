import * as angular from "angular";
import moment from "moment";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import { ApiUserListService, ToastHelperService } from "../shared/services";

export class ApiUserEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "apiUserListService",
    "apiUser",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IApiUserEditScope,
    private $mdDialog: ng.material.IDialogService,
    private apiUserListService: ApiUserListService,
    private apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.apiUserListService = apiUserListService;
    $scope.apiUser = apiUser;

    $scope.request = {
      apiuser: angular.copy(apiUser)
    };

    const utcDate = moment.utc($scope.request.apiuser.expiredate);

    $scope.expireDate = new Date(
      utcDate.year(),
      utcDate.month(),
      utcDate.date(),
      utcDate.hours(),
      utcDate.minutes(),
      utcDate.seconds(),
      utcDate.milliseconds()
    );

    $scope.expireTime = new Date(
      Date.UTC(
        utcDate.year(),
        utcDate.month(),
        utcDate.date(),
        utcDate.hours(),
        utcDate.minutes(),
        utcDate.seconds(),
        utcDate.milliseconds()
      )
    );
  }

  submit(request: webserviceModels.Merchant.Request.ApiUser.updateapiuser) {
    const { expireDate, expireTime } = this.$scope;

    const date = new Date(
      Date.UTC(
        expireDate.getUTCFullYear(),
        expireDate.getUTCMonth(),
        expireDate.getUTCDate(),
        expireTime.getUTCHours(),
        expireTime.getUTCMinutes(),
        expireTime.getUTCSeconds(),
        expireTime.getUTCMilliseconds()
      )
    );

    const isoDate = date.toISOString();

    request.apiuser.expiredate = isoDate;

    this.apiUserListService.update(this.apiUser.accesstoken, request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString("{{email}} was successfully updated.", {
            email: request.apiuser.email
          }),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{email}} could not be updated at this time.",
              { email: request.apiuser.email }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface IApiUserEditScope extends ng.IScope {
  request: webserviceModels.Merchant.Request.ApiUser.updateapiuser;
  apiUserListService: ApiUserListService;
  apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser;
  expireDate: Date;
  expireTime: Date;
}

import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  AgreementGroupListService,
  AcquirerListService,
  CurrencyListService,
  PaymentCollectionListService,
  ToastHelperService
} from "../shared/services";
import { IPaymentTypeFeeListScope } from "./payment-type-fee-list.controller";

export class PaymentTypeFeeEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "agreementGroupListService",
    "acquirerListService",
    "paymentTypeFee",
    "currencyListService",
    "paymentCollectionListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IPaymentTypeFeeEditScope,
    private $mdDialog: ng.material.IDialogService,
    private agreementGroupListService: AgreementGroupListService,
    private acquirerListService: AcquirerListService,
    private paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee,
    private currencyListService: CurrencyListService,
    private paymentCollectionListService: PaymentCollectionListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.agreementGroupListService = agreementGroupListService;
    $scope.currencyListService = currencyListService;

    $scope.request = {
      paymenttypefee: {
        agreementid: paymentTypeFee.agreementid,
        agreementidvalue: paymentTypeFee.agreementidvalue,
        feecalculatorname: paymentTypeFee.feecalculatorname,
        feedata: { common: [], ranges: [] },
        order: paymentTypeFee.order,
        paymenttypeid: paymentTypeFee.paymenttype.id,
        securitylevel: (paymentTypeFee as any).securitylevel,
        addfee: "Default"
      } as any
    };

    const paymentGroupId = Enumerable.from(
      paymentCollectionListService.paymentCollections
    )
      .selectMany(paymentCollection => paymentCollection.paymentgroups)
      .first(
        paymentGroup => paymentGroup.id === paymentTypeFee.paymenttype.groupid
      ).id;

    $scope.showSecurityLevel = [1, 2, 3, 4].indexOf(paymentGroupId) > -1;

    $scope.agreement = Enumerable.from(
      agreementGroupListService.agreementGroups
    )
      .selectMany(agreementGroup => agreementGroup.agreements)
      .first(agreement => agreement.id === paymentTypeFee.agreementid);

    this.selectedCurrency = $scope.agreement.currency.code;

    $scope.paymentType = paymentTypeFee.paymenttype;

    $scope.common = {};
    paymentTypeFee.feedata.common.forEach(keyValuePair => {
      switch (keyValuePair.key.toLowerCase()) {
        case "defaultpercentage": {
          $scope.common[keyValuePair.key] = parseFloat(keyValuePair.value);
          break;
        }
        case "defaultfixedfee":
        case "defaultminimumfee":
        case "defaultmaximumfee": {
          $scope.common[keyValuePair.key] = parseInt(keyValuePair.value, 10);
          break;
        }
        default: {
          $scope.common[keyValuePair.key] = keyValuePair.value;
          break;
        }
      }
    });

    $scope.common.Rounding = $scope.common.Rounding || "Ceiling";
    $scope.common.Type = $scope.common.Type || "FeeFee";

    $scope.showFinalizedRange = [];
    $scope.ranges = [];
    paymentTypeFee.feedata.ranges.forEach(feeDataRange => {
      const range: any = {};
      feeDataRange.range.forEach(keyValuePair => {
        switch (keyValuePair.key.toLowerCase()) {
          case "percentage": {
            range[keyValuePair.key] = parseFloat(keyValuePair.value);
            break;
          }
          case "fromamount":
          case "toamount":
          case "fixedfee":
          case "minimumfee":
          case "maximumfee": {
            range[keyValuePair.key] = parseInt(keyValuePair.value, 10);
            break;
          }
          default: {
            range[keyValuePair.key] = keyValuePair.value;
            break;
          }
        }
      });
      range.showFinalized = true;
      $scope.ranges.push(range);
    });
  }

  // tslint:disable-next-line:member-ordering
  selectedCurrency: string;

  addRange() {
    this.$scope.ranges.push({});
    $("md-dialog-content.dynamic-dialog-content").animate(
      {
        scrollTop: $("md-content.dynamic-dialog-content").height()
      },
      200
    );
  }

  removeRange(range: any) {
    this.$scope.ranges.splice(this.$scope.ranges.indexOf(range), 1);
  }

  submit(
    request: webserviceModels.Common.Request.PaymentTypeFee.updatepaymenttypefee,
    common: any = {},
    ranges: any = {}
  ) {
    switch (request.paymenttypefee.feecalculatorname) {
      case "NoFee": {
        common = {
          CurrencyCode: common.CurrencyCode
        };
        break;
      }
      case "FixedFee": {
        common = {
          CurrencyCode: common.CurrencyCode,
          DefaultFixedFee: common.DefaultFixedFee
        };
        break;
      }
      case "PercentageWiseFee": {
        common = {
          DefaultPercentage: common.DefaultPercentage,
          DefaultFixedFee: common.DefaultFixedFee,
          DefaultMinimumFee: common.DefaultMinimumFee,
          DefaultMaximumFee: common.DefaultMaximumFee,
          Rounding: common.Rounding,
          Type: common.Type,
          CurrencyCode: common.CurrencyCode
        };
        break;
      }
    }

    request.paymenttypefee.feedata.common = $.map(common, (value, index) => {
      return { key: index, value } as webserviceModels.Common.input;
    });

    request.paymenttypefee.feedata.ranges = [];

    ranges.forEach(range => {
      request.paymenttypefee.feedata.ranges.push({
        range: $.map(angular.copy(range), (value, index) => {
          if (index === "showFinalized") return;
          return { key: index, value } as webserviceModels.Common.input;
        })
      });
    });

    this.agreementGroupListService
      .updatePaymentTypeFee(this.paymentTypeFee.id, request)
      .then(
        success => {
          this.$mdDialog.hide();
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "The payment type was successfully updated."
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "The payment type could not be updated at this time."
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface IPaymentTypeFeeEditScope extends IPaymentTypeFeeListScope {
  request: webserviceModels.Common.Request.PaymentTypeFee.updatepaymenttypefee;
  agreement: webserviceModels.Common.Response.Agreement.agreement;
  paymentType: webserviceModels.Data.Response.ListPaymentTypes.paymenttype;
  common: any;
  ranges: any;
  showSecurityLevel: boolean;
  agreementGroupListService: AgreementGroupListService;
  currencyListService: CurrencyListService;
  showFinalizedRange: Array<boolean>;
}

import * as angular from "angular";
import * as uiRouter from "angular-ui-router";
import {
  AuthenticationService,
  CurrencyListService,
  CountryListService,
  AcquirerListService,
  PaymentCollectionListService,
  CompanyListService,
  ResponseCodeListService,
  CompanyService,
  MerchantNumberListService,
  DomainListService,
  ImpersonateService,
  AgreementGroupListService,
  WebUserListService,
  ApiUserListService,
  TabHelperService,
  RequirementsFilterListService,
  UserListService,
  ResellerListService,
  AcceptInvitationService
} from "./shared/services";
import { ResponseCodesFilterService } from "./responsecodes";
import { PaymentsFilterService } from "./payments";
import { LogsFilterService } from "./merchantlog";

export class AppStateConfig {
  static $inject = ["$stateProvider", "$urlRouterProvider"];

  constructor(
    $stateProvider: IStateProvider,
    $urlRouterProvider: uiRouter.IUrlRouterProvider
  ) {
    // Create new $stateProvider method 'stateAuth' for states requiring authentication.
    $stateProvider.stateAuth = (name: string, config: ng.ui.IState) => {
      config.resolve = config.resolve || {};

      angular.extend(config.resolve, {
        isAuthenticated: [
          "$q",
          "authenticationService",
          ($q: ng.IQService, authenticationService: AuthenticationService) => {
            const deferred = $q.defer();
            if (!authenticationService.isAuthenticated()) {
              authenticationService.authenticateByClientSideStorage().then(
                success => {
                  deferred.resolve();
                },
                error => {
                  deferred.reject({ needsAuthentication: true });
                }
              );
            } else deferred.resolve();
            return deferred.promise;
          }
        ]
      });

      return $stateProvider.state(name, config);
    };

    // Iterate through states and apply configurations.
    AppStateConfig.states.forEach(state => {
      if (state.requiresAuthentication) {
        $stateProvider.stateAuth(state.name, state.config);
      } else $stateProvider.state(state.name, state.config);
    });

    // Default states.
    const otherwise = ($injector: ng.auto.IInjectorService) => {
      const authenticationService = $injector.get<AuthenticationService>(
        "authenticationService"
      );
      const $state = $injector.get<ng.ui.IStateService>("$state");

      if (!authenticationService.isAuthenticated()) {
        authenticationService.authenticateByClientSideStorage().then(
          success => {
            $state.go("companyList");
          },
          error => {
            $state.go("login");
          }
        );
      }
    };
    otherwise.$inject = ["$injector"];
    $urlRouterProvider.otherwise(otherwise);
  }

  // tslint:disable-next-line:member-ordering
  static states: any = [
    // Array<IStateConfiguration> = [
    {
      name: "authenticated",
      config: {
        abstract: true,
        resolve: {
          resolveCurrencyList: [
            "currencyListService",
            "isAuthenticated",
            (currencyListService: CurrencyListService, isAuthenticated) => {
              return currencyListService.load();
            }
          ],
          resolveRequirementsFilterList: [
            "requirementsFilterListService",
            "isAuthenticated",
            (
              requirementsFilterListService: RequirementsFilterListService,
              isAuthenticated
            ) => {
              return requirementsFilterListService.load();
            }
          ],
          resolveCountryList: [
            "countryListService",
            "isAuthenticated",
            (countryListService: CountryListService, isAuthenticated) => {
              return countryListService.load();
            }
          ],
          resolveAcquirerList: [
            "acquirerListService",
            "isAuthenticated",
            (acquirerListService: AcquirerListService, isAuthenticated) => {
              return acquirerListService.load();
            }
          ],
          resolvePaymentCollectionList: [
            "paymentCollectionListService",
            "isAuthenticated",
            (
              paymentCollectionListService: PaymentCollectionListService,
              isAuthenticated
            ) => {
              return paymentCollectionListService.load();
            }
          ]
        }
      },
      requiresAuthentication: true
    },
    {
      name: "responseCodeList",
      config: {
        parent: "authenticated",
        url: "/responsecodes",
        resolve: {
          resolveResponseCodeList: [
            "responseCodeListService",
            "isAuthenticated",
            (responseCodeListService: CompanyListService, isAuthenticated) => {
              return responseCodeListService.load(null, true);
            }
          ],
          title: () => "Response Codes"
        },
        views: {
          "@": {
            template: require("./responsecodes/response-code-list.html"),
            controller: "responseCodeListController",
            controllerAs: "responseCodeList"
          }
        }
      }
    },
    {
      name: "userList",
      config: {
        parent: "authenticated",
        url: "/users",
        resolve: {
          resolveUserList: [
            "userListService",
            "resellerListService",
            "isAuthenticated",
            (
              userListService: UserListService,
              resellerListService: ResellerListService,
              isAuthenticated
            ) => {
              return Promise.all([
                resellerListService.load(),
                userListService.load(true)
              ]);
            }
          ],
          title: () => "Users"
        },
        views: {
          "@": {
            template: require("./user/user-list.html"),
            controller: "userListController",
            controllerAs: "userList"
          }
        },
        onExit: [
          "responseCodesFilterService",
          (responseCodesFilterService: ResponseCodesFilterService) =>
            responseCodesFilterService.clearAll()
        ]
      }
    },

    //#region Company
    {
      name: "companyList",
      config: {
        parent: "authenticated",
        url: "/companies",
        resolve: {
          resolveCompanyList: [
            "companyListService",
            "isAuthenticated",
            (companyListService: CompanyListService, isAuthenticated) => {
              return companyListService.load(null, true);
            }
          ],
          title: () => "Companies"
        },
        views: {
          "@": {
            template: require("./company/company-list.html"),
            controller: "companyListController",
            controllerAs: "companyList"
          }
        }
      }
    },
    {
      name: "companyDetails",
      config: {
        parent: "authenticated",
        url: "/companies/{companyid}",
        resolve: {
          resolveCompany: [
            "companyService",
            "isAuthenticated",
            "$stateParams",
            (companyService: CompanyService, isAuthenticated, $stateParams) => {
              return companyService.load($stateParams.companyid);
            }
          ],
          resolveMerchantNumberList: [
            "merchantNumberListService",
            "isAuthenticated",
            "$stateParams",
            (
              merchantNumberListService: MerchantNumberListService,
              isAuthenticated,
              $stateParams
            ) => {
              return merchantNumberListService.load($stateParams.companyid);
            }
          ],
          resolveDomainList: [
            "domainListService",
            "isAuthenticated",
            "$stateParams",
            (
              domainListService: DomainListService,
              isAuthenticated,
              $stateParams
            ) => {
              return domainListService.load($stateParams.companyid);
            }
          ],
          title: [
            "resolveCompany",
            "companyService",
            (resolveCompany, companyService: CompanyService) => {
              return companyService.company.name;
            }
          ]
        },
        views: {
          "sidenav-right@": {
            controller: "companyDetailsSidenavController",
            controllerAs: "companyDetailsSidenav",
            template: require("./company/company-details-sidenav.html")
          }
        },
        redirectTo: "agreementList"
      } as any
    },
    {
      name: "merchantNumber",
      config: {
        parent: "companyDetails",
        url: "/merchantnumbers/{merchantnumber}",
        redirectTo: "agreementList",
        resolve: {
          resolveSelectedMerchantNumber: [
            "resolveMerchantNumberList",
            "merchantNumberListService",
            "$stateParams",
            "$q",
            "$state",
            (
              resolveMerchantNumberList,
              merchantNumberListService: MerchantNumberListService,
              $stateParams,
              $q: ng.IQService,
              $state: ng.ui.IStateService
            ) => {
              if ($stateParams.merchantnumber) {
                merchantNumberListService.setSelectedMerchantNumber(
                  $stateParams.merchantnumber
                );
              }

              if (
                $stateParams.merchantnumber !==
                merchantNumberListService.selectedMerchantNumber.number
              ) {
                $state.go("merchantNumber", {
                  companyid: $stateParams.companyid,
                  merchantnumber:
                    merchantNumberListService.selectedMerchantNumber.number
                });
              }

              return $q.when();
            }
          ],
          resolveImpersonate: [
            "impersonateService",
            "isAuthenticated",
            "$stateParams",
            "resolveSelectedMerchantNumber",
            "merchantNumberListService",
            (
              impersonateService: ImpersonateService,
              isAuthenticated,
              $stateParams,
              resolveSelectedMerchantNumber,
              merchantNumberListService: MerchantNumberListService
            ) => {
              return impersonateService.impersonateMasterWebUser(
                $stateParams.companyid,
                merchantNumberListService.selectedMerchantNumber.number
              );
            }
          ],
          resolveAgreementGroupList: [
            "agreementGroupListService",
            "isAuthenticated",
            "$stateParams",
            "resolveSelectedMerchantNumber",
            "merchantNumberListService",
            (
              agreementGroupListService: AgreementGroupListService,
              isAuthenticated,
              $stateParams,
              resolveSelectedMerchantNumber,
              merchantNumberListService: MerchantNumberListService
            ) => {
              return agreementGroupListService.load(
                $stateParams.companyid,
                merchantNumberListService.selectedMerchantNumber.number
              );
            }
          ],
          resolveWebUserList: [
            "webUserListService",
            "isAuthenticated",
            "$stateParams",
            "resolveSelectedMerchantNumber",
            "merchantNumberListService",
            (
              webUserListService: WebUserListService,
              isAuthenticated,
              $stateParams,
              resolveSelectedMerchantNumber,
              merchantNumberListService: MerchantNumberListService
            ) => {
              return webUserListService.load(
                $stateParams.companyid,
                merchantNumberListService.selectedMerchantNumber.number
              );
            }
          ],
          resolveApiUserList: [
            "apiUserListService",
            "isAuthenticated",
            "$stateParams",
            "resolveSelectedMerchantNumber",
            "merchantNumberListService",
            "resolveImpersonate",
            (
              apiUserListService: ApiUserListService,
              isAuthenticated,
              $stateParams,
              resolveSelectedMerchantNumber,
              merchantNumberListService: MerchantNumberListService,
              resolveImpersonate
            ) => {
              return apiUserListService.load(
                $stateParams.companyid,
                merchantNumberListService.selectedMerchantNumber.number
              );
            }
          ],
          resolvePaymentWindowList: [
            "paymentWindowListService",
            "isAuthenticated",
            "$stateParams",
            "resolveSelectedMerchantNumber",
            "merchantNumberListService",
            "resolveImpersonate",
            (
              paymentWindowListService: ApiUserListService,
              isAuthenticated,
              $stateParams,
              resolveSelectedMerchantNumber,
              merchantNumberListService: MerchantNumberListService,
              resolveImpersonate
            ) => {
              return paymentWindowListService.load(
                $stateParams.companyid,
                merchantNumberListService.selectedMerchantNumber.number
              );
            }
          ]
        }
      }
    },
    //#endregion

    //#region Web user
    {
      name: "webUserList",
      config: {
        parent: "merchantNumber",
        url: "/webusers",
        views: {
          "@": {
            template: require("./webuser/web-user-list.html"),
            controller: "webUserListController",
            controllerAs: "webUserList"
          }
        },
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Payment
    {
      name: "payments",
      config: {
        parent: "merchantNumber",
        url: "/payments",
        resolve: {
          resolveResponseCodeList: [
            "responseCodeListService",
            (responseCodeListService: ResponseCodeListService) => {
              return responseCodeListService.load();
            }
          ]
        },
        views: {
          "@": {
            templateProvider: [
              "$stateParams",
              $stateParams => {
                return `<payments company-id="${$stateParams.companyid}" merchant-number="${$stateParams.merchantnumber}"></payments>`;
              }
            ]
          }
        },
        onEnter: [
          "tabHelper",
          (tabHelper: TabHelperService) => (tabHelper.currentTab = 8)
        ],
        onExit: [
          "paymentsFilterService",
          (paymentsFilterService: PaymentsFilterService) =>
            paymentsFilterService.clearAll()
        ],
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Merchant log
    {
      name: "logs",
      config: {
        parent: "merchantNumber",
        url: "/logs",
        views: {
          "@": {
            templateProvider: [
              "$stateParams",
              $stateParams => {
                return `<logs company-id="${$stateParams.companyid}" merchant-number="${$stateParams.merchantnumber}"></logs>`;
              }
            ]
          }
        },
        onEnter: [
          "tabHelper",
          (tabHelper: TabHelperService) => (tabHelper.currentTab = 2)
        ],
        onExit: [
          "logsFilterService",
          (logsFilterService: LogsFilterService) => logsFilterService.clearAll()
        ],
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Domain
    {
      name: "domainList",
      config: {
        parent: "companyDetails",
        url: "/domains",
        views: {
          "@": {
            template: require("./domain/domain-list.html"),
            controller: "domainListController",
            controllerAs: "domainList"
          }
        },
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Api User
    {
      name: "apiUserList",
      config: {
        parent: "merchantNumber",
        url: "/apiusers",
        views: {
          "@": {
            template: require("./api-user/api-user-list.html"),
            controller: "apiUserListController",
            controllerAs: "apiUserList"
          }
        },
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Payment window
    {
      name: "paymentWindowList",
      config: {
        parent: "merchantNumber",
        url: "/paymentwindows",
        views: {
          "@": {
            template: require("./paymentwindows/payment-window-list.html"),
            controller: "paymentWindowListController",
            controllerAs: "paymentWindowList"
          }
        },
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Agreement
    {
      name: "agreementList",
      config: {
        parent: "merchantNumber",
        url: "/agreements",
        views: {
          "@": {
            template: require("./agreement/agreement-list.html"),
            controller: "agreementListController",
            controllerAs: "agreementList"
          }
        },
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Merchant Number
    {
      name: "merchantNumberList",
      config: {
        parent: "companyDetails",
        url: "/merchantnumbers",
        views: {
          "@": {
            template: require("./merchantnumber/merchant-number-list.html"),
            controller: "merchantNumberListController",
            controllerAs: "merchantNumberList"
          }
        },
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Payment Type Fee
    {
      name: "paymentTypeFeeList",
      config: {
        parent: "merchantNumber",
        url: "/paymenttypefees",
        views: {
          "@": {
            template: require("./paymenttypefee/payment-type-fee-list.html"),
            controller: "paymentTypeFeeListController",
            controllerAs: "paymentTypeFeeList"
          }
        },
        onExit: [
          "$mdBottomSheet",
          ($mdBottomSheet: ng.material.IBottomSheetService) => {
            $mdBottomSheet.hide();
          }
        ],
        saveVisitedCompaniesState: true
      }
    },
    //#endregion

    //#region Account
    {
      name: "unauthenticated",
      config: {
        url: "/account",
        abstract: true
      }
    },
    {
      name: "login",
      config: {
        parent: "unauthenticated",
        url: "/login",
        views: {
          "@": {
            controller: "accountController",
            controllerAs: "account",
            template: require("./account/login.html")
          }
        }
      }
    },
    {
      name: "otp",
      config: {
        parent: "login",
        url: "/otp",
        views: {
          "@": {
            controller: "accountController",
            controllerAs: "account",
            template: require("./account/otp.html")
          }
        }
      }
    },
    {
      name: "resetOTP",
      config: {
        parent: "unauthenticated",
        url: "/resetotp/{email}/{resetotptoken}",
        views: {
          "@": {
            controller: "accountController",
            controllerAs: "account",
            template: require("./account/reset-otp.html")
          }
        }
      }
    },
    {
      name: "forgotPassword",
      config: {
        parent: "unauthenticated",
        url: "/forgotpassword",
        views: {
          "@": {
            controller: "accountController",
            controllerAs: "account",
            template: require("./account/forgot-password.html")
          }
        }
      }
    },
    {
      name: "resetPassword",
      config: {
        parent: "unauthenticated",
        url: "/resetpassword/{email}/{resetpasswordtoken}",
        views: {
          "@": {
            controller: "accountController",
            controllerAs: "account",
            template: require("./account/reset-password.html")
          }
        }
      }
    },
    {
      name: "logout",
      config: {
        parent: "unauthenticated",
        url: "/logout",
        resolve: {
          resolveLogout: [
            "authenticationService",
            "isAuthenticated",
            (authenticationService: AuthenticationService, isAuthenticated) => {
              return authenticationService.logout();
            }
          ]
        }
      },
      requiresAuthentication: true
    },
    {
      name: "error",
      config: {
        parent: "unauthenticated",
        url: "^/error/{errorCode}",
        views: {
          "@": {
            controller: "errorController",
            template: require("./error/error.html")
          }
        }
      }
    },
    {
      name: "acceptInvitation",
      config: {
        parent: "unauthenticated",
        url: "/invitation/{invitationId}",
        resolve: {
          resolveInvitation: [
            "$stateParams",
            "acceptInvitationService",
            (
              $stateParams,
              acceptInvitationService: AcceptInvitationService
            ) => {
              return acceptInvitationService.load($stateParams.invitationId);
            }
          ],
          title: () => "Accept invitation"
        },
        views: {
          "@": {
            template: require("./user/accept-invitation.html"),
            controller: "acceptInvitationController",
            controllerAs: "acceptInvitation"
          }
        }
      }
    }
    //#endregion
  ];
}

export interface IStateConfiguration {
  name: string;
  config: ng.ui.IState;
  requiresAuthentication?: boolean;
}

export type IStateProvider = uiRouter.IStateProvider & {
  stateAuth(name: string, config: uiRouter.IState): uiRouter.IStateProvider;
};

import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";

export class ResellerListService {
  // tslint:disable-next-line:variable-name
  private _resellers: Array<webserviceModels.Common.reseller>;

  // tslint:disable-next-line:member-ordering
  static $inject = ["resellerService", "$q"];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService
  ) {}

  get resellers(): Array<webserviceModels.Common.reseller> {
    if (this._resellers) return this._resellers;
    throw new ReferenceError("The reseller list has not been initialized.");
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._resellers) {
      deferred.resolve();
    } else {
      const response = this.resellerService.listresellers();

      response.then(
        success => {
          if (success.meta.result) {
            this._resellers = success.resellers;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._resellers) return this.load(true);
    throw new ReferenceError("The reseller list has not been initialized.");
  }
}

export default ResellerListService;

import "./payment.scss";
import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../../shared/decorators";
import * as webserviceModels from "../../shared/interfaces/webservice-models";
import * as checkoutModels from "../../shared/interfaces/checkout-models";
import {
  ImpersonateService,
  SearchHelperService,
  ToastHelperService,
  CheckoutService,
  MerchantService
} from "../../shared/services";

@Component({
  selector: "payment",
  template: require("./payment.html"),
  bindings: {
    payment: "<",
    _getEvents: "&getEvents",
    _getOperations: "&getOperations"
  }
})
export class PaymentComponent {
  payment: webserviceModels.Merchant.transaction;

  // tslint:disable:variable-name
  private _events: Array<checkoutModels.checkouteventitem>;
  private _operations: Array<webserviceModels.Merchant.transactionoperation>;

  private _getEvents: () => ng.IPromise<checkoutModels.checkouteventsresponse>;
  private _getOperations: () => ng.IPromise<
    webserviceModels.Merchant.Response.TransactionOperation.listtransactionoperationsresponse
  >;

  private _isInitializingEvents: boolean;
  private _isInitializingOperations: boolean;

  get events() {
    return this._events;
  }

  get operations() {
    return this._operations;
  }

  get isInitializingEvents() {
    return this._isInitializingEvents;
  }

  get isInitializingOperations() {
    return this._isInitializingOperations;
  }

  $onInit() {
    this._isInitializingEvents = true;
    this._isInitializingOperations = true;

    this._getOperations()
      .then(getOperationsResponse => {
        this._operations = this.getFlattenedOperationTree(
          getOperationsResponse.transactionoperations
        );
      })
      .finally(() => (this._isInitializingOperations = false));
  }

  private getFlattenedOperationTree(
    operations: Array<webserviceModels.Merchant.transactionoperation>
  ): Array<webserviceModels.Merchant.transactionoperation> {
    const flattenedOperations: Array<
      webserviceModels.Merchant.transactionoperation
    > = [];

    flattenOperations(operations);

    return Enumerable.from(flattenedOperations)
      .orderByDescending(operation => operation.id)
      .toArray();

    function flattenOperations(
      ops: Array<webserviceModels.Merchant.transactionoperation>
    ) {
      Enumerable.from(ops).forEach(operation => {
        const flattenedOperation = angular.copy(operation);
        flattenedOperation.isleaf =
          !flattenedOperation.transactionoperations ||
          flattenedOperation.transactionoperations.length < 1;
        delete flattenedOperation.transactionoperations;
        flattenedOperations.push(flattenedOperation);

        flattenOperations(operation.transactionoperations);
      });
    }
  }
}

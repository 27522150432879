import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  PaymentWindowListService,
  ToastHelperService,
  TabHelperService,
  SearchHelperService,
  LoginService,
  ImpersonateService,
  PaymentWindowService
} from "../shared/services";
import { CircularLoaderService } from "../shared/components/circular-loader";

export class PaymentWindowListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "paymentWindowListService",
    "$mdDialog",
    "gettextCatalog",
    "toastHelper",
    "circularLoader",
    "tabHelper",
    "searchHelperService",
    "$mdMedia",
    "$timeout",
    "$rootScope",
    "loginService",
    "$http",
    "impersonateService",
    "$q",
    "paymentWindowService"
  ];

  constructor(
    private $scope: IPaymentWindowListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private paymentWindowListService: PaymentWindowListService,
    private $mdDialog: ng.material.IDialogService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
    private loginService: LoginService,
    private $http: ng.IHttpService,
    private impersonateService: ImpersonateService,
    private $q: ng.IQService,
    private paymentWindowService: PaymentWindowService
  ) {
    tabHelper.currentTab = 7;

    $scope.paymentWindowListService = paymentWindowListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on(
      "paymentWindowListService.itemAdded",
      (
        event,
        paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
      ) => {
        $timeout(() => {
          $(`md-list div[name='${paymentWindow.id}']`)
            .velocity("scroll", {
              container: $("#container"),
              duration: 500,
              offset: -100
            })
            .velocity("callout.pulse");
        });
      }
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.paymentWindowListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The payment window list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The payment window list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./payment-window-create.html"),
      controller: "paymentWindowCreateController",
      controllerAs: "paymentWindowCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    });
  }

  edit(
    $event: MouseEvent,
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ) {
    this.$mdDialog.show({
      template: require("./payment-window-edit.html"),
      controller: "paymentWindowEditController",
      controllerAs: "paymentWindowEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        companyid: this.$stateParams.companyid,
        paymentWindow
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  editLogo(
    $event: MouseEvent,
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ) {
    const element = angular.element(`#changeLogoInput_${paymentWindow.id}`);

    element.on("change", event => {
      element.off("change");

      this.uploadLogo(element[0] as HTMLInputElement, paymentWindow);
    });

    element.click();
  }

  uploadLogo(
    input: HTMLInputElement,
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ) {
    if (!input || !input.files[0]) return;

    const file = input.files[0];
    const reader = new FileReader();

    reader.onload = event => {
      this.paymentWindowService
        .addpaymentwindowlogo(paymentWindow.id, {
          base64image: (event.target as any).result.split(",")[1]
        })
        .then(
          success => {
            this.paymentWindowListService.refresh().finally(() => {
              this.toastHelper.toast(
                this.gettextCatalog.getString(
                  "Payment window with ID {{id}} was successfully updated.",
                  { id: paymentWindow.id }
                ),
                this.gettextCatalog.getString("Dismiss")
              );
            });
          },
          error => {
            this.toastHelper.toast(
              error ||
                this.gettextCatalog.getString(
                  "Payment window with ID {{id}} could not be updated at this time.",
                  { id: paymentWindow.id }
                ),
              this.gettextCatalog.getString("Dismiss")
            );
          }
        )
        .finally(() => {
          input.value = "";
        });
    };

    reader.readAsDataURL(file);
  }

  deleteLogo(
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ) {
    this.paymentWindowService.deletepaymentwindowlogo(paymentWindow.id).then(
      success => {
        this.paymentWindowListService.refresh().finally(() => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "Payment window with ID {{id}} was successfully updated.",
              { id: paymentWindow.id }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        });
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Payment window with ID {{id}} could not be updated at this time.",
              { id: paymentWindow.id }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  delete(
    $event: MouseEvent,
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm Payment Window Deletion"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to delete the payment window with ID <i>{{id}}</i>?",
          { id: paymentWindow.id }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Delete"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      this.paymentWindowListService.delete(paymentWindow).then(
        success => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "Payment window with ID {{id}} was successfully deleted.",
              { id: paymentWindow.id }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "Payment window with ID {{id}} could not be deleted at this time.",
                { id: paymentWindow.id }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
    });
  }

  togglePaymentWindowStatus(
    paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow
  ) {
    const request: webserviceModels.PaymentWindow.Request.updatepaymentwindow = {
      paymentwindow: angular.extend({}, paymentWindow, {
        active: !paymentWindow.active
      })
    };

    this.paymentWindowListService.update(paymentWindow.id, request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "Payment window with ID {{id}} was successfully updated.",
            { id: paymentWindow.id }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Payment window with ID {{id}} could not be updated at this time.",
              { id: paymentWindow.id }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  get defaultPaymentWindow(): webserviceModels.PaymentWindow.Response.paymentwindow {
    return Enumerable.from(this.paymentWindowListService.paymentWindows)
      .orderBy(paymentWindow => paymentWindow.id)
      .firstOrDefault(paymentWindow => paymentWindow.active, null);
  }
}

export interface IPaymentWindowListScope extends ng.IScope {
  paymentWindowListService: PaymentWindowListService;
  searchHelperService: SearchHelperService;
}

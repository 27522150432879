import * as webserviceModels from "../../interfaces/webservice-models";
import { DataService } from "../clients";

export class AcquirerListService {
  // tslint:disable-next-line:variable-name
  private _acquirers: Array<
    webserviceModels.Data.Response.ListAcquirer.acquirer
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = ["dataService", "$q"];
  constructor(private dataService: DataService, private $q: ng.IQService) {}

  get acquirers(): Array<webserviceModels.Data.Response.ListAcquirer.acquirer> {
    if (this._acquirers) return this._acquirers;
    throw new ReferenceError("The acquirer list has not been initialized.");
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._acquirers) {
      deferred.resolve();
    } else {
      const response = this.dataService.listacquirers();

      response.then(
        success => {
          if (success.meta.result) {
            this._acquirers = success.acquirers;
            deferred.resolve();
          } else {
            deferred.reject(success.meta);
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._acquirers) return this.load(true);
    throw new ReferenceError("The acquirer list has not been initialized.");
  }

  getAcquirerDisplayNameFromAcquirerName(acquirerName: string) {
    for (var i = 0; i < this.acquirers.length; i++) {
      if (this.acquirers[i].name.toLowerCase() === acquirerName.toLowerCase()) {
        return this.acquirers[i].displayname;
      }
    }
    return acquirerName;
  }

  getAgreementAcquirers() {
    return this.acquirers.filter(
      acquirer => acquirer.status.toLowerCase() !== "disabled"
    );
  }
}

export default AcquirerListService;

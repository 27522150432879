import * as webserviceModels from "../shared/interfaces/webservice-models";
import { ApiUserListService, ToastHelperService } from "../shared/services";

export class ApiUserCreateController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "apiUserListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IApiUserCreateScope,
    private $mdDialog: ng.material.IDialogService,
    private apiUserListService: ApiUserListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.apiUserListService = apiUserListService;

    $scope.request = {
      apiuser: {
        email: "",
        description: ""
      }
    };

    $scope.created = null;
  }

  submit(request: webserviceModels.Merchant.Request.ApiUser.addapiuser) {
    this.apiUserListService.create(request).then(
      success => {
        this.$scope.created = {
          accesstoken: success.accesstoken,
          secrettoken: success.secrettoken,
          email: success.email,
          merchantnumbers: success.merchantnumbers,
          apikeys: {}
        };

        success.merchantnumbers.map(merchantnumber => {
          const apiKey = btoa(
            `${success.accesstoken}@${merchantnumber.number}:${success.secrettoken}`
          );
          this.$scope.created.apikeys[merchantnumber.number] = apiKey;
        });

        this.toastHelper.toast(
          this.gettextCatalog.getString("{{email}} was successfully created.", {
            email: request.apiuser.email
          }),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{email}} could not be created at this time.",
              { email: request.apiuser.email }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  hide() {
    this.$mdDialog.hide();
  }
}

export interface IApiUserCreateScope extends ng.IScope {
  request: webserviceModels.Merchant.Request.ApiUser.addapiuser;
  apiUserListService: ApiUserListService;
  created: any;
}

import tinycolor from "tinycolor2";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  ToastHelperService,
  WebUserListService,
  TabHelperService,
  ResellerService,
  SearchHelperService
} from "../shared/services";
import * as endpoints from "../app.endpoints";
import { CircularLoaderService } from "../shared/components/circular-loader";

export interface IWebUserListScope extends ng.IScope {
  webUserListService: WebUserListService;
  searchHelperService: SearchHelperService;
}

export class WebUserListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "gettextCatalog",
    "toastHelper",
    "circularLoader",
    "webUserListService",
    "tabHelper",
    "resellerService",
    "$window",
    "searchHelperService",
    "$rootScope"
  ];

  constructor(
    private $scope: IWebUserListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private circularLoader: CircularLoaderService,
    private webUserListService: WebUserListService,
    private tabHelper: TabHelperService,
    private resellerService: ResellerService,
    private $window: ng.IWindowService,
    private searchHelperService: SearchHelperService,
    private $rootScope: ng.IRootScopeService
  ) {
    tabHelper.currentTab = 3;

    $scope.webUserListService = webUserListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.webUserListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The web user list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The web user list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  impersonateWebUser(
    $event: MouseEvent,
    webUser: webserviceModels.Common.Response.WebUser.webuser
  ) {
    this.resellerService
      .impersonatewebuser(
        this.$stateParams.companyid,
        this.$stateParams.merchantnumber,
        webUser.accesstoken
      )
      .then(
        success => {
          if (success.meta.result) {
            const apiKey = btoa(
              `${success.accesstoken}@${this.$stateParams
                .merchantnumber}:${success.secrettoken}`
            );

            const url = endpoints.impersonate + "#" + apiKey;

            window.open(url, "_blank");
          } else {
            this.toastHelper.toast(
              this.gettextCatalog.getString(
                "The web user could not be impersonated at this time."
              ),
              this.gettextCatalog.getString("Dismiss")
            );
          }
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "The web user could not be impersonated at this time."
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  getWebUserColor(nameOrEmail: string): string {
    let hash = 0;

    for (let i = 0; i < nameOrEmail.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = nameOrEmail.charCodeAt(i) + ((hash << 5) - hash);
    }

    // tslint:disable-next-line:no-bitwise
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();

    const hex = "00000".substring(0, 6 - c.length) + c;

    const hsl = tinycolor(hex).toHsl();
    hsl.h = parseFloat((Math.round(hsl.h * 10) / 10).toFixed(1)); // Decrease number of variants.
    hsl.s = 0.5; // Normalizing saturation.
    hsl.l = 0.5; // Normalizing lightness.

    return tinycolor(hsl).toRgbString();
  }

  getAvatarLetter(name: string) {
    return name.charAt(0);
  }
}

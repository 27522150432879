export class StringToNumber implements ng.IDirective {
  restrict = "A";
  require = "ngModel";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    ngModel: ng.INgModelController
  ) => {
    ngModel.$parsers.push(value => "" + value);
    ngModel.$formatters.push(value => parseFloat(value));
  };
}

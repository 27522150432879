import $ from "jquery";
import tinycolor from "tinycolor2";
import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  PermissionService,
  CompanyListService,
  MerchantNumberListService,
  ResellerService,
  SearchHelperService,
  AuthenticationService,
  ImpersonateService,
  ToastHelperService
} from "../shared/services";
import * as endpoints from "../app.endpoints";
import { ScrollLoaderService } from "../shared/directives/scroll-loader.directive";
import { CircularLoaderService } from "../shared/components/circular-loader/circular-loader.component";
import "./company-delete-dialog.scss";

export class CompanyListController {
  static $inject = [
    "$scope",
    "circularLoader",
    "permissionService",
    "$state",
    "$mdDialog",
    "gettextCatalog",
    "companyListService",
    "merchantNumberListService",
    "toastHelper",
    "resellerService",
    "searchHelperService",
    "$mdMedia",
    "authenticationService",
    "$timeout",
    "scrollLoaderService",
    "$rootScope",
    "impersonateService"
  ];

  constructor(
    private $scope: ICompanyListScope,
    private circularLoader: CircularLoaderService,
    private permissionService: PermissionService,
    private $state: ng.ui.IStateService,
    private $mdDialog: ng.material.IDialogService,
    private gettextCatalog: any,
    private companyListService: CompanyListService,
    private merchantNumberListService: MerchantNumberListService,
    private toastHelper: ToastHelperService,
    private resellerService: ResellerService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private authenticationService: AuthenticationService,
    private $timeout: ng.ITimeoutService,
    private scrollLoaderService: ScrollLoaderService,
    private $rootScope: ng.IRootScopeService,
    private impersonateService: ImpersonateService
  ) {
    $scope.companyListService = companyListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    let timeout = null;
    $scope.$watch("searchHelperService.searchQuery", newValue => {
      $timeout.cancel(timeout);
      timeout = $timeout(() => {
        companyListService.load();
      }, 300);
    });

    this.initScrollHandler();

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }
  validDeleteDate(date: string): boolean {
    return new Date(date).getUTCFullYear() <= 3000;
  }

  initScrollHandler() {
    const container = $("#container");
    let oldValue: boolean;
    const self = this;

    container.on("scroll", onScroll);

    this.$scope.$on("$destroy", () => {
      container.off("scroll", onScroll);
    });

    function onScroll(event: JQueryEventObject) {
      const newValue =
        container[0].scrollHeight - container.height() ===
        container.scrollTop();

      if (newValue && !oldValue && !self.scrollLoaderService.active) {
        self.scrollLoaderService.active = true;

        self.companyListService.load().finally(() => {
          self.scrollLoaderService.active = false;
        });
      }

      oldValue = newValue;
    }
  }

  onRefreshClicked() {
    const refreshPromise = this.companyListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The company list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The company list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  showCompanyDetails(
    $event: JQueryEventObject,
    company: webserviceModels.Common.Response.Company.company
  ) {
    this.merchantNumberListService.load(company.id).then(
      success => {
        this.$state
          .go("agreementList", {
            companyid: company.id,
            merchantnumber: this.merchantNumberListService
              .selectedMerchantNumber.number
          })
          .then(
            // tslint:disable-next-line:no-empty
            () => {},
            error => {
              this.toastHelper.toast(
                error ||
                  this.gettextCatalog.getString(
                    "The company details could not be retrived at this time."
                  ),
                this.gettextCatalog.getString("Dismiss")
              );
            }
          );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The company details could not be retrived at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./company-create.html"),
      controller: "companyCreateController",
      controllerAs: "companyCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  edit(
    $event: MouseEvent,
    company: webserviceModels.Common.Response.Company.company
  ) {
    this.$mdDialog.show({
      template: require("./company-edit.html"),
      controller: "companyEditController",
      controllerAs: "companyEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        company
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  delete(
    $event: MouseEvent,
    companyItem: webserviceModels.Common.Response.Company.company,
    $index: number
  ) {
    this.$mdDialog.show({
      controller: "companyDeleteController",
      controllerAs: "companyDelete",
      template: require("./company-delete-dialog.html"),
      parent: angular.element(document.body),
      companyname: companyItem.name,
      companyid: companyItem.id,
      targetEvent: $event,
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        company: companyItem,
        isListItem: true
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  canceldelete(
    $event: MouseEvent,
    companyItem: webserviceModels.Common.Response.Company.company,
    $index: number
  ) {
    this.$mdDialog.show({
      controller: "companyCancelDeleteController",
      controllerAs: "companyCancelDelete",
      template: require("./company-canceldelete-dialog.html"),
      parent: angular.element(document.body),
      companyname: companyItem.name,
      companyid: companyItem.id,
      targetEvent: $event,
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        company: companyItem,
        isListItem: true
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  impersonateMasterWebUser(
    $event: MouseEvent,
    company: webserviceModels.Common.Response.Company.company
  ) {
    this.merchantNumberListService.load(company.id).then(
      success => {
        this.impersonateService
          .impersonateMasterWebUser(
            company.id,
            this.merchantNumberListService.defaultMerchantNumber.number
          )
          .then(
            successX => {
              if (successX.meta.result) {
                const apiKey = btoa(
                  successX.accesstoken +
                    "@" +
                    this.merchantNumberListService.selectedMerchantNumber
                      .number +
                    ":" +
                    successX.secrettoken
                );
                const actionString = endpoints.impersonate + "#" + apiKey;
                const html = `
                  <form action="${actionString}" target="_blank" method="post" style="display: none">
                  </form>
                `;
                const form = $(html);
                $("body").append(form);
                form.submit();
                form.remove();
              } else {
                this.toastHelper.toast(
                  this.gettextCatalog.getString(
                    "The master web user could not be impersonated at this time."
                  ),
                  this.gettextCatalog.getString("Dismiss")
                );
              }
            },
            error => {
              this.toastHelper.toast(
                error ||
                  this.gettextCatalog.getString(
                    "The master web user could not be impersonated at this time."
                  ),
                this.gettextCatalog.getString("Dismiss")
              );
            }
          );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The master web user could not be impersonated at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  getResellerColor(companyName: string): string {
    let hash = 0;

    for (let i = 0; i < companyName.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = companyName.charCodeAt(i) + ((hash << 5) - hash);
    }

    // tslint:disable-next-line:no-bitwise
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();

    const hex = "00000".substring(0, 6 - c.length) + c;

    const hsl = tinycolor(hex).toHsl();
    hsl.h = hsl.h * 0.77777 + 40; // Removing red from the color spectrum.
    hsl.s = 0.5; // Normalizing saturation.
    hsl.l = 0.5; // Normalizing lightness.

    return tinycolor(hsl).toRgbString();
  }

  resellerIsSelf(resellerNumber: string): boolean {
    return (
      this.authenticationService.userSession.selectedResellerNumber ===
      resellerNumber
    );
  }

  showReseller(
    company: webserviceModels.Common.Response.Company.company
  ): boolean {
    if (!company || !company.resellers) return false;
    return !this.resellerIsSelf(company.resellers[0].number);
  }
}

export interface ICompanyListScope extends ng.IScope {
  companyListService: CompanyListService;
  searchHelperService: SearchHelperService;
}

import "./condition-filter.scss";
import * as Enumerable from "linq";
import * as angular from "angular";
import { Component } from "../../decorators";
import * as webserviceModels from "../../interfaces/webservice-models";
import { RequirementsFilterListService } from "../../services";

@Component({
  selector: "conditionFilter",
  template: require("./condition-filter.html"),
  bindings: {
    filterable: "<",
    headline: "<",
    onSave: "&",
    currencyCode: "<"
  }
})
export class ConditionFilterComponent {
  filterable: IFilterable;
  filters: IFilterable["filters"];
  headline: string;
  searchTerm: Array<string> = [];
  onSave: (data: { filters: IFilterable["filters"] }) => Promise<any>;
  newFilter: string;
  currencyCode: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "gettextCatalog",
    "$mdDialog",
    "requirementsFilterListService",
    "$timeout"
  ];

  constructor(
    private gettextCatalog: ng.gettext.gettextCatalog,
    private $mdDialog: ng.material.IDialogService,
    private requirementsFilterListService: RequirementsFilterListService,
    private $timeout: ng.ITimeoutService
  ) {}

  get requirementsFilters() {
    return this.requirementsFilterListService.requirementsFilters;
  }

  $onChanges(changes) {
    const { filterable } = changes;

    if (filterable && filterable.isFirstChange()) {
      this.filters = angular.copy(filterable.currentValue.filters) || [];
    }
  }

  getFilterByCondition(condition: string) {
    return Enumerable.from(this.requirementsFilters).first(
      filter => filter.condition === condition
    );
  }

  clearSearchTerm($index) {
    this.searchTerm[$index] = "";
  }

  addFilter(filterCondition: string) {
    this.filters.push({
      condition: filterCondition,
      operator: null,
      values: []
    });

    this.$timeout(() => {
      this.newFilter = null;
    });
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
  }

  close() {
    this.$mdDialog.hide();
  }

  save() {
    this.onSave({ filters: this.filters }).then(() => this.$mdDialog.hide());
  }

  getDisplayName(conditionOrOperator: string) {
    switch (conditionOrOperator) {
      case "amount":
        return "Amount";
      case "issuercountry":
        return "Country of issuer";
      case "issuercountrygroup":
        return "Country group of issuer";
      case "locationcountry":
        return "Country of use";
      case "locationcountrygroup":
        return "Country group of use";
      case "walletname":
        return "Wallet name";
      case "flags":
        return "Flags";
      case "cardtype":
        return "Card type";
      case "cardorigin":
        return "Card origin";
      case "cardcommercialmarker":
        return "Card commercial marker";
      case "cardprefix":
        return "Card prefix";
      case "equal":
        return "equals";
      case "notequal":
        return "does NOT equal";
      case "in":
        return "includes";
      case "notin":
        return "excludes";
      case "notcontains":
        return "does NOT contain";
      case "startswith":
        return "starts with";
      case "notstartswith":
        return "does NOT start with";
      case "endswith":
        return "ends with";
      case "notendswith":
        return "does NOT end with";
      case "regexmatch":
        return "matches the regex";
      case "notregexmatch":
        return "does NOT match the regex";
      case "greaterthan":
        return "is greater than";
      case "greaterthanorequal":
        return "is greater than or equal to";
      case "lessthan":
        return "is less than";
      case "lessthanorequal":
        return "is less than or equal to";
    }

    return conditionOrOperator;
  }
}

export interface IFilterable {
  filters: Array<{
    condition: string;
    operator: string;
    values: Array<string>;
  }>;
}

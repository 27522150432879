import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  PaymentWindowListService,
  ToastHelperService
} from "../shared/services";

export class PaymentWindowEditController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "paymentWindowListService",
    "paymentWindow",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IPaymentWindowEditScope,
    private $mdDialog: ng.material.IDialogService,
    private paymentWindowListService: PaymentWindowListService,
    private paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow,
    private gettextCatalog: ng.gettext.gettextCatalog,
    private toastHelper: ToastHelperService
  ) {
    $scope.paymentWindowListService = paymentWindowListService;
    $scope.paymentWindow = paymentWindow;

    $scope.request = {
      paymentwindow: angular.copy(paymentWindow)
    };
  }

  submit(request: webserviceModels.PaymentWindow.Request.updatepaymentwindow) {
    this.paymentWindowListService.update(this.paymentWindow.id, request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "Payment window with ID {{id}} was successfully updated.",
            { id: this.paymentWindow.id }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Payment window with ID {{id}} could not be updated at this time.",
              { id: this.paymentWindow.id }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

export interface IPaymentWindowEditScope extends ng.IScope {
  request: webserviceModels.PaymentWindow.Request.updatepaymentwindow;
  paymentWindowListService: PaymentWindowListService;
  paymentWindow: webserviceModels.PaymentWindow.Response.paymentwindow;
}

import * as angular from "angular";
import { Component } from "../../../shared/decorators";
import * as checkoutModels from "../../../shared/interfaces/checkout-models";

@Component({
  selector: "paymentEvents",
  template: require("./payment-events.html"),
  bindings: {
    _events: "<events",
    isInitializing: "<"
  }
})
export class PaymentEventsComponent {
  isInitializing: boolean;

  // tslint:disable-next-line:variable-name
  private _events: Array<checkoutModels.checkouteventitem>;

  // tslint:disable-next-line:member-ordering
  static $inject = ["$mdDialog", "$mdMedia"];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia
  ) {}

  get events() {
    return this._events;
  }

  getMessage(event: checkoutModels.checkouteventitem) {
    const eventReply = this.getEventReply(event);
    if (!eventReply) return "";

    return eventReply.meta.message;
  }

  getAction(event: checkoutModels.checkouteventitem) {
    const eventReply = this.getEventReply(event);
    if (!eventReply) return "";

    return eventReply.meta.action;
  }

  showEventDetails(
    event: checkoutModels.checkouteventitem,
    $event: MouseEvent
  ) {
    this.$mdDialog.show({
      template: `
                <md-dialog class="modalDialog" flex="40" flex-gt-lg="35">
                    <event-details event="$ctrl.event"></event-details>
                </md-dialog>
            `,
      controller() {
        this.event = event;
      },
      controllerAs: "$ctrl",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    });
  }

  private getEventReply(event: checkoutModels.checkouteventitem) {
    try {
      const eventReply = JSON.parse(event.reply)[0];
      return eventReply;
    } catch (e) {
      return null;
    }
  }
}

import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";
import { CompanyListService } from ".";

export class CompanyService {
  // tslint:disable-next-line:variable-name
  private _company: webserviceModels.Common.Response.Company.company;

  // tslint:disable-next-line:member-ordering
  static $inject = ["resellerService", "companyListService", "$q"];

  constructor(
    private resellerService: ResellerService,
    private companyListService: CompanyListService,
    private $q: ng.IQService
  ) {}

  get company(): webserviceModels.Common.Response.Company.company {
    if (this._company) return this._company;
    throw new ReferenceError("The company has not been initialized.");
  }

  load(companyId: string, forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const companyIsInServiceList = this.isInListService(companyId);

    if (!forceReload && this._company && this._company.id === companyId) {
      if (companyIsInServiceList) {
        this._company = Enumerable.from(
          this.companyListService.companies
        ).first(company => (company as any).id === companyId) as any;
      }
      deferred.resolve();
    } else {
      const response = this.resellerService.getcompany(companyId);

      response.then(
        success => {
          if (success.meta.result) {
            if (companyIsInServiceList) {
              this._company = angular.extend(
                Enumerable.from(this.companyListService.companies).first(
                  company => (company as any).id === companyId
                ),
                success.company
              );
            } else {
              this._company = success.company;
            }
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._company) return this.load(this.company.id, true);
    throw new ReferenceError("The company has not been initialized.");
  }

  isInListService(companyId): boolean {
    try {
      return Enumerable.from(this.companyListService.companies).any(
        company => (company as any).id === companyId
      );
    } catch (e) {
      return false;
    }
  }

  update(
    request: webserviceModels.Common.Request.Company.updatecompany
  ): ng.IPromise<any> {
    if (!this._company) {
      throw new ReferenceError("The company has not been initialized.");
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.updatecompany(
      this.company.id,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.extend(this.company, request.company);
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(isFutureDate: boolean, date?: string): ng.IPromise<any> {
    if (!this._company) {
      throw new ReferenceError("The company has not been initialized.");
    }

    const deferred = this.$q.defer();

    if (isFutureDate) {
      const response = this.resellerService.deletecompany(
        this.company.id,
        date
      );

      response.then(
        success => {
          this.company.deleteddate = date;
          if (success.meta.result) {
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    } else if (this.isInListService(this._company.id)) {
      this.companyListService.delete(this._company, isFutureDate).then(
        success => {
          this._company = null;
          deferred.resolve();
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    } else {
      const response = this.resellerService.deletecompany(this.company.id);
      response.then(
        success => {
          if (success.meta.result) {
            this._company = null;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  canceldelete(): ng.IPromise<any> {
    if (!this._company) {
      throw new ReferenceError("The company has not been initialized.");
    }

    const deferred = this.$q.defer();
    if (this.isInListService(this._company.id)) {
      this.companyListService.canceldelete(this._company).then(
        success => {
          this._company.deleteddate = "9999-12-31";
          deferred.resolve();
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    } else {
      this.resellerService.canceldeletecompany(this.company.id).then(
        success => {
          if (success.meta.result) {
            this._company.deleteddate = "9999-12-31";
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }
    return deferred.promise;
  }
}

export default CompanyService;

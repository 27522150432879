import * as Enumerable from "linq";
import { AuthenticationService } from ".";
import WebserviceType from "../../enums/webservice-types";
import getPermission from "../../helpers/permission-helper";

export class PermissionService {
  static $inject = ["authenticationService"];

  constructor(private authenticationService: AuthenticationService) {}

  hasPermission(webservice: WebserviceType, permission: string): boolean {
    const fn: string = getPermission(webservice, permission);
    return Enumerable.from(
      this.authenticationService.userSession.permissions
    ).any(x => x.name === fn);
  }
}

export default PermissionService;

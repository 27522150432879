import "velocity-animate";
import {
  ToastHelperService,
  AcceptInvitationService,
  UserListService
} from "../shared/services";

export class AcceptInvitationController {
  static $inject = [
    "$scope",
    "$state",
    "$stateParams",
    "acceptInvitationService",
    "userListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IAcceptInvitationScope,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private acceptInvitationService: AcceptInvitationService,
    private userListService: UserListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.acceptInvitationService = acceptInvitationService;
    ($scope as any).request = {
      password: null,
      email: acceptInvitationService.invitation.recipientemail,
      name: acceptInvitationService.invitation.name
    };
  }

  accept() {
    if (!this.$stateParams.invitationId) {
      this.toastHelper.toast(
        this.gettextCatalog.getString(
          "Invitation not found. Try to reload the page."
        ),
        this.gettextCatalog.getString("Dismiss")
      );
    }

    this.acceptInvitationService.accept(this.$stateParams.invitationId).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString("Invitation accepted"),
          this.gettextCatalog.getString("Dismiss")
        );

        this.$state.go("login");
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Something went wrong. Try to reload the page."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  createUser() {
    if (!this.$stateParams.invitationId) {
      this.toastHelper.toast(
        this.gettextCatalog.getString(
          "Invitation not found. Try to reload the page."
        ),
        this.gettextCatalog.getString("Dismiss")
      );
    }

    const addUserRequest = {
      webuser: {
        invitationid: this.$stateParams.invitationId,
        password: (this.$scope as any).request.password,
        name: (this.$scope as any).request.name,
        email: (this.$scope as any).request.email
      }
    };

    this.userListService.createAndAccept(addUserRequest).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString("Account created successfully"),
          this.gettextCatalog.getString("Dismiss")
        );

        this.$state.go("login");
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "Something went wrong. Try to reload the page."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }
}

export interface IAcceptInvitationScope extends ng.IScope {
  acceptInvitationService: AcceptInvitationService;
}

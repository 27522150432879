export class RightMenuController {
  static $inject = ["$scope", "$mdSidenav"];

  constructor(
    private $scope: any,
    private $mdSidenav: ng.material.ISidenavService
  ) {
    $scope.close = () => {
      this.$mdSidenav("sidenav-right").close();
    };

    $scope.open = () => {
      this.$mdSidenav("sidenav-right").open();
    };

    $scope.toggle = () => {
      this.$mdSidenav("sidenav-right").toggle();
    };
  }
}

import $ from "jquery";
import * as angular from "angular";
import moment from "moment";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  ApiUserListService,
  ToastHelperService,
  ImpersonateService,
  TabHelperService,
  SearchHelperService,
  LoginService
} from "../shared/services";
import * as endpoints from "../app.endpoints";
import { CircularLoaderService } from "../shared/components/circular-loader/circular-loader.component";

export class ApiUserListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "apiUserListService",
    "$mdDialog",
    "gettextCatalog",
    "toastHelper",
    "circularLoader",
    "tabHelper",
    "searchHelperService",
    "$mdMedia",
    "$timeout",
    "$rootScope",
    "loginService",
    "$http",
    "impersonateService",
    "$q"
  ];

  constructor(
    private $scope: IApiUserListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private apiUserListService: ApiUserListService,
    private $mdDialog: ng.material.IDialogService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService,
    private loginService: LoginService,
    private $http: ng.IHttpService,
    private impersonateService: ImpersonateService,
    private $q: ng.IQService
  ) {
    tabHelper.currentTab = 4;

    $scope.apiUserListService = apiUserListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on(
      "apiUserListService.itemAdded",
      (event, apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser) => {
        $timeout(() => {
          $(`md-list div[name='${apiUser.accesstoken}']`)
            .velocity("scroll", {
              container: $("#container"),
              duration: 500,
              offset: -100
            })
            .velocity("callout.pulse");
        });
      }
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.apiUserListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The api user list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The api user list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./api-user-create.html"),
      controller: "apiUserCreateController",
      controllerAs: "apiUserCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    });
  }

  edit(
    $event: MouseEvent,
    apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser
  ) {
    this.$mdDialog.show({
      template: require("./api-user-edit.html"),
      controller: "apiUserEditController",
      controllerAs: "apiUserEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        companyid: this.$stateParams.companyid,
        apiUser
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  editPermissions(
    $event: MouseEvent,
    apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser
  ) {
    const { companyid, merchantnumber } = this.$stateParams;

    this.impersonateService
      .impersonateMasterWebUser(companyid, merchantnumber)
      .then(response => {
        const { accesstoken, secrettoken } = response;

        const authToken = btoa(
          `${accesstoken}@${merchantnumber}:${secrettoken}`
        );
        const options = { headers: { Authorization: `BASIC ${authToken}` } };

        const listPermissionsPromise = this.loginService
          .listfunctionpermissions()
          .then(listPermissionsResponse => listPermissionsResponse.apis);

        const listPermissionsByAccessTokenPromise = this.$http
          .get(
            `${endpoints.login}/accesstokens/${apiUser.accesstoken}/functionpermissions`,
            options
          )
          .then(
            listPermissionsResponse =>
              listPermissionsResponse.data as webserviceModels.Login.Response.FunctionPermission.listfunctionpermissionsbyaccesstokenresponse
          )
          .then(
            listPermissionsResponse =>
              listPermissionsResponse.functionpermissions
          );

        this.$q
          .all([listPermissionsPromise, listPermissionsByAccessTokenPromise])
          .then(([apis, permissions]) => {
            this.$mdDialog.show({
              template: require("./api-user-permission.html"),
              controller: "apiUserPermissionController",
              controllerAs: "apiUserPermission",
              targetEvent: $event,
              parent: angular.element(document.body),
              escapeToClose: false,
              clickOutsideToClose: false,
              locals: {
                companyid,
                apiUser,
                apis,
                permissions
              },
              fullscreen: !this.$mdMedia("gt-md")
            });
          });
      });
  }

  delete(
    $event: MouseEvent,
    apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm API User Deletion"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to delete the API user <i>{{email}}</i>?",
          { email: apiUser.email }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Delete"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      this.apiUserListService.delete(apiUser).then(
        success => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{email}} was successfully deleted.",
              { email: apiUser.email }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{email}} could not be deleted at this time.",
                { email: apiUser.email }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
    });
  }

  toggleApiUserStatus(
    apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser
  ) {
    const request: webserviceModels.Merchant.Request.ApiUser.updateapiuser = {
      apiuser: angular.extend({}, apiUser, {
        status: apiUser.status === "active" ? "inactive" : "active"
      })
    };

    this.apiUserListService.update(apiUser.accesstoken, request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString("{{email}} was successfully updated.", {
            email: apiUser.email
          }),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{email}} could not be updated at this time.",
              { email: apiUser.email }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  hasExpired(date: string) {
    return new Date(date) < new Date(Date.now());
  }
}

export interface IApiUserListScope extends ng.IScope {
  apiUserListService: ApiUserListService;
  searchHelperService: SearchHelperService;
}

export function AnimateListItem(): any {
  const animateIn = (element: JQuery, doneFn) => {
    element
      .velocity("transition.expandIn", {
        duration: 300,
        display: null
      })
      .velocity("slideDown", {
        complete: doneFn,
        duration: 300,
        display: null,
        queue: false
      });
  };

  const animateOut = (element: JQuery, doneFn) => {
    element
      .velocity("transition.expandOut", {
        duration: 300
      })
      .velocity("slideUp", {
        complete: doneFn,
        duration: 300,
        queue: false
      });
  };

  return {
    enter: animateIn,
    leave: animateOut,
    addClass: (element: JQuery, className: string, doneFn) => {
      animateOut(element, doneFn);
    },
    removeClass: (element: JQuery, className: string, doneFn) => {
      animateIn(element, doneFn);
    }
  };
}

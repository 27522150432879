import * as punycode from "punycode";

export function PunycodeFilter() {
  return (input: string, action: string) => {
    input = input || "";
    if (action === "toUnicode") {
      return punycode.toUnicode(input);
    }
    return punycode.toASCII(input);
  };
}

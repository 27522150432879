import { config } from "../../app.config";
import WebserviceType from "../enums/webservice-types";

export function getPermissionFor(
  webservice: WebserviceType,
  fn: string,
  version: string = config.webserviceVersion
) {
  return `function#${webservice}#${version}#${fn}`;
}

export default getPermissionFor;

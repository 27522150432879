import "./payments-filter.scss";
import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../../shared/decorators";
import {
  SearchHelperService,
  CurrencyListService,
  PaymentCollectionListService
} from "../../shared/services";
import { PaymentsFilterService } from "../shared/payments-filter.service";

@Component({
  selector: "paymentsFilter",
  template: require("./payments-filter.html")
})
export class PaymentsFilterComponent {
  searchTerm: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "searchHelperService",
    "$mdBottomSheet",
    "currencyListService",
    "paymentCollectionListService",
    "paymentsFilterService",
    "$mdMedia"
  ];

  constructor(
    private searchHelperService: SearchHelperService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private currencyListService: CurrencyListService,
    private paymentCollectionListService: PaymentCollectionListService,
    private paymentsFilterService: PaymentsFilterService,
    private $mdMedia: ng.material.IMedia
  ) {}

  get currencies() {
    return this.currencyListService.currencies;
  }

  get paymentTypes() {
    return Enumerable.from(this.paymentCollectionListService.paymentCollections)
      .selectMany(paymentCollection => paymentCollection.paymentgroups)
      .orderBy(paymentGroup => paymentGroup.displayname)
      .toArray();
  }

  get filter() {
    return this.paymentsFilterService.filter;
  }

  $onInit() {
    if (
      this.isGtSm() &&
      !!(angular.element("#overlay-container")[0] as any).$mdGesture
    ) {
      // tslint:disable-next-line:no-empty
      (angular.element("#overlay-container")[0] as any).$mdGesture = () => {};
    }
  }

  close() {
    this.$mdBottomSheet.hide();
  }

  clearSearchTerm() {
    this.searchTerm = "";
  }

  clearAll() {
    this.paymentsFilterService.clearAll();
  }

  isGtSm(): boolean {
    return this.$mdMedia("gt-sm");
  }

  apply() {
    const filters: any = {};
    let filterString = "";

    if (this.filter.currency) {
      filters.currencycode = `eq ${this.filter.currency.code}`;
    }

    if (this.filter.fromAmount) {
      if (this.filter.toAmount) {
        filters.amount = `btw ${this.filter.fromAmount},${this.filter
          .toAmount}`;
      } else {
        filters.amount = `gte ${this.filter.fromAmount}`;
      }
    } else if (this.filter.toAmount) {
      filters.amount = `lte ${this.filter.toAmount}`;
    }

    if (this.filter.paymentTypes && this.filter.paymentTypes.length) {
      filters.paymentgroup = "in ";

      for (const paymentTypeId of this.filter.paymentTypes) {
        filters.paymentgroup += `${paymentTypeId},`;
      }

      filters.paymentgroup = filters.paymentgroup.slice(0, -1);
    }

    if (this.filter.date) {
      const fromDate = angular.copy(this.filter.date);
      const toDate = angular.copy(this.filter.date);

      if (this.filter.time) {
        const hours = this.filter.time.getHours();
        const minutes = this.filter.time.getMinutes();

        fromDate.setHours(hours);
        fromDate.setMinutes(minutes);
        fromDate.setSeconds(0, 0);

        toDate.setHours(hours);
        toDate.setMinutes(minutes);
        toDate.setSeconds(59);
      } else {
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0, 0);

        toDate.setHours(23);
        toDate.setMinutes(59);
        toDate.setSeconds(59);
      }

      filters.date = `btw ${fromDate.toISOString()},${toDate.toISOString()}`;
    }

    Enumerable.from(filters).forEach(filter => {
      filterString += `|${filter.key}::${filter.value}`;
    });

    this.searchHelperService.filterString = filterString;
  }
}

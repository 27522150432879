export class TabHelperService {
  currentTab: number = 0;
  direction: string = "left";

  // tslint:disable-next-line:member-ordering
  static $inject = ["$rootScope"];

  constructor(private $rootScope: ng.IRootScopeService) {
    $rootScope.$watch(
      () => this.currentTab,
      (newValue, oldValue) => {
        this.direction = newValue > oldValue ? "left" : "right";
      }
    );
  }
}

import moment from "moment";

export const config = {
  webserviceVersion: "v1"
};

export class AppConfig {
  static $inject = [
    "$stateProvider",
    "$urlRouterProvider",
    "$locationProvider",
    "$httpProvider",
    "$sessionStorageProvider",
    "$localStorageProvider",
    "usSpinnerConfigProvider",
    "$urlMatcherFactoryProvider",
    "$mdThemingProvider",
    "$mdIconProvider",
    "$animateProvider",
    "cfpLoadingBarProvider",
    "$mdDateLocaleProvider",
    "$mdAriaProvider",
    "$compileProvider"
  ];

  constructor(
    $stateProvider: ng.ui.IStateProvider,
    $urlRouterProvider: ng.ui.IUrlRouterProvider,
    $locationProvider: ng.ILocationProvider,
    $httpProvider: ng.IHttpProvider,
    $sessionStorageProvider: any,
    $localStorageProvider: any,
    usSpinnerConfigProvider,
    $urlMatcherFactoryProvider: ng.ui.IUrlMatcherFactory,
    $mdThemingProvider: ng.material.IThemingProvider,
    $mdIconProvider: ng.material.IIconProvider,
    $animateProvider: ng.animate.IAnimateProvider,
    cfpLoadingBarProvider: any,
    $mdDateLocaleProvider: ng.material.IDateLocaleProvider,
    $mdAriaProvider: any,
    $compileProvider: ng.ICompileProvider
  ) {
    $compileProvider.debugInfoEnabled(false);

    $urlMatcherFactoryProvider.strictMode(false);
    $urlMatcherFactoryProvider.caseInsensitive(true);

    $locationProvider.html5Mode(true).hashPrefix("");

    $mdAriaProvider.disableWarnings();

    $sessionStorageProvider.setKeyPrefix("bamboraPartner");
    $localStorageProvider.setKeyPrefix("bamboraPartner");

    $animateProvider.classNameFilter(/^(?:(?!disable-animations).)*$/);

    cfpLoadingBarProvider.includeSpinner = false;

    $mdThemingProvider.definePalette("primarypurple", {
      "50": "#d9c7e7",
      "100": "#b591d1",
      "200": "#9b6ac0",
      "300": "#77439f",
      "400": "#673a89",
      "500": "#573174",
      "600": "#47285e",
      "700": "#371f49",
      "800": "#271633",
      "900": "#160d1e",
      A100: "#d9c7e7",
      A200: "#b591d1",
      A400: "#673a89",
      A700: "#371f49",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200"
    });

    $mdThemingProvider.definePalette("sure_sage", {
      "50": "#fff",
      "100": "#E5F5F4",
      "200": "#84D7D7",
      "300": "#4FC6C6",
      "400": "#38AEAE",
      "500": "#2D8C8C",
      "600": "#2B8182",
      "700": "#236D6D",
      "800": "#1D5959",
      "900": "#164545",
      A100: "#E5F5F4",
      A200: "#84D7D7",
      A400: "#2D8C8C",
      A700: "#236D6D",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200"
    });

    $mdThemingProvider.definePalette("master_mint", {
      "50": "#fff",
      "100": "#D3FAE7",
      "200": "#AAF7D8",
      "300": "#7cE8C5",
      "400": "#58D2B5",
      "500": "#45BEAA",
      "600": "#149990",
      "700": "#007B7C",
      "800": "#005962",
      "900": "#004150",
      A100: "#D3FAE7",
      A200: "#AAF7D8",
      A400: "#45BEAA",
      A700: "#005962",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200"
    });
    $mdThemingProvider.definePalette("reliable_rhubarb", {
      "50": "#fff",
      "100": "#FCE6E8",
      "200": "#FAD5D9",
      "300": "#F8C4C9",
      "400": "#F6B3BA",
      "500": "#F07791",
      "600": "#CF576C",
      "700": "#A93C57",
      "800": "#842745",
      "900": "#6C183B",
      A100: "#FCE6E8",
      A200: "#FAD5D9",
      A400: "#F07791",
      A700: "#6C183B",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200"
    });

    $mdThemingProvider.definePalette("secondarypurple", {
      "50": "#ffffff",
      "100": "#dccbea",
      "200": "#c1a3da",
      "300": "#9e70c6",
      "400": "#8f5abd",
      "500": "#8047b2",
      "600": "#703e9c",
      "700": "#613686",
      "800": "#512d70",
      "900": "#41245a",
      A100: "#ffffff",
      A200: "#dccbea",
      A400: "#8f5abd",
      A700: "#613686",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 300 A100 A200"
    });

    $mdThemingProvider.definePalette("secondarypurple2", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#ffffff",
      "300": "#e6d8f0",
      "400": "#d8c2e8",
      "500": "#caacdf",
      "600": "#bc96d6",
      "700": "#ae80ce",
      "800": "#a06ac5",
      "900": "#9254bd",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#d8c2e8",
      A700: "#ae80ce",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("pancho", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#ffffff",
      "300": "#fbf1e6",
      "400": "#f6e3cc",
      "500": "#f2d4b2",
      "600": "#eec598",
      "700": "#e9b77e",
      "800": "#e5a863",
      "900": "#e09a49",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#f6e3cc",
      A700: "#e9b77e",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("blush", {
      "50": "#ffffff",
      "100": "#f3dadf",
      "200": "#e5afbb",
      "300": "#d4798d",
      "400": "#cc627a",
      "500": "#c54b66",
      "600": "#b73b56",
      "700": "#9f334b",
      "800": "#882c40",
      "900": "#712435",
      A100: "#ffffff",
      A200: "#f3dadf",
      A400: "#cc627a",
      A700: "#9f334b",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 300 400 A100 A200 A400"
    });

    $mdThemingProvider.definePalette("disco", {
      "50": "#f0bed5",
      "100": "#e37fae",
      "200": "#d85190",
      "300": "#b9296c",
      "400": "#a0245e",
      "500": "#871e4f",
      "600": "#6e1840",
      "700": "#551332",
      "800": "#3c0d23",
      "900": "#230814",
      A100: "#f0bed5",
      A200: "#e37fae",
      A400: "#a0245e",
      A700: "#551332",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200"
    });

    $mdThemingProvider.definePalette("pear", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#ffffff",
      "300": "#f8fbf5",
      "400": "#e7f3de",
      "500": "#d7ebc8",
      "600": "#c7e3b2",
      "700": "#b6db9b",
      "800": "#a6d385",
      "900": "#96ca6e",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#e7f3de",
      A700: "#b6db9b",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("persiangreen", {
      "50": "#b0fff8",
      "100": "#64fff2",
      "200": "#2cffed",
      "300": "#00e3d0",
      "400": "#00c5b4",
      "500": "#00a698",
      "600": "#00877c",
      "700": "#006960",
      "800": "#004a44",
      "900": "#002c28",
      A100: "#b0fff8",
      A200: "#64fff2",
      A400: "#00c5b4",
      A700: "#006960",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 300 400 A100 A200 A400"
    });

    $mdThemingProvider.definePalette("cornflower", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#ffffff",
      "300": "#f1f8fd",
      "400": "#d6ecf8",
      "500": "#bcdff4",
      "600": "#a2d2f0",
      "700": "#87c6eb",
      "800": "#6db9e7",
      "900": "#53ade3",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#d6ecf8",
      A700: "#87c6eb",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("easternblue", {
      "50": "#ddf4fa",
      "100": "#9bdeef",
      "200": "#6acde7",
      "300": "#2cb9de",
      "400": "#20a8cb",
      "500": "#1c92b1",
      "600": "#187c97",
      "700": "#14667c",
      "800": "#0f5162",
      "900": "#0b3b47",
      A100: "#ddf4fa",
      A200: "#9bdeef",
      A400: "#20a8cb",
      A700: "#14667c",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 300 400 A100 A200 A400"
    });

    $mdThemingProvider.definePalette("goldenrod", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#fffdfa",
      "300": "#fde4b4",
      "400": "#fdd996",
      "500": "#fcce78",
      "600": "#fbc35a",
      "700": "#fbb83c",
      "800": "#faad1e",
      "900": "#f4a105",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#fdd996",
      A700: "#fbb83c",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("pampas", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#ffffff",
      "300": "#ffffff",
      "400": "#f9f8f6",
      "500": "#ede9e3",
      "600": "#e1dad0",
      "700": "#d5ccbe",
      "800": "#c9bdab",
      "900": "#bdae99",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#f9f8f6",
      A700: "#d5ccbe",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("silverrust", {
      "50": "#ffffff",
      "100": "#ffffff",
      "200": "#ffffff",
      "300": "#e7e5e4",
      "400": "#d9d6d3",
      "500": "#cbc6c3",
      "600": "#bdb6b3",
      "700": "#afa7a2",
      "800": "#a09792",
      "900": "#928881",
      A100: "#ffffff",
      A200: "#ffffff",
      A400: "#d9d6d3",
      A700: "#afa7a2",
      contrastDefaultColor: "light",
      contrastDarkColors:
        "50 100 200 300 400 500 600 700 800 900 A100 A200 A400 A700"
    });

    $mdThemingProvider.definePalette("bandicoot", {
      "50": "#ffffff",
      "100": "#e3e3e2",
      "200": "#c8c8c6",
      "300": "#a5a5a1",
      "400": "#969692",
      "500": "#878782",
      "600": "#787873",
      "700": "#686864",
      "800": "#585855",
      "900": "#494946",
      A100: "#ffffff",
      A200: "#e3e3e2",
      A400: "#969692",
      A700: "#686864",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 300 400 500 A100 A200 A400"
    });

    $mdThemingProvider.definePalette("stormdust", {
      "50": "#dbdbdb",
      "100": "#b5b5b4",
      "200": "#999998",
      "300": "#767674",
      "400": "#666665",
      "500": "#575756",
      "600": "#484847",
      "700": "#383838",
      "800": "#292928",
      "900": "#191919",
      A100: "#dbdbdb",
      A200: "#b5b5b4",
      A400: "#666665",
      A700: "#383838",
      contrastDefaultColor: "light",
      contrastDarkColors: "50 100 200 A100 A200"
    });

    const epayColorPalette = $mdThemingProvider.extendPalette("blue", {
      "50": "c5effc",
      "100": "98e0f7",
      "200": "66d1f2",
      "300": "46c4eb",
      "400": "1db9e9",
      "500": "009aca",
      "600": "007ea9",
      "700": "006687",
      "800": "00586f",
      "900": "004251",
      A100: "9bf4fc",
      A200: "6ae2fc",
      A400: "1fc2f8",
      A700: "007d9c"
    });
    $mdThemingProvider.definePalette("epay", epayColorPalette);

    $mdThemingProvider
      .theme("default")
      .primaryPalette("sure_sage")
      .accentPalette("master_mint");

    $mdThemingProvider
      .theme("default-inverted")
      .primaryPalette("master_mint")
      .accentPalette("sure_sage");

    $mdThemingProvider
      .theme("sidenav-left")
      .primaryPalette("sure_sage")
      .accentPalette("master_mint");

    $mdThemingProvider
      .theme("topmenu")
      .primaryPalette("master_mint")
      .accentPalette("sure_sage");

    $mdThemingProvider
      .theme("black")
      .primaryPalette("grey")
      .accentPalette("sure_sage");

    $mdThemingProvider
      .theme("cyan")
      .primaryPalette("deep-purple")
      .accentPalette("indigo");

    $mdThemingProvider
      .theme("default-dark")
      .dark()
      .primaryPalette("sure_sage")
      .accentPalette("master_mint");

    $mdIconProvider
      .icon(
        "bambora-partner-logo",
        require("./shared/assets/images/worldline-logo.svg")
      )
      .icon(
        "bambora:invoice",
        require("./shared/assets/images/icon-invoice.svg")
      )
      .icon("bambora:domain", require("./shared/assets/images/icon-domain.svg"))
      .icon(
        "bambora:impersonate",
        require("./shared/assets/images/icon-impersonate.svg")
      )
      .icon(
        "bambora:remove_image",
        require("./shared/assets/images/icon-remove_image.svg")
      )
      .icon(
        "bambora:monitoring",
        require("./shared/assets/images/icon-monitoring.svg")
      );

    const spinnerOptions = {
      lines: 13,
      length: 0,
      width: 12,
      radius: 27,
      color: "#8FDAFF",
      position: "relative",
      top: "40px",
      hwaccel: true
    };

    usSpinnerConfigProvider.setDefaults(spinnerOptions);

    $mdDateLocaleProvider.firstDayOfWeek = 1;
    $mdDateLocaleProvider.formatDate = (date: Date) => {
      if (!date) return "";

      const m = moment(date);
      if (!m.isValid()) return "";

      return m.format("YYYY-MM-DD");
    };
  }
}

import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  MerchantNumberListService,
  ToastHelperService
} from "../shared/services";

export class MerchantNumberCreateController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "merchantNumberListService",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IMerchantNumberCreateScope,
    private $mdDialog: ng.material.IDialogService,
    private merchantNumberListService: MerchantNumberListService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    $scope.request = {
      type: "production"
    };
  }

  submit(
    request: webserviceModels.Common.Request.MerchantNumber.addmerchantnumber
  ) {
    this.merchantNumberListService.create(request).then(
      success => {
        this.$mdDialog.hide();
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The merchant number was successfully created."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The merchant number could not be created at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setSubmitted(form: ng.IFormController) {
    form.$setSubmitted();

    angular.forEach(form, item => {
      if (item && item.$$parentForm === form && item.$setSubmitted) {
        this.setSubmitted(item);
      }
    });
  }
}

export interface IMerchantNumberCreateScope extends ng.IScope {
  request: webserviceModels.Common.Request.MerchantNumber.addmerchantnumber;
}

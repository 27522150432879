import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService, LoginService } from "../clients";
import { CompanyService } from ".";

export class ImpersonateService {
  static $inject = ["resellerService", "$injector", "companyService", "$q"];
  constructor(
    private resellerService: ResellerService,
    private $injector: ng.auto.IInjectorService,
    private companyService: CompanyService,
    private $q: ng.IQService
  ) {}

  // tslint:disable:member-ordering
  // tslint:disable:variable-name
  private _accessToken: string;
  private _secretToken: string;
  private _authenticationToken: string;
  private _companyId: string;
  private _merchantNumber: string;

  get authenticationToken() {
    return this._authenticationToken;
  }

  private setAuthenticationToken(
    merchantNumber: string,
    accessToken: string,
    secretToken: string
  ) {
    const authenticationToken = btoa(
      `${accessToken}@${merchantNumber}:${secretToken}`
    );

    this._accessToken = accessToken;
    this._secretToken = secretToken;
    this._authenticationToken = authenticationToken;
  }

  impersonateMasterWebUser(
    companyId: string,
    merchantNumber: string,
    forceReload: boolean = false
  ): ng.IPromise<
    webserviceModels.Reseller.Response.impersonatewebuserresponse
  > {
    const deferred = this.$q.defer();

    if (
      !forceReload &&
      this._companyId === companyId &&
      this._merchantNumber === merchantNumber
    ) {
      deferred.resolve({
        meta: { result: true },
        accesstoken: this._accessToken,
        secrettoken: this._secretToken
      });
    } else {
      this.companyService
        .load(companyId)
        .then(response => {
          if (!this.companyService.company.masterwebusers.length) {
            return deferred.reject();
          }

          this.resellerService
            .impersonatewebuser(
              companyId,
              merchantNumber,
              this.companyService.company.masterwebusers[0].accesstoken
            )
            .then(impersonateResponse => {
              if (impersonateResponse.meta.result) {
                this.setAuthenticationToken(
                  merchantNumber,
                  impersonateResponse.accesstoken,
                  impersonateResponse.secrettoken
                );
                this._companyId = companyId;
                this._merchantNumber = merchantNumber;
                deferred.resolve(impersonateResponse);
              } else {
                deferred.reject(impersonateResponse.meta.message);
              }
            })
            .catch(error => deferred.reject(error));
        })
        .catch(error => deferred.reject(error));
    }

    return deferred.promise as any;
  }

  refresh(): ng.IPromise<any> {
    if (this._authenticationToken) {
      return this.impersonateMasterWebUser(
        this._companyId,
        this._merchantNumber,
        true
      );
    }

    throw new ReferenceError("An impersonation has not yet been initialized.");
  }
}

export default ImpersonateService;

import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import { LoginService, ToastHelperService } from "../shared/services";

export class ApiUserPermissionController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "loginService",
    "apis",
    "permissions",
    "apiUser",
    "gettextCatalog",
    "toastHelper"
  ];

  constructor(
    private $scope: IApiUserPermissionScope,
    private $mdDialog: ng.material.IDialogService,
    private loginService: LoginService,
    private apis: Array<webserviceModels.Login.Response.FunctionPermission.api>,
    private permissions: Array<
      webserviceModels.Login.Response.FunctionPermission.functionpermission
    >,
    private apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService
  ) {
    this.apis = Enumerable.from(apis)
      .orderBy(api => api.name)
      .toArray();
    this.permissions = Enumerable.from(permissions)
      .orderBy(permission => permission.description)
      .toArray();

    $scope.loginService = loginService;
    $scope.apiUser = apiUser;
    $scope.apis = apis;

    $scope.request = {
      functionpermissions: permissions.map(permission => ({
        functionname: permission.name
      }))
    };

    $scope.permissions = {};

    permissions.map(permission => ($scope.permissions[permission.name] = true));
  }

  isChecked(apiName: string) {
    const checked = this.getChecked(apiName);

    return checked.all(value => value);
  }

  isIndeterminate(apiName: string) {
    const checked = this.getChecked(apiName);

    return checked.any(value => value) && checked.any(value => !value);
  }

  toggleChecked(apiName: string) {
    const shouldCheck = !this.isChecked(apiName);
    const api = Enumerable.from(this.apis).first(apiX => apiX.name === apiName);

    api.functionpermissions.map(permission => {
      this.$scope.permissions[permission.name] = shouldCheck;
    });
  }

  submit(
    request: webserviceModels.Login.Request.FunctionPermission.updatefunctionpermission
  ) {
    request.functionpermissions = [];

    this.apis.map(api => {
      api.functionpermissions.map(permission => {
        if (!this.$scope.permissions[permission.name]) return;

        request.functionpermissions.push({ functionname: permission.name });
      });
    });

    this.loginService
      .updatefunctionpermissions(this.apiUser.accesstoken, request)
      .then(
        success => {
          this.$mdDialog.hide();
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "Permissions for {{email}} were successfully updated.",
              { email: this.apiUser.email }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "Permissions for {{email}} could not be updated at this time.",
                { email: this.apiUser.email }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  private getChecked(apiName: string) {
    const api = Enumerable.from(this.apis).first(apiX => apiX.name === apiName);

    const checked = Enumerable.from(
      api.functionpermissions.map(
        permission => this.$scope.permissions[permission.name]
      )
    );

    return checked;
  }
}

export interface IApiUserPermissionScope extends ng.IScope {
  request: webserviceModels.Login.Request.FunctionPermission.updatefunctionpermission;
  loginService: LoginService;
  apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser;
  apis: Array<webserviceModels.Login.Response.FunctionPermission.api>;
  permissions: { [name: string]: boolean };
}

import "./giraffy-loader.scss";
import { getFactoryFor } from "../../helpers/directive-helper";

export class GiraffyLoader implements ng.IDirective {
  static delay: number = 90;

  restrict = "AE";
  template = require("./giraffy-loader.html");

  link: ng.IDirectiveLinkFn = (
    scope: IGiraffyLoaderScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    const paths = instanceElement.find("path");

    scope.$watch("stop", (newValue, oldValue) => {
      if (!newValue && paths) {
        this.animate(paths);
      }
    });
  };

  animate(paths: ng.IAugmentedJQuery) {
    (function loop() {
      $(paths).velocity("transition.shrinkIn", {
        stagger: 150,
        complete: () => {
          $(paths).velocity("transition.shrinkOut", {
            stagger: 150,
            complete: () => {
              loop();
            }
          });
        }
      });
    })();
  }
}

export interface IGiraffyLoaderScope extends ng.IScope {
  stop: boolean;
}

import * as Enumerable from "linq";
import moment from "moment";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";
import { SearchHelperService } from "../helpers";

export class LogitemListService {
  // tslint:disable:variable-name
  private _logitems: Array<webserviceModels.Common.Response.Logging.logitem>;
  private _logitemGroups: Array<ILogitemGroup> = [];
  private _companyid: string;
  private _merchantnumber: string;
  private _lastEvaluatedKey: string;
  private _lastSearchFilter: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "resellerService",
    "$q",
    "searchHelperService",
    "$rootScope",
    "$timeout"
  ];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService,
    private searchHelperService: SearchHelperService,
    private $rootScope: ng.IRootScopeService,
    private $timeout: ng.ITimeoutService
  ) {
    let timeout = null;
    $rootScope.$on("BamboraPartner:LogSearchFilterUpdated", () => {
      $timeout.cancel(timeout);
      timeout = $timeout(() => {
        this.load(this._companyid, this._merchantnumber, true);
      }, 300);
    });
  }

  get logitemGroups(): Array<ILogitemGroup> {
    return this._logitemGroups;
  }

  get logItems(): Array<webserviceModels.Common.Response.Logging.logitem> {
    if (this._logitems) return this._logitems;
    throw new ReferenceError("The logitem list has not been initialized.");
  }

  load(
    companyid: string,
    merchantnumber: string,
    forceReload: boolean = false,
    conditionalLoad: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();
    const searchQuery = this.searchHelperService.searchQuery;
    const filterString = this.searchHelperService.filterString;
    const searchFilter = searchQuery + filterString;

    // tslint:disable-next-line:prefer-const
    let filters: string = null;

    if (!searchQuery && filterString) {
      filters = filterString;
    } else if (searchQuery && !filterString) {
      filters = `((identifiers.transactionreference::eq:${searchQuery})or(identifiers.collationid::eq:${searchQuery}))`;
    } else if (searchQuery && filterString) {
      filters = `(((identifiers.transactionreference::eq:${searchQuery})or(identifiers.collationid::eq:${searchQuery}))and${filterString})`;
    }

    if (searchFilter !== this._lastSearchFilter) forceReload = true;

    if (
      forceReload ||
      this._lastEvaluatedKey === undefined ||
      this._merchantnumber !== merchantnumber ||
      this._companyid !== companyid
    ) {
      this.resellerService
        .listlogitems(
          companyid,
          merchantnumber,
          {
            exclusivestartkey: undefined,
            pagesize: "20"
          },
          "desc",
          {
            filters,
            preloadchildlevel: "2",
            relationlevel: "200"
          }
        )
        .then(success => {
          if (success.meta.result) {
            this._logitems = success.logitems.map(
              sortLogitemChildrenRecursively
            );
            this.generateLogitemGroups();
            this._companyid = companyid;
            this._merchantnumber = merchantnumber;
            this._lastEvaluatedKey = success.meta.paging.lastevaluatedkey;
            this._lastSearchFilter = searchFilter;

            deferred.resolve();
          } else {
            this._companyid = undefined;
            this._merchantnumber = undefined;
            this._logitems = undefined;
            this._lastEvaluatedKey = undefined;
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        })
        .catch(error => {
          this._logitems = undefined;
          this._companyid = undefined;
          this._merchantnumber = undefined;
          this._lastEvaluatedKey = undefined;
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        });
    } else {
      if (
        this._lastEvaluatedKey === null ||
        (conditionalLoad && this._logitems)
      ) {
        deferred.resolve();
      } else {
        this.resellerService
          .listlogitems(
            companyid,
            merchantnumber,
            {
              exclusivestartkey: this._lastEvaluatedKey,
              pagesize: "20"
            },
            "desc",
            {
              filters,
              preloadchildlevel: "2"
            }
          )
          .then(success => {
            if (success.meta.result) {
              this._logitems.push(
                ...success.logitems
                  .filter(
                    logitem =>
                      !Enumerable.from(this._logitems).any(
                        ({ id }) => logitem.id === id
                      )
                  )
                  .map(sortLogitemChildrenRecursively)
              );

              this.generateLogitemGroups();

              this._companyid = companyid;
              this._merchantnumber = merchantnumber;
              this._lastEvaluatedKey = success.meta.paging.lastevaluatedkey;
              this._lastSearchFilter = searchFilter;

              deferred.resolve();
            } else {
              try {
                deferred.reject(success.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          })
          .catch(error => {
            try {
              deferred.reject(error.data.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          });
      }
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._logitems) {
      return this.load(this._companyid, this._merchantnumber, true);
    }

    throw new ReferenceError("The logitem list has not been initialized.");
  }

  generateLogitemGroups() {
    this._logitemGroups.length = 0;

    const logitemGroups = Enumerable.from(this._logitems)
      .groupBy(logitem => moment(logitem.timestamp).format("YYYY-MM-DD"))
      .toArray()
      .map<ILogitemGroup>(logitemGroup => {
        const fulldate = logitemGroup.key();
        const momentDate = moment(fulldate);

        return {
          fulldate,
          date: momentDate.format("D"),
          day: momentDate.format("ddd"),
          month: momentDate.format("MMMM"),
          year: momentDate.format("YYYY"),
          logitems: logitemGroup.toArray()
        };
      });

    this._logitemGroups.push(...logitemGroups);
  }
}

function sortLogitemChildrenRecursively(
  logitem: webserviceModels.Common.Response.Logging.logitem
) {
  logitem.children = logitem.children
    .sort((a, b) => moment(b.timestamp).unix() - moment(a.timestamp).unix())
    .map(sortLogitemChildrenRecursively);

  return logitem;
}

export interface ILogitemGroup {
  fulldate: string;
  date: string;
  day: string;
  month: string;
  year: string;
  logitems: Array<webserviceModels.Common.Response.Logging.logitem>;
}

export class Punycode implements ng.IDirective {
  static $inject = ["punycodeFilter"];

  constructor(private punycodeFilter: (...args) => string) {}

  // tslint:disable:member-ordering
  restrict = "A";
  require = "ngModel";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    ngModel: ng.INgModelController
  ) => {
    ngModel.$parsers.push((value: string) => {
      return this.punycodeFilter(value, "toASCII");
    });
    ngModel.$formatters.push((value: string) => {
      return this.punycodeFilter(value, "toUnicode");
    });
  };
}

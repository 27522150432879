import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import tinycolor from "tinycolor2";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  ResellerService,
  CompanyService,
  MerchantNumberListService,
  ToastHelperService,
  AuthenticationService,
  CountryListService,
  ImpersonateService
} from "../shared/services";
import * as endpoints from "../app.endpoints";
import "./company-delete-dialog.scss";

export class CompanyDetailsSidenavController {
  static $inject = [
    "$scope",
    "resellerService",
    "companyService",
    "$state",
    "merchantNumberListService",
    "$mdSidenav",
    "$mdDialog",
    "gettextCatalog",
    "toastHelper",
    "$mdMedia",
    "authenticationService",
    "countryListService",
    "impersonateService"
  ];

  constructor(
    private $scope: ICompanyDetailsSidenavScope,
    private resellerService: ResellerService,
    private companyService: CompanyService,
    private $state: ng.ui.IStateService,
    private merchantNumberListService: MerchantNumberListService,
    private $mdSidenav: ng.material.ISidenavService,
    private $mdDialog: ng.material.IDialogService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private $mdMedia: ng.material.IMedia,
    private authenticationService: AuthenticationService,
    private countryListService: CountryListService,
    private impersonateService: ImpersonateService
  ) {
    $scope.companyService = companyService;
    $scope.merchantNumberListService = merchantNumberListService;
  }

  selectMerchantNumber(
    merchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber
  ) {
    const lastMerchantNumber = this.merchantNumberListService
      .selectedMerchantNumber;
    this.$state
      .go(this.$state.current.name, {
        companyid: this.companyService.company.id,
        merchantnumber: merchantNumber.number
      })
      .then(
        success => {
          this.merchantNumberListService.selectedMerchantNumber = merchantNumber;
          this.closeSidenav();
        },
        error => {
          this.merchantNumberListService.selectedMerchantNumber = lastMerchantNumber;
        }
      );
  }

  validDeleteDate(date: string): boolean {
    return new Date(date).getUTCFullYear() <= 3000;
  }

  closeSidenav() {
    this.$mdSidenav("sidenav-right").close();
  }

  edit($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./company-edit.html"),
      controller: "companyEditController",
      controllerAs: "companyEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        company: this.companyService.company
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  delete($event: MouseEvent) {
    this.$mdDialog.show({
      controller: "companyDeleteController",
      controllerAs: "companyDelete",
      template: require("./company-delete-dialog.html"),
      parent: angular.element(document.body),
      targetEvent: $event,
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        company: this.companyService.company,
        isListItem: false
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  canceldelete($event: MouseEvent) {
    this.$mdDialog.show({
      controller: "companyCancelDeleteController",
      controllerAs: "companyCancelDelete",
      template: require("./company-canceldelete-dialog.html"),
      parent: angular.element(document.body),
      targetEvent: $event,
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        company: this.companyService.company,
        isListItem: false
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  impersonateMasterWebUser($event: MouseEvent) {
    this.impersonateService
      .impersonateMasterWebUser(
        this.companyService.company.id,
        this.merchantNumberListService.selectedMerchantNumber.number
      )
      .then(success => {
        if (success.meta.result) {
          const apiKey = btoa(
            success.accesstoken +
              "@" +
              this.merchantNumberListService.selectedMerchantNumber.number +
              ":" +
              success.secrettoken
          );
          const actionString = endpoints.impersonate + "#" + apiKey;
          const html = `
                        <form action="${actionString}" target="_blank" method="post" style="display: none">
                        </form>
                    `;
          const form = $(html);
          $("body").append(form);
          form.submit();
          form.remove();
        } else {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "The master web user could not be impersonated at this time."
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      })
      .catch(error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The master web user could not be impersonated at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      });
  }

  getResellerColor(companyName: string): string {
    let hash = 0;

    for (let i = 0; i < companyName.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = companyName.charCodeAt(i) + ((hash << 5) - hash);
    }

    // tslint:disable-next-line:no-bitwise
    const c = (hash & 0x00ffffff).toString(16).toUpperCase();

    const hex = "00000".substring(0, 6 - c.length) + c;

    const hsl = tinycolor(hex).toHsl();
    hsl.h = hsl.h * 0.77777 + 40; // Removing red from the color spectrum.
    hsl.s = 0.5; // Normalizing saturation.
    hsl.l = 0.5; // Normalizing lightness.

    return tinycolor(hsl).toRgbString();
  }

  resellerIsSelf(resellerNumber: string): boolean {
    return (
      this.authenticationService.userSession.selectedResellerNumber ===
      resellerNumber
    );
  }

  showReseller(
    company: webserviceModels.Common.Response.Company.company
  ): boolean {
    if (!company || !company.resellers) return false;
    return !this.resellerIsSelf(company.resellers[0].number);
  }

  getCountryName(countryCode: string): string {
    return Enumerable.from(this.countryListService.countries).first(
      country => country.isoa3code === countryCode
    ).commonname;
  }
}

export interface ICompanyDetailsSidenavScope extends ng.IScope {
  companyService: CompanyService;
  merchantNumberListService: MerchantNumberListService;
  selectedMerchantNumber: webserviceModels.Common.Response.MerchantNumber.merchantnumber;
}

import { Component } from "../../../../shared/decorators";
import * as webserviceModels from "../../../../shared/interfaces/webservice-models";
import { AcquirerListService } from "../../../../shared/services";

@Component({
  selector: "invoiceDetails",
  template: require("./invoice-details.html"),
  bindings: {
    _invoice: "<invoice",
    operation: "<"
  }
})
export class InvoiceDetailsComponent {
  operation: webserviceModels.Merchant.transactionoperation;

  // tslint:disable:member-ordering
  static $inject = ["$mdDialog", "acquirerListService"];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private acquirerListService: AcquirerListService
  ) {}

  // tslint:disable-next-line:variable-name
  private _invoice: webserviceModels.Common.invoice;

  get invoice() {
    return this._invoice;
  }

  get acquirerDisplayName() {
    let acquirerName = this.operation.acquirername;
    return this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      acquirerName
    );
  }

  closeDialog() {
    this.$mdDialog.hide();
  }
}

export class ToastHelperService {
  static $inject = ["$mdToast"];
  constructor(private $mdToast: ng.material.IToastService) {}

  toast(content: string, action: string) {
    this.$mdToast.show(
      (this.$mdToast
        .simple()
        .action(action)
        .highlightAction(false)
        .parent("#main-content") as any).textContent(content)
    );
  }
}

declare namespace angular {
  interface IRootScopeService {
    toastHelper: ToastHelperService;
  }
}

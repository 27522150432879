import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../shared/decorators";
import {
  ToastHelperService,
  AgreementGroupListService,
  SearchHelperService,
  SyncFABService
} from "../shared/services";

@Component({
  selector: "createPaymentTypeFeeFab",
  template: require("./create-payment-type-fee-fab.html")
})
export class CreatePaymentTypeFeeFabComponent {
  // tslint:disable:variable-name
  private _showPanel = false;
  private _synchronizing = false;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "$mdDialog",
    "toastHelper",
    "agreementGroupListService",
    "$q",
    "searchHelperService",
    "syncFABService",
    "$mdMedia",
    "gettextCatalog",
    "$mdBottomSheet",
    "$timeout"
  ];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private toastHelper: ToastHelperService,
    private agreementGroupListService: AgreementGroupListService,
    private $q: ng.IQService,
    private searchHelperService: SearchHelperService,
    private syncFABService: SyncFABService,
    private $mdMedia: ng.material.IMedia,
    private gettextCatalog,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $timeout: ng.ITimeoutService
  ) {}

  showDialog($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./payment-type-fee-create.html"),
      controller: "paymentTypeFeeCreateController",
      controllerAs: "paymentTypeFeeCreate",
      preserveScope: true,
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  get showAddButton() {
    return (
      !this.syncFABService.active &&
      !this.searchHelperService.showSearch &&
      !this._showPanel
    );
  }

  get showSyncButton() {
    return (
      this.syncFABService.active &&
      !this.searchHelperService.showSearch &&
      !this._showPanel
    );
  }

  get synchronizing() {
    return this._synchronizing;
  }

  togglePaymentGroupPanel() {
    if (this._showPanel) {
      this.$mdBottomSheet.hide();
    } else {
      this._showPanel = true;

      const preventDrag = event => {
        event.stopPropagation();
        return false;
      };

      this.$mdBottomSheet
        .show({
          parent: angular.element("#overlay-container"),
          clickOutsideToClose: false,
          disableBackdrop: true,
          disableParentScroll: false,
          escapeToClose: false,
          template: `
                        <md-bottom-sheet class="md-grid md-whiteframe-z1" layout="column">
                            <payment-group-list></payment-group-list>
                        </md-bottom-sheet>
                    `
        } as any)
        .finally(() => {
          this._showPanel = false;
        });

      this.$timeout(() => {
        angular
          .element("md-bottom-sheet")
          .parent()
          .off("$md.dragstart")
          .off("$md.drag")
          .off("$md.dragend");
      });
    }
  }

  synchronizeOrder() {
    if (this._synchronizing) return;
    this._synchronizing = true;

    const agreementGroups = Enumerable.from(
      this.agreementGroupListService.agreementGroups
    ).where(agreementGroup => agreementGroup.sync);

    agreementGroups.forEach(agreementGroup => {
      for (let i = 0; i < agreementGroup.paymentTypeFees.length; i++) {
        agreementGroup.paymentTypeFees[i].order = i;
        this.agreementGroupListService.patchPaymentTypeFee(
          agreementGroup.paymentTypeFees[i].id,
          {
            paymenttypefee: {
              order: i
            }
          }
        );
      }
    });

    let patchTasks = [];

    agreementGroups.forEach(agreementGroup => {
      const patchArray = $.map(
        agreementGroup.paymentTypeFees,
        (value, index) => {
          return this.agreementGroupListService.patchPaymentTypeFee(value.id, {
            paymenttypefee: {
              order: value.order
            }
          });
        }
      );

      patchTasks = patchTasks.concat(patchArray);
    });

    const response = this.$q.all(patchTasks);

    response
      .then(
        allSucceeded => {
          agreementGroups.forEach(agreementGroup => {
            agreementGroup.sync = false;
          });
          this.syncFABService.active = false;

          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "The payment types were successfully synchronized."
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "The payment types could not be synchronized at this time."
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      )
      .finally(() => {
        this._synchronizing = false;
      });
  }
}

import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService, MerchantService } from "../clients";
import { ImpersonateService } from "./";

export type AddApiUserResponse = webserviceModels.Merchant.Response.ApiUser.addapiuserresponse &
  webserviceModels.Merchant.Response.ApiUser.apiuser;

export class ApiUserListService {
  private companyid: string;
  private merchantnumber: string;
  // tslint:disable-next-line:variable-name
  private _apiUsers: Array<webserviceModels.Merchant.Response.ApiUser.apiuser>;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "merchantService",
    "resellerService",
    "impersonateService",
    "$q",
    "$rootScope"
  ];
  constructor(
    private merchantService: MerchantService,
    private resellerService: ResellerService,
    private impersonateService: ImpersonateService,
    private $q: ng.IQService,
    private $rootScope: ng.IRootScopeService
  ) {}

  get apiUsers(): Array<webserviceModels.Merchant.Response.ApiUser.apiuser> {
    if (this._apiUsers) return this._apiUsers;
    throw new ReferenceError("The api user list has not been initialized.");
  }

  load(
    companyid: string,
    merchantnumber: string,
    forceReload: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (
      !forceReload &&
      this.companyid === companyid &&
      this.merchantnumber === merchantnumber
    ) {
      deferred.resolve();
    } else {
      this.impersonateService
        .impersonateMasterWebUser(companyid, merchantnumber)
        .then(impersonateWebUserResponse => this.merchantService.listapiusers())
        .then(
          success => {
            if (success.meta.result) {
              this.companyid = companyid;
              this.merchantnumber = merchantnumber;
              this._apiUsers = success.apiusers;
              deferred.resolve();
            } else {
              try {
                deferred.reject(success.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          },
          error => {
            try {
              deferred.reject(error.data.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._apiUsers) {
      return this.load(this.companyid, this.merchantnumber, true);
    }
    throw new ReferenceError("The api user list has not been initialized.");
  }

  create(
    request: webserviceModels.Merchant.Request.ApiUser.addapiuser
  ): ng.IPromise<AddApiUserResponse> {
    if (!this._apiUsers) {
      throw new ReferenceError("The api user list has not been initialized.");
    }

    const deferred = this.$q.defer<
      webserviceModels.Merchant.Response.ApiUser.addapiuserresponse
    >();
    const response = this.merchantService.addapiuser(request);

    response.then(
      success => {
        if (success.meta.result) {
          this.merchantService.getapiuser(success.accesstoken).then(
            result => {
              this._apiUsers.push(result.apiuser);
              this.$rootScope.$broadcast(
                "apiUserListService.itemAdded",
                result.apiuser
              );
              deferred.resolve(angular.extend({}, success, result.apiuser));
            },
            error => {
              deferred.resolve();
            }
          );
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise as any;
  }

  update(
    accessToken: string,
    request: webserviceModels.Merchant.Request.ApiUser.updateapiuser
  ): ng.IPromise<any> {
    if (!this._apiUsers) {
      throw new ReferenceError("The api user list has not been initialized.");
    }

    const deferred = this.$q.defer();
    const response = this.merchantService.updateapiuser(accessToken, request);

    response.then(
      success => {
        if (success.meta.result) {
          angular.copy(
            success.apiuser,
            Enumerable.from(this._apiUsers).first(
              apiUser => apiUser.accesstoken === accessToken
            )
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(
    apiUser: webserviceModels.Merchant.Response.ApiUser.apiuser
  ): ng.IPromise<any> {
    if (!this._apiUsers) {
      throw new ReferenceError("The api user list has not been initialized.");
    }

    const deferred = this.$q.defer();

    const response = this.merchantService.deleteaccesstomerchantnumber(
      apiUser.accesstoken
    );

    response.then(
      success => {
        if (success.meta.result) {
          this._apiUsers = Enumerable.from(this._apiUsers)
            .except([apiUser])
            .toArray();
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

export default ApiUserListService;

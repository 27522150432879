import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";

export class DomainListService {
  private companyid: string;
  // tslint:disable-next-line:variable-name
  private _domains: Array<webserviceModels.Common.Response.Domain.domain>;

  // tslint:disable-next-line:member-ordering
  static $inject = ["resellerService", "$q", "$rootScope"];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService,
    private $rootScope: ng.IRootScopeService
  ) {}

  get domains(): Array<webserviceModels.Common.Response.Domain.domain> {
    if (this._domains) return this._domains;
    throw new ReferenceError("The domain list has not been initialized.");
  }

  load(companyid: string, forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this.companyid === companyid) {
      deferred.resolve();
    } else {
      const response = this.resellerService.listdomains(companyid);

      response.then(
        success => {
          if (success.meta.result) {
            this.companyid = companyid;
            this._domains = success.domains;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._domains) return this.load(this.companyid, true);
    throw new ReferenceError("The domain list has not been initialized.");
  }

  create(
    request: webserviceModels.Common.Request.Domain.adddomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.domain> {
    if (!this._domains) {
      throw new ReferenceError("The domain list has not been initialized.");
    }

    const deferred = this.$q.defer<
      webserviceModels.Common.Response.Domain.domain
    >();
    const response = this.resellerService.adddomain(this.companyid, request);

    response.then(
      success => {
        if (success.meta.result) {
          this._domains.push(success.domain);
          this.$rootScope.$broadcast(
            "domainListService.itemAdded",
            success.domain
          );
          deferred.resolve(success.domain);
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  update(
    domainId: string,
    request: webserviceModels.Common.Request.Domain.updatedomain
  ): ng.IPromise<any> {
    if (!this._domains) {
      throw new ReferenceError("The domain list has not been initialized.");
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.updatedomain(
      this.companyid,
      domainId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.copy(
            success.domain,
            Enumerable.from(this._domains).first(
              domain => domain.id === domainId
            )
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  patch(
    domainId: string,
    request: webserviceModels.Common.Request.Domain.patchdomain
  ): ng.IPromise<any> {
    if (!this._domains) {
      throw new ReferenceError("The domain list has not been initialized.");
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.patchdomain(
      this.companyid,
      domainId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.copy(
            success.domain,
            Enumerable.from(this._domains).first(
              domain => domain.id === domainId
            )
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  delete(
    domain: webserviceModels.Common.Response.Domain.domain
  ): ng.IPromise<any> {
    if (!this._domains) {
      throw new ReferenceError("The domain list has not been initialized.");
    }

    const deferred = this.$q.defer();

    const response = this.resellerService.deletedomain(
      this.companyid,
      domain.id
    );

    response.then(
      success => {
        if (success.meta.result) {
          this._domains = Enumerable.from(this._domains)
            .except([domain])
            .toArray();
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }
}

export default DomainListService;

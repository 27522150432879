import { Component } from "../../../shared/decorators";
import * as webserviceModels from "../../../shared/interfaces/webservice-models";
import {
  AcquirerListService,
  PaymentCollectionListService
} from "../../../shared/services";
@Component({
  selector: "paymentDetails",
  template: require("./payment-details.html"),
  bindings: {
    _payment: "<payment",
    _operations: "<operations"
  }
})
export class PaymentDetailsComponent {
  // tslint:disable-next-line:variable-name
  private _payment: webserviceModels.Merchant.transaction;
  private _operations: Array<webserviceModels.Merchant.transactionoperation>;
  private _nordeaEPaymentFIBank: { bank: string; name: string };
  static $inject = [
    "$scope",
    "acquirerListService",
    "paymentCollectionListService"
  ];

  constructor(
    private $scope: ng.IScope,
    private acquirerListService: AcquirerListService,
    private paymentCollectionListService: PaymentCollectionListService
  ) {
    $scope.$watch(
      () => {
        return this._operations;
      },
      (newValue, oldValue) => {
        this._nordeaEPaymentFIBank = this.getNordeaEPaymentFIBank(newValue);
      }
    );
  }

  get acquirerDisplayName() {
    let acquirerName = this.payment.information.acquirers[0].name;
    return this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      acquirerName
    );
  }

  get walletDisplayName() {
    let walletName = this.payment.information.wallets[0].name;
    let walletPaymentType = this.paymentCollectionListService.paymentTypes.find(
      paymentType => {
        return (
          paymentType.name.toLocaleLowerCase() == walletName.toLocaleLowerCase()
        );
      }
    );

    if (walletPaymentType) return walletPaymentType.displayname;

    return walletName;
  }

  get payment() {
    return this._payment;
  }

  get distinctExemptions() {
    if (!this.payment.information.exemptions) return null;

    return this.payment.information.exemptions.filter(
      (exemptionItem, index, array) =>
        array.findIndex(
          arrayItem => arrayItem.value === exemptionItem.value
        ) === index
    );
  }

  get nordeaEPaymentFIBank() {
    return this._nordeaEPaymentFIBank;
  }

  getNordeaEPaymentFIBank(
    operations: Array<webserviceModels.Merchant.transactionoperation>
  ) {
    if (!operations) return null;

    const nordeaAuthorizeReturnOperation = operations.find(
      x =>
        x.acquirername.toLowerCase() === "nordeaepaymentfi" &&
        x.action.toLowerCase() === "authorize" &&
        x.subaction.toLowerCase() === "return"
    );

    if (
      !nordeaAuthorizeReturnOperation ||
      !nordeaAuthorizeReturnOperation.acquirerdata
    )
      return null;

    const bankAcquirerData = nordeaAuthorizeReturnOperation.acquirerdata.find(
      x => x.key.toLowerCase() === "nordeaepaymentfi.customerbank"
    );

    if (!bankAcquirerData) return null;

    const banks: Array<{ bank: string; name: string }> = [
      { bank: "nordea", name: "Nordea" },
      { bank: "op", name: "OP" },
      { bank: "spankki", name: "S-Pankki" },
      { bank: "danskebank", name: "Danske Bank" },
      { bank: "alandsbanken", name: "Ålandsbanken" },
      { bank: "handelsbanken", name: "Handelsbanken" },
      { bank: "saastopankki", name: "Säästöpankki" },
      { bank: "omasp", name: "Oma Säästöpankki" },
      { bank: "poppankki", name: "POP Pankki" }
    ];

    const bank = banks.find(
      x => x.bank === bankAcquirerData.value.toLowerCase()
    );

    if (!bank) {
      return null;
    }
    return bank;
  }
}

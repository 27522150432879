import * as angular from "angular";

export function highlightUrl() {
  return (urlString: string) => {
    // tslint:disable-next-line:no-shadowed-variable
    function parse(url: string) {
      const a = angular.element("<a></a>")[0] as HTMLAnchorElement;
      a.href = url;

      const parsedUrl: any = {
        source: url,
        protocol: a.protocol,
        host: a.hostname,
        port: a.port,
        query: a.search,
        params: (() => {
          // tslint:disable-next-line:no-shadowed-variable
          const queryParams = {};
          const segments = a.search.replace(/^\?/, "").split("&");
          let idx = segments.length;

          while (idx--) {
            if (segments[idx]) {
              const nameValue = segments[idx].split("=");
              queryParams[nameValue[0]] = nameValue[1];
            }
          }
          return queryParams;
        })(),

        file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ""])[1],
        hash: a.hash.replace("#", ""),
        path: a.pathname.replace(/^([^\/])/, "/$1"),
        relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ""])[1],
        segments: a.pathname.replace(/^\//, "").split("/")
      };

      if (parsedUrl.protocol === "data-uri:") {
        parsedUrl.mimeType = a.pathname.replace(/,.*$/, "");
        parsedUrl.encoding = a.pathname.replace(/(^.*,)(.*)(;.*$)/, "$2");
        parsedUrl.data = a.pathname
          .replace(/(^.*,)(.*)(;.*$)/, "$3")
          .substring(1);
      }

      return parsedUrl;
    }

    const protocol = '<span class="url-protocol">{{protocol}}</span>';
    const mimeType = '<span class="url-mime-type">{{mimeType}}</span>';
    const mimeTypeDelimiter = '<span class="url-mime-type-delimiter">,</span>';
    const encoding = '<span class="url-encoding">{{encoding}}</span>';
    const encodingDelimiter = '<span class="url-encoding-delimiter">;</span>';
    const data = '<span class="url-data">{{data}}</span>';
    const protocolDelimiter = '<span class="url-protocol-delimiter">//</span>';
    const host = '<span class="url-host">{{host}}</span>';
    const portDelimiter = '<span class="url-port-delimiter">:</span>';
    const port = '<span class="url-port">{{port}}</span>';
    const path = '<span class="url-path">{{path}}</span>';
    const queryDelimiter = '<span class="url-query-delimiter">?</span>';
    const queryParamName = '<span class="url-query-param-name">{{name}}</span>';
    const queryParamAssign = '<span class="url-query-param-assign">=</span>';
    const queryParamValue =
      '<span class="url-query-param-value">{{value}}</span>';
    const queryParamDelimiter =
      '<span class="url-query-param-delimiter">&amp;</span>';
    const hashDelimiter = '<span class="url-hash-delimiter">#</span>';
    const hash = '<span class="url-hash">{{hash}}</span>';

    function fill(template: any, value?: any) {
      return template.replace(/{{.*}}/, value);
    }

    const opt: any = { url: urlString };

    const url = parse(opt.url);

    if (url.data) {
      return [
        fill(opt.protocol || protocol, url.protocol),
        fill(opt.mimeType || mimeType, url.mimeType),
        fill(opt.urlmimeTypeDelimiter || mimeTypeDelimiter),
        fill(opt.encoding || encoding, url.encoding),
        fill(opt.encodingDelimiter || encodingDelimiter),
        fill(opt.data || data, url.data)
      ].join("");
    }

    const queryParams = [];
    for (const paramName in url.params) {
      if (url.params.hasOwnProperty(paramName)) {
        queryParams.push(
          [
            fill(opt.queryParamName || queryParamName, paramName),
            fill(opt.queryParamAssign || queryParamAssign),
            fill(opt.queryParamValue || queryParamValue, url.params[paramName])
          ].join("")
        );
      }
    }

    return [
      fill(opt.protocol || protocol, url.protocol),
      fill(opt.protocolDelimiter || protocolDelimiter),
      fill(opt.host || host, url.host),
      url.port ? fill(opt.portDelimiter || portDelimiter) : "",
      url.port ? fill(opt.port || port, url.port) : "",
      url.path !== "/" ? fill(opt.path || path, url.path) : "",
      queryParams.length ? fill(opt.queryDelimiter || queryDelimiter) : "",
      queryParams.join(fill(opt.queryParamDelimiter || queryParamDelimiter)),
      url.hash ? fill(opt.hashDelimiter || hashDelimiter) : "",
      url.hash ? fill(opt.hash || hash, url.hash) : ""
    ].join("");
  };
}

import * as webserviceModels from "../shared/interfaces/webservice-models";
import { ToastHelperService, UserListService } from "../shared/services";
import { IUserListScope } from "./user-list.controller";

export class UserDeleteController {
  static $inject = [
    "$scope",
    "$mdDialog",
    "user",
    "userListService",
    "$state",
    "gettextCatalog",
    "toastHelper",
    "isListItem"
  ];

  constructor(
    private $scope: IDeleteUserScope,
    private $mdDialog: ng.material.IDialogService,
    private user: webserviceModels.Common.Response.WebUser.webuser,
    private userListService: UserListService,
    private $state: ng.ui.IStateService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private isListItem: boolean
  ) {
    $scope.user = user;
    $scope.userListService = userListService;
  }

  delete() {
    this.userListService.delete(this.user).then(
      success => {
        this.showSuccessDeletedToast(this.user.name);
      },
      error => {
        this.showErrorDeletedToast(this.user.name, error);
      }
    );
    this.$mdDialog.hide();
  }

  showSuccessDeletedToast(name: string) {
    let message = this.gettextCatalog.getString(
      "{{name}} was successfully deleted.",
      {
        name
      }
    );
    this.toastHelper.toast(message, this.gettextCatalog.getString("Dismiss"));
  }

  showErrorDeletedToast(name: string, error: any) {
    let message =
      error ||
      this.gettextCatalog.getString(
        "{{name}} could not be deleted at this time.",
        {
          name
        }
      );
    this.toastHelper.toast(message, this.gettextCatalog.getString("Dismiss"));
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}

export interface IDeleteUserScope extends IUserListScope {
  user: webserviceModels.Common.Response.WebUser.webuser;
  userListService: UserListService;
}

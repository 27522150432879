import * as webserviceModels from "../../interfaces/webservice-models";
import { DataService } from "../clients";

export class CountryListService {
  // tslint:disable-next-line:variable-name
  private _countries: Array<webserviceModels.Data.Response.Country.country>;

  // tslint:disable-next-line:member-ordering
  static $inject = ["dataService", "$q"];
  constructor(private dataService: DataService, private $q: ng.IQService) {}

  get countries(): Array<webserviceModels.Data.Response.Country.country> {
    if (this._countries) return this._countries;
    throw new ReferenceError("The country list has not been initialized.");
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._countries) {
      deferred.resolve();
    } else {
      const response = this.dataService.listcountries();

      response.then(
        success => {
          if (success.meta.result) {
            this._countries = success.countries;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._countries) return this.load(true);
    throw new ReferenceError("The country list has not been initialized.");
  }
}

export default CountryListService;

import "./circular-loader.scss";
import { getFactoryFor } from "../../helpers/directive-helper";

export class CircularLoader implements ng.IDirective {
  static $inject = ["circularLoader"];
  constructor(private circularLoader: CircularLoaderService) {}

  // tslint:disable:member-ordering
  restrict = "E";
  replace = true;

  template = `
            <div class="md-whiteframe-z3 circular-loader" ng-if="circularLoader.active" ng-cloak>
                <md-progress-circular md-diameter="40" md-mode="indeterminate" class="md-accent md-hue-3"></md-progress-circular>
            </div>
        `;

  link: ng.IDirectiveLinkFn = (
    scope: ICircularLoaderScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    scope.circularLoader = this.circularLoader;
  };
}

export interface ICircularLoaderScope extends ng.IScope {
  circularLoader: CircularLoaderService;
}

// tslint:disable:max-classes-per-file
export class Refresh implements ng.IDirective {
  static $inject = ["circularLoader", "$rootScope"];

  constructor(
    private circularLoader: CircularLoaderService,
    private $rootScope: ng.IRootScopeService
  ) {}

  restrict = "A";

  link: ng.IDirectiveLinkFn = (
    scope: any,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    scope.refresh = () => {
      this.circularLoader.active = true;
      this.$rootScope.$emit("BamboraPartner:RefreshClicked");
    };
  };
}

export class CircularLoaderService {
  static get minimumDuration() {
    return 1000;
  }

  static $inject = ["$q", "$timeout", "$rootScope"];
  constructor(
    private $q: ng.IQService,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService
  ) {
    $rootScope.$on(
      "BamboraPartner:RefreshInitiated",
      (event, refreshPromise) => {
        if (!refreshPromise) return;

        const beginTime = new Date().getTime();

        refreshPromise.finally(() => {
          const timeDiff = new Date().getTime() - beginTime;

          if (timeDiff > CircularLoaderService.minimumDuration) {
            this.active = false;
          } else {
            this.$timeout(
              () => (this.active = false),
              CircularLoaderService.minimumDuration - timeDiff
            );
          }
        });
      }
    );
  }

  active: boolean = false;

  bind(scope: ng.IScope, fn: () => void): () => void {
    const onRefreshed = () => {
      const beginTime = new Date().getTime();
      this.$q.when(fn()).finally(() => {
        const timeDiff = new Date().getTime() - beginTime;
        if (timeDiff > CircularLoaderService.minimumDuration) {
          this.active = false;
        } else {
          this.$timeout(
            () => (this.active = false),
            CircularLoaderService.minimumDuration - timeDiff
          );
        }
      });
    };

    return this.$rootScope.$on("BamboraPartner:Refreshed", onRefreshed);
  }
}

export class ParentClick implements ng.IDirective {
  static $inject = ["$compile"];

  constructor(private $compile: ng.ICompileService) {}

  // tslint:disable-next-line:member-ordering
  restrict = "A";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    const newHtml = `<div ng-click="${instanceAttributes.parentClick}" div>`;
    const newElement = this.$compile(newHtml)(scope);
    newElement.css("outline", 0);
    instanceElement.after(newElement);
    newElement.append(instanceElement);
  };
}

import * as queryString from "query-string";
import * as endpoints from "../../../app.endpoints";
import * as webserviceModels from "../../interfaces/webservice-models";

export class ResellerService {
  static $inject: Array<string> = ["$http", "$q"];
  constructor(private $http: ng.IHttpService, private $q: ng.IQService) {}

  // tslint:disable-next-line:member-ordering
  private endpoint: string = endpoints.reseller;

  //#region Payment Type Fee

  addpaymenttypefee(
    companyid: string,
    merchantnumber: string,
    request: webserviceModels.Reseller.Request.PaymentTypeFee.addpaymenttypefee
  ): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.addpaymenttypefeeresponse
  > {
    return this.$http
      .post(
        `${this
          .endpoint}/companies/${companyid}/merchantnumbers/${merchantnumber}/paymenttypefees`,
        request
      )
      .then<any>(response => response.data);
  }

  getpaymenttypefee(
    companyid: string,
    merchantnumber: string,
    paymenttypefeeid: string
  ): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.getpaymenttypefeeresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/paymenttypefees/" +
          paymenttypefeeid
      )
      .then<any>(response => response.data);
  }

  patchpaymenttypefee(
    companyid: string,
    merchantnumber: string,
    paymenttypefeeid: string,
    request: webserviceModels.Common.Request.PaymentTypeFee.patchpaymenttypefee
  ): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.patchpaymenttypefeeresponse
  > {
    return this.$http
      .patch(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/paymenttypefees/" +
          paymenttypefeeid,
        request
      )
      .then<any>(response => response.data);
  }

  updatepaymenttypefee(
    companyid: string,
    merchantnumber: string,
    paymenttypefeeid: string,
    request: webserviceModels.Common.Request.PaymentTypeFee.updatepaymenttypefee
  ): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.updatepaymenttypefeeresponse
  > {
    return this.$http
      .put(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/paymenttypefees/" +
          paymenttypefeeid,
        request
      )
      .then<any>(response => response.data);
  }

  deletepaymenttypefee(
    companyid: string,
    merchantnumber: string,
    paymenttypefeeid: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/paymenttypefees/" +
          paymenttypefeeid
      )
      .then<any>(response => response.data);
  }

  listpaymenttypefees(
    companyid: string,
    merchantnumber: string
  ): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.listpaymenttypefeesresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/paymenttypefees"
      )
      .then<any>(response => response.data);
  }

  //#endregion

  //#region Company

  signup(
    request: webserviceModels.Reseller.Request.SignUp.signup
  ): ng.IPromise<webserviceModels.Reseller.Response.signupresponse> {
    return this.$http
      .post(this.endpoint + "/signup", request)
      .then<any>(response => response.data);
  }

  listwebusers(
    companyid: string,
    merchantnumber: string
  ): ng.IPromise<
    webserviceModels.Common.Response.WebUser.listwebusersresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/webusers"
      )
      .then<any>(response => response.data);
  }

  listusers(
    filters: string
  ): ng.IPromise<
    webserviceModels.Common.Response.WebUser.listwebusersresponse
  > {
    let params = filters ? "?filters=" + filters : "";
    return this.$http
      .get(this.endpoint + "/webusers" + params)
      .then<any>(response => response.data);
  }

  listresellers(): ng.IPromise<
    webserviceModels.Common.Response.Reseller.listresellersresponse
  > {
    return this.$http
      .get(this.endpoint + "/resellers")
      .then<any>(response => response.data);
  }

  sendInvitation(
    model: webserviceModels.Common.Request.WebUser.sendInvitation
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(this.endpoint + "/webusers/invitations", model)
      .then<any>(response => response.data);
  }

  acceptInvitation(
    invitationId: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(this.endpoint + `/webusers/invitations/${invitationId}/accept`, null)
      .then<any>(response => response.data);
  }

  getInvitation(
    invitationId: string
  ): ng.IPromise<
    webserviceModels.Common.Response.Reseller.getinvitationresponse
  > {
    return this.$http
      .get(this.endpoint + `/webusers/invitations/${invitationId}`)
      .then<any>(response => response.data);
  }

  createUserAndAcceptInvitation(
    model: webserviceModels.Merchant.Request.WebUser.addwebuser
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .post(this.endpoint + "/webusers", model)
      .then<any>(response => response.data);
  }

  activatewebuser(
    accesstoken: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .patch(`${this.endpoint}/webusers/${accesstoken}/activate`, null)
      .then<any>(response => response.data);
  }

  deactivatewebuser(
    accesstoken: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .patch(`${this.endpoint}/webusers/${accesstoken}/deactivate`, null)
      .then<any>(response => response.data);
  }

  impersonatewebuser(
    companyid: string,
    merchantnumber: string,
    accesstoken: string
  ): ng.IPromise<
    webserviceModels.Reseller.Response.impersonatewebuserresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/webusers/" +
          accesstoken +
          "/impersonate"
      )
      .then<any>(response => response.data);
  }

  listcompanies(
    exclusivestartkey?: string,
    pagesize?: string,
    filters?: string
  ): ng.IPromise<
    webserviceModels.Common.Response.Company.listcompaniesresponse
  > {
    let params =
      (exclusivestartkey ? "&exclusivestartkey=" + exclusivestartkey : "") +
      (pagesize ? "&pagesize=" + pagesize : "") +
      (filters ? "&filters=" + filters : "");
    params = params === "" ? "" : "?" + params;
    return this.$http
      .get(this.endpoint + "/companies" + params)
      .then<any>(response => response.data);
  }

  deleteUser(
    accessToken: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(this.endpoint + `/webusers/${accessToken}`)
      .then<any>(response => response.data);
  }

  getcompany(
    companyid: string
  ): ng.IPromise<webserviceModels.Common.Response.Company.getcompanyresponse> {
    return this.$http
      .get(this.endpoint + "/companies/" + companyid)
      .then<any>(response => response.data);
  }

  updatecompany(
    companyid: string,
    request: webserviceModels.Common.Request.Company.updatecompany
  ): ng.IPromise<
    webserviceModels.Common.Response.Company.updatecompanyresponse
  > {
    return this.$http
      .put(this.endpoint + "/companies/" + companyid, request)
      .then<any>(response => response.data);
  }

  deletecompany(
    companyid: string,
    date?: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    let params = date ? "date=" + date : "";
    params = params === "" ? "" : "?" + params;
    return this.$http
      .delete(this.endpoint + "/companies/" + companyid + params)
      .then<any>(response => response.data);
  }

  canceldeletecompany(
    companyid: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(this.endpoint + "/companies/" + companyid + "?date=9999-12-31")
      .then<any>(response => response.data);
  }

  activatecompany(
    companyid: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(this.endpoint + "/companies/" + companyid + "/activate", null)
      .then<any>(response => response.data);
  }

  //#endregion

  //#region Agreement

  addagreement(
    companyid: string,
    merchantnumber: string,
    request: webserviceModels.Common.Request.Agreement.addagreement
  ): ng.IPromise<
    webserviceModels.Common.Response.Agreement.addagreementresponse
  > {
    return this.$http
      .post(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements",
        request
      )
      .then<any>(response => response.data);
  }

  getagreement(
    companyid: string,
    merchantnumber: string,
    agreementid: string
  ): ng.IPromise<
    webserviceModels.Common.Response.Agreement.getagreementresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements/" +
          agreementid
      )
      .then<any>(response => response.data);
  }

  updateagreement(
    companyid: string,
    merchantnumber: string,
    agreementid: string,
    request: webserviceModels.Common.Request.Agreement.updateagreement
  ): ng.IPromise<
    webserviceModels.Common.Response.Agreement.updateagreementresponse
  > {
    return this.$http
      .put(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements/" +
          agreementid,
        request
      )
      .then<any>(response => response.data);
  }

  patchagreement(
    companyid: string,
    merchantnumber: string,
    agreementid: string,
    request: webserviceModels.Common.Request.Agreement.patchagreement
  ): ng.IPromise<
    webserviceModels.Common.Response.Agreement.patchagreementresponse
  > {
    return this.$http
      .patch(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements/" +
          agreementid,
        request
      )
      .then<any>(response => response.data);
  }

  deleteagreement(
    companyid: string,
    merchantnumber: string,
    agreementid: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements/" +
          agreementid
      )
      .then<any>(response => response.data);
  }

  activateagreement(
    companyid: string,
    merchantnumber: string,
    agreementid: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements/" +
          agreementid +
          "/activate",
        null
      )
      .then<any>(response => response.data);
  }

  listagreements(
    companyid: string,
    merchantnumber: string
  ): ng.IPromise<
    webserviceModels.Common.Response.Agreement.listagreementsresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements"
      )
      .then<any>(response => response.data);
  }

  getagreementdatafile(
    companyid: string,
    merchantnumber: string,
    agreementid: string,
    key: string
  ): ng.IPromise<Blob | webserviceModels.Common.Response.response> {
    const deferred = this.$q.defer<
      Blob | webserviceModels.Common.Response.response
    >();

    this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/agreements/" +
          agreementid +
          "/files/" +
          key,
        { responseType: "arraybuffer" }
      )
      .then(
        success => {
          deferred.resolve(
            new Blob([success.data as BlobPart], {
              type: success.headers("content-type")
            })
          );
        },
        error => {
          const response = JSON.parse(
            String.fromCharCode.apply(null, new Uint8Array(error.data))
          );
          deferred.reject(response);
        }
      );

    return deferred.promise;
  }

  //#endregion

  //#region Domain

  adddomain(
    companyid: string,
    request: webserviceModels.Common.Request.Domain.adddomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.adddomainresponse> {
    return this.$http
      .post(this.endpoint + "/companies/" + companyid + "/domains", request)
      .then<any>(response => response.data);
  }

  getdomain(
    companyid: string,
    domainid: string
  ): ng.IPromise<webserviceModels.Common.Response.Domain.getdomainresponse> {
    return this.$http
      .get(this.endpoint + "/companies/" + companyid + "/domains/" + domainid)
      .then<any>(response => response.data);
  }

  updatedomain(
    companyid: string,
    domainid: string,
    request: webserviceModels.Common.Request.Domain.updatedomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.updatedomainresponse> {
    return this.$http
      .put(
        this.endpoint + "/companies/" + companyid + "/domains/" + domainid,
        request
      )
      .then<any>(response => response.data);
  }

  patchdomain(
    companyid: string,
    domainid: string,
    request: webserviceModels.Common.Request.Domain.patchdomain
  ): ng.IPromise<webserviceModels.Common.Response.Domain.patchdomainresponse> {
    return this.$http
      .patch(
        this.endpoint + "/companies/" + companyid + "/domains/" + domainid,
        request
      )
      .then<any>(response => response.data);
  }

  deletedomain(
    companyid: string,
    domainid: string
  ): ng.IPromise<webserviceModels.Common.Response.Domain.deletedomainresponse> {
    return this.$http
      .delete(
        this.endpoint + "/companies/" + companyid + "/domains/" + domainid
      )
      .then<any>(response => response.data);
  }

  listdomains(
    companyid: string
  ): ng.IPromise<webserviceModels.Common.Response.Domain.listdomainsresponse> {
    return this.$http
      .get(this.endpoint + "/companies/" + companyid + "/domains")
      .then<any>(response => response.data);
  }

  //#endregion

  //#region Merchant Number

  addmerchantnumber(
    companyid: string,
    request: webserviceModels.Common.Request.MerchantNumber.addmerchantnumber
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.addmerchantnumberresponse
  > {
    return this.$http
      .post(
        this.endpoint + "/companies/" + companyid + "/merchantnumbers",
        request
      )
      .then<any>(response => response.data);
  }

  listmerchantnumbers(
    companyid: string
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.listmerchantnumbersresponse
  > {
    return this.$http
      .get(this.endpoint + "/companies/" + companyid + "/merchantnumbers")
      .then<any>(response => response.data);
  }

  getmerchantnumber(
    companyid: string,
    merchantnumber: string
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.getmerchantnumberresponse
  > {
    return this.$http
      .get(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber
      )
      .then<any>(response => response.data);
  }

  updatemerchantnumber(
    companyid: string,
    merchantnumber: string,
    request: webserviceModels.Common.Request.MerchantNumber.updatemerchantnumber
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.updatemerchantnumberresponse
  > {
    return this.$http
      .put(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber,
        request
      )
      .then<any>(response => response.data);
  }

  patchmerchantnumber(
    companyid: string,
    merchantnumber: string,
    request: webserviceModels.Common.Request.MerchantNumber.patchmerchantnumber
  ): ng.IPromise<
    webserviceModels.Common.Response.MerchantNumber.patchmerchantnumberresponse
  > {
    return this.$http
      .patch(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber,
        request
      )
      .then<any>(response => response.data);
  }

  deletemerchantnumber(
    companyid: string,
    merchantnumber: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .delete(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber
      )
      .then<any>(response => response.data);
  }

  activatemerchantnumber(
    companyid: string,
    merchantnumber: string
  ): ng.IPromise<webserviceModels.Common.Response.response> {
    return this.$http
      .put(
        this.endpoint +
          "/companies/" +
          companyid +
          "/merchantnumbers/" +
          merchantnumber +
          "/activate",
        null
      )
      .then<any>(response => response.data);
  }

  //#endregion

  //#region Merchant log

  listlogitems(
    companyid: string,
    merchantnumber: string,
    pagination?: {
      exclusivestartkey?: string;
      pagesize?: string;
    },
    sorting?: string,
    query?: {
      filters?: string;
      preloadchildlevel?: string;
      relateby?: string;
      relationlevel?: string;
    }
  ): ng.IPromise<
    webserviceModels.Common.Response.Logging.listlogitemsresponse
  > {
    const getParameters = {
      sorting,
      ...pagination,
      ...query
    };

    const getParametersString = queryString.stringify(getParameters);

    return this.$http
      .get(
        `${this
          .endpoint}/companies/${companyid}/merchantnumbers/${merchantnumber}/logs?${getParametersString}`
      )
      .then<any>(response => response.data);
  }

  getlogitem(
    companyid: string,
    merchantnumber: string,
    id: string
  ): ng.IPromise<webserviceModels.Common.Response.Logging.getlogitemresponse> {
    return this.$http
      .get(
        `${this
          .endpoint}/companies/${companyid}/merchantnumbers/${merchantnumber}/logs/${id}`
      )
      .then<any>(response => response.data);
  }

  //#endregion
}

export default ResellerService;

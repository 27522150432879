// #region Stylesheet dependencies
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "angular-loading-bar/build/loading-bar.min.css";
import "animate.css/animate.css";
import "angular-material/angular-material.css";
import "./app.scss";

// #endregion

// #region External dependencies
import * as jQuery from "jquery";
import * as angular from "angular";
import "jquery-ui/ui/core.js";
import "jquery-ui/ui/widget.js";
import "jquery-ui/ui/widgets/mouse.js";
import "jquery-ui/ui/widgets/sortable.js";
import "jquery-ui/ui/effect.js";
import "velocity-animate";
import "velocity-animate/velocity.ui.min.js";
import "spin.js";
import "angular-animate";
import "angular-aria";
import "angular-messages";
import "angular-sanitize";
import "angular-material";
import "angular-gettext";
import "angular-local-storage";
import "angular-ui-router";
import "angular-ui-sortable";
import "angular-loading-bar";
import "ngstorage";
import "angular-spinner";
import "angular-filter";
import "./shared/lib/bambora-currency/angular-bambora-currency";
import "../node_modules/qrcode-generator/qrcode.js";
import "../node_modules/qrcode-generator/qrcode_UTF8.js";
import "../node_modules/angular-qrcode/angular-qrcode.js";
// #endregion

// #region Internal dependencies
import { AppStateConfig } from "./app.states";
import { AppConfig } from "./app.config";
import { AppRun } from "./app.run";
import * as shared from "./shared";
import * as account from "./account";
import * as agreement from "./agreement";
import * as apiUser from "./api-user";
import * as company from "./company";
import * as domain from "./domain";
import * as error from "./error";
import * as merchantlog from "./merchantlog";
import * as merchantnumber from "./merchantnumber";
import * as payments from "./payments";
import * as paymenttypefee from "./paymenttypefee";
import * as paymentwindows from "./paymentwindows";
import * as responsecodes from "./responsecodes";
import * as webuser from "./webuser";
import * as user from "./user";
import { addComponentToModule } from "./shared/decorators";
import { getFactoryFor } from "./shared/helpers/directive-helper";
// #endregion

// Declare module
export const moduleName = "becApp";

export const app: ng.IModule = angular.module(moduleName, [
  "ngAnimate",
  "ngMessages",
  "ui.router",
  "ngSanitize",
  "ngStorage",
  "angular-loading-bar",
  "gettext",
  "angularSpinner",
  "ngAria",
  "ngMaterial",
  "angular.filter",
  "ui.sortable",
  "ngBamboraCurrency",
  "monospaced.qrcode"
]);

// #region Services
app.service("logsFilterService", merchantlog.LogsFilterService);
app.service("paymentsFilterService", payments.PaymentsFilterService);
app.service("transactionsService", payments.TransactionsService);
app.service("paymentGroupListService", paymenttypefee.PaymentGroupListService);
app.service(
  "responseCodesFilterService",
  responsecodes.ResponseCodesFilterService
);
app.service("circularLoader", shared.components.CircularLoaderService);
app.service(
  "visitedCompaniesListService",
  shared.components.VisitedCompaniesListService
);
app.service("scrollLoaderService", shared.directives.ScrollLoaderService);
app.service("checkoutService", shared.services.CheckoutService);
app.service("dataService", shared.services.DataService);
app.service("loginService", shared.services.LoginService);
app.service("merchantService", shared.services.MerchantService);
app.service("paymentWindowService", shared.services.PaymentWindowService);
app.service("resellerService", shared.services.ResellerService);
app.service("resellerListService", shared.services.ResellerListService);
app.service("searchHelperService", shared.services.SearchHelperService);
app.service("syncFABService", shared.services.SyncFABService);
app.service("tabHelper", shared.services.TabHelperService);
app.service("toastHelper", shared.services.ToastHelperService);
app.service("acquirerListService", shared.services.AcquirerListService);
app.service(
  "agreementGroupListService",
  shared.services.AgreementGroupListService
);
app.service("apiUserListService", shared.services.ApiUserListService);
app.service("authenticationService", shared.services.AuthenticationService);
app.service("companyListService", shared.services.CompanyListService);
app.service("companyService", shared.services.CompanyService);
app.service("countryListService", shared.services.CountryListService);
app.service("currencyListService", shared.services.CurrencyListService);
app.service("domainListService", shared.services.DomainListService);
app.service("impersonateService", shared.services.ImpersonateService);
app.service("logitemListService", shared.services.LogitemListService);
app.service(
  "merchantNumberListService",
  shared.services.MerchantNumberListService
);
app.service(
  "paymentCollectionListService",
  shared.services.PaymentCollectionListService
);
app.service(
  "paymentWindowListService",
  shared.services.PaymentWindowListService
);
app.service("permissionService", shared.services.PermissionService);
app.service(
  "requirementsFilterListService",
  shared.services.RequirementsFilterListService
);
app.service("responseCodeListService", shared.services.ResponseCodeListService);
app.service("webStorageService", shared.services.WebStorageService);
app.service("webUserListService", shared.services.WebUserListService);
app.service("userListService", shared.services.UserListService);
app.service("acceptInvitationService", shared.services.AcceptInvitationService);
// #endregion

// #region Filters
app.filter("capitalize", shared.filters.CapitalizeFilter);
app.filter("dateFormat", shared.filters.DateFormatFilter);
app.filter(
  "getPaymentTypeByGroupId",
  shared.filters.GetPaymentTypeByGroupIdFilter
);
app.filter("highlightJson", shared.filters.highlightJson);
app.filter("highlightUrl", shared.filters.highlightUrl);
app.filter("punycode", shared.filters.PunycodeFilter);
// #endregion

// #region Animations
app.animation(".animate-height-scale", shared.animations.AnimateHeightScale);
app.animation(".animate-height", shared.animations.animateHeight);
app.animation(".animate-list-item", shared.animations.AnimateListItem);
app.animation(".animate-expand-list", shared.animations.AnimateExpandList);
app.animation(".animate-slide-list", shared.animations.AnimateSlideList);
app.animation(
  ".animate-slide-leftright",
  shared.animations.AnimateSlideLeftToRight
);
app.animation(
  ".animate-slide-rightleft",
  shared.animations.AnimateSlideRightToLeft
);
app.animation(".animate-width-scale", shared.animations.AnimateWidth);
// #endregion

// #region Directives
app.directive("accentifyList", getFactoryFor(shared.directives.AccentifyList));
app.directive("fabify", getFactoryFor(shared.directives.Fabify));
app.directive("ifState", getFactoryFor(shared.directives.IfState));
app.directive("keyEvent", getFactoryFor(shared.directives.KeyEvent));
app.directive("parentClick", getFactoryFor(shared.directives.ParentClick));
app.directive(
  "requiresPermission",
  getFactoryFor(shared.directives.RequiresPermission)
);
app.directive("punycode", getFactoryFor(shared.directives.Punycode));
app.directive("scrollLoader", getFactoryFor(shared.directives.ScrollLoader));
app.directive("sidenavLeft", getFactoryFor(shared.directives.SidenavLeft));
app.directive("sidenavRight", getFactoryFor(shared.directives.SidenavRight));
app.directive(
  "stopPropagation",
  getFactoryFor(shared.directives.StopPropagation)
);
app.directive(
  "stringToNumber",
  getFactoryFor(shared.directives.StringToNumber)
);
app.directive(
  "transparentOnDrag",
  getFactoryFor(shared.directives.TransparentOnDrag)
);
app.directive(
  "validateInCollection",
  getFactoryFor(shared.directives.ValidateInCollection)
);
app.directive(
  "validateNotInCollection",
  getFactoryFor(shared.directives.ValidateNotInCollection)
);
app.directive(
  "validateRangeOverlap",
  getFactoryFor(shared.directives.ValidateRangeOverlap)
);
// #endregion

// #region Controllers
app.controller("accountController", account.AccountController);
app.controller("agreementCopyController", agreement.AgreementCopyController);
app.controller(
  "agreementCreateController",
  agreement.AgreementCreateController
);
app.controller("agreementEditController", agreement.AgreementEditController);
app.controller("agreementListController", agreement.AgreementListController);
app.controller("apiUserCreateController", apiUser.ApiUserCreateController);
app.controller("apiUserEditController", apiUser.ApiUserEditController);
app.controller("apiUserListController", apiUser.ApiUserListController);
app.controller(
  "apiUserPermissionController",
  apiUser.ApiUserPermissionController
);
app.controller("companyCreateController", company.CompanyCreateController);
app.controller(
  "companyDetailsSidenavController",
  company.CompanyDetailsSidenavController
);
app.controller("companyEditController", company.CompanyEditController);
app.controller("companyListController", company.CompanyListController);
app.controller("companyDeleteController", company.CompanyDeleteController);
app.controller(
  "companyCancelDeleteController",
  company.CompanyCancelDeleteController
);
app.controller("domainCreateController", domain.DomainCreateController);
app.controller("domainEditController", domain.DomainEditController);
app.controller("domainListController", domain.DomainListController);
app.controller("errorController", error.ErrorController);
app.controller(
  "merchantNumberCreateController",
  merchantnumber.MerchantNumberCreateController
);
app.controller(
  "merchantNumberEditController",
  merchantnumber.MerchantNumberEditController
);
app.controller(
  "merchantNumberListController",
  merchantnumber.MerchantNumberListController
);
app.controller(
  "paymentTypeFeeCreateController",
  paymenttypefee.PaymentTypeFeeCreateController
);
app.controller(
  "paymentTypeFeeEditController",
  paymenttypefee.PaymentTypeFeeEditController
);
app.controller(
  "paymentTypeFeeListController",
  paymenttypefee.PaymentTypeFeeListController
);
app.controller(
  "paymentWindowCreateController",
  paymentwindows.PaymentWindowCreateController
);
app.controller(
  "paymentWindowEditController",
  paymentwindows.PaymentWindowEditController
);
app.controller(
  "paymentWindowListController",
  paymentwindows.PaymentWindowListController
);
app.controller(
  "responseCodeListController",
  responsecodes.ResponseCodeListController
);
app.controller("webUserListController", webuser.WebUserListController);
app.controller("userListController", user.UserListController);
app.controller("acceptInvitationController", user.AcceptInvitationController);
app.controller("userCreateController", user.UserCreateController);
app.controller("userDeleteController", user.UserDeleteController);
app.controller(
  "appBarAuthenticatedController",
  shared.components.AppBarAuthenticatedController
);
app.controller("sidenavLeftController", shared.layout.SidenavLeftController);
app.controller("rightMenuController", shared.layout.RightMenuController);
// #endregion

// #region Components
addComponentToModule(app, shared.components.ConditionFilterComponent);
addComponentToModule(app, agreement.AgreementDataEditComponent);
addComponentToModule(app, merchantlog.LogsComponent);
addComponentToModule(app, merchantlog.LogitemsComponent);
addComponentToModule(app, merchantlog.LogitemDetailsComponent);
addComponentToModule(app, merchantlog.LogsFilterComponent);
addComponentToModule(app, payments.PaymentsComponent);
addComponentToModule(app, payments.PaymentDetailsComponent);
addComponentToModule(app, payments.EventDetailsComponent);
addComponentToModule(app, payments.PaymentEventsComponent);
addComponentToModule(app, payments.InvoiceDetailsComponent);
addComponentToModule(app, payments.OperationDetailsComponent);
addComponentToModule(app, payments.PaymentOperationsComponent);
addComponentToModule(app, payments.PaymentComponent);
addComponentToModule(app, payments.PaymentsFilterComponent);
addComponentToModule(app, paymenttypefee.CreatePaymentTypeFeeFabComponent);
addComponentToModule(app, paymenttypefee.PaymentGroupListComponent);
addComponentToModule(app, responsecodes.ResponseCodesFilterComponent);

app.directive(
  "appContentAuthenticated",
  getFactoryFor(shared.components.AppContentAuthenticated)
);
app.directive(
  "appContentUnauthenticated",
  getFactoryFor(shared.components.AppContentUnauthenticated)
);
app.directive("appLoader", getFactoryFor(shared.components.AppLoader));
app.directive(
  "appBarAuthenticated",
  getFactoryFor(shared.components.AppBarAuthenticated)
);
app.directive(
  "appBarUnauthenticated",
  getFactoryFor(shared.components.AppBarUnauthenticated)
);
app.directive(
  "circularLoader",
  getFactoryFor(shared.components.CircularLoader)
);
app.directive("refresh", getFactoryFor(shared.components.Refresh));
app.directive("giraffyLoader", getFactoryFor(shared.components.GiraffyLoader));
app.directive("userAvatar", getFactoryFor(shared.components.UserAvatar));
app.directive(
  "visitedCompaniesList",
  getFactoryFor(shared.components.VisitedCompaniesList)
);
// #endregion

// Initialize states
app.config(AppStateConfig);

app.config(AppConfig);
app.run(AppRun);
app.run(shared.components.VisitedCompaniesListRunConfig);

// Bootstrap app
angular
  .element(document)
  .ready(() => angular.bootstrap(document, [moduleName], { strictDi: true }));

import "./logs-filter.scss";
import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../../shared/decorators";
import {
  SearchHelperService,
  CurrencyListService,
  LogitemListService
} from "../../shared/services";
import { LogsFilterService } from "../shared/logs-filter.service";

@Component({
  selector: "logsFilter",
  template: require("./logs-filter.html")
})
export class LogsFilterComponent {
  searchTerm: string;
  logtypes = [];

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "searchHelperService",
    "$mdBottomSheet",
    "currencyListService",
    "logitemListService",
    "logsFilterService",
    "$mdMedia",
    "gettextCatalog"
  ];

  constructor(
    private searchHelperService: SearchHelperService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private currencyListService: CurrencyListService,
    private logitemListService: LogitemListService,
    private logsFilterService: LogsFilterService,
    private $mdMedia: ng.material.IMedia,
    private gettextCatalog: ng.gettext.gettextCatalog
  ) {
    this.logtypes.push(
      { key: "api", value: gettextCatalog.getString("API") },
      { key: "log", value: gettextCatalog.getString("Application") },
      { key: "event", value: gettextCatalog.getString("Event") }
    );
  }

  get currencies() {
    return this.currencyListService.currencies;
  }

  get filter() {
    return this.logsFilterService.filter;
  }

  $onInit() {
    if (
      this.isGtSm() &&
      !!(angular.element("#overlay-container")[0] as any).$mdGesture
    ) {
      // tslint:disable-next-line:no-empty
      (angular.element("#overlay-container")[0] as any).$mdGesture = () => {};
    }
  }

  close() {
    this.$mdBottomSheet.hide();
  }

  clearSearchTerm() {
    this.searchTerm = "";
  }

  clearAll() {
    this.logsFilterService.clearAll();
  }

  isGtSm(): boolean {
    return this.$mdMedia("gt-sm");
  }

  apply() {
    const filters: Array<string> = [];

    if (this.filter.logtypes && this.filter.logtypes.length) {
      filters.push(`(logtype::in:${this.filter.logtypes.join(",")})`);
    }

    if (this.filter.logsubtype) {
      filters.push(`(logsubtype::eq:${this.filter.logsubtype})`);
    }

    if (this.filter.result) {
      filters.push("(result::eq:false)");
    }

    const filterString =
      filters.length > 1 ? `(${filters.join("and")})` : filters.join("and");

    this.searchHelperService.filterString = filterString;
  }
}

import "./logs.scss";
import * as Enumerable from "linq";
import moment from "moment";
import urlParse from "url-parse";
import * as angular from "angular";
import { Component } from "../shared/decorators";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  SearchHelperService,
  ToastHelperService,
  LogitemListService
} from "../shared/services";

@Component({
  selector: "logs",
  template: require("./logs.html"),
  bindings: {
    _companyId: "@companyId",
    _merchantNumber: "@merchantNumber"
  }
})
export class LogsComponent {
  // tslint:disable:variable-name
  currentYear = moment().format("YYYY");
  currentYearAndMonth = moment().format("YYYY:MMMM");
  scrollLoaderActive = false;

  private _scrollValue;
  private _scrollContainer: JQuery;

  private _companyId: string;
  private _merchantNumber: string;
  private _showFilters: boolean;
  private _isInitializing: boolean;
  private _unbindOnRefreshHandler: () => any;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "$stateParams",
    "logitemListService",
    "searchHelperService",
    "$mdBottomSheet",
    "$rootScope",
    "toastHelper",
    "gettextCatalog"
  ];

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private logitemListService: LogitemListService,
    private searchHelperService: SearchHelperService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $rootScope: ng.IRootScopeService,
    private toastHelper: ToastHelperService,
    private gettextCatalog: ng.gettext.gettextCatalog
  ) {}

  get logitemGroups() {
    if (this._isInitializing) return [];
    try {
      return this.logitemListService.logitemGroups;
    } catch (e) {
      return null;
    }
  }

  get logitems() {
    if (this._isInitializing) return [];
    try {
      return this.logitemListService.logItems;
    } catch (e) {
      return null;
    }
  }

  get isInitializing() {
    return this._isInitializing;
  }

  $onInit() {
    this._isInitializing = true;

    this.load();

    this.initScrollHandler();

    this._unbindOnRefreshHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefresh()
    );
  }

  $onDestroy() {
    if (this._unbindOnRefreshHandler) this._unbindOnRefreshHandler();
    this._scrollContainer.off("scroll");
  }

  initScrollHandler() {
    this._scrollContainer = $("#container");
    this._scrollContainer.on("scroll", this.onScroll.bind(this));
  }

  onScroll(event: JQueryEventObject) {
    const newValue =
      this._scrollContainer[0].scrollHeight - this._scrollContainer.height() ===
      this._scrollContainer.scrollTop();

    if (newValue && !this._scrollValue && !this.scrollLoaderActive) {
      this.scrollLoaderActive = true;

      this.logitemListService
        .load(this._companyId, this._merchantNumber)
        .finally(() => {
          this.scrollLoaderActive = false;
        });
    }

    this._scrollValue = newValue;
  }

  load() {
    this.logitemListService
      .load(this._companyId, this._merchantNumber)
      .finally(() => (this._isInitializing = false));
  }

  onRefresh() {
    const refreshPromise = this.logitemListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The logitem list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The logitem list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  getTypeColor(logitem: webserviceModels.Common.Response.Logging.logitem) {
    if (logitem.result === false) return "#CC4547";

    if (logitem.logtype === "api") {
      const isError = Enumerable.from(
        (logitem as webserviceModels.Common.Response.Logging.ApiSpecificItem)
          .properties
      ).any(
        property =>
          property.key === "httpstatuscode" &&
          ((httpStatusCode: number) =>
            httpStatusCode < 200 && httpStatusCode > 299)(
            parseInt(property.value, 10)
          )
      );

      if (isError) return "#CC4547";
    }

    if (logitem.logtype === "event") {
      if (this.getAmountOfCallbackTries(logitem) > 0) {
        return "#EFCB47";
      }
    }

    switch (logitem.logtype) {
      case "api":
        return "#1C92B1";
      case "event":
        return "#41AF94";
      case "log":
        return "#537EB2";
      default:
        return null;
    }
  }

  getType(type: string) {
    switch (type) {
      case "api":
        return this.gettextCatalog.getString("API");
      case "event":
        return this.gettextCatalog.getString("Event");
      case "log":
        return this.gettextCatalog.getString("Application");
      default:
        return null;
    }
  }

  getLogitemProperty(
    logitem: webserviceModels.Common.Response.Logging.logitem,
    key: string
  ) {
    const property = Enumerable.from(logitem.properties).firstOrDefault(
      propertyX => propertyX.key === key,
      null
    );

    return (property && property.value) || null;
  }

  getHostName(url: string) {
    return urlParse(url).hostname;
  }

  getAmountOfCallbackTries(
    logitem: webserviceModels.Common.Response.Logging.EventSpecificItem
  ) {
    if (!logitem.children || !logitem.children.length) return 0;
    return logitem.children.length - 1;
  }

  toggleFilters() {
    if (this._showFilters) {
      this.$mdBottomSheet.hide();
    } else {
      this._showFilters = true;
      this.$mdBottomSheet
        .show({
          parent: angular.element("#overlay-container"),
          clickOutsideToClose: false,
          disableBackdrop: true,
          disableParentScroll: false,
          escapeToClose: false,
          template: `
            <md-bottom-sheet class="md-grid md-whiteframe-z1" layout="column">
                <logs-filter></logs-filter>
            </md-bottom-sheet>
          `
        } as any)
        .finally(() => (this._showFilters = false));
    }
  }

  getTime(timestamp: string) {
    return moment(timestamp).format("HH:mm:ss.SSS");
  }
}

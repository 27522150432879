import * as angular from "angular";
import { reflector } from "./reflector";

export function Component(options: {
  selector: string;
  controllerAs?: string;
  template?: string;
  templateUrl?: string;
  bindings?: any;
  transclude?: any;
  scope?: any;
  require?: any;
}) {
  return (controller: any) => {
    reflector.addComponentOptions(controller, options);
  };
}

import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";

export class WebUserListService {
  // tslint:disable-next-line:variable-name
  private _webUsers: Array<webserviceModels.Common.Response.WebUser.webuser>;
  private companyid: string;
  private merchantnumber: string;

  // tslint:disable-next-line:member-ordering
  static $inject = ["resellerService", "$q"];
  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService
  ) {}

  get webUsers(): Array<webserviceModels.Common.Response.WebUser.webuser> {
    if (this._webUsers) return this._webUsers;
    throw new ReferenceError("The web user list has not been initialized.");
  }

  load(
    companyid: string,
    merchantnumber: string,
    forceReload: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (
      !forceReload &&
      this.companyid === companyid &&
      this.merchantnumber === merchantnumber
    ) {
      deferred.resolve();
    } else {
      const response = this.resellerService.listwebusers(
        companyid,
        merchantnumber
      );

      response.then(
        success => {
          if (success.meta.result) {
            this.companyid = companyid;
            this.merchantnumber = merchantnumber;
            this._webUsers = success.webusers;
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._webUsers) {
      return this.load(this.companyid, this.merchantnumber, true);
    }

    throw new ReferenceError("The domain list has not been initialized.");
  }
}

export class AppContentAuthenticated implements ng.IDirective {
  restrict = "E";
  replace = true;
  template = require("./authenticated.html");
}

// tslint:disable-next-line:max-classes-per-file
export class AppContentUnauthenticated implements ng.IDirective {
  restrict = "E";
  replace = true;
  template = require("./unauthenticated.html");
}

import * as angular from "angular";
import * as Enumerable from "linq";

export interface IExtendedComponentOptions extends ng.IComponentOptions {
  selector: string;
}

export interface IReflector {
  addComponentOptions(controller: any, options: ng.IComponentOptions);
  getComponentOptions(controller: any): IExtendedComponentOptions;
}

export interface IReflectorMap {
  component: any;
  options: IExtendedComponentOptions;
}

class Reflector implements IReflector {
  // tslint:disable-next-line:variable-name
  private _options: Array<IExtendedComponentOptions> = [];

  addComponentOptions(component: any, options: IExtendedComponentOptions) {
    const opts = angular.extend({}, options, {
      controller: component
    }) as IExtendedComponentOptions;

    this._options.push(opts);
  }

  getComponentOptions(component: any): IExtendedComponentOptions {
    return Enumerable.from(this._options).first(
      option => option.controller === component
    );
  }
}

export const reflector = new Reflector();

export function addComponentToModule(module: ng.IModule, component: any) {
  const options = angular.copy(reflector.getComponentOptions(component));
  const selector = options.selector;

  delete options.selector;

  module.component(selector, options);
}

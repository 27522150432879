import { getFactoryFor } from "../../helpers/directive-helper";

export class AppBarAuthenticated implements ng.IDirective {
  restrict = "E";
  replace = true;
  template = require("./appbar-authenticated.html");
}

// tslint:disable-next-line:max-classes-per-file
export class AppBarUnauthenticated implements ng.IDirective {
  restrict = "E";
  replace = true;
  template = require("./appbar-unauthenticated.html");
}

export class ScrollLoader implements ng.IDirective {
  static $inject = ["scrollLoaderService"];
  constructor(private scrollLoaderService: ScrollLoaderService) {}

  // tslint:disable-next-line:member-ordering
  restrict = "A";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    scope.$watch(
      () => this.scrollLoaderService.active,
      (newValue, oldValue) => {
        if (newValue) instanceElement.show(200);
        else instanceElement.hide(200);
      }
    );
  };
}

// tslint:disable-next-line:max-classes-per-file
export class ScrollLoaderService {
  active: boolean = false;

  toggle() {
    this.active = !this.active;
  }
}

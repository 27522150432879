import $ from "jquery";
import * as angular from "angular";
import { Component } from "../shared/decorators";
import { PaymentGroupListService } from "./payment-group-list.service";

@Component({
  selector: "paymentGroupList",
  template: require("./payment-group-list.html")
})
export class PaymentGroupListComponent {
  static $inject = [
    "paymentGroupListService",
    "$mdBottomSheet",
    "paymentCollectionListService"
  ];
  constructor(
    private paymentGroupListService: PaymentGroupListService,
    private $mdBottomSheet: ng.material.IBottomSheetService
  ) {}

  $onInit() {
    if ((angular.element("#overlay-container")[0] as any).$mdGesture) {
      // tslint:disable-next-line:no-empty
      (angular.element("#overlay-container")[0] as any).$mdGesture = () => {};
    }
  }

  close() {
    this.$mdBottomSheet.hide();
  }

  get mappedPaymentTypes() {
    return this.paymentGroupListService.mappedPaymentTypes;
  }

  // tslint:disable-next-line:member-ordering
  sortablePaymentTypesOptions = {
    connectWith: ".drop-target",
    cursor: "move",
    cursorAt: { top: 32 },
    tolerance: "pointer",
    placeholder: "sortable-placeholder",
    start: (e, ui) => {
      $(".sortable-placeholder").css("height", ui.item.height());
    }
  };
}

export class StopPropagation implements ng.IDirective {
  static $inject = ["$timeout"];

  constructor(private $timeout: ng.ITimeoutService) {}

  // tslint:disable-next-line:member-ordering
  restrict = "A";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes
  ) => {
    instanceElement.on("keydown", (event: JQueryKeyEventObject) => {
      event.stopPropagation();
    });
  };
}

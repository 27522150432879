import $ from "jquery";
import "velocity-animate";
import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  DataService,
  TabHelperService,
  ToastHelperService,
  SearchHelperService,
  UserListService
} from "../shared/services";
import { CircularLoaderService } from "../shared/components/circular-loader/circular-loader.component";

export class UserListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "dataService",
    "userListService",
    "$mdDialog",
    "$mdToast",
    "gettextCatalog",
    "circularLoader",
    "tabHelper",
    "toastHelper",
    "searchHelperService",
    "$mdMedia",
    "$timeout",
    "$rootScope"
  ];

  constructor(
    private $scope: IUserListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private dataService: DataService,
    private userListService: UserListService,
    private $mdDialog: ng.material.IDialogService,
    private $mdToast: ng.material.IToastService,
    private gettextCatalog: any,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private toastHelper: ToastHelperService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private $rootScope: ng.IRootScopeService
  ) {
    tabHelper.currentTab = 0;

    $scope.userListService = userListService;
    $scope.searchHelperService = searchHelperService;

    let timeout = null;
    $scope.$watch("searchHelperService.searchQuery", newValue => {
      $timeout.cancel(timeout);
      timeout = $timeout(() => {
        userListService.load();
      }, 300);
    });

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./user-create-dialog.html"),
      controller: "userCreateController",
      controllerAs: "userCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  toggleStatus(user: webserviceModels.Common.Response.WebUser.webuser) {
    if (user.status === "active")
      this.userListService.deactivate(user.accesstoken);
    else if (user.status === "inactive")
      this.userListService.activate(user.accesstoken);
  }

  onRefreshClicked() {
    const refreshPromise = this.userListService
      .load(true)
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The user list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The user list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  delete(
    $event: MouseEvent,
    user: webserviceModels.Common.Response.WebUser.webuser,
    $index: number
  ) {
    this.$mdDialog.show({
      controller: "userDeleteController",
      controllerAs: "userDelete",
      template: require("./user-delete-dialog.html"),
      parent: angular.element(document.body),
      userName: user.name,
      userAccessToken: user.accesstoken,
      targetEvent: $event,
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        user: user,
        isListItem: true
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }
}

export interface IUserListScope extends ng.IScope {
  userListService: UserListService;
  searchHelperService: SearchHelperService;
}

import * as angular from "angular";

export class ResponseCodesFilterService {
  // tslint:disable-next-line:variable-name
  private _filter: {
    sources?: Array<string>;
    includeRelated?: boolean;
  } = {};

  get filter() {
    return this._filter;
  }

  clearAll() {
    angular.copy({}, this.filter);
  }
}

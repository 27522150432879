import { AuthenticationService } from "../services";
import "./sidenav-left.scss";

export interface ISidenavLeftScope {
  close: () => void;
  open: () => void;
  toggle: () => void;
  authenticationService: AuthenticationService;
  go: (state: string) => void;
  openStatusPage: () => void;
}

export class SidenavLeftController {
  static $inject = ["$scope", "$state", "$mdSidenav", "authenticationService"];

  constructor(
    private $scope: ISidenavLeftScope,
    private $state: ng.ui.IStateService,
    private $mdSidenav: ng.material.ISidenavService,
    private authenticationService: AuthenticationService
  ) {
    $scope.close = () => {
      this.$mdSidenav("sidenav-left").close();
    };

    $scope.open = () => {
      this.$mdSidenav("sidenav-left").open();
    };

    $scope.toggle = () => {
      this.$mdSidenav("sidenav-left").toggle();
    };

    $scope.authenticationService = authenticationService;

    $scope.go = (state: string) => {
      $state.go(state);
      $scope.close();
    };

    $scope.openStatusPage = () => {
      window.open("https://onlinestatus.bambora.com");
    };
  }
}

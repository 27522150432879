import "./logitem-details.scss";
import { Component } from "../../shared/decorators";
import * as webserviceModels from "../../shared/interfaces/webservice-models";

@Component({
  selector: "logitemDetails",
  template: require("./logitem-details.html"),
  bindings: {
    logitem: "<"
  }
})
export class LogitemDetailsComponent {
  logitem: webserviceModels.Common.Response.Logging.logitem;
  logAttributes: Array<{ key: string; value: string }> = [];

  // tslint:disable-next-line:member-ordering
  static $inject = ["gettextCatalog", "$mdDialog"];

  constructor(
    private gettextCatalog: ng.gettext.gettextCatalog,
    private $mdDialog: ng.material.IDialogService
  ) {}

  close() {
    this.$mdDialog.hide();
  }

  $onChanges(changes) {
    if (!changes || !changes.logitem || !changes.logitem.currentValue) return;

    const logitem: webserviceModels.Common.Response.Logging.logitem =
      changes.logitem.currentValue;

    this.logAttributes.push(
      { key: "logtype", value: logitem.logtype },
      { key: "logsubtype", value: logitem.logsubtype },
      { key: "message", value: logitem.message },
      { key: "longmessage", value: logitem.longmessage },
      { key: "timestamp", value: logitem.timestamp },
      { key: "id", value: logitem.id },
      { key: "result", value: logitem.result ? "true" : "false" },
      ...logitem.identifiers,
      ...logitem.properties
    );
  }
}

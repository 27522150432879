import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { ResellerService } from "../clients";
import { PaymentCollectionListService } from "./";

export class AgreementGroupListService {
  // tslint:disable-next-line:variable-name
  private _agreementGroups: Array<IAgreementGroup>;
  public companyid: string;
  public merchantnumber: string;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "resellerService",
    "$q",
    "$filter",
    "paymentCollectionListService",
    "$rootScope"
  ];

  constructor(
    private resellerService: ResellerService,
    private $q: ng.IQService,
    private $filter: ng.IFilterService,
    private paymentCollectionListService: PaymentCollectionListService,
    private $rootScope: ng.IRootScopeService
  ) {}

  get agreementGroups(): Array<IAgreementGroup> {
    if (this._agreementGroups) return this._agreementGroups;
    throw new ReferenceError(
      "The payment type fee list has not been initialized."
    );
  }

  load(
    companyid: string,
    merchantnumber: string,
    forceReload: boolean = false
  ): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (
      !forceReload &&
      this.companyid === companyid &&
      this.merchantnumber === merchantnumber
    ) {
      deferred.resolve();
    } else {
      this.$q
        .all({
          listpaymenttypefeesresponse: this.resellerService.listpaymenttypefees(
            companyid,
            merchantnumber
          ),
          listagreementsresponse: this.resellerService.listagreements(
            companyid,
            merchantnumber
          )
        })
        .then(
          success => {
            const listpaymenttypefeesresponse: webserviceModels.Common.Response.PaymentTypeFee.listpaymenttypefeesresponse =
              success.listpaymenttypefeesresponse;
            const listagreementsresponse: webserviceModels.Common.Response.Agreement.listagreementsresponse =
              success.listagreementsresponse;

            if (
              listpaymenttypefeesresponse.meta.result &&
              listagreementsresponse.meta.result
            ) {
              this._agreementGroups = this.createAgreementGroups(
                listpaymenttypefeesresponse.paymenttypefees,
                listagreementsresponse.agreements
              );
              this.companyid = companyid;
              this.merchantnumber = merchantnumber;
              deferred.resolve();
            } else {
              deferred.reject();
            }
          },
          error => {
            deferred.reject();
          }
        );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._agreementGroups) {
      return this.load(this.companyid, this.merchantnumber, true).then(() => {
        this._agreementGroups.forEach(agreementGroup => {
          agreementGroup.sync = false;
        });
      });
    }
    throw new ReferenceError(
      "The agreement group list has not been initialized."
    );
  }

  createPaymentTypeFee(
    request: webserviceModels.Reseller.Request.PaymentTypeFee.addpaymenttypefee,
    replacePos?: number
  ): ng.IPromise<
    webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
  > {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer<
      webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
    >();

    const agreementGroup = this.getAgreementGroup(
      request.paymenttypefee.agreementid
    );

    const patchArray = $.map(
      this.getAgreementGroup(request.paymenttypefee.agreementid)
        .paymentTypeFees,
      (paymentTypeFee, index) => {
        if (paymentTypeFee.id === undefined || paymentTypeFee.id === null) {
          const d = this.$q.defer();
          d.resolve();
          return d.promise;
        } else {
          return this.patchPaymentTypeFee(paymentTypeFee.id, {
            paymenttypefee: {
              order: index
            }
          } as any);
        }
      }
    );

    const orderResponse = this.$q.all(patchArray);

    orderResponse.then(
      allSucceeded => {
        const response = this.resellerService.addpaymenttypefee(
          this.companyid,
          this.merchantnumber,
          request
        );

        response.then(
          success => {
            if (success.meta.result) {
              if (replacePos === null || replacePos === undefined) {
                agreementGroup.paymentTypeFees.splice(
                  success.paymenttypefee.order,
                  0,
                  angular.copy(success.paymenttypefee)
                );
              } else {
                angular.copy(
                  success.paymenttypefee,
                  agreementGroup.paymentTypeFees[success.paymenttypefee.order]
                );
              }

              this.$rootScope.$broadcast(
                "agreementGroupListService.paymentTypeFeeAdded",
                success.paymenttypefee
              );
              deferred.resolve(
                agreementGroup.paymentTypeFees[success.paymenttypefee.order]
              );
            } else {
              try {
                deferred.reject(success.meta.message.merchant);
              } catch (e) {
                deferred.reject();
              }
            }
          },
          error => {
            try {
              deferred.reject(error.data.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        );
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  updatePaymentTypeFee(
    paymentTypeFeeId: string,
    request: webserviceModels.Common.Request.PaymentTypeFee.updatepaymenttypefee
  ): ng.IPromise<any> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.updatepaymenttypefee(
      this.companyid,
      this.merchantnumber,
      paymentTypeFeeId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          const agreementGroup = this.getAgreementGroup(
            request.paymenttypefee.agreementid
          );
          const paymentTypeFee = Enumerable.from(
            agreementGroup.paymentTypeFees
          ).first(paymentTypeFeeX => paymentTypeFeeX.id === paymentTypeFeeId);
          angular.extend(paymentTypeFee, success.paymenttypefee);
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  patchPaymentTypeFee(
    paymentTypeFeeId: string,
    request: webserviceModels.Common.Request.PaymentTypeFee.patchpaymenttypefee
  ): ng.IPromise<any> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.patchpaymenttypefee(
      this.companyid,
      this.merchantnumber,
      paymentTypeFeeId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.extend(
            Enumerable.from(this._agreementGroups)
              .selectMany(
                (agreementGroup: IAgreementGroup) =>
                  agreementGroup.paymentTypeFees
              )
              .first(paymentTypeFee => paymentTypeFee.id === paymentTypeFeeId),
            success.paymenttypefee
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  deletePaymentTypeFee(
    paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
  ): ng.IPromise<any> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.deletepaymenttypefee(
      this.companyid,
      this.merchantnumber,
      paymentTypeFee.id
    );

    response.then(
      success => {
        if (success.meta.result) {
          const agreementGroup = this.getAgreementGroup(
            paymentTypeFee.agreementid
          );
          agreementGroup.paymentTypeFees = Enumerable.from(
            agreementGroup.paymentTypeFees
          )
            .except([paymentTypeFee])
            .toArray();
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  createAgreement(
    request: webserviceModels.Common.Request.Agreement.addagreement
  ): ng.IPromise<webserviceModels.Common.Response.Agreement.agreement> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer<
      webserviceModels.Common.Response.Agreement.agreement
    >();
    const response = this.resellerService.addagreement(
      this.companyid,
      this.merchantnumber,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          try {
            Enumerable.from(this._agreementGroups)
              .first(
                agreementGroup =>
                  agreementGroup.currencyCode ===
                  success.agreement.currency.code
              )
              .agreements.push(success.agreement);
          } catch (e) {
            this._agreementGroups.push({
              currencyCode: success.agreement.currency.code,
              agreements: [success.agreement],
              paymentTypeFees: []
            });
          }
          this.$rootScope.$broadcast(
            "agreementGroupListService.agreementAdded",
            success.agreement
          );
          deferred.resolve(success.agreement);
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  updateAgreement(
    agreementId: string,
    request: webserviceModels.Common.Request.Agreement.updateagreement
  ): ng.IPromise<any> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.updateagreement(
      this.companyid,
      this.merchantnumber,
      agreementId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          const agreement = Enumerable.from(this._agreementGroups)
            .selectMany(agreementGroup => agreementGroup.agreements)
            .first(agreementX => agreementX.id === agreementId);

          if (agreement.currency.code !== success.agreement.currency.code) {
            const agreementGroup = Enumerable.from(
              this._agreementGroups
            ).first(agreementGroupX =>
              Enumerable.from(agreementGroup.agreements).any(
                a => a.id === agreementId
              )
            );

            agreementGroup.agreements = Enumerable.from(
              agreementGroup.agreements
            )
              .except([agreement])
              .toArray();

            try {
              Enumerable.from(this._agreementGroups)
                .first(
                  agreementGroupX =>
                    agreementGroupX.currencyCode ===
                    success.agreement.currency.code
                )
                .agreements.push(agreement);
            } catch (e) {
              this._agreementGroups.push({
                currencyCode: success.agreement.currency.code,
                agreements: [agreement],
                paymentTypeFees: []
              });
            }
          }

          angular.extend(agreement, success.agreement);

          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  patchAgreement(
    agreementId: string,
    request: webserviceModels.Common.Request.Agreement.patchagreement
  ): ng.IPromise<any> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.patchagreement(
      this.companyid,
      this.merchantnumber,
      agreementId,
      request
    );

    response.then(
      success => {
        if (success.meta.result) {
          angular.extend(
            Enumerable.from(this._agreementGroups)
              .selectMany(
                (agreementGroup: IAgreementGroup) => agreementGroup.agreements
              )
              .first(agreement => agreement.id === agreementId),
            success.agreement
          );
          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  deleteAgreement(
    agreement: webserviceModels.Common.Response.Agreement.agreement
  ): ng.IPromise<any> {
    if (!this._agreementGroups) {
      throw new ReferenceError(
        "The agreement group list has not been initialized."
      );
    }

    const deferred = this.$q.defer();
    const response = this.resellerService.deleteagreement(
      this.companyid,
      this.merchantnumber,
      agreement.id
    );

    response.then(
      success => {
        if (success.meta.result) {
          const agreementGroup = Enumerable.from(
            this._agreementGroups
          ).first(agreementGroupX =>
            Enumerable.from(agreementGroupX.agreements).any(
              a => a.id === agreement.id
            )
          );

          agreementGroup.agreements = Enumerable.from(agreementGroup.agreements)
            .except([agreement])
            .toArray();

          if (!agreementGroup.agreements.length) {
            this._agreementGroups = Enumerable.from(this._agreementGroups)
              .except([agreementGroup])
              .toArray();
          }

          deferred.resolve();
        } else {
          try {
            deferred.reject(success.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      },
      error => {
        try {
          deferred.reject(error.data.meta.message.merchant);
        } catch (e) {
          deferred.reject();
        }
      }
    );

    return deferred.promise;
  }

  private getAgreementGroup(agreementId: string): IAgreementGroup {
    return Enumerable.from(this._agreementGroups).first(agreementGroup =>
      Enumerable.from(agreementGroup.agreements).any(
        agreement => agreement.id === agreementId
      )
    );
  }

  private createAgreementGroups(
    paymentTypeFees: Array<
      webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
    >,
    agreements: Array<webserviceModels.Common.Response.Agreement.agreement>
  ) {
    const agreementGroups: Array<IAgreementGroup> = [];

    Enumerable.from(agreements)
      .groupBy(
        agreement => agreement.currency.code,
        null,
        (currencyCode, agreementsX) => ({
          currencyCode,
          agreements: agreementsX
        })
      )
      .forEach(agreementGroup => {
        const agreementPaymentTypeFees = Enumerable.from(paymentTypeFees)
          .where(paymentTypeFee =>
            Enumerable.from(agreements).any(
              agreement =>
                paymentTypeFee.agreementid === agreement.id &&
                agreement.currency.code === agreementGroup.currencyCode
            )
          )
          .toArray();

        agreementGroups.push({
          agreements: agreementGroup.agreements.toArray(),
          currencyCode: agreementGroup.currencyCode,
          paymentTypeFees: agreementPaymentTypeFees
        } as any);
      });

    return agreementGroups;
  }
}

export interface IAgreementGroup {
  agreements: Array<webserviceModels.Common.Response.Agreement.agreement>;
  paymentTypeFees: Array<
    webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
  >;
  currencyCode: string;
  sync?: boolean;
}

export default AgreementGroupListService;

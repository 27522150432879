import $ from "jquery";
import * as angular from "angular";
import * as Enumerable from "linq";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import { IAgreementGroup } from "../shared/interfaces";
import {
  AgreementGroupListService,
  AcquirerListService,
  PaymentCollectionListService,
  ToastHelperService,
  TabHelperService,
  SearchHelperService,
  SyncFABService
} from "../shared/services";
import { PaymentGroupListService } from "./payment-group-list.service";
import { CircularLoaderService } from "../shared/components/circular-loader";

export class PaymentTypeFeeListController {
  originalMappedPaymentTypes: Array<
    Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "$scope",
    "agreementGroupListService",
    "acquirerListService",
    "paymentCollectionListService",
    "gettextCatalog",
    "$mdDialog",
    "toastHelper",
    "circularLoader",
    "tabHelper",
    "searchHelperService",
    "paymentGroupListService",
    "syncFABService",
    "$mdMedia",
    "$filter",
    "$timeout",
    "$mdBottomSheet",
    "$rootScope"
  ];

  constructor(
    private $scope: IPaymentTypeFeeListScope,
    private agreementGroupListService: AgreementGroupListService,
    private acquirerListService: AcquirerListService,
    private paymentCollectionListService: PaymentCollectionListService,
    private gettextCatalog: any,
    private $mdDialog: ng.material.IDialogService,
    private toastHelper: ToastHelperService,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private searchHelperService: SearchHelperService,
    private paymentGroupListService: PaymentGroupListService,
    private syncFABService: SyncFABService,
    private $mdMedia: ng.material.IMedia,
    private $filter: any,
    private $timeout: ng.ITimeoutService,
    private $mdBottomSheet: ng.material.IBottomSheetService,
    private $rootScope: ng.IRootScopeService
  ) {
    tabHelper.currentTab = 1;

    $scope.agreementGroupListService = agreementGroupListService;
    $scope.searchHelperService = searchHelperService;

    $scope.agreementGroupListService.agreementGroups.forEach(agreementGroup => {
      agreementGroup.paymentTypeFees.sort((a, b) => a.order - b.order);
    });

    $scope.paymentGroups = Enumerable.from(
      paymentCollectionListService.paymentCollections
    )
      .selectMany(
        (
          paymentCollection: webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
        ) => paymentCollection.paymentgroups
      )
      .toArray();

    $scope.$watch(
      (scope: IPaymentTypeFeeListScope) => {
        return scope.received;
      },
      (newValue, oldValue, scope: IPaymentTypeFeeListScope) => {
        scope.received = false;
        if (newValue) {
          const newPaymentTypeFee = Enumerable.from(
            agreementGroupListService.agreementGroups
          )
            .selectMany(
              (agreementGroupX: IAgreementGroup) =>
                agreementGroupX.paymentTypeFees
            )
            .firstOrDefault(
              (
                paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
              ) => paymentTypeFee.id === null,
              null
            );

          const agreementGroup = Enumerable.from(
            agreementGroupListService.agreementGroups
          ).firstOrDefault(
            agreementGroupX =>
              Enumerable.from(agreementGroupX.paymentTypeFees).any(
                paymentTypeFee => paymentTypeFee === newPaymentTypeFee
              ),
            null
          );

          const noAgreementsAvailable = !Enumerable.from(
            agreementGroup.agreements
          ).any(
            agreement =>
              !this.agreementIsTaken(
                agreementGroup,
                agreement.id,
                newPaymentTypeFee.paymenttype.groupid.toString()
              )
          );

          if (noAgreementsAvailable) {
            agreementGroup.paymentTypeFees.splice(
              agreementGroup.paymentTypeFees.indexOf(newPaymentTypeFee),
              1
            );

            this.$mdDialog.show(
              this.$mdDialog
                .alert()
                .clickOutsideToClose(true)
                .title(
                  gettextCatalog.getString(
                    "The payment type could not be added"
                  )
                )
                .textContent(
                  gettextCatalog.getString(
                    "All {{paymentType}} payment types have already been added to all {{currencyCode}} agreements.",
                    {
                      paymentType: this.getPaymentGroup(
                        newPaymentTypeFee.paymenttype.groupid.toString()
                      ).displayname,
                      currencyCode: agreementGroup.currencyCode
                    }
                  )
                )
                .ok(gettextCatalog.getString("Got it!"))
            );
          } else {
            this.$mdDialog.show({
              template: require("./payment-type-fee-create.html"),
              controller: "paymentTypeFeeCreateController",
              controllerAs: "paymentTypeFeeCreate",
              locals: {
                newPaymentTypeFee,
                agreementGroup
              },
              parent: angular.element(document.body),
              escapeToClose: false,
              fullscreen: !$mdMedia("gt-md")
            } as any);
          }
        }
      }
    );

    agreementGroupListService.agreementGroups.forEach(
      (agreementGroup: IAgreementGroup, index: number) => {
        $scope.$watchCollection(
          (scope: IPaymentTypeFeeListScope) => {
            return agreementGroupListService.agreementGroups[index]
              .paymentTypeFees;
          },
          (newValue, oldValue, scope: IPaymentTypeFeeListScope) => {
            if (
              newValue.length === oldValue.length &&
              newValue !== oldValue &&
              !$scope.refreshed
            ) {
              Enumerable.from(agreementGroupListService.agreementGroups)
                .where(aG => aG.currencyCode === agreementGroup.currencyCode)
                .forEach(aG => {
                  aG.sync = true;
                });
              syncFABService.active = Enumerable.from(
                agreementGroupListService.agreementGroups
              ).any(agreementGroupX => agreementGroupX.sync);
            }
            if ($scope.refreshed) $scope.refreshed = false;
          }
        );
      }
    );

    $scope.sortablePaymentTypeFeesOptions = {
      zIndex: 39,
      revert: 200,
      axis: "y",
      containment: "parent",
      handle: ".draggable",
      tolerance: "pointer",
      receive: (e, ui) => {
        paymentGroupListService.reset();
        $scope.received = true;
      },
      placeholder: "sortable-placeholder",
      start: (e, ui) => {
        $mdBottomSheet.hide();
        $(".sortable-placeholder").css("height", ui.item.height());
      }
    };

    $scope.$on(
      "agreementGroupListService.paymentTypeFeeAdded",
      (
        event,
        paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
      ) => {
        $timeout(() => {
          $(`md-list div[name='${paymentTypeFee.id}']`)
            .velocity("scroll", {
              container: $("#container"),
              duration: 500,
              offset: -100
            })
            .velocity("callout.pulse");
        });
      }
    );

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.agreementGroupListService
      .refresh()
      .then(success => {
        this.$scope.agreementGroupListService.agreementGroups.forEach(
          agreementGroup => {
            agreementGroup.paymentTypeFees.sort((a, b) => a.order - b.order);
          }
        );

        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The payment type list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "The payment type list could not be updated at this time."
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .finally(() => {
        this.syncFABService.active = false;
        this.$scope.refreshed = true;
        this.$scope.received = false;
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  getPaymentGroup(
    paymentGroupId: string
  ): webserviceModels.Data.Response.ListPaymentTypes.paymentgroup {
    return Enumerable.from(this.paymentCollectionListService.paymentCollections)
      .selectMany(
        (
          paymentCollection: webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
        ) => paymentCollection.paymentgroups
      )
      .firstOrDefault(
        paymentGroup => paymentGroup.id.toString() === paymentGroupId
      );
  }

  agreementIsTaken(
    agreementGroup: IAgreementGroup,
    agreementId: string,
    paymentGroupId: string
  ): boolean {
    const paymentGroup = this.getPaymentGroup(paymentGroupId);

    return Enumerable.from(
      paymentGroup.paymenttypes
    ).all(
      (
        paymentType: webserviceModels.Data.Response.ListPaymentTypes.paymenttype
      ) =>
        Enumerable.from(agreementGroup.paymentTypeFees).any(
          paymentTypeFee =>
            paymentTypeFee.agreementid === agreementId &&
            paymentTypeFee.paymenttype.id === paymentType.id
        )
    );
  }

  mapPaymentCollectionsToPaymentTypeFees(
    paymentCollections: Array<
      webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
    >
  ): Array<
    Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
  > {
    const mappedPaymentTypeFees: Array<
      Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
    > = [];

    Enumerable.from(paymentCollections)
      .where(x => x.name === "paymentcard")
      .selectMany(
        (
          paymentCollection: webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
        ) => paymentCollection.paymentgroups
      )
      .forEach(
        (
          paymentGroup: webserviceModels.Data.Response.ListPaymentTypes.paymentgroup
        ) => {
          mappedPaymentTypeFees.push([
            {
              agreementid: null,
              agreementidvalue: null,
              feecalculatorname: null,
              feedata: {
                common: [{ key: "CurrencyCode", value: "" }],
                ranges: []
              },
              id: null,
              merchantnumber: null,
              order: null,
              paymenttype: {
                displayname: paymentGroup.displayname,
                groupid: paymentGroup.id,
                id: paymentGroup.id,
                name: paymentGroup.name
              },
              addfee: "Default",
              filters: []
            }
          ]);
        }
      );

    return mappedPaymentTypeFees;
  }

  viewFilters(
    $event: MouseEvent,
    paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee,
    currencyCode: string
  ) {
    const $mdDialog = this.$mdDialog;
    const headline = `Filter config for ${paymentTypeFee.paymenttype
      .displayname}`;
    const onSave = filters =>
      this.agreementGroupListService.patchPaymentTypeFee(paymentTypeFee.id, {
        paymenttypefee: {
          filters: filters
        }
      });

    this.$mdDialog.show({
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      controller: () => ({
        paymentTypeFee,
        headline,
        onSave,
        currencyCode
      }),
      controllerAs: "$ctrl",
      fullscreen: true,
      autoWrap: false,
      template: `
        <md-dialog layout="column" flex="100" flex-lg="50" flex-gt-lg="40">
          <condition-filter
            filterable="$ctrl.paymentTypeFee"
            headline="$ctrl.headline"
            on-save="$ctrl.onSave(filters)"
            currency-code="$ctrl.currencyCode">
          </condition-filter>
        </md-dialog>
      `
    });
  }

  getAcquirerNameByAgreementId(agreementId: string): string {
    const acquireName =
      Enumerable.from(this.$scope.agreementGroupListService.agreementGroups)
        .selectMany(agreementGroup => agreementGroup.agreements)
        .firstOrDefault(agreement => agreement.id === agreementId, null)
        .acquirername || "";

    return this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      acquireName
    );
  }

  getSecurityLevelString(securityLevel: string) {
    const securityLevelMap = {
      none: this.gettextCatalog.getString("None"),
      require3d: this.gettextCatalog.getString("3D required"),
      use3difpossible: this.gettextCatalog.getString("3D when possible"),
      require3dunlessdomestic: this.gettextCatalog.getString(
        "3D required for foreign cards"
      ),
      use3difpossibleunlessdomestic: this.gettextCatalog.getString(
        "3D when possible for foreign cards"
      )
    };

    return securityLevelMap[securityLevel.toLowerCase()];
  }

  getFeeString(
    paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee,
    agreementGroup: IAgreementGroup
  ): string {
    const currencyCode = Enumerable.from(
      paymentTypeFee.feedata.common
    ).firstOrDefault(keyValuePair => keyValuePair.key === "CurrencyCode");
    const percentage = Enumerable.from(
      paymentTypeFee.feedata.common
    ).firstOrDefault(keyValuePair => keyValuePair.key === "DefaultPercentage");
    const fixedFee = Enumerable.from(
      paymentTypeFee.feedata.common
    ).firstOrDefault(keyValuePair => keyValuePair.key === "DefaultFixedFee");
    const rangeFees = paymentTypeFee.feedata.ranges.length;

    if (
      percentage &&
      parseFloat(percentage.value) > 0 &&
      fixedFee &&
      parseInt(fixedFee.value, 10) > 0
    ) {
      if (rangeFees) {
        return this.gettextCatalog.getPlural(
          rangeFees,
          "With a {{fixedFee}} {{currencyCode}} + {{percentage}}% default fee and one ranged fee",
          "With a {{fixedFee}} {{currencyCode}} + {{percentage}}% default fee and {{rangeFees}} ranged fees",
          {
            fixedFee: this.$filter("bamboraCurrency")(
              parseInt(fixedFee.value, 10),
              "mask",
              { isoCurrencySymbol: currencyCode.value }
            ),
            currencyCode: currencyCode.value,
            percentage: parseFloat(percentage.value).toLocaleString(undefined, {
              maximumFractionDigits: 20
            }),
            rangeFees
          }
        );
      }

      return this.gettextCatalog.getString(
        "With a {{fixedFee}} {{currencyCode}} + {{percentage}}% fee",
        {
          fixedFee: this.$filter("bamboraCurrency")(
            parseInt(fixedFee.value, 10),
            "mask",
            { isoCurrencySymbol: currencyCode.value }
          ),
          currencyCode: currencyCode.value,
          percentage: parseFloat(percentage.value).toLocaleString(undefined, {
            maximumFractionDigits: 20
          })
        }
      );
    }

    if (
      percentage &&
      parseFloat(percentage.value) > 0 &&
      (!fixedFee || !(parseInt(fixedFee.value, 10) > 0))
    ) {
      if (rangeFees) {
        return this.gettextCatalog.getPlural(
          rangeFees,
          "With a {{percentage}}% default fee and one ranged fee",
          "With a {{percentage}}% default fee and {{rangeFees}} ranged fees",
          {
            currencyCode: currencyCode.value,
            percentage: parseFloat(percentage.value).toLocaleString(undefined, {
              maximumFractionDigits: 20
            }),
            rangeFees
          }
        );
      }

      return this.gettextCatalog.getString("With a {{percentage}}% fee", {
        fixedFee: this.$filter("bamboraCurrency")(
          parseInt(fixedFee.value, 10),
          "mask",
          { isoCurrencySymbol: currencyCode.value }
        ),
        currencyCode: currencyCode.value,
        percentage: parseFloat(percentage.value).toLocaleString(undefined, {
          maximumFractionDigits: 20
        })
      });
    }

    if (
      (!percentage || !(parseFloat(percentage.value) > 0)) &&
      fixedFee &&
      parseInt(fixedFee.value, 10) > 0
    ) {
      if (rangeFees) {
        return this.gettextCatalog.getPlural(
          rangeFees,
          "With a {{fixedFee}} {{currencyCode}} default fee and one ranged fee",
          "With a {{fixedFee}} {{currencyCode}} default fee and {{rangeFees}} ranged fees",
          {
            fixedFee: this.$filter("bamboraCurrency")(
              parseInt(fixedFee.value, 10),
              "mask",
              { isoCurrencySymbol: currencyCode.value }
            ),
            currencyCode: currencyCode.value,
            rangeFees
          }
        );
      }

      return this.gettextCatalog.getString(
        "With a {{fixedFee}} {{currencyCode}} fee",
        {
          fixedFee: this.$filter("bamboraCurrency")(
            parseInt(fixedFee.value, 10),
            "mask",
            { isoCurrencySymbol: currencyCode.value }
          ),
          currencyCode: currencyCode.value
        }
      );
    }

    if ((!fixedFee || parseInt(fixedFee.value, 10) === 0) && rangeFees) {
      return this.gettextCatalog.getPlural(
        rangeFees,
        "With no default fee and one ranged fee",
        "With no default fee and {{rangeFees}} ranged fees",
        {
          rangeFees
        }
      );
    }

    return null;
  }

  isAgreementDisabled(agreementId: string): boolean {
    return Enumerable.from(
      this.agreementGroupListService.agreementGroups
    ).any(agreementGroup =>
      Enumerable.from(agreementGroup.agreements).any(
        agreement =>
          agreement.id === agreementId &&
          agreement.status.toLowerCase() === "inactive"
      )
    );
  }

  edit(
    $event: MouseEvent,
    paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee,
    agreementGroup: IAgreementGroup
  ) {
    this.$mdDialog.show({
      template: require("./payment-type-fee-edit.html"),
      controller: "paymentTypeFeeEditController",
      controllerAs: "paymentTypeFeeEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        paymentTypeFee,
        agreementGroup
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  delete(
    $event: MouseEvent,
    paymentTypeFee: webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm Payment Type Removal"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to remove <i>{{paymenttypename}}</i>?",
          { paymenttypename: paymentTypeFee.paymenttype.displayname }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Delete"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      this.agreementGroupListService.deletePaymentTypeFee(paymentTypeFee).then(
        success => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{paymenttypename}} was successfully deleted.",
              { paymenttypename: paymentTypeFee.paymenttype.displayname }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{paymenttypename}} could not be deleted at this time.",
                { paymenttypename: paymentTypeFee.paymenttype.displayname }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
    });
  }
}

export interface IPaymentTypeFeeListScope extends ng.IScope {
  agreementGroupListService: AgreementGroupListService;
  sortablePaymentTypesOptions: ng.ui.UISortableOptions<any>;
  sortablePaymentTypeFeesOptions: ng.ui.UISortableOptions<any>;
  mappedPaymentTypes: Array<
    Array<webserviceModels.Common.Response.PaymentTypeFee.paymenttypefee>
  >;
  paymentGroups: Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymentgroup
  >;
  currencies: Array<webserviceModels.Common.currency>;
  received: boolean;
  refreshed: boolean;
  searchHelperService: SearchHelperService;
  paymentGroupListService: PaymentGroupListService;
}

export enum WebserviceType {
  LoginService = "loginservice",
  MerchantService = "merchantservice",
  TransactionService = "transactionservice",
  PaymentWindowService = "paymentwindowservice",
  ResellerService = "resellerservice",
  DataService = "dataservice",
  SubscriptionService = "subscriptionservice"
}

export default WebserviceType;

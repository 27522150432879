import * as angular from "angular";
import currencyData from "./data.bambora-currency";

// #region Constants
export const MAX_SAFE_INTEGER = Math.pow(2, 53) - 1;
export const MIN_SAFE_INTEGER = -MAX_SAFE_INTEGER;
export const DEFAULT_MASK_OPTIONS: IOptions = {
  action: "maskCurrency",
  isoCurrencySymbol: "USD",
  isoLanguageCode:
    /*window.navigator.userLanguage || window.navigator.language ||*/ "en-US",
  number: null,
  unitType: "minor"
};
export enum CharacterType {
  Number,
  GroupSeparator,
  DecimalSeparator,
  Undefined,
  Negation
}
// #endregion

function getRegionInfo(
  isoCurrencySymbol: string,
  isoLanguageCode: string
): IRegionInfo {
  let regionInfoArray = $.grep(
    currencyData,
    (regionInfo: IRegionInfo, i: number) => {
      return (
        regionInfo.currency.isoSymbol === isoCurrencySymbol &&
        regionInfo.language.code.indexOf(isoLanguageCode) !== -1
      );
    }
  );

  if (regionInfoArray.length === 0) {
    regionInfoArray = $.grep(
      currencyData,
      (regionInfo: IRegionInfo, i: number) => {
        return regionInfo.currency.isoSymbol === isoCurrencySymbol;
      }
    );
  }

  if (regionInfoArray.length === 0) {
    regionInfoArray = $.grep(
      currencyData,
      (regionInfo: IRegionInfo, i: number) => {
        return regionInfo.language.code.indexOf(isoLanguageCode) !== -1;
      }
    );
  }

  if (regionInfoArray.length === 0) {
    regionInfoArray = $.grep(
      currencyData,
      (regionInfo: IRegionInfo, i: number) => {
        return (
          regionInfo.currency.isoSymbol === "USD" &&
          regionInfo.language.code === "en-US"
        );
      }
    );
  }

  if (regionInfoArray.length === 0) $.error("Currency symbol not found.");

  return regionInfoArray[0];
}

function escapeRegExp(str: string) {
  return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
}

export function maskCurrency(options?: IOptions): string {
  options = $.extend({}, DEFAULT_MASK_OPTIONS, options);
  const regionInfo = getRegionInfo(
    options.isoCurrencySymbol,
    options.isoLanguageCode
  );

  return mask(
    options.number as number,
    regionInfo.currency.format.decimalDigits,
    regionInfo.currency.format.decimalSeparator,
    regionInfo.currency.format.groupSeparator,
    regionInfo.currency.format.groupSizes[0],
    options.unitType
  );
}

export function unmaskCurrency(options?: IOptions): number {
  options = $.extend({}, DEFAULT_MASK_OPTIONS, options);
  const regionInfo = getRegionInfo(
    options.isoCurrencySymbol,
    options.isoLanguageCode
  );

  return unmask(
    options.number as string,
    regionInfo.currency.format.decimalDigits,
    regionInfo.currency.format.decimalSeparator,
    regionInfo.currency.format.groupSeparator,
    regionInfo.currency.format.groupSizes[0],
    options.unitType
  );
}

function mask(
  num: number,
  decimalDigits: number,
  decimalSeparator: string,
  groupSeparator: string,
  groupSize: number,
  unitType: string = "minor"
): string {
  if ((num as any) === "" || (num as any) === "-" || num === null) {
    return null;
  }

  // Throw error if not number type
  if (typeof num !== "number") {
    if ($.isNumeric(num)) {
      let parsed: number;

      if (unitType === "minor") {
        parsed = parseInt(num as any, 10);
      } else {
        parsed = parseFloat(num as any);
        parsed = parseInt(parsed.toFixed(decimalDigits).replace(".", ""), 10);
      }

      // tslint:disable-next-line:use-isnan
      if (!Number.isNaN(parsed)) {
        num = parsed;
      } else {
        $.error("Could not be parsed as integer.");
      }
    } else {
      $.error("Is not a number type.");
    }
  } else if (unitType !== "minor") {
    num = Math.round(num * Math.pow(10, decimalDigits));
  }

  // Throw error if number is higher than max safe integer
  if (num > MAX_SAFE_INTEGER) $.error("The number is too large.");

  // Throw error if number is lower than min safe integer
  if (num < MIN_SAFE_INTEGER) $.error("The number is too small.");

  // Convert to array
  const length: number = num.toString().length;
  const arr: Array<string> = num.toString().split("");
  let negative: boolean = false;

  // Check if negative and remove negation character
  if (arr[0] === "-") {
    negative = true;
    arr.shift();
  }

  // Partion the number
  let integerPart: Array<string>;
  let fractionalPart: Array<string>;

  if (arr.length === decimalDigits) {
    integerPart = ["0"];
    fractionalPart = arr;
  } else if (arr.length < decimalDigits) {
    integerPart = ["0"];
    fractionalPart = arr;
    // Add leading zeros
    for (
      let i = 0, zeroQuantity = decimalDigits - arr.length;
      i < zeroQuantity;
      i++
    ) {
      fractionalPart.unshift("0");
    }
  } else {
    integerPart = arr.slice(0, arr.length - decimalDigits);
    fractionalPart = arr.slice(arr.length - decimalDigits);
  }

  // Calculate group separator quantity
  let numGroupSeparators: number = Math.floor(integerPart.length / groupSize);
  const modGroupSeparator: number = integerPart.length % groupSize;

  if (modGroupSeparator === 0) {
    numGroupSeparators--;
  }

  // Insert group separators
  if (numGroupSeparators > 0) {
    for (
      let i = 0,
        startPos: number =
          modGroupSeparator === 0 ? groupSize : modGroupSeparator;
      i < numGroupSeparators;
      i++
    ) {
      integerPart.splice(startPos + i * groupSize + i, 0, groupSeparator);
    }
  }

  // Add decimal separator and combine arrays to string
  let str: string =
    integerPart.join("") + decimalSeparator + fractionalPart.join("");

  // If negative number, add negation character
  if (negative) str = "-" + str;
  return str;
}

function unmask(
  // tslint:disable-next-line:variable-name
  number: string,
  decimalDigits: number,
  decimalSeparator: string,
  groupSeparator: string,
  groupSize: number,
  unitType: string = "minor"
): number {
  if (number === null || number === undefined) return null;

  // Throw error if not string
  if (typeof number !== "string") {
    $.error("The number must be of type string.");
  }

  // Throw error if not correctly formatted
  if (
    !new RegExp(
      "^(-?\\d{1," +
        groupSize +
        "}(" +
        escapeRegExp(groupSeparator) +
        "\\d{" +
        groupSize +
        "})*" +
        escapeRegExp(decimalSeparator) +
        "\\d{" +
        decimalDigits +
        "})?$"
    ).test(number)
  ) {
    $.error("The string is wrongly formatted.");
  }

  // Remove separators to get value in minor units
  number = number
    .replace(new RegExp(escapeRegExp(groupSeparator), "g"), "")
    .replace(decimalSeparator, "");

  // Throw error if not numeric (should not be possible at this point)
  if (!$.isNumeric(number)) {
    if (number === "" || number === NaN.toString()) {
      return null;
    } else $.error("The string could not be parsed as a number.");
  }

  // Parse the number
  let num = parseInt(number, 10);

  // Convert to floating point number if requested in major units
  if (unitType !== "minor") {
    num = parseFloat(
      (num / Math.pow(10, decimalDigits)).toFixed(decimalDigits)
    );
  }

  // Throw error if number is higher than max safe integer
  if (num > MAX_SAFE_INTEGER) $.error("The number is too large.");

  // Throw error if number is lower than min safe integer
  if (num < MIN_SAFE_INTEGER) $.error("The number is too small.");

  return num;
}

export function mapCaretPositionToUnmasked(
  caretPos: number,
  maskedLength: number,
  regionInfo: IRegionInfo,
  unmaskedLength?: number,
  negative = false
) {
  let unmaskedCaretPos: number = 0;
  let caretIsInDecimalPart: boolean = false;
  const neighborCharacterTypes = {
    left: CharacterType.Number,
    right: CharacterType.Number
  };

  const caretPosFromRight = maskedLength - caretPos;
  const fullDecimalPartLength =
    regionInfo.currency.format.decimalDigits +
    regionInfo.currency.format.decimalSeparator.length;
  const fullIntegerPartLength = maskedLength - fullDecimalPartLength;
  const fullGroupLength =
    regionInfo.currency.format.groupSizes[0] +
    regionInfo.currency.format.groupSeparator.length;
  const numGroups = Math.ceil(
    (maskedLength - fullDecimalPartLength) /
      (regionInfo.currency.format.groupSizes[0] +
        regionInfo.currency.format.groupSeparator.length)
  );

  // Calculate unmasked length or use argument if provided
  if (!unmaskedLength) {
    unmaskedLength = maskedLength;
    unmaskedLength -= regionInfo.currency.format.decimalSeparator.length;
    unmaskedLength -=
      (numGroups - 1) * regionInfo.currency.format.groupSeparator.length;
  }

  // If caret is after decimal separator:
  if (caretPosFromRight <= regionInfo.currency.format.decimalDigits) {
    unmaskedCaretPos = unmaskedLength - caretPosFromRight;
    caretIsInDecimalPart = true;

    if (caretPosFromRight === 0) {
      neighborCharacterTypes.right = CharacterType.Undefined;
    } else if (caretPosFromRight === regionInfo.currency.format.decimalDigits) {
      neighborCharacterTypes.left = CharacterType.DecimalSeparator;
    }
  } else if (caretPosFromRight < fullDecimalPartLength) {
    // If caret is in decimal separator (only when longer than 1 char):
    unmaskedCaretPos =
      unmaskedLength - regionInfo.currency.format.decimalDigits;
    caretIsInDecimalPart = true;

    neighborCharacterTypes.left = CharacterType.DecimalSeparator;
    neighborCharacterTypes.right = CharacterType.DecimalSeparator;
  } else {
    // If caret is before decimal separator:
    const caretPosFromDecimal = caretPosFromRight - fullDecimalPartLength;
    const unmaskedDecimalPartLength = regionInfo.currency.format.decimalDigits;

    unmaskedCaretPos =
      caretPosFromRight - regionInfo.currency.format.decimalSeparator.length;
    // tslint:disable-next-line:no-bitwise
    const numPreceedingGroupSeparators = ~~(
      caretPosFromDecimal / fullGroupLength
    );
    unmaskedCaretPos -=
      numPreceedingGroupSeparators *
      regionInfo.currency.format.groupSeparator.length;
    unmaskedCaretPos = unmaskedLength - unmaskedCaretPos;

    if (caretPosFromDecimal === 0) {
      neighborCharacterTypes.right = CharacterType.DecimalSeparator;
    } else {
      if (caretPosFromDecimal % fullGroupLength === 0) {
        neighborCharacterTypes.right = CharacterType.GroupSeparator;
      }
      if (fullGroupLength - caretPosFromDecimal % fullGroupLength === 1) {
        neighborCharacterTypes.left = CharacterType.GroupSeparator;
      }
      if (caretPos === 0) {
        neighborCharacterTypes.left = CharacterType.Undefined;
        neighborCharacterTypes.right = negative
          ? CharacterType.Negation
          : CharacterType.Number;
      }
      if (caretPos === 1 && negative) {
        neighborCharacterTypes.left = CharacterType.Negation;
      }
    }
  }

  return {
    pos: unmaskedCaretPos,
    isInDecimalPart: caretIsInDecimalPart,
    unmaskedLength,
    neighborCharacterTypes
  };
}

function getCaretPosition(instanceElement: JQuery): ICaretPosition {
  return {
    start: (instanceElement[0] as any).selectionStart,
    end: (instanceElement[0] as any).selectionEnd
  };
}

function setCaretPosition(instanceElement: JQuery, position: ICaretPosition) {
  (instanceElement[0] as any).selectionStart = position.start;
  (instanceElement[0] as any).selectionEnd = position.end;
}

function toMinorUnits(amount: number, regionInfo: IRegionInfo): number {
  return Math.round(
    amount * Math.pow(10, regionInfo.currency.format.decimalDigits)
  );
}

function liveFormat(
  event: JQueryEventObject,
  instanceElement: JQuery,
  controller: ng.INgModelController,
  options: IOptions,
  regionInfo: IRegionInfo
) {
  if (
    !(
      (event.which >= 35 && event.which <= 40) || // Home, end, arrow keys
      event.which === 17 || // Ctrl
      event.which === 9 || // Tab
      (event.shiftKey && event.which === 9) || // Shift + Tab
      (event.ctrlKey && (event.which === 65 || event.which === 67))
    ) /*|| event.which === 86*/ // Ctrl + { A | C | V }
  ) {
    event.preventDefault();

    const maskedArray = (controller.$viewValue || "").split("");
    const unmaskedArray = ((options.unitType === "minor"
      ? controller.$modelValue
      : toMinorUnits(controller.$modelValue, regionInfo)) || 0
    )
      .toString()
      .split("");

    const negative = maskedArray[0] === "-";
    const originalCaretPos = getCaretPosition(instanceElement);
    let newCaretPos = angular.copy(originalCaretPos);

    const unmaskedCaret = {
      start: mapCaretPositionToUnmasked(
        originalCaretPos.start,
        maskedArray.length,
        regionInfo,
        unmaskedArray.length,
        negative
      ),
      end: mapCaretPositionToUnmasked(
        originalCaretPos.end,
        maskedArray.length,
        regionInfo,
        unmaskedArray.length,
        negative
      )
    };

    const posDiff = unmaskedCaret.end.pos - unmaskedCaret.start.pos;

    // If start caret is in decimal part:
    if (unmaskedCaret.start.isInDecimalPart) {
      // Key: Delete
      if (event.which === 46) {
        if (
          controller.$modelValue === null ||
          controller.$modelValue === undefined
        ) {
          return;
        } else if (unmaskedArray.length === 1 && unmaskedArray[0] === "0") {
          controller.$setViewValue(null);
          controller.$render();
          return;
        }
        if (!posDiff) {
          if (
            unmaskedCaret.start.neighborCharacterTypes.right !==
            CharacterType.Undefined
          ) {
            if (unmaskedCaret.start.pos >= (negative ? 1 : 0)) {
              unmaskedArray.splice(unmaskedCaret.start.pos, 1);
            }
            unmaskedArray.push("0");
          }
          newCaretPos = originalCaretPos;
        } else {
          if (unmaskedCaret.start.pos >= (negative ? 1 : 0)) {
            for (let i = 0; i < posDiff; i++) {
              unmaskedArray.splice(unmaskedCaret.start.pos + i, 1, "0");
            }
          }
          newCaretPos = originalCaretPos;
          newCaretPos.start = newCaretPos.end;
        }
      } else if (event.which === 8) {
        // Key: Backspace
        if (
          controller.$modelValue === null ||
          controller.$modelValue === undefined
        ) {
          return;
        } else if (unmaskedArray.length === 1 && unmaskedArray[0] === "0") {
          controller.$setViewValue(null);
          controller.$render();
          return;
        }
        if (!posDiff) {
          if (
            unmaskedCaret.start.neighborCharacterTypes.left ===
            CharacterType.DecimalSeparator
          ) {
            newCaretPos.start =
              originalCaretPos.start -
              regionInfo.currency.format.decimalSeparator.length;
          } else {
            if (unmaskedCaret.start.pos > (negative ? 1 : 0)) {
              unmaskedArray.splice(unmaskedCaret.start.pos - 1, 1);
            }
            unmaskedArray.push("0");
            newCaretPos.start = originalCaretPos.start - 1;
          }
        } else {
          if (unmaskedCaret.start.pos >= (negative ? 1 : 0)) {
            for (let i = 0; i < posDiff; i++) {
              unmaskedArray.splice(unmaskedCaret.start.pos + i, 1, "0");
            }
          }
          newCaretPos = originalCaretPos;
        }
        newCaretPos.end = newCaretPos.start;
      } else if (
        (event.which >= 48 && event.which <= 57) ||
        (event.which >= 96 && event.which <= 105)
      ) {
        // Key: Any number
        const num =
          event.which >= 48 && event.which <= 57
            ? event.which - 48
            : event.which - 96;

        if (
          controller.$modelValue === null ||
          controller.$modelValue === undefined
        ) {
          unmaskedArray.splice(0, unmaskedArray.length);
          for (let i = 0; i < regionInfo.currency.format.decimalDigits; i++) {
            unmaskedArray.unshift("0");
          }
          unmaskedArray.unshift(num.toString());
          newCaretPos.start = 1;
        } else if (!posDiff) {
          if (
            unmaskedCaret.start.neighborCharacterTypes.right ===
            CharacterType.Undefined
          ) {
            unmaskedArray.pop();
            unmaskedArray.push(num.toString());
            newCaretPos = originalCaretPos;
          } else if (
            unmaskedArray.length >=
              regionInfo.currency.format.decimalDigits + (negative ? 1 : 0) ||
            unmaskedCaret.start.pos >= 0 + (negative ? 1 : 0)
          ) {
            unmaskedArray.splice(unmaskedCaret.start.pos, 1, num.toString());
            newCaretPos.start = originalCaretPos.start + 1;
          } else {
            if (negative) {
              for (let i = unmaskedCaret.start.pos + 1; i < 0; i++) {
                unmaskedArray.splice(1, 0, "0");
              }
              unmaskedArray.splice(1, 0, num.toString());
            } else {
              for (let i = unmaskedCaret.start.pos + 1; i < 0; i++) {
                unmaskedArray.unshift("0");
              }
              unmaskedArray.unshift(num.toString());
            }
            newCaretPos.start = originalCaretPos.start + 1;
          }
        } else {
          unmaskedArray.splice(unmaskedCaret.start.pos, 1, num);
          for (let i = 1; i < posDiff; i++) {
            unmaskedArray.splice(unmaskedCaret.start.pos + i, 1, "0");
          }
          newCaretPos.start = originalCaretPos.start + 1;
        }
        newCaretPos.end = newCaretPos.start;
      }
    } else {
      // If start caret is in integer part:
      // Key: Delete
      if (event.which === 46) {
        if (
          controller.$modelValue === null ||
          controller.$modelValue === undefined
        ) {
          return;
        } else if (unmaskedArray.length === 1 && unmaskedArray[0] === "0") {
          controller.$setViewValue(null);
          controller.$render();
          return;
        } else if (!posDiff) {
          if (unmaskedArray.length > regionInfo.currency.format.decimalDigits) {
            if (
              unmaskedCaret.start.neighborCharacterTypes.right ===
              CharacterType.Number
            ) {
              unmaskedArray.splice(unmaskedCaret.start.pos, 1);
              const opts =
                options.unitType === "minor"
                  ? angular.extend({}, options, {
                      number: parseInt(unmaskedArray.join(""), 10)
                    })
                  : angular.extend({}, options, {
                      number: (parseInt(unmaskedArray.join(""), 10) /
                        Math.pow(10, regionInfo.currency.format.decimalDigits)
                      ).toFixed(regionInfo.currency.format.decimalDigits)
                    });
              newCaretPos.start =
                originalCaretPos.start -
                (maskedArray.length - maskCurrency(opts).length - 1);
              if (negative && newCaretPos.start === 0) newCaretPos.start++;
            } else if (
              unmaskedCaret.start.neighborCharacterTypes.right ===
              CharacterType.GroupSeparator
            ) {
              newCaretPos.start =
                originalCaretPos.start +
                regionInfo.currency.format.groupSeparator.length;
            } else if (
              unmaskedCaret.start.neighborCharacterTypes.right ===
              CharacterType.DecimalSeparator
            ) {
              newCaretPos.start =
                originalCaretPos.start +
                regionInfo.currency.format.decimalSeparator.length;
            } else if (
              unmaskedCaret.start.neighborCharacterTypes.right ===
              CharacterType.Negation
            ) {
              newCaretPos.start = originalCaretPos.start + 1;
            }
          } else {
            newCaretPos.start = originalCaretPos.start + 1;
          }
        } else {
          if (unmaskedCaret.end.isInDecimalPart) {
            const endCaretPosFromRight =
              unmaskedArray.length - unmaskedCaret.end.pos;
            const decimalReplaceAmount =
              regionInfo.currency.format.decimalDigits - endCaretPosFromRight;

            unmaskedArray.splice(
              unmaskedCaret.start.pos,
              posDiff - decimalReplaceAmount
            );

            for (let i = 0; i < decimalReplaceAmount; i++) {
              unmaskedArray.splice(
                unmaskedArray.length -
                  endCaretPosFromRight -
                  (decimalReplaceAmount - i),
                1,
                "0"
              );
            }

            const opts =
              options.unitType === "minor"
                ? angular.extend({}, options, {
                    number: parseInt(unmaskedArray.join(""), 10)
                  })
                : angular.extend({}, options, {
                    number: (parseInt(unmaskedArray.join(""), 10) /
                      Math.pow(10, regionInfo.currency.format.decimalDigits)
                    ).toFixed(regionInfo.currency.format.decimalDigits)
                  });
            newCaretPos.start =
              originalCaretPos.end -
              (maskedArray.length - maskCurrency(opts).length - 1) -
              endCaretPosFromRight;
          } else {
            unmaskedArray.splice(unmaskedCaret.start.pos, posDiff);
            newCaretPos.start = originalCaretPos.start;
          }
        }
        newCaretPos.end = newCaretPos.start;
      } else if (event.which === 8) {
        // Key: Backspace
        if (
          controller.$modelValue === null ||
          controller.$modelValue === undefined
        ) {
          return;
        } else if (unmaskedArray.length === 1 && unmaskedArray[0] === "0") {
          controller.$setViewValue(null);
          controller.$render();
          return;
        }
        if (!posDiff) {
          if (
            unmaskedCaret.start.neighborCharacterTypes.left ===
            CharacterType.GroupSeparator
          ) {
            newCaretPos.start =
              originalCaretPos.start -
              regionInfo.currency.format.groupSeparator.length;
          } else if (
            unmaskedCaret.start.neighborCharacterTypes.left ===
            CharacterType.Negation
          ) {
            // do nothing
          } else if (
            unmaskedCaret.start.neighborCharacterTypes.left !==
              CharacterType.Undefined &&
            unmaskedCaret.start.pos > (negative ? 1 : 0)
          ) {
            unmaskedArray.splice(unmaskedCaret.start.pos - 1, 1);
            const opts =
              options.unitType === "minor"
                ? angular.extend({}, options, {
                    number: parseInt(unmaskedArray.join(""), 10)
                  })
                : angular.extend({}, options, {
                    number:
                      parseInt(unmaskedArray.join(""), 10) /
                      Math.pow(10, regionInfo.currency.format.decimalDigits)
                  });
            newCaretPos.start =
              originalCaretPos.start -
              (maskedArray.length - maskCurrency(opts).length);
            if (negative && newCaretPos.start === 0) newCaretPos.start++;
          }
        } else {
          if (unmaskedCaret.end.isInDecimalPart) {
            const endCaretPosFromRight =
              unmaskedArray.length - unmaskedCaret.end.pos;
            const decimalReplaceAmount =
              regionInfo.currency.format.decimalDigits - endCaretPosFromRight;
            const beforeLength = unmaskedArray.length;

            unmaskedArray.splice(
              unmaskedCaret.start.pos,
              posDiff - decimalReplaceAmount
            );

            for (let i = 0; i < decimalReplaceAmount; i++) {
              unmaskedArray.splice(
                unmaskedArray.length -
                  endCaretPosFromRight -
                  (decimalReplaceAmount - i),
                1,
                "0"
              );
            }

            const opts =
              options.unitType === "minor"
                ? angular.extend({}, options, {
                    number: parseInt(unmaskedArray.join(""), 10)
                  })
                : angular.extend({}, options, {
                    number:
                      parseInt(unmaskedArray.join(""), 10) /
                      Math.pow(10, regionInfo.currency.format.decimalDigits)
                  });
            newCaretPos.start =
              originalCaretPos.start -
              (maskedArray.length -
                maskCurrency(opts).length -
                (beforeLength - unmaskedArray.length));
            if (
              unmaskedCaret.start.neighborCharacterTypes.left !==
              CharacterType.GroupSeparator
            ) {
              newCaretPos.start +=
                regionInfo.currency.format.groupSeparator.length;
            }
            if (negative && newCaretPos.start === 0) newCaretPos.start++;
          } else {
            unmaskedArray.splice(unmaskedCaret.start.pos, posDiff);
            newCaretPos.start = originalCaretPos.start;
          }
        }
        newCaretPos.end = newCaretPos.start;
      } else if (
        (event.which >= 48 && event.which <= 57) ||
        (event.which >= 96 && event.which <= 105)
      ) {
        // Key: Any number
        if (
          unmaskedCaret.end.neighborCharacterTypes.right !==
          CharacterType.Negation
        ) {
          const num =
            event.which >= 48 && event.which <= 57
              ? event.which - 48
              : event.which - 96;

          if (posDiff) {
            if (unmaskedCaret.end.isInDecimalPart) {
              const endCaretPosFromRight =
                unmaskedArray.length - unmaskedCaret.end.pos;
              const decimalReplaceAmount =
                regionInfo.currency.format.decimalDigits - endCaretPosFromRight;

              unmaskedArray.splice(
                unmaskedCaret.start.pos,
                posDiff - decimalReplaceAmount
              );

              for (let i = 0; i < decimalReplaceAmount; i++) {
                unmaskedArray.splice(
                  unmaskedArray.length -
                    endCaretPosFromRight -
                    (decimalReplaceAmount - i),
                  1,
                  "0"
                );
              }
            } else {
              unmaskedArray.splice(unmaskedCaret.start.pos, posDiff);
            }
          }

          if (
            unmaskedArray.length >
            regionInfo.currency.format.decimalDigits + (negative ? 1 : 0)
          ) {
            unmaskedArray.splice(unmaskedCaret.start.pos, 0, num.toString());
            const opts =
              options.unitType === "minor"
                ? angular.extend({}, options, {
                    number: parseInt(unmaskedArray.join(""), 10)
                  })
                : angular.extend({}, options, {
                    number:
                      parseInt(unmaskedArray.join(""), 10) /
                      Math.pow(10, regionInfo.currency.format.decimalDigits)
                  });

            if (originalCaretPos.start === (negative ? 1 : 0)) {
              newCaretPos.start = originalCaretPos.start + 1;
            } else if (
              unmaskedCaret.start.neighborCharacterTypes.left ===
              CharacterType.GroupSeparator
            ) {
              newCaretPos.start =
                originalCaretPos.start +
                (maskCurrency(opts).length - maskedArray.length) -
                1;
            } else {
              newCaretPos.start =
                originalCaretPos.start +
                (maskCurrency(opts).length - maskedArray.length);
            }
          } else {
            if (negative) {
              for (
                let i =
                  unmaskedCaret.start.pos +
                  (originalCaretPos.start === 1 ? 1 : 0) -
                  1;
                i < 0;
                i++
              ) {
                unmaskedArray.splice(1, 0, "0");
              }
              unmaskedArray.splice(1, 0, num.toString());
              newCaretPos.start = 2;
            } else {
              for (
                let i =
                  unmaskedCaret.start.pos +
                  (originalCaretPos.start === 0 ? 1 : 0);
                i < 0;
                i++
              ) {
                unmaskedArray.unshift("0");
              }
              unmaskedArray.unshift(num.toString());
              newCaretPos.start = 1;
            }
          }

          if (posDiff) {
            newCaretPos.start = originalCaretPos.start + 1;
          }
        } else {
          newCaretPos.start = originalCaretPos.start + 1;
        }
        newCaretPos.end = newCaretPos.start;
      } else if (
        event.which === 32 ||
        event.which === 60 ||
        event.which === 188 ||
        event.which === 190 ||
        event.which === 222 ||
        event.which === 160 ||
        event.which === 192 ||
        event.which === 110 ||
        event.which === 107 ||
        event.which === 106 ||
        event.which === 111
      ) {
        // Key: Decimal point, dot, space, most special characters
        switch (unmaskedCaret.start.neighborCharacterTypes.right) {
          case CharacterType.DecimalSeparator: {
            newCaretPos.start =
              originalCaretPos.start +
              regionInfo.currency.format.decimalSeparator.length;
            break;
          }
          case CharacterType.GroupSeparator: {
            newCaretPos.start =
              originalCaretPos.start +
              regionInfo.currency.format.groupSeparator.length;
            break;
          }
        }
        newCaretPos.end = newCaretPos.start;
      }
    }

    // Key: Negation character
    if (event.which === 173 || event.which === 109) {
      if (negative) {
        unmaskedArray.shift();
        newCaretPos.start = originalCaretPos.start - 1;
      } else {
        unmaskedArray.unshift("-");
        newCaretPos.start = originalCaretPos.start + 1;
      }
      newCaretPos.end = newCaretPos.start;
    }

    // Key: Plus
    if ((event.which === 171 || event.which === 107) && negative) {
      unmaskedArray.shift();
      newCaretPos.start = originalCaretPos.start - 1;
      newCaretPos.end = newCaretPos.start;
    }

    let unmasked = parseInt(unmaskedArray.join(""), 10);
    if (options.unitType !== "minor") {
      unmasked = parseFloat(
        (unmasked / Math.pow(10, regionInfo.currency.format.decimalDigits)
        ).toFixed(regionInfo.currency.format.decimalDigits)
      );
    }
    options.number = unmasked;
    const masked = maskCurrency(options);

    controller.$setViewValue(masked);
    controller.$render();
    setCaretPosition(instanceElement, {
      start: newCaretPos.start,
      end: newCaretPos.end
    });
  }
}

export class BamboraCurrency implements ng.IDirective {
  restrict = "A";
  require = "ngModel";

  link: ng.IDirectiveLinkFn = (
    scope: ng.IScope,
    instanceElement: ng.IAugmentedJQuery,
    instanceAttributes: ng.IAttributes,
    controller: ng.INgModelController,
    transclude: ng.ITranscludeFunction
  ): void => {
    // var self = this;
    let instanceOptions: IOptions;
    let instanceRegion: IRegionInfo;

    instanceAttributes.$observe("bamboraCurrency", (value: string) => {
      updateOptions(scope.$eval(value));
      // controller.$setViewValue(formatModelValue(controller.$modelValue));
      // controller.$render();
    });

    scope.$watch(instanceAttributes.bamboraCurrency, updateOptions, true);

    bindHandlers();

    function bindHandlers() {
      // Mask unmasked number value to be displayed in the input field.
      controller.$formatters.unshift(formatModelValue);

      // Unmask masked input value to be stored in the model.
      controller.$parsers.unshift(parseViewValue);

      // To fix double key press on first press in some browsers.
      instanceElement.on("keypress", keyPressEventHandler);

      // This is where the magic happens.
      instanceElement.on("keydown", keyDownEventHandler);

      // Paste not yet implemented.
      instanceElement.on("paste", event => {
        event.preventDefault();
      });
    }

    function updateOptions(options: IOptions) {
      instanceOptions = instanceOptions || angular.copy(DEFAULT_MASK_OPTIONS);
      instanceRegion =
        instanceRegion ||
        angular.copy(
          getRegionInfo(
            instanceOptions.isoCurrencySymbol,
            instanceOptions.isoLanguageCode
          )
        );
      // options = angular.extend({}, DEFAULT_MASK_OPTIONS, options);

      const normalizedOptions: IOptions = {};
      Object.keys(DEFAULT_MASK_OPTIONS).forEach((key, index) => {
        normalizedOptions[key] =
          (options ? options[key] : undefined) ||
          instanceOptions[key] ||
          DEFAULT_MASK_OPTIONS[key];
      });

      const regionInfo = getRegionInfo(
        normalizedOptions.isoCurrencySymbol,
        instanceOptions.isoLanguageCode
      );

      angular.extend(instanceOptions, angular.copy(normalizedOptions));
      angular.extend(instanceRegion, angular.copy(regionInfo));

      if (controller.$modelValue) {
        controller.$setViewValue(formatModelValue(controller.$modelValue));
        controller.$render();
      }
    }

    function formatModelValue(modelValue: number): string | void {
      let masked: string;

      try {
        masked = maskCurrency(
          angular.extend({}, instanceOptions, { number: modelValue })
        );
      } catch (e) {
        return controller.$rollbackViewValue();
      }

      BamboraCurrency.validateValueConstraints(
        modelValue,
        scope,
        instanceAttributes,
        controller
      );
      return masked;
    }

    function parseViewValue(viewValue): number | void {
      let unmasked: number;

      try {
        unmasked = unmaskCurrency(
          angular.extend({}, instanceOptions, { number: viewValue })
        );
      } catch (e) {
        return controller.$rollbackViewValue();
      }

      BamboraCurrency.validateValueConstraints(
        unmasked,
        scope,
        instanceAttributes,
        controller
      );
      return unmasked;
    }

    function keyPressEventHandler(event: JQueryEventObject) {
      event.which = event.which || event.keyCode;

      if (
        !(
          (event.which >= 35 && event.which <= 40) || // Home, end, arrow keys
          event.which === 17 || // Ctrl
          event.which === 9 || // Tab
          (event.shiftKey && event.which === 9) || // Shift + Tab
          (event.ctrlKey && (event.which === 65 || event.which === 67))
        ) /*|| event.which === 86*/ // Ctrl + { A | C | V }
      ) {
        event.preventDefault();
      }

      instanceElement.off("keypress");
    }

    function keyDownEventHandler(event: JQueryEventObject) {
      try {
        liveFormat(
          event,
          instanceElement,
          controller,
          instanceOptions,
          instanceRegion
        );
      } catch (e) {
        // The number is out of bounds, invalid, or otherwise => catch exceptions and do nothing.
      }
    }
  };

  // tslint:disable-next-line:member-ordering
  static validateValueConstraints(
    value: number,
    scope: ng.IScope,
    instanceAttributes: ng.IAttributes,
    controller: ng.INgModelController
  ): any {
    if (value === undefined || value === null) {
      controller.$setValidity("bamboraMaxValue", true);
      controller.$setValidity("bamboraMinValue", true);
      return;
    }

    // tslint:disable-next-line:one-variable-per-declaration
    let minValue: number, maxValue: number;

    try {
      minValue = instanceAttributes.bamboraMinValue
        ? scope.$eval(instanceAttributes.bamboraMinValue)
        : MIN_SAFE_INTEGER;
    } catch (e) {
      minValue = MIN_SAFE_INTEGER;
    }

    try {
      maxValue = instanceAttributes.bamboraMaxValue
        ? scope.$eval(instanceAttributes.bamboraMaxValue)
        : MAX_SAFE_INTEGER;
    } catch (e) {
      maxValue = MAX_SAFE_INTEGER;
    }

    controller.$setValidity("bamboraMaxValue", value <= maxValue);
    controller.$setValidity("bamboraMinValue", value >= minValue);
  }

  // tslint:disable-next-line:member-ordering
  static factory(): ng.IDirectiveFactory {
    const directive = () => new BamboraCurrency();
    directive.$inject = [];
    return directive;
  }
}

angular
  .module("ngBamboraCurrency", [])
  .filter("bamboraCurrency", () => {
    return (
      input: number | string,
      action: string,
      options?: IOptions
    ): number | string => {
      if (action.toLowerCase() === "mask") {
        return maskCurrency(angular.extend({ number: input }, options));
      }

      if (action.toLowerCase() === "unmask") {
        return unmaskCurrency(angular.extend({ number: input }, options));
      }

      return input;
    };
  })
  .filter("bamboraCurrencyMask", () => {
    return (input: number | string, options?: IOptions): number | string => {
      return maskCurrency(angular.extend({ number: input }, options));
    };
  })
  .filter("bamboraCurrencyUnmask", () => {
    return (input: number | string, options?: IOptions): number | string => {
      return unmaskCurrency(angular.extend({ number: input }, options));
    };
  })
  .directive("bamboraCurrency", BamboraCurrency.factory());

// tslint:disable-next-line:interface-name
export interface Currency {
  currencyData: any;
  parseRelaxedJSON(relaxedJSON: string): any;
  init($sel: JQuery, options: IOptions): JQuery;
  maskCurrency(options?: IOptions): string;
  unmaskCurrency(options?: IOptions): number;
}

export interface ICaretPosition {
  start: number;
  end: number;
}

export interface IElement {
  maskCurrency(): void;
  unmaskCurrency(): void;
}

export interface IOptions {
  action?: string;
  number?: number | string;
  isoCurrencySymbol?: string;
  isoLanguageCode?: string;
  target?: string;

  /**
   * The type of the unmasked number.
   * "major" for major units as floating point number (i.e. "1,234.50" => 1234.5 or 12 => "12.00").
   * "minor" for minor units as integer (i.e. "1,234.50" => 123450 or 12 => "0.12").
   * If omitted, the minor unit type is assumed.
   */
  unitType?: string;
}

export interface ICurrencyFormat {
  name: string;
  code: string;
  numeric: string;
  major: string;
  minor: string;
  decimals: number;
  format: string;
}

export interface IRegionInfo {
  englishName: string;
  nativeName: string;
  threeLetterISORegionName: string;
  twoLetterISORegionName: string;
  language: {
    nativeName: string;
    englishName: string;
    code: string;
    threeLetterIsoLanguageName: string;
    twoLetterIsoLanguageName: string;
  };
  currency: {
    isoSymbol: string;
    englishName: string;
    nativeName: string;
    format: {
      decimalDigits: number;
      decimalSeparator: string;
      groupSeparator: string;
      groupSizes: Array<number>;
      symbol: string;
    };
  };
}

export function AnimateSlideLeftToRight(
  $animateCss: ng.animate.IAnimateCssService,
  $timeout: ng.ITimeoutService
): any {
  return {
    enter: (element: JQuery, doneFn: () => void) => {
      const offset = element.offset();
      const containerWidth = element.parents("#container").width();

      $timeout(() => {
        element.siblings(".containerView").offset(offset);

        element.velocity(
          {
            translateX: [0, -containerWidth],
            opacity: [1, 0]
          },
          containerWidth / Math.log(containerWidth) + 200,
          "ease-out",
          () => {
            doneFn();
          }
        );
      });
    },
    leave: (element: JQuery, doneFn: () => void) => {
      const containerWidth = element.parents("#container").width();

      element.velocity(
        {
          translateX: [containerWidth, 0],
          opacity: [0, 1]
        },
        containerWidth / Math.log(containerWidth) + 200,
        "ease-in",
        () => {
          doneFn();
        }
      );
    }
  };
}
AnimateSlideLeftToRight.$inject = ["$animateCss", "$timeout"];

export function AnimateSlideRightToLeft(
  $animateCss: ng.animate.IAnimateCssService,
  $timeout: ng.ITimeoutService
): any {
  return {
    enter: (element: JQuery, doneFn: () => void) => {
      const offset = element.offset();
      const containerWidth = element.parents("#container").width();

      $timeout(() => {
        element.siblings(".containerView").offset(offset);

        element.velocity(
          {
            translateX: [0, containerWidth],
            opacity: [1, 0]
          },
          containerWidth / Math.log(containerWidth) + 200,
          "ease-out",
          () => {
            doneFn();
          }
        );
      });
    },
    leave: (element: JQuery, doneFn: () => void) => {
      const containerWidth = element.parents("#container").width();

      element.velocity(
        {
          translateX: [-containerWidth, 0],
          opacity: [0, 1]
        },
        containerWidth / Math.log(containerWidth) + 200,
        "ease-in",
        () => {
          doneFn();
        }
      );
    }
  };
}
AnimateSlideRightToLeft.$inject = ["$animateCss", "$timeout"];

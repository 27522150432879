import * as Enumerable from "linq";
import * as webserviceModels from "../../interfaces/webservice-models";
import { DataService } from "../clients";

export class PaymentCollectionListService {
  // tslint:disable-next-line:variable-name
  private _paymentCollections: Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
  >;
  // tslint:disable-next-line:variable-name
  private _paymentGroups: Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymentgroup
  >;
  // tslint:disable-next-line:variable-name
  private _paymentTypes: Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymenttype
  >;

  // tslint:disable-next-line:member-ordering
  static $inject = ["dataService", "$q"];
  constructor(private dataService: DataService, private $q: ng.IQService) {}

  get paymentCollections(): Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymentcollection
  > {
    if (this._paymentCollections) return this._paymentCollections;
    throw new ReferenceError(
      "The payment collection has not been initialized."
    );
  }

  get paymentGroups(): Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymentgroup
  > {
    if (this._paymentGroups) return this._paymentGroups;
    throw new ReferenceError("The payment groups have not been initialized.");
  }

  get paymentTypes(): Array<
    webserviceModels.Data.Response.ListPaymentTypes.paymenttype
  > {
    if (this._paymentTypes) return this._paymentTypes;
    throw new ReferenceError("The payment types have not been initialized.");
  }

  load(forceReload: boolean = false): ng.IPromise<any> {
    const deferred = this.$q.defer();

    if (!forceReload && this._paymentCollections) {
      deferred.resolve();
    } else {
      const response = this.dataService.listpaymenttypes();

      response.then(
        success => {
          if (success.meta.result) {
            this._paymentCollections = success.paymentcollections;
            let paymentGroups = Enumerable.from(
              success.paymentcollections
            ).selectMany(paymentCollection => paymentCollection.paymentgroups);
            this._paymentGroups = paymentGroups.toArray();
            this._paymentTypes = paymentGroups
              .selectMany(paymentGroup => paymentGroup.paymenttypes)
              .toArray();
            deferred.resolve();
          } else {
            try {
              deferred.reject(success.meta.message.merchant);
            } catch (e) {
              deferred.reject();
            }
          }
        },
        error => {
          try {
            deferred.reject(error.data.meta.message.merchant);
          } catch (e) {
            deferred.reject();
          }
        }
      );
    }

    return deferred.promise;
  }

  refresh(): ng.IPromise<any> {
    if (this._paymentCollections) return this.load(true);
    throw new ReferenceError(
      "The payment collection has not been initialized."
    );
  }
}

export default PaymentCollectionListService;

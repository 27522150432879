import * as Enumerable from "linq";
import { Component } from "../../../../shared/decorators";
import * as webserviceModels from "../../../../shared/interfaces/webservice-models";
import {
  PaymentCollectionListService,
  AcquirerListService,
  ResponseCodeListService
} from "../../../../shared/services";

@Component({
  selector: "operationDetails",
  template: require("./operation-details.html"),
  bindings: {
    _operation: "<operation"
  }
})
export class OperationDetailsComponent {
  static $inject = [
    "$mdDialog",
    "paymentCollectionListService",
    "acquirerListService",
    "responseCodeListService"
  ];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private paymentCollectionListService: PaymentCollectionListService,
    private acquirerListService: AcquirerListService,
    private responseCodeListService: ResponseCodeListService
  ) {}

  // tslint:disable:member-ordering
  // tslint:disable-next-line:variable-name
  private _operation: webserviceModels.Merchant.transactionoperation;

  get operation() {
    return this._operation;
  }

  closeDialog() {
    this.$mdDialog.hide();
  }

  getPaymentTypeName(paymentType: webserviceModels.Common.paymenttype): string {
    const pType = Enumerable.from(
      this.paymentCollectionListService.paymentCollections
    )
      .selectMany(paymentCollection => paymentCollection.paymentgroups)
      .selectMany(paymentGroup => paymentGroup.paymenttypes)
      .firstOrDefault(pT => pT.id === paymentType.id);

    return pType ? pType.displayname : "";
  }

  get acquirerDisplayName() {
    let acquirerName = this.operation.acquirername;
    return this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      acquirerName
    );
  }

  getResponseCodeMerchantLabel(actionsource: string, actioncode: string) {
    let responseCode = this.responseCodeListService.getResponseCode(
      actionsource,
      actioncode
    );
    if (responseCode) {
      return responseCode.merchantlabel;
    }

    return null;
  }
}

import * as angular from "angular";
import * as Enumerable from "linq";
import { Component } from "../../../shared/decorators";
import * as webserviceModels from "../../../shared/interfaces/webservice-models";
import * as checkoutModels from "../../../shared/interfaces/checkout-models";
import {
  MerchantService,
  ToastHelperService,
  AcquirerListService,
  ResponseCodeListService
} from "../../../shared/services";

@Component({
  selector: "paymentOperations",
  template: require("./payment-operations.html"),
  bindings: {
    _operations: "<operations",
    isInitializing: "<",
    payment: "<"
  }
})
export class PaymentOperationsComponent {
  payment: webserviceModels.Merchant.transaction;
  isInitializing: boolean;

  // tslint:disable-next-line:variable-name
  private _operations: Array<webserviceModels.Merchant.transactionoperation>;

  // tslint:disable-next-line:member-ordering
  static $inject = [
    "$mdDialog",
    "$mdMedia",
    "merchantService",
    "acquirerListService",
    "toastHelper",
    "gettextCatalog",
    "responseCodeListService"
  ];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $mdMedia: ng.material.IMedia,
    private merchantService: MerchantService,
    private acquirerListService: AcquirerListService,
    private toastHelper: ToastHelperService,
    private gettextCatalog: ng.gettext.gettextCatalog,
    private responseCodeListService: ResponseCodeListService
  ) {}

  get operations() {
    return this._operations;
  }

  isInvoice() {
    return Enumerable.from(this._operations).any(
      operation => operation.acquirername.toLowerCase() === "collectorbank"
    );
  }

  showOperationDetails(
    operation: webserviceModels.Merchant.transactionoperation,
    $event: MouseEvent
  ) {
    this.$mdDialog.show({
      template: `
                <md-dialog class="modalDialog" flex="40" flex-gt-lg="30">
                    <operation-details operation="$ctrl.operation"></operation-details>
                </md-dialog>
            `,
      controller() {
        this.operation = operation;
      },
      controllerAs: "$ctrl",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    });
  }

  showInvoice(
    operation: webserviceModels.Merchant.transactionoperation,
    $event: MouseEvent
  ) {
    this.merchantService
      .getinvoice(this.payment.id, operation.id)
      .then(getInvoiceResponse => {
        this.$mdDialog.show({
          template: `
                        <md-dialog class="modalDialog" flex="40" flex-gt-lg="30">
                            <invoice-details invoice="$ctrl.invoice" operation="$ctrl.operation"></invoice-details>
                        </md-dialog>
                    `,
          controller() {
            this.invoice = getInvoiceResponse.invoice;
            this.operation = operation;
          },
          controllerAs: "$ctrl",
          targetEvent: $event,
          parent: angular.element(document.body),
          escapeToClose: false,
          clickOutsideToClose: false,
          fullscreen: !this.$mdMedia("gt-md")
        });
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The invoice could not be retrived at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });
  }

  getacquirerDisplayName(acquirerName: string) {
    return this.acquirerListService.getAcquirerDisplayNameFromAcquirerName(
      acquirerName
    );
  }

  getResponseCodeMerchantLabel(actionsource: string, actioncode: string) {
    let responseCode = this.responseCodeListService.getResponseCode(
      actionsource,
      actioncode
    );
    if (responseCode) {
      return responseCode.merchantlabel;
    }

    return null;
  }
}

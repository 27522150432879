export class IfState implements ng.IDirective {
  static $inject = ["$state", "$animate"];
  constructor(
    private $state: ng.ui.IStateService,
    private $animate: ng.animate.IAnimateService
  ) {}

  // tslint:disable:member-ordering
  multiElement = true;
  transclude: "element" = "element";
  priority = 600;
  terminal = true;
  restrict = "A";
  $$tlb = true;

  link: ng.IDirectiveLinkFn = (
    $scope: ng.IScope,
    $element: ng.IAugmentedJQuery,
    $attr: ng.IAttributes,
    ctrl: ng.IControllerService,
    $transclude: ng.ITranscludeFunction
  ) => {
    const $animate = this.$animate;
    // tslint:disable-next-line:one-variable-per-declaration
    let block, childScope, previousElements;
    $scope.$on(
      "$stateChangeSuccess",
      (
        event: ng.IAngularEvent,
        toState,
        toParams,
        fromState,
        fromParams,
        error
      ) => {
        if ((this.$state.$current as any).includes[$attr.ifState]) {
          if (!childScope) {
            $transclude((clone, newScope) => {
              childScope = newScope;
              (clone as any)[clone.length++] = document.createComment(
                " end ifState: " + $attr.ifState + " "
              );
              // Note: We only need the first/last node of the cloned nodes.
              // However, we need to keep the reference to the jqlite wrapper as it might be changed later
              // by a directive with templateUrl when its template arrives.
              block = {
                clone
              };
              $animate.enter(clone, $element.parent(), $element);
            });
          }
        } else {
          if (previousElements) {
            previousElements.remove();
            previousElements = null;
          }
          if (childScope) {
            childScope.$destroy();
            childScope = null;
          }
          if (block) {
            previousElements = IfState.getBlockNodes(block.clone);
            $animate.leave(previousElements).then(() => {
              previousElements = null;
            });
            block = null;
          }
        }
      }
    );
  };

  /**
   * Return the DOM siblings between the first and last node in the given array.
   * @param {Array} array like object
   * @returns {Array} the inputted object or a jqLite collection containing the nodes
   */
  static getBlockNodes = nodes => {
    let node = nodes[0];
    const endNode = nodes[nodes.length - 1];
    let blockNodes;

    // tslint:disable-next-line:no-conditional-assignment
    for (let i = 1; node !== endNode && (node = node.nextSibling); i++) {
      if (blockNodes || nodes[i] !== node) {
        if (!blockNodes) {
          blockNodes = $(Array.prototype.slice.call(nodes, 0, i));
        }
        blockNodes.push(node);
      }
    }

    return blockNodes || nodes;
  };
}

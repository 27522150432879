export class SidenavLeft implements ng.IDirective {
  restrict = "E";
  replace = true;
  template = require("../layout/sidenav-left.html");
}

// tslint:disable-next-line:max-classes-per-file
export class SidenavRight implements ng.IDirective {
  restrict = "E";
  replace = true;
  template = require("../layout/sidenav-right.html");
}

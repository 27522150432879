import $ from "jquery";
import * as angular from "angular";
import * as webserviceModels from "../shared/interfaces/webservice-models";
import {
  DomainListService,
  ToastHelperService,
  TabHelperService,
  SearchHelperService
} from "../shared/services";
import { CircularLoaderService } from "../shared/components/circular-loader/circular-loader.component";

export class DomainListController {
  static $inject = [
    "$scope",
    "$stateParams",
    "domainListService",
    "$mdDialog",
    "gettextCatalog",
    "toastHelper",
    "circularLoader",
    "tabHelper",
    "searchHelperService",
    "$mdMedia",
    "$timeout",
    "punycodeFilter",
    "$rootScope"
  ];

  constructor(
    private $scope: IDomainListScope,
    private $stateParams: ng.ui.IStateParamsService,
    private domainListService: DomainListService,
    private $mdDialog: ng.material.IDialogService,
    private gettextCatalog: any,
    private toastHelper: ToastHelperService,
    private circularLoader: CircularLoaderService,
    private tabHelper: TabHelperService,
    private searchHelperService: SearchHelperService,
    private $mdMedia: ng.material.IMedia,
    private $timeout: ng.ITimeoutService,
    private punycodeFilter: (...args) => string,
    private $rootScope: ng.IRootScopeService
  ) {
    tabHelper.currentTab = 5;

    $scope.domainListService = domainListService;
    $scope.searchHelperService = searchHelperService;

    const unbindOnRefreshClickedHandler = this.$rootScope.$on(
      "BamboraPartner:RefreshClicked",
      () => this.onRefreshClicked()
    );

    $scope.$on(
      "domainListService.itemAdded",
      (event, domain: webserviceModels.Common.Response.Domain.domain) => {
        $timeout(() => {
          $(`md-list div[name='${domain.id}']`)
            .velocity("scroll", {
              container: $("#container"),
              duration: 500,
              offset: -100
            })
            .velocity("callout.pulse");
        });
      }
    );

    $scope.$on("$destroy", () => {
      unbindOnRefreshClickedHandler();
    });
  }

  onRefreshClicked() {
    const refreshPromise = this.domainListService
      .refresh()
      .then(success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The domain list was successfully updated."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      })
      .catch(error => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "The domain list could not be updated at this time."
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      });

    this.$rootScope.$emit("BamboraPartner:RefreshInitiated", refreshPromise);
  }

  create($event: MouseEvent) {
    this.$mdDialog.show({
      template: require("./domain-create.html"),
      controller: "domainCreateController",
      controllerAs: "domainCreate",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      fullscreen: !this.$mdMedia("gt-md")
    });
  }

  edit(
    $event: MouseEvent,
    domain: webserviceModels.Common.Response.Domain.domain
  ) {
    this.$mdDialog.show({
      template: require("./domain-edit.html"),
      controller: "domainEditController",
      controllerAs: "domainEdit",
      targetEvent: $event,
      parent: angular.element(document.body),
      escapeToClose: false,
      clickOutsideToClose: false,
      locals: {
        companyid: this.$stateParams.companyid,
        domain
      },
      fullscreen: !this.$mdMedia("gt-md")
    } as any);
  }

  delete(
    $event: MouseEvent,
    domain: webserviceModels.Common.Response.Domain.domain
  ) {
    const confirm = this.$mdDialog
      .confirm()
      .title(this.gettextCatalog.getString("Confirm Domain Deletion"))
      .htmlContent(
        this.gettextCatalog.getString(
          "Are you sure want to delete the domain <i>{{domainname}}</i>?",
          { domainname: this.punycodeFilter(domain.name, "toUnicode") }
        )
      )
      .targetEvent($event)
      .ok(this.gettextCatalog.getString("Delete"))
      .cancel(this.gettextCatalog.getString("Cancel"));

    this.$mdDialog.show(confirm).then(confirmed => {
      this.domainListService.delete(domain).then(
        success => {
          this.toastHelper.toast(
            this.gettextCatalog.getString(
              "{{domainname}} was successfully deleted.",
              { domainname: this.punycodeFilter(domain.name, "toUnicode") }
            ),
            this.gettextCatalog.getString("Dismiss")
          );
        },
        error => {
          this.toastHelper.toast(
            error ||
              this.gettextCatalog.getString(
                "{{domainname}} could not be deleted at this time.",
                { domainname: this.punycodeFilter(domain.name, "toUnicode") }
              ),
            this.gettextCatalog.getString("Dismiss")
          );
        }
      );
    });
  }

  toggleDomainStatus(domain: webserviceModels.Common.Response.Domain.domain) {
    const request: webserviceModels.Common.Request.Domain.patchdomain = {
      domain: {
        status: domain.status === "active" ? "inactive" : "active"
      }
    };

    this.domainListService.patch(domain.id, request).then(
      success => {
        this.toastHelper.toast(
          this.gettextCatalog.getString(
            "{{domainname}} was successfully updated.",
            { domainname: this.punycodeFilter(domain.name, "toUnicode") }
          ),
          this.gettextCatalog.getString("Dismiss")
        );
      },
      error => {
        this.toastHelper.toast(
          error ||
            this.gettextCatalog.getString(
              "{{domainname}} could not be updated at this time.",
              { domainname: this.punycodeFilter(domain.name, "toUnicode") }
            ),
          this.gettextCatalog.getString("Dismiss")
        );
      }
    );
  }
}

export interface IDomainListScope extends ng.IScope {
  domainListService: DomainListService;
  searchHelperService: SearchHelperService;
}
